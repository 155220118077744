import { Params$CompanyInviteController_updateOneById } from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';

import { useApiClientMutation } from '@hooks';

const useUpdateCompanyInvite = () => {
  return useApiClientMutation<
    Params$CompanyInviteController_updateOneById,
    Schemas.User
  >({
    method: 'CompanyInviteController_updateOneById',
  });
};

export default useUpdateCompanyInvite;
