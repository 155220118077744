import { Flex, Typography } from 'antd';

import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';

type ItemType = {
  isempty?: boolean;
  isdragging?: boolean;
};

export const EmptyContainer = styled(Flex)`
  padding: 6px;
  margin-left: 8px;
  cursor: default;
`;

export const EmptyText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isempty',
})<ItemType>`
  color: ${({ theme, isempty }) =>
    isempty ? theme.colors.textDisabled : theme.colors.primary};
`;

export const SubItem = styled.div<ItemType>`
  opacity: ${({ isdragging }) => (isdragging ? 0 : 1)};
`;

export const SubItemContainer = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  padding: 6px;

  &:hover {
    background: ${({ theme }) => theme.colors.bgLightBlue200};
  }
`;

export const SubItemTitle = styled(Flex)`
  align-items: center;
`;

export const DotsContainer = styled.div`
  width: 20px;
`;

export const DotsWrapper = styled(Flex)`
  width: 20px;
  height: 14px;
  align-items: center;
  cursor: grab;
`;

export const SubItemActionsContainer = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  width: 48px;
`;

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.colors.primary};
`;

export const EditIcon = styled(EditOutlined)`
  color: ${({ theme }) => theme.colors.text400};
  cursor: pointer;
  width: 20px;
  height: 20px;
`;

export const DeleteIcon = styled(DeleteOutlined)`
  color: ${({ theme }) => theme.colors.error};
  cursor: pointer;
  width: 20px;
  height: 20px;
`;
