import { useMemo } from 'react';

import { Params$CompanyInviteController_acceptByToken } from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';
import { type QueryOptions, useApiClient } from '@hooks';

type CompanyInvite = Schemas.CompanyInvite;
type Params = Params$CompanyInviteController_acceptByToken;

type CompanyInviteProps = {
  params: Params['parameter'];
  config?: QueryOptions<CompanyInvite>;
};

const useGetCompanyInviteByToken = ({ config, params }: CompanyInviteProps) => {
  const {
    data: invite,
    loading,
    isError,
    failureCount,
    refetch,
  } = useApiClient<Params, CompanyInvite>({
    method: 'CompanyInviteController_acceptByToken',
    params: {
      parameter: params,
    },
    config,
  });

  return useMemo(
    () => ({
      invite,
      loading,
      isError,
      failureCount,
      refetch,
    }),
    [invite, loading, isError, failureCount, refetch]
  );
};

export default useGetCompanyInviteByToken;
