import { Schemas } from '@api-client/generated/types';

import { type QueryOptions, useApiClient } from '@hooks';

import { Params$ContactsController_findOneById } from '@api-client/generated/client';

type Contact = Schemas.Contact;
type Params = Params$ContactsController_findOneById;

type ContactProps = {
  params: Params['parameter'];
  config?: QueryOptions<Contact>;
};

const useGetContactById = ({ config, params }: ContactProps) => {
  const { data: contact, ...rest } = useApiClient<Params, Contact>({
    method: 'ContactsController_findOneById',
    params: {
      parameter: params,
    },
    config,
  });

  return {
    contact: contact || null,
    ...rest,
  };
};

export default useGetContactById;
