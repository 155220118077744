import styled from '@emotion/styled';

import { type ButtonDashedProps } from './ButtonDashed';

export const Button = styled.a<ButtonDashedProps>`
  width: ${({ block }) => (block ? '100%' : 'auto')};
  height: ${({ height }) => (height ? `${height}px` : '42px')};
  border: 1px dashed ${({ theme }) => theme.colors.primary};
  border-radius: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  font-weight: 600;
  padding: 0 70px;
`;
