import { FC } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { TransactionsTable } from '@entities';

import { Scrollbar } from '@components';

import { useTranslate } from '@hooks';

import * as S from './styled';

const ContactsTransactions: FC = () => {
  const { id: contactId } = useParams();
  const navigate = useNavigate();

  const { translate } = useTranslate();

  return (
    <Scrollbar height="calc(100vh - 385px)">
      <S.Container>
        <TransactionsTable
          hiddenColumns={['contact']}
          baseOptions={{
            contactIds: [contactId],
          }}
          filterParams={{
            controls: {
              left: [
                {
                  type: 'search',
                  formName: 'term',
                },
                {
                  type: 'divider',
                },
                {
                  type: 'module-filter',
                  controls: [
                    {
                      label: translate('filter.category.label'),
                      type: 'list-categories',
                      formName: 'categoryIds',
                    },
                    {
                      label: translate('filter.bank.label'),
                      type: 'list-banks',
                      formName: 'accountIds',
                    },
                    {
                      label: translate('filter.project.label'),
                      type: 'list-projects',
                      formName: 'projectIds',
                    },
                    {
                      label: translate(
                        'transactionsPage.label.withoutDocuments'
                      ),
                      type: 'switch',
                      formName: 'onlyWithoutFiles',
                    },
                    {
                      label: translate('transactionsPage.label.byPlDate'),
                      type: 'switch',
                      formName: 'showByPlDate',
                      isBoolean: true,
                    },
                  ],
                },
              ],
              right: [
                {
                  type: 'module-sort',
                },
              ],
            },
            withSearchParams: false,
            inversionTheme: true,
          }}
          onAfterContentClose={() => navigate(`/contacts/${contactId}`)}
          disabledConfig
          disabledRedirect
          enabledRequest
        />
      </S.Container>
    </Scrollbar>
  );
};

export default ContactsTransactions;
