import { FC } from 'react';
import { Trans } from 'react-i18next';

import { Typography, Button, Flex } from 'antd';

import { OnboardingMessage } from '@entities';
import { useTranslate, useOnboarding } from '@hooks';

import { MessageErrorDocuments } from '@assets';

import * as S from './styled';

const { Paragraph } = Typography;

const OnboardingMessageErrorDocuments: FC = () => {
  const { translate } = useTranslate();
  const { updateScreen, incorporationDetails } = useOnboarding();

  const handleReturn = () => {
    if (incorporationDetails?.isKycFailed) {
      updateScreen('UPLOAD_DOCUMENTS', { active: true });
      return;
    }

    if (incorporationDetails?.isAddressProofFailed) {
      updateScreen('PROVIDE_ADDRESS', { active: true });
      return;
    }
  };

  return (
    <S.Container>
      <OnboardingMessage
        picture={<MessageErrorDocuments />}
        title={translate('onboarding.messages.errorDocuments.title')}
        description={
          <Trans
            i18nKey={translate(
              'onboarding.messages.errorDocuments.description'
            )}
            components={[<Paragraph />]}
          />
        }
      />

      <Flex justify="flex-end">
        <Button type="primary" onClick={handleReturn}>
          {translate('onboarding.buttonReturnToUpload')}
        </Button>
      </Flex>
    </S.Container>
  );
};

export default OnboardingMessageErrorDocuments;
