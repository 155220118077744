import { Flex } from 'antd';

import { CutCornerCard } from '@entities';
import { useTranslate } from '@hooks';

import { Highlight } from '../Highlight';
import * as S from './styled';

export const BaseCornerCard = ({
  contact,
  description,
  formattedDate,
  formattedAmount,
  isArchived,
}: {
  contact?: React.ReactNode;
  description?: React.ReactNode;
  formattedAmount?: React.ReactNode;
  formattedDate: string;
  isArchived?: boolean;
}) => {
  const { translate } = useTranslate();

  return (
    <S.Wrapper>
      <CutCornerCard isFaded={isArchived} nonInteractive>
        <Flex gap={19} justify="space-between" vertical>
          <Flex gap={6} vertical>
            <S.CornerCardAdditionalTitle>
              {translate('common.cornerCard.paymentDocument')}
            </S.CornerCardAdditionalTitle>
            <S.CornerCardTitle>{contact || <>&nbsp;</>}</S.CornerCardTitle>
            <S.CornerCardDescription>
              {description || <>&nbsp;</>}
            </S.CornerCardDescription>
          </Flex>
          <S.Footer justify="space-between">
            <S.CornerCardPrice>
              {formattedAmount || <>&nbsp;</>}
            </S.CornerCardPrice>
            <S.CornerCardDate>{formattedDate}</S.CornerCardDate>
          </S.Footer>
        </Flex>
      </CutCornerCard>
    </S.Wrapper>
  );
};

export const CornerCard = ({
  contact,
  type,
  isArchived,
}: {
  contact?: string;
  type: 'condition' | 'action';
  isArchived?: boolean;
}) => {
  const { translate } = useTranslate();

  return (
    <BaseCornerCard
      contact={
        contact ? (
          <Highlight type={type}>{contact}</Highlight>
        ) : (
          translate('rules.rule.anyContact')
        )
      }
      description={translate('rules.rule.anyDescription')}
      formattedAmount="€XXX"
      formattedDate="DD.MM.YYYY"
      isArchived={isArchived}
    />
  );
};
