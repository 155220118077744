import styled from '@emotion/styled';

import { Typography } from 'antd';

const { Text } = Typography;

export const Amount = styled(Text)`
  font-weight: 500;
  white-space: nowrap;
`;
