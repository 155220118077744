import { FC } from 'react';

import { Flex, Typography, Button } from 'antd';

import { OnboardingCardHeader, OnboardingServicesBlock } from '@entities';
import { useTranslate, useOnboarding } from '@hooks';

import * as S from './styled';

const { Paragraph } = Typography;

const OnboardingReviewServices: FC = () => {
  const { translate } = useTranslate();

  const { updateScreen } = useOnboarding();

  const handleNext = () => {
    updateScreen('INVITE_SHAREHOLDERS_MANAGERS', { active: true });
  };

  return (
    <S.Container>
      <S.Inner>
        <OnboardingCardHeader
          title={translate('onboarding.reviewServices.title')}
          description={
            <>
              <Paragraph>
                {translate('onboarding.reviewServices.description')}
              </Paragraph>

              <Paragraph strong>
                {translate('onboarding.reviewServices.descriptionImportant')}
              </Paragraph>
            </>
          }
        />

        <OnboardingServicesBlock />
      </S.Inner>

      <Flex justify="flex-end">
        <Button type="primary" onClick={handleNext}>
          {translate('onboarding.buttonNext')}
        </Button>
      </Flex>
    </S.Container>
  );
};

export default OnboardingReviewServices;
