import { FC, useState, useEffect, ReactNode } from 'react';

import { Select as SelectLibrary, Flex, Button } from 'antd';

import { type SelectProps, Select } from '@components';
import { useTranslate } from '@hooks';
import { IconArrowDown, IconArrowUp } from '@assets';

import { DefaultOptionType } from 'antd/es/select';

import * as S from './styled';

type MultipleSelectProps = {
  options: DefaultOptionType[];
  value?: string[];
  onChange?: (
    option: string[] | DefaultOptionType | DefaultOptionType[]
  ) => void;
  placeholder?: string | ReactNode;
} & SelectProps &
  any;

type TagRender = SelectProps['tagRender'];

const MultipleSelect: FC<MultipleSelectProps> = ({
  options,
  value = [],
  onChange,
  placeholder,
  ...rest
}) => {
  const [tempSelectedItems, setTempSelectedItems] = useState<string[]>([]);
  const { translate } = useTranslate();
  useEffect(() => {
    setTempSelectedItems(value);
  }, [value]);

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const handleApply = () => {
    if (onChange) {
      onChange(tempSelectedItems as string[]);
      setIsDropdownVisible(false);
    }
  };

  const tagRender: TagRender = (props) => {
    const { label, closable, onClose, value } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };

    const onCloseTag: React.MouseEventHandler<HTMLElement> = (e) => {
      const newValue = tempSelectedItems.filter((item) => item !== value);
      if (onChange) {
        onChange(newValue);
      }
      onClose(e);
    };
    return (
      <S.Tag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onCloseTag}
      >
        {label}
      </S.Tag>
    );
  };

  return (
    <Select
      mode="multiple"
      value={tempSelectedItems}
      optionFilterProp="label"
      options={options}
      tagRender={tagRender}
      placeholder={placeholder ?? translate('common.dropdown.placeholder')}
      size="large"
      suffixIcon={isDropdownVisible ? <IconArrowUp /> : <IconArrowDown />}
      maxTagCount={1}
      open={isDropdownVisible}
      dropdownStyle={{ minWidth: '200px' }}
      maxTagPlaceholder={(count) =>
        `+${count.length} ${translate('common.dropdown.more')}`
      }
      dropdownRender={(menu) => (
        <Flex gap={8} vertical>
          {menu}

          <Button type="primary" size="small" onClick={handleApply} block>
            {translate('common.actions.apply')}
          </Button>
        </Flex>
      )}
      onDropdownVisibleChange={(open) => {
        setIsDropdownVisible(open);
        if (!open) {
          setTempSelectedItems(value);
        }
      }}
      onChange={setTempSelectedItems}
      {...rest}
    >
      {options.map(({ value, label }: DefaultOptionType) => (
        <SelectLibrary.Option size="large" key={value} value={value}>
          {label}
        </SelectLibrary.Option>
      ))}
    </Select>
  );
};

export default MultipleSelect;
