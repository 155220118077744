import styled from '@emotion/styled';
import { rgba } from 'emotion-rgba';
import { Link, type LinkProps } from 'react-router-dom';

export const Categories = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const Category = styled(Link)<LinkProps>`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 20px;
  box-shadow: 0 3px 15px 0 ${({ theme }) => rgba(theme.colors.dark, 0.1)};
  border: 1px solid ${({ theme }) => theme.colors.bgGrey};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
`;

export const CategoryIcon = styled.div`
  width: 72px;
  height: 72px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.colors.bgLightBlue};
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 25px;
  }
`;

export const CategoryName = styled.div`
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  flex: 1;
`;
