import { createRoot } from 'react-dom/client';
import { init } from '@sentry/react';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { initI18n } from './locales/i18n.config';

import 'antd/dist/reset.css';

import App from './App';

if (import.meta.env.MODE !== 'development') {
  init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    maxBreadcrumbs: 50,
    environment: import.meta.env.MODE,
  });
}

dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(quarterOfYear);
dayjs.extend(customParseFormat);

initI18n();

createRoot(document.getElementById('root')!).render(<App />);
