import { FC } from 'react';

import * as S from './styled';
import { Typography } from 'antd';
import { useTranslate } from '@hooks';

type EmptyDocumentsProps = {
  actionButton?: JSX.Element;
};

const EmptyDocuments: FC<EmptyDocumentsProps> = ({ actionButton }) => {
  const { translate } = useTranslate();
  return (
    <S.Container justify="center" align="center" vertical gap={15}>
      <S.IconContainer>
        <S.Icon />
      </S.IconContainer>
      <S.Title level={4}>
        {translate('documentsPage.messages.noDocuments')}
      </S.Title>
      <Typography.Text>
        {translate('documentsPage.messages.shouldAttach')}
      </Typography.Text>
      {actionButton}
    </S.Container>
  );
};

export default EmptyDocuments;
