import { FC } from 'react';

import { Form, Input, Button } from 'antd';

import { useTranslate } from '@hooks';
import { Schemas } from '@api-client/generated/types';

type ForgotPasswordFormProps = {
  isLoading?: boolean;
  onSubmit: (values: Schemas.LoginDto) => void;
};

const ForgotPasswordForm: FC<ForgotPasswordFormProps> = ({
  isLoading,
  onSubmit,
}) => {
  const { translate } = useTranslate();

  return (
    <Form onFinish={onSubmit} layout="vertical" requiredMark={false}>
      <Form.Item
        label={translate('auth.forgotPassword.email.label')}
        name="email"
        rules={[
          {
            required: true,
            message: translate('auth.forgotPassword.email.error'),
          },
        ]}
      >
        <Input
          placeholder={translate('auth.forgotPassword.email.placeholder')}
          size="large"
        />
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          size="large"
          shape="round"
          loading={isLoading}
          block
        >
          {translate('auth.forgotPassword.buttonSend')}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ForgotPasswordForm;
