import { FC } from 'react';

import { Helmet } from 'react-helmet';

type PageMetaProps = {
  title?: string;
};

const PageMeta: FC<PageMetaProps> = ({
  title,
}) => (
  <Helmet>
    <title>EasyBiz {title ? `| ${title}` : ''}</title>
  </Helmet>
);

export default PageMeta;