import { FC } from 'react';

import * as S from './styled';
import { useTranslate } from '@hooks';

type EmptyDocumentPreviewProps = {
  unsupported?: boolean;
};

const EmptyDocumentPreview: FC<EmptyDocumentPreviewProps> = ({
  unsupported = false,
}) => {
  const { translate } = useTranslate();
  return (
    <S.Container justify="center" align="center" vertical gap={15} flex={1}>
      <S.IconContainer>
        <S.Icon />
      </S.IconContainer>
      <S.Title level={4}>
        {translate('documentsPage.preview.noDocument')}
      </S.Title>
      <S.Description>
        {unsupported
          ? translate('documentsPage.preview.unsupported')
          : translate('documentsPage.preview.upload')}
      </S.Description>
    </S.Container>
  );
};

export default EmptyDocumentPreview;
