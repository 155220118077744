import { Link, type LinkProps } from 'react-router-dom';

import styled from '@emotion/styled';

export const Logotype = styled(Link)<LinkProps>`
  width: 100%;
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 100%;
    height: 22px;
  }
`;
