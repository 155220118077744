import accessDenied from './assets/access-denied.png';
import { GenericError } from '@entities/errorBoundary/GenericError';
import { useNavigate } from 'react-router-dom';

export const AccessDenied = () => {
  const navigate = useNavigate();

  return (
    <GenericError
      description="common.errors.accessDenied.pageDescription"
      imgSrc={accessDenied}
      title="common.errors.accessDenied.pageTitle"
      buttonText="common.errors.accessDenied.pageButton"
      onClick={() => navigate(-1)}
    />
  );
};
