import styled from '@emotion/styled';

import {
  Flex,
  Form,
  Modal,
  Pagination as AntdPagination,
  Typography,
} from 'antd';

import { IconEye } from '@assets';
import { SearchControl } from '@entities';

export const Item = styled(Form.Item)`
  margin-bottom: 16px;

  &&.ui-col,
  .ui-form-item-label {
    padding-bottom: 4px;
  }
`;

export const Wrapper = styled.div`
  width: 333px;
  height: 150px;
  position: relative;

  :before {
    content: '';
    width: 44px;
    height: 44px;
    background: transparent;
    border-left: 1px solid ${({ theme }) => theme.colors.bgDarkGrey};
    border-bottom: 1px solid ${({ theme }) => theme.colors.bgDarkGrey};
    border-top: none;
    border-right: none;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 6;
  }
`;

export const Preview = styled.div`
  min-width: 405px;
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TableContainer = styled(Flex)`
  width: 708px;
  height: 508px;
  min-height: 504px;

  padding-bottom: 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.bgDarkGrey};
`;

export const Search = styled(SearchControl)`
  && {
    width: 358px;
  }
`;

export const List = styled(Flex)`
  height: 508px;
  align-items: center;
  justify-content: center;
`;

export const ModalPaper = styled(Modal)`
  && {
    .ui-modal-content {
      display: flex;
      flex-direction: column;
      gap: 24px;

      .ui-modal-header {
        margin-bottom: 0;
      }

      .ui-modal-footer {
        margin-top: -4px;
      }
    }
  }
`;

export const Pagination = styled(AntdPagination)`
  .ui-pagination-item {
    border-radius: 50%;
  }

  .ui-pagination-item-active {
    background-color: ${({ theme }) => theme.colors.primary};

    & > a {
      color: ${({ theme }) => theme.colors.white};
    }

    & > a:hover {
      color: ${({ theme }) => theme.colors.white};
    }
  }
`;

export const Divider = styled.div`
  height: 35px;
  border-right: 2px solid ${({ theme }) => theme.colors.strokeDarkGrey};
  margin: 0 10px 0 5px;
`;

export const Text = styled(Typography.Text)`
  color: ${({ theme }) => theme.colors.text400};
  font-weight: 500;
`;

export const View = styled(IconEye)`
  color: ${({ theme }) => theme.colors.text400};
  width: 24px;
  height: 24px;

  :hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;
