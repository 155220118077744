import styled from '@emotion/styled';

import { Typography } from 'antd';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 56px;
  text-align: center;

  .ui-btn {
    padding-inline: 32px;
    font-size: 16px;
  }
`;

export const Icon = styled.div`
  width: 120px;
  height: 120px;
  background-color: ${({ theme }) => theme.colors.text200};
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;

  svg {
    width: 56px;
    height: 56px;
  }
`;

export const Title = styled(Typography.Title)`
  &.ui-typography {
    font-size: 22px;
    margin-bottom: 12px;
  }
`;

export const Description = styled(Typography.Paragraph)`
  &.ui-typography {
    font-size: 16px;
    margin-bottom: 40px;
  }
`;
