import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import * as S from './styled';

const LayoutAccessible: FC = () => (
  <S.Container>
    <Outlet />
  </S.Container>
);

export default LayoutAccessible;
