import styled from '@emotion/styled';

import { Layout, type LayoutProps } from 'antd';

import { layout } from '@theme';

type ContainerProps = {
  withPadding: boolean;
} & LayoutProps;

export const Container = styled(Layout, {
  shouldForwardProp: (prop) => prop !== 'withPadding',
})<ContainerProps>`
  min-height: 100vh;
  background-color: ${({ theme }) => theme.colors.bgLightBlue200};
  padding-left: ${({ withPadding }) =>
    withPadding ? `${layout.sidebarWidth}px` : 0};
`;

export const Content = styled(Layout.Content)<LayoutProps>`
  padding: 50px;
`;
