import { TreeSelect } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { CustomTagProps } from 'rc-select/lib/BaseSelect';
import { FC, useState } from 'react';

import { useAccountsController_getAccounts } from '@api-client/generated/AccountsController/getAccounts';
import { useCategoryGroupController_findAll } from '@api-client/generated/CategoryGroupController/findAll';
import { useContactsController_findAll } from '@api-client/generated/ContactsController/findAll';
import { useProjectController_findAll } from '@api-client/generated/ProjectController/findAll';
import { Select, type SelectProps } from '@components';
import { useAccount, useExtractCategoryGroups, useTranslate } from '@hooks';
import { selectFilterOption } from '@utils';

import * as S from './styled';

const { SHOW_PARENT } = TreeSelect;

type FilterCategory = SelectProps & {
  label?: string;
  dataVariant?:
    | 'contactsSelect'
    | 'categorySelect'
    | 'accountsSelect'
    | 'projectsSelect'
    | '';
  isFiltersModalOpen?: boolean;
};

const FilterCategory: FC<FilterCategory> = ({
  placeholder,
  onChange,
  label,
  dataVariant,
  value = [],
  isFiltersModalOpen,
  ...rest
}) => {
  const { companyId } = useAccount();
  const { translate } = useTranslate();

  const { data: categoryGroups } = useCategoryGroupController_findAll({
    params: {
      companyId: companyId!,
    },
    config: {
      enabled: isFiltersModalOpen,
    },
  });

  const { data: contacts } = useContactsController_findAll({
    params: {
      companyId: companyId!,
    },
    config: {
      enabled: isFiltersModalOpen,
    },
  });

  const { data: projects } = useProjectController_findAll({
    params: {
      companyId: companyId!,
    },
    config: {
      enabled: isFiltersModalOpen,
    },
  });

  const { data: accounts } = useAccountsController_getAccounts({
    params: {
      companyId: companyId!,
    },
    config: {
      enabled: isFiltersModalOpen,
    },
  });

  const { extractedGroupsWithChildren, findParentsOrChildren, findById } =
    useExtractCategoryGroups(categoryGroups);

  const [searchValue, setSearchValue] = useState('');

  const getData = () => {
    if (dataVariant) {
      switch (dataVariant) {
        case 'categorySelect':
          return extractedGroupsWithChildren.map((group) => ({
            title: group.title,
            value: [group.id, group.options?.map((option) => option.id)].join(
              ','
            ),
            children: group.options,
          }));
        case 'contactsSelect':
          return (contacts?.records || []).map((item) => ({
            label: item.name,
            value: item.id,
          }));
        case 'projectsSelect':
          return (projects || []).map((item) => ({
            label: item.name,
            value: item.id,
          }));
        case 'accountsSelect':
          return (accounts || []).map((item) => ({
            label: item.name || item.accountName,
            value: item.id,
          }));
        default:
          return [];
      }
    } else return [];
  };

  const getSelectedItemsCountDescription = () => {
    switch (dataVariant) {
      case 'categorySelect':
        return translate('common.filterCategory.counts.categories');
      case 'contactsSelect':
        return translate('common.filterCategory.counts.contacts');
      case 'accountsSelect':
        return translate('common.filterCategory.counts.banks');
      case 'projectsSelect':
        return translate('common.filterCategory.counts.projects');
      default:
        return translate('common.filterCategory.counts.categories');
    }
  };

  const renderValue = (props: CustomTagProps) => {
    const { label } = props;
    return value.length === 1 ? <S.SingleValue>{label}</S.SingleValue> : <></>;
  };

  const displayedValue =
    value.length > 1 && !searchValue.length
      ? `${value.length} ${getSelectedItemsCountDescription()}`
      : '';

  const filterSort = (
    firstOption: DefaultOptionType,
    secondOption: DefaultOptionType
  ) => {
    if (
      typeof firstOption?.label === 'string' &&
      typeof secondOption?.label === 'string'
    ) {
      return (firstOption?.label ?? '')
        ?.toLowerCase()
        .localeCompare((secondOption?.label ?? '').toLowerCase());
    }
    return 0;
  };

  const handleChange = (value: any, option: any) => {
    onChange?.(
      value,
      dataVariant === 'categorySelect' ? findById(value[0]) : option
    );
    setSearchValue('');
  };

  return (
    <S.SelectContainer data-testid={dataVariant}>
      <S.Label>{label}</S.Label>
      {dataVariant === 'categorySelect' ? (
        <S.SelectTree
          treeData={getData()}
          defaultValue={findParentsOrChildren(value)}
          value={value}
          onChange={handleChange}
          showCheckedStrategy={SHOW_PARENT}
          placeholder={placeholder}
          treeNodeFilterProp="label"
          treeCheckable
        />
      ) : (
        <>
          <Select
            className="ui-category-filter-select"
            filterOption={selectFilterOption}
            filterSort={filterSort}
            searchValue={searchValue}
            onSearch={setSearchValue}
            mode="multiple"
            size="large"
            defaultValue={value}
            value={value}
            tagRender={renderValue}
            onChange={handleChange}
            options={getData()}
            placeholder={placeholder}
            {...rest}
          />
          {value.length > 1 && (
            <S.DisplayedValue>{displayedValue}</S.DisplayedValue>
          )}
        </>
      )}
    </S.SelectContainer>
  );
};

export default FilterCategory;
