import { useMemo } from 'react';

import { Params$CompanyController_findOneById } from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';
import { type QueryOptions, useApiClient } from '@hooks';

type Company = Schemas.Company;
type Params = Params$CompanyController_findOneById;

type CompanyProps = {
  params: Params['parameter'];
  config?: QueryOptions<Company>;
};

const useGetCompanyById = ({ config, params }: CompanyProps) => {
  const {
    data: company,
    loading,
    refetch,
  } = useApiClient<Params, Schemas.Company>({
    method: 'CompanyController_findOneById',
    params: {
      parameter: params,
    },
    config,
  });

  return useMemo(
    () => ({
      company,
      loading,
      refetch,
    }),
    [company, loading, refetch]
  );
};

export default useGetCompanyById;
