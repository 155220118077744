import { FC } from 'react';

import { Flex } from 'antd';

import * as S from './styled';

const PaymentDocumentSkeleton: FC = () => (
  <S.Container justify="space-between" vertical>
    <Flex gap={87} vertical>
      <Flex align="center" justify="space-between">
        <S.Circle />

        <Flex gap={15} vertical>
          <S.Line width={153} />
          <S.Line width={153} />
          <S.Line width={153} />
        </Flex>
      </Flex>

      <Flex align="center" justify="space-between">
        <Flex gap={20} vertical>
          <S.Line width={126} />
          <S.Line width={126} />
          <S.Line width={126} />
        </Flex>

        <Flex gap={20} vertical>
          <S.Line width={69} />
          <S.Line width={69} />
          <S.Line width={69} />
        </Flex>

        <Flex gap={20} vertical>
          <S.Line width={69} />
          <S.Line width={69} />
          <S.Line width={69} />
        </Flex>

        <Flex gap={20} vertical>
          <S.Line width={69} />
          <S.Line width={69} />
          <S.Line width={69} />
        </Flex>
      </Flex>
    </Flex>

    <Flex gap={20} align="center" justify="flex-end">
      <S.Line width={158} />
      <S.Line width={72} />
    </Flex>
  </S.Container>
);

export default PaymentDocumentSkeleton;
