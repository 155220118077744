import styled from '@emotion/styled';

import { type RadioGroupProps } from './RadioGroup';

type ControlProps = {
  selected?: boolean;
  size?: RadioGroupProps['size'];
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Control = styled.div<ControlProps>`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid
    ${({ theme, selected }) =>
      selected ? theme.colors.primary : theme.colors.strokeGrey};
  border-radius: ${({ size }) => (size === 'large' ? '20px' : '11px')};
  flex: 1;
  display: flex;
  align-items: flex-start;
  gap: 16px;
  cursor: pointer;
  padding: ${({ size }) =>
    size === 'large' ? '20px 20px 20px 60px' : '20px 20px 20px 52px'};
  position: relative;
`;

export const Title = styled.div<ControlProps>`
  font-weight: 600;
  font-size: ${({ size }) => (size === 'large' ? '16px' : '14px')};
  margin: 0;
`;

export const Content = styled.div`
  margin-top: 10px;
`;

export const ControlIcon = styled.div<ControlProps>`
  min-width: 24px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid
    ${({ theme, selected }) =>
      selected ? theme.colors.primary : theme.colors.strokeGrey};
  position: absolute;
  top: 11px;
  left: 12px;
  top: ${({ size }) => (size === 'large' ? '20px' : '11px')};
  left: ${({ size }) => (size === 'large' ? '20px' : '12px')};

  &:after {
    content: '';
    width: 16px;
    height: 16px;
    background-color: ${({ theme, selected }) =>
      selected ? theme.colors.primary : 'transparent'};
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
