import { Layout } from 'antd';
import { FC, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';

import { Header, Sidebar } from '@entities';
import { useAccount } from '@hooks';
import { useGetUserData } from '@hooks-api';

import * as S from './styled';

const LayoutProtected: FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { isLogged, account, setAccount, company, setUserAccess } =
    useAccount();
  const { user, loading } = useGetUserData();

  const { boot } = useIntercom();

  useEffect(() => {
    if (user && !account) {
      setAccount(user);
    }

    if (user) {
      setUserAccess(user.companies[0]?.userAccessRights);
    }

    if (account) {
      if (account.intercomHash) {
        boot({
          email: account.email,
          name: account.fullName,
          userId: account.id,
          userHash: account.intercomHash,
        });
      }
    }
  }, [user, account, setAccount, setUserAccess, boot]);

  useEffect(() => {
    if (!isLogged) {
      navigate('/login');
    } else {
      if (company?.status === 'incorporating') {
        navigate('/start');
      }
    }
  }, [company?.status, isLogged, navigate]);

  if (!user || loading) {
    return null;
  }

  return (
    <S.Container>
      <Sidebar />

      <Layout>
        <Header />

        {pathname.includes('/transactions-import/') ? (
          <S.TransactionsImportContent>
            <Outlet />
          </S.TransactionsImportContent>
        ) : (
          <S.Content pathname={pathname}>
            <Outlet />
          </S.Content>
        )}
      </Layout>
    </S.Container>
  );
};

export default LayoutProtected;
