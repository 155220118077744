import { FC } from 'react';
import { Trans } from 'react-i18next';

import { Typography, Button, Flex } from 'antd';

import { OnboardingMessage } from '@entities';
import { useTranslate, useOnboarding } from '@hooks';

import { MessageProcessVerifyDocuments } from '@assets';

import * as S from './styled';

const { Paragraph, Text } = Typography;

const OnboardingMessageProcessVerifyDocuments: FC = () => {
  const { translate, tDynamic } = useTranslate();
  const { updateScreen, incorporationDetails } = useOnboarding();

  const handleContinue = () => {
    updateScreen('INCORPORATION', { active: true });
  };

  return (
    <S.Container>
      <OnboardingMessage
        picture={<MessageProcessVerifyDocuments />}
        title={tDynamic(
          `onboarding.messages.processVerifyDocuments.title_${incorporationDetails?.incorporationType}`
        )}
        description={
          <Trans
            i18nKey={tDynamic(
              `onboarding.messages.processVerifyDocuments.description_${incorporationDetails?.incorporationType}`
            )}
            components={[<Paragraph />, <Text strong />]}
          />
        }
      />

      <Flex justify="flex-end">
        <Button type="primary" onClick={handleContinue}>
          {translate('onboarding.buttonContinue')}
        </Button>
      </Flex>
    </S.Container>
  );
};

export default OnboardingMessageProcessVerifyDocuments;
