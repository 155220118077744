import { InputNumber, type InputNumberProps } from 'antd';

import styled from '@emotion/styled';

export const InputNumberMoney = styled(InputNumber)<InputNumberProps>`
  width: 100%;
  background-color: transparent;
  border-color: transparent;
  color: ${({ theme, defaultValue }) =>
    Number(defaultValue) > 0 ? theme.colors.success : theme.colors.error};
  font-weight: 500;
  font-size: 14px;

  .ui-input-number-input {
    color: ${({ theme, defaultValue }) =>
      Number(defaultValue) > 0 ? theme.colors.success : theme.colors.error};
    font-weight: 500;
    font-size: 14px;
  }
`;
