import { Button, Flex, Input } from 'antd';
import { FC } from 'react';

import { IconAttach } from '@assets';
import styled from '@emotion/styled';

const Container = styled(Flex)`
  border-radius: 10px;
  border: 1px solid var(--Dark-Grey);
  background: var(--White);
  padding: 16px;
`;

const Field = styled(Input.TextArea)`
  flex: 1;
  padding-inline: 0;
  padding-top: 0;

  ::placeholder {
    color: var(--Text-text-300);
  }
`;

const AttachFile = styled(IconAttach)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: var(--Grey-300);
`;

type AddMessageProps = {
  onAttach: VoidFunction;
  onChange: (value: string) => void;
  onSend: VoidFunction;
  value: string;
  isDisabled?: boolean;
};

const AddMessage: FC<AddMessageProps> = ({
  onAttach,
  onChange,
  onSend,
  value,
  isDisabled,
}) => (
  <Container align="top" gap={16}>
    <AttachFile onClick={onAttach} />

    <Field
      variant="borderless"
      size="large"
      placeholder={t('tasks.chat.fieldMessage.placeholder')()}
      value={value}
      onPressEnter={(e) => {
        e.preventDefault();
        onSend();
      }}
      onChange={(e) => onChange(e.target.value)}
      autoSize={{ minRows: 1, maxRows: 5 }}
    />

    <Button type="primary" onClick={onSend} size="small" disabled={isDisabled}>
      {t('chatLive.buttonSend')()}
    </Button>
  </Container>
);

export default AddMessage;
