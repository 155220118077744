import { Params$CompanyFileController_delete } from '@api-client/generated/client';

import { useApiClientMutation } from '@hooks';
import { Schemas } from '@api-client/generated/types';

const useDeleteCompanyFile = () => {
  return useApiClientMutation<Params$CompanyFileController_delete, Schemas.CompanyFile>({
    method: 'CompanyFileController_delete',
  });
};

export default useDeleteCompanyFile;
