//
// Generated by @himenon/openapi-typescript-code-generator v0.27.4
//
// OpenApi : 3.0.0
//
//
import {
  Params$DocumentController_updateOneById,
  Response$DocumentController_updateOneById$Status$200,
  ResponseContentType$DocumentController_updateOneById,
} from '@api-client/generated/client';
import { useApiClientMutation } from '@hooks/useApiClientMutation';

export const useDocumentController_updateOneById = () =>
  useApiClientMutation<
    Params$DocumentController_updateOneById,
    Response$DocumentController_updateOneById$Status$200[ResponseContentType$DocumentController_updateOneById]
  >({ method: 'DocumentController_updateOneById' });
