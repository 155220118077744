import { Button, Flex, Form } from 'antd';
import { FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useDocumentController_delete } from '@api-client/generated/DocumentController/delete';
import { useDocumentController_updateOneById } from '@api-client/generated/DocumentController/updateOneById';
import { IconTrash } from '@assets';
import { Language, currencyCodes } from '@constants';
import { InvoiceWrapperFormControl, PopupDelete } from '@entities';
import { useAccount, useTranslate } from '@hooks';

import * as S from './styled';

type InvoiceDto = any;

type InvoiceFormProps = {
  onSubmit?: (values: Partial<InvoiceDto>) => void;
};

const InvoiceForm: FC<InvoiceFormProps> = ({ onSubmit }) => {
  const { id: documentId } = useParams();
  const navigate = useNavigate();
  const { companyId } = useAccount();

  const { translate } = useTranslate();

  const [form] = Form.useForm();

  const [isOpenPopupConfirm, setIsOpenPopupConfirm] = useState(false);

  const [updateDocument] = useDocumentController_updateOneById();
  const [deleteDocument] = useDocumentController_delete();

  const handleSubmit = (values: InvoiceDto) => {
    onSubmit?.(values);
  };

  const handleDeleteDraft = () => {
    deleteDocument(
      {
        parameter: {
          companyId: companyId!,
          id: documentId!,
        },
      },
      {
        onSuccess: () => {
          navigate('/income');
        },
      }
    );
  };

  const handleDraftDocument = () => {
    updateDocument(
      {
        parameter: {
          companyId: companyId!,
          id: documentId!,
        },
        requestBody: {},
      },
      {
        onSuccess: () => {
          navigate('/income');
        },
      }
    );
  };

  return (
    <Flex gap={16} vertical>
      <PopupDelete
        open={isOpenPopupConfirm}
        title={translate('invoiceGenerator.deleteConfirm.title')}
        confirmParams={{
          text: translate('invoiceGenerator.deleteConfirm.buttonConfirm'),
        }}
        onRemove={handleDeleteDraft}
        onCancel={() => setIsOpenPopupConfirm(false)}
      />

      <S.Card>
        <S.Title>{translate('invoiceGenerator.draft.title')}</S.Title>

        <Form
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
          requiredMark={false}
          initialValues={{
            language: Language.En,
            currencyCode: 'EUR',
          }}
        >
          <InvoiceWrapperFormControl
            type="select"
            form={{
              label: translate('invoiceGenerator.draft.fieldLanguage.label'),
              name: 'language',
              rules: [
                {
                  required: true,
                  message: translate(
                    'invoiceGenerator.draft.fieldLanguage.placeholder'
                  ),
                },
              ],
            }}
            control={{
              placeholder: translate(
                'invoiceGenerator.draft.fieldLanguage.placeholder'
              ),
              options: [
                {
                  label: 'English',
                  value: Language.En,
                },
                {
                  label: 'Français',
                  value: Language.Fr,
                },
              ],
            }}
          />

          <InvoiceWrapperFormControl
            type="select"
            form={{
              label: translate('invoiceGenerator.draft.fieldCurrency.label'),
              name: 'currencyCode',
              rules: [
                {
                  required: true,
                  message: translate(
                    'invoiceGenerator.draft.fieldCurrency.placeholder'
                  ),
                },
              ],
            }}
            control={{
              placeholder: translate(
                'invoiceGenerator.draft.fieldCurrency.placeholder'
              ),
              options: currencyCodes.map((code) => ({
                label: code,
                value: code,
              })),
            }}
          />

          <S.Submit>
            <Form.Item noStyle>
              <Flex align="center" gap={8}>
                <Button
                  type="primary"
                  onClick={() => handleDraftDocument()}
                  block
                >
                  {translate('invoiceGenerator.draft.buttonSaveDraft')}
                </Button>

                <Button type="primary" block>
                  {translate('invoiceGenerator.draft.buttonFinalize')}
                </Button>
              </Flex>
            </Form.Item>
          </S.Submit>
        </Form>
      </S.Card>

      <S.ButtonDelete
        type="text"
        icon={<IconTrash />}
        onClick={() => setIsOpenPopupConfirm(true)}
        danger
      >
        {translate('invoiceGenerator.draft.buttonDeleteDraft')}
      </S.ButtonDelete>
    </Flex>
  );
};

export default InvoiceForm;
