import { FC, ReactNode } from 'react';

import * as S from './styled';

type OnboardingWelcomeProps = {
  title: string;
  description?: ReactNode;
  shorted?: boolean;
};

const OnboardingCardHeader: FC<OnboardingWelcomeProps> = ({
  title,
  description,
  shorted,
}) => (
  <S.Container shorted={shorted}>
    <S.Title level={3}>{title}</S.Title>
    {description && <S.Description>{description}</S.Description>}
  </S.Container>
);

export default OnboardingCardHeader;
