import styled from '@emotion/styled';
import { Flex } from 'antd';

export const Wrapper = styled(Flex)`
  height: 282px;
`;
export const IconWrapper = styled(Flex)`
  width: 64px;
  height: 64px;
  border-radius: 100%;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.text200};
`;

export const Title = styled.span`
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
`;

export const Description = styled.span`
  font-size: 12px;
  line-height: 20px;
`;
