import styled from '@emotion/styled';

import { Typography } from 'antd';

export const Wrapper = styled.div`
  max-width: 400px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 20px;
  box-shadow: 0 4px 24px 0 rgba(6, 24, 60, 0.08);
  padding: 24px 32px;
  flex: 1;
`;

export const Title = styled(Typography.Title)`
  &.ui-typography {
    font-size: 20px;
    margin-bottom: 22px;
  }
`;

export const InfoCell = styled.div`
  &:not(:last-child) {
    border-bottom: 2px solid ${({ theme }) => theme.colors.bgLightBlue};
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
`;

export const InfoLabel = styled.div`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.textBlue400};
`;

export const InfoValue = styled.div`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.black};
`;
