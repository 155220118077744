import styled from '@emotion/styled';
import { Flex } from 'antd';

export const Wrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 12px;
`

export const Item = styled(Flex)`
  margin-bottom: 19px;
  padding-bottom: 19px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.bgDarkGrey};
  
  :last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
`

export const Title = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
`

export const Description = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  width: 222px;
`

