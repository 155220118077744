import { FC } from 'react';

import { useAccount } from '@hooks';

import * as S from './styled';

const CompaniesSelect: FC = () => {
  const { companyId, companies, setCompany, userAccess } = useAccount();

  const getCompanyDetailsById = (id: string) =>
    companies.filter((company) => company.id === id)[0];

  const selectCompanyAndMaybeRedirect = (id: string) => {
    const company = getCompanyDetailsById(id);

    setCompany(company);

    if (company.status === 'in_service') {
      window.location.href = userAccess?.transactions
        ? '/transactions'
        : '/documents';
    } else {
      window.location.href = '/start';
    }
  };

  return (
    <S.Control
      width={175}
      value={companyId}
      options={companies.map((company) => ({
        label: company.name,
        value: company.id,
      }))}
      popupClassName="ui-dropdown-darken"
      onChange={(id) => selectCompanyAndMaybeRedirect(id)}
    />
  );
};

export default CompaniesSelect;
