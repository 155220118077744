import { Flex, Form } from 'antd';
import { useCallback } from 'react';

import { useTranslate } from '@hooks';

import RulesFormConstructor from './RulesFormConstructor/RulesFormConstructor';
import { Control, FormValues } from './utils';

const RulesForm = <
  TIfOptionValue extends string,
  TIfSelectorFieldName extends string,
  TIfOperatorFieldName extends string,
  TThenOptionValue extends string,
  TThenSelectorFieldName extends string,
  TThenOperatorFieldName extends string,
>({
  title,
  onChange,
  controls,
  formValues,
  additionalButton,
}: {
  controls: {
    if: Control<TIfOptionValue, TIfSelectorFieldName, TIfOperatorFieldName>;
    then: Control<
      TThenOptionValue,
      TThenSelectorFieldName,
      TThenOperatorFieldName
    >;
  };
  title: string;
  onChange: (
    formValues: Partial<{
      conditions: FormValues<
        TIfOptionValue,
        TIfSelectorFieldName,
        TIfOperatorFieldName
      >[];
      actions: FormValues<
        TThenOptionValue,
        TThenSelectorFieldName,
        TThenOperatorFieldName
      >[];
    }>
  ) => void;
  formValues: {
    conditions: FormValues<
      TIfOptionValue,
      TIfSelectorFieldName,
      TIfOperatorFieldName
    >[];
    actions: FormValues<
      TThenOptionValue,
      TThenSelectorFieldName,
      TThenOperatorFieldName
    >[];
  };
  additionalButton?: {
    title: string;
    onClick: VoidFunction;
  };
}) => {
  const { translate } = useTranslate();

  const onConditionChange = useCallback(
    (
      conditions: FormValues<
        TIfOptionValue,
        TIfSelectorFieldName,
        TIfOperatorFieldName
      >[]
    ) => onChange({ conditions }),
    [onChange]
  );

  const onActionsChange = useCallback(
    (
      actions: FormValues<
        TThenOptionValue,
        TThenSelectorFieldName,
        TThenOperatorFieldName
      >[]
    ) => onChange({ actions }),
    [onChange]
  );

  return (
    <Form autoComplete="off">
      <Flex gap={24} vertical>
        <RulesFormConstructor<
          TIfOptionValue,
          TIfSelectorFieldName,
          TIfOperatorFieldName
        >
          title={title}
          controls={controls.if}
          onChange={onConditionChange}
          addRowButtonTitle={translate('rules.modal.addCondition')}
          formValues={formValues['conditions']}
          additionalButton={additionalButton}
        />
        <RulesFormConstructor<
          TThenOptionValue,
          TThenSelectorFieldName,
          TThenOperatorFieldName
        >
          title={translate('rules.modal.then')}
          controls={controls.then}
          onChange={onActionsChange}
          addRowButtonTitle={translate('rules.modal.addAction')}
          formValues={formValues['actions']}
        />
      </Flex>
    </Form>
  );
};

export default RulesForm;
