import { FC, MouseEvent, ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Flex } from 'antd';

import { IconArrowLeft } from '@assets';
import { useTranslate } from '@hooks';

import * as S from './styled';

type GoBackProps = {
  name?: string;
  contentRight?: ReactNode;
  to?: string;
};

const GoBack: FC<GoBackProps> = ({ name, contentRight, to }) => {
  const { pathname } = useLocation();

  const navigate = useNavigate();
  const { translate } = useTranslate();

  const handleBack = (e: MouseEvent) => {
    e.preventDefault();

    const path = pathname.split('/');

    if (path.length) {
      navigate(to || `/${path[1]}`);
    } else {
      navigate(-1);
    }
  };

  return (
    <Flex align="center" justify="space-between">
      <S.Link onClick={handleBack}>
        <IconArrowLeft /> {translate('common.navigation.goBack')} {name}
      </S.Link>

      {contentRight}
    </Flex>
  );
};

export default GoBack;
