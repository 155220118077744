import { useContext } from 'react';

import { TransactionsImportContext } from '@context';

const useTransactionsImport = () => {
  const transactionsImport = useContext(TransactionsImportContext);

  return transactionsImport;
};

export default useTransactionsImport;
