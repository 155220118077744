import notFound from './assets/notFound.png';
import { GenericError } from '@entities/errorBoundary/GenericError';
import { useNavigate } from 'react-router-dom';

export const NotFoundError = () => {
  const navigate = useNavigate();

  return (
    <GenericError
      description="common.errors.notFound.pageDescription"
      imgSrc={notFound}
      title="common.errors.notFound.pageTitle"
      buttonText="common.errors.notFound.pageButton"
      onClick={() => navigate(-1)}
    />
  );
};
