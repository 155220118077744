import { FC } from 'react';

import { useTranslate } from '@hooks';
import { PaymentDocumentType } from '@entities';
import { IconFileCheck } from '@assets';

import * as S from './styled';

type PaymentDocumentNoInvoicesProps = {
  type: PaymentDocumentType;
  onDelete?: () => void;
};

const PaymentDocumentNoInvoices: FC<PaymentDocumentNoInvoicesProps> = ({
  type,
}) => {
  const { translate } = useTranslate();

  const translationPrefix = type === 'income' ? 'income' : 'expenses';

  return (
    <S.Container>
      <S.Icon>
        <IconFileCheck width={32} height={32} />
      </S.Icon>

      <S.Title>{translate(`${translationPrefix}.noInvoices.title`)}</S.Title>

      <S.Description>
        {translate(`${translationPrefix}.noInvoices.description`)}
      </S.Description>
    </S.Container>
  );
};

export default PaymentDocumentNoInvoices;
