import { Button } from 'antd';
import { FC, useCallback, useEffect, useState } from 'react';

import { useAccount, useOnboarding, useTranslate } from '@hooks';
import {
  useClaimSuccessfulPaymentIntent,
  useUpdateIncorporation,
} from '@hooks-api';
import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { StripePaymentElementOptions } from '@stripe/stripe-js';

import * as S from './styled';

const OnboardingCheckoutForm: FC = () => {
  const stripe = useStripe();
  const elements = useElements();
  const { companyId } = useAccount();

  const [message, setMessage] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const { translate, tDynamic } = useTranslate();
  const { updateScreen, updateIncorporation, paymentIntentSecret } =
    useOnboarding();
  const [update] = useUpdateIncorporation();

  const [claimSuccessfulPayment] = useClaimSuccessfulPaymentIntent();

  const updateIsPaying = useCallback(() => {
    update({
      parameter: {
        companyId: companyId!,
      },
      requestBody: {
        isPaying: true,
      },
    });
  }, [companyId, update]);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    if (!paymentIntentSecret) {
      return;
    }

    stripe
      .retrievePaymentIntent(paymentIntentSecret)
      .then(({ paymentIntent }) => {
        switch (paymentIntent!.status) {
          case 'succeeded':
            claimSuccessfulPayment(
              {
                parameter: {
                  companyId: companyId!,
                },
              },
              {
                onSuccess: () => {
                  updateIncorporation({ isPaid: true });
                  updateScreen('MESSAGE_PROCESS_PAYMENT_SENT', {
                    active: true,
                  });
                },
                onFailure: () => {
                  setMessage(tDynamic('onboarding.payment.errors.not_paid'));
                },
              }
            );
            break;
          case 'processing':
            updateIsPaying();
            updateIncorporation({ isPaying: true });
            updateScreen('MESSAGE_PROCESS_PAYMENT_SENT', { active: true });
            break;
          case 'requires_payment_method':
            // Show error
            setMessage(tDynamic('onboarding.payment.errors.not_paid'));
            break;
          default:
            // Show error
            setMessage(tDynamic('onboarding.payment.errors.unexpected'));
            break;
        }
      });
  }, [
    claimSuccessfulPayment,
    companyId,
    paymentIntentSecret,
    stripe,
    tDynamic,
    updateIncorporation,
    updateIsPaying,
    updateScreen,
  ]);

  const handleSubmit = async (event: Event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}${window.location.pathname}`,
      },
    });

    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message);
    } else {
      setMessage(tDynamic('onboarding.payment.errors.unexpected'));
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: 'tabs',
  } as StripePaymentElementOptions;

  return (
    <div>
      {message && <S.ErrorMessage message={message} type="error" />}
      <form onSubmit={(event: any) => handleSubmit(event)}>
        <PaymentElement id="payment-element" options={paymentElementOptions} />

        <S.Pay justify="flex-end">
          <Button
            type="primary"
            htmlType="submit"
            disabled={isLoading || !stripe || !elements}
          >
            {translate('onboarding.buttonPay')}
          </Button>
        </S.Pay>
      </form>
    </div>
  );
};

export default OnboardingCheckoutForm;
