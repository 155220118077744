import { Params$AuthController_confirmEmail } from '@api-client/generated/client';

import { useApiClientMutation } from '@hooks';

type Params = Params$AuthController_confirmEmail;

const useConfirmEmailByToken = () => {
  return useApiClientMutation<Params>({
    method: 'AuthController_confirmEmail',
  });
};

export default useConfirmEmailByToken;
