import { FC, JSXElementConstructor, ReactElement } from 'react';

import { AttachDropdownItem } from '@entities';
import { IconClose, IconTrash } from '@assets';
import { colors } from '@theme';
import { useTranslate } from '@hooks';

import { Schemas } from '@api-client/generated/types';
import { DefaultOptionType } from 'antd/es/select';

import * as S from './styled';

type AttachTemplateItemProps = {
  isListLoading: boolean;
  documentsList: Schemas.Document[];
  onSearch: (value: string) => void;
  onAttach: (transaction: Schemas.Transaction) => void;
  onDelete: () => void;
};

const AttachTemplateItem: FC<AttachTemplateItemProps> = ({
  documentsList,
  isListLoading,
  onAttach,
  onSearch,
  onDelete,
}) => {
  const { translate } = useTranslate();
  const options = documentsList?.map((document) => ({
    ...document,
    label: document?.contact?.name || document?.name,
    value: document.id,
  }));

  const dropdownRender = (
    menu: ReactElement<any, string | JSXElementConstructor<any>>
  ) => <div className="ui-attach-select-dropdown">{menu}</div>;

  const optionRender = (oriOption: DefaultOptionType) => (
    <AttachDropdownItem
      item={oriOption.data as Schemas.Document}
      width="100%"
      height="121px"
      key={oriOption.key}
    />
  );

  const onChange = (
    _: string,
    option: DefaultOptionType | DefaultOptionType[]
  ) => onAttach?.(option as Schemas.Transaction);

  const clearOptions = {
    clearIcon: <IconClose width={20} height={20} />,
  };

  return (
    <S.Wrapper vertical>
      <S.Content gap={16} vertical>
        <S.Title>{translate('income.selectTransactionText')}</S.Title>

        <S.ListSelect
          options={options}
          onSearch={onSearch}
          className="ui-attach-select"
          placeholder={translate('common.cornerCard.searchPlaceholder')}
          onChange={onChange}
          dropdownRender={dropdownRender}
          optionRender={optionRender}
          allowClear={clearOptions}
          loading={isListLoading}
          filterOption={false}
          defaultOpen
          showSearch
          autoFocus
        />
      </S.Content>

      <S.Footer justify="flex-start" align="center">
        <S.DetachButton type="link" onClick={onDelete}>
          <IconTrash color={colors.error} />
          {translate('common.actions.delete')}
        </S.DetachButton>
      </S.Footer>
    </S.Wrapper>
  );
};

export default AttachTemplateItem;
