import styled from '@emotion/styled';
import { Flex } from 'antd';

import { IconEye } from '@assets';

export const AttachItem = styled(Flex)<{ isactive: string }>`
  height: 56px;
  border-radius: 12px;
  padding: 12px 20px;
  background-color: ${({ theme, isactive }) =>
    isactive ? theme.colors.bgBrightBlue : theme.colors.badgeGrey};
  border: 1px solid
    ${({ theme, isactive }) =>
      isactive ? theme.colors.primary : theme.colors.bgDarkGrey};
  cursor: pointer;
`;

export const AttachItemName = styled.span`
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.text400};
`;

export const View = styled(IconEye)`
  color: ${({ theme }) => theme.colors.text400};
  width: 24px;
  height: 24px;

  :hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;
