import styled from '@emotion/styled';

import { Typography } from 'antd';

export const Title = styled(Typography.Title)`
  &.ui-typography {
    font-size: 18px;
    margin-bottom: 0;
  }
`;

export const Details = styled(Typography.Paragraph)`
  &.ui-typography {
    color: ${({ theme }) => theme.colors.text400};
    margin-top: 8px;
  }
`;

export const Form = styled.div`
  .ui-form {
    .ui-form-item {
      margin-bottom: 12px;

      .ui-form-item-label {
        label {
          font-size: 12px;
        }
      }

      .ui-input-affix-wrapper {
        padding-block: 5px;
      }
    }
  }
`;

export const Submit = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
`;

export const Copy = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;

  &:not(:hover) {
    color: ${({ theme }) => theme.colors.text400};
  }
`;
