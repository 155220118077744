import { Params$CategoryGroupController_create } from '@api-client/generated/client';
import { useApiClientMutation } from '@hooks';

const useCreateCategoryGroup = () => {
  return useApiClientMutation<Params$CategoryGroupController_create>({
    method: 'CategoryGroupController_create',
  });
};

export default useCreateCategoryGroup;
