import styled from '@emotion/styled';
import { Alert, Button, ButtonProps, Input } from 'antd';

export const ShareLink = styled.div`
  background-color: ${({ theme }) => theme.colors.bgYellow};
  padding: 15px 20px;
  border-radius: 12px;
`

export const Instruction = styled.div`
  font-size: 12px;
`;

export const DisabledLikeEnabledInput = styled(Input)`
  margin: 12px 0px;
  background-color: ${({ theme }) => theme.colors.white} !important;
  border: 1px solid #d9d9d9 !important;
  color: ${({ theme }) => theme.colors.black} !important;
`;

export const ErrorAlert = styled(Alert)`
  margin-bottom: 12px;
`;

export const Notice = styled.div`
  color: ${({ theme }) => theme.colors.text400};
  font-size: 12px;
`;

export const ButtonNotified= styled(Button)<ButtonProps>`
  height: 32px;
  margin-top: 12px;
`;