import {
  Button,
  type ButtonProps,
  Flex,
  type FlexProps,
  InputNumber,
  type InputNumberProps,
} from 'antd';

import styled from '@emotion/styled';

export const Container = styled(Flex)<FlexProps>`
  .ui-btn {
    &:first-child {
      border-radius: 6px 0 0 6px;
    }

    &:last-child {
      border-radius: 0 6px 6px 0;
    }
  }
`;

export const InputNumberInline = styled(InputNumber)<InputNumberProps>`
  width: 100%;
  height: 40px;
  border-radius: 0;
  margin: 0 -1px;
  z-index: 1;

  && {
    .ui-input-number-input {
      height: 38px;
      padding-inline: 0;
      text-align: center;
    }
  }
`;

export const Action = styled(Button)<ButtonProps>`
  && {
    width: 40px;
    height: 40px;
    min-width: 40px;
    border-color: ${({ theme }) => theme.colors.strokeGrey};
    border-width: 1px;
    color: ${({ theme }) => theme.colors.text400};

    &:hover {
      z-index: 2;
    }
  }
`;
