import { Params$IncorporationController_createPaymentIntent } from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';

import { useApiClientMutation } from '@hooks';

const useCreatePaymentIntent = () => {
  return useApiClientMutation<
    Params$IncorporationController_createPaymentIntent,
    Schemas.PaymentIntent
  >({
    method: 'IncorporationController_createPaymentIntent',
  });
};

export default useCreatePaymentIntent;
