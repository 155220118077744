import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import copy from 'copy-to-clipboard';

import {
  DetailsHeader,
  TransactionButtonCopy,
  TransactionContent, TransactionSettingsButton,
} from '@entities';
import { Loader } from '@components';
import { useGetTransactionById, useUpdateTransactionById } from '@hooks-api';
import { useAccount, useTranslate } from '@hooks';
import { Button, Flex, message, Tooltip } from 'antd';
import { IconChain, IconSplit, IconUploadCloud } from '@assets';
import { colors } from '@theme';
import { CheckOutlined } from '@ant-design/icons';

import * as S from './styled';

const TransactionDetails: FC = () => {
  const { id: transactionId } = useParams();
  const { companyId } = useAccount();
  const { translate } = useTranslate();

  const { transaction, loading, refetch } = useGetTransactionById({
    params: {
      id: transactionId!,
      companyId: companyId!,
    },
  });

  const [updateTransactionById] = useUpdateTransactionById();

  const [copied, setCopied] = useState(false);
  const [isFilelessOpen, setIsFilelessOpen] = useState(false);

  if (!transaction || loading) {
    return <Loader />;
  }

  const scrollToElement = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleCopy = () => {
    copy(location.href);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const handleUpdateTransaction = (
    key: 'isFileless' | 'isIgnoredForPL',
    value: boolean
  ) => {
    if (transactionId) {
      updateTransactionById(
        {
          parameter: {
            id: transactionId,
            companyId: companyId!,
          },
          requestBody: {
            [key]: value,
          },
        },
        {
          onSuccess: () => {
            refetch();
            message.success(translate('transaction.success.update'));
          },
        }
      );
    }
  };

  const Title = () => (
    <DetailsHeader>
      <Flex align="center" gap={4}>
        <S.Name type="secondary">{transaction.contact?.name}</S.Name>
        <TransactionButtonCopy id={transaction.id} />
      </Flex>
      <Flex align="center" gap={10}>
        <Tooltip
          title={
            copied
              ? translate('transaction.breadcrumbs.buttonCopy.linkCopied')
              : translate('transaction.breadcrumbs.buttonCopy.copyLink')
          }
          placement="bottom"
        >
          <Button
            size="small"
            type="text"
            onClick={handleCopy}
            icon={
              copied ? (
                <CheckOutlined color={colors.primary} />
              ) : (
                <IconChain color={colors.text400} />
              )
            }
          />
        </Tooltip>
        <Tooltip
          placement="bottom"
          title={translate('transaction.breadcrumbs.upload.document')}
        >
          <Button
            onClick={() => scrollToElement('documents-upload')}
            icon={<IconUploadCloud color={colors.text400} />}
            size="small"
            type="text"
          />
        </Tooltip>
        <Tooltip
          placement="bottom"
          title={translate('transaction.breadcrumbs.split')}
        >
          <Button
            icon={<IconSplit color={colors.text400} />}
            size="small"
            onClick={() => scrollToElement('transactions-split')}
            type="text"
          />
        </Tooltip>
      </Flex>
      <TransactionSettingsButton
        initialFileless={transaction?.isFileless}
        initialPlDate={transaction?.isIgnoredForPL}
        isFilelessOpen={isFilelessOpen}
        onToggle={() => setIsFilelessOpen((prev) => !prev)}
        onChange={handleUpdateTransaction}
      />
    </DetailsHeader>
  );

  return (
    <TransactionContent
      id={transactionId!}
      transaction={transaction}
      headerContent={<Title />}
      isPlDateDisabled={transaction?.isIgnoredForPL}
      isFileless={transaction?.isFileless}
      onChangeFileless={(val) => handleUpdateTransaction('isFileless', val)}
      onRefresh={refetch}
      isModal={false}
    />
  );
};

export default TransactionDetails;
