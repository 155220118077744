import { type DatePickerProps } from 'antd';
import { Dayjs } from 'dayjs';
import { FC } from 'react';

import * as S from './styled';

const DatePickerHidden: FC<
  DatePickerProps & {
    onChange: (date: Dayjs, dateString: string | string[]) => void;
  }
> = (props) => (
  <S.DatePickerHidden format="DD.MM.YYYY" allowClear={false} {...props} />
);

export default DatePickerHidden;
