import { Flex, Switch } from 'antd';
import { FC, useState } from 'react';

import { useTranslate, useAccount } from '@hooks';

import * as S from './styled';

type SettingsCardProps = {
  initialFileless: boolean;
  initialPlDate: boolean;
  onChange: (key: 'isFileless' | 'isIgnoredForPL', val: boolean) => void;
};

const SettingsCard: FC<SettingsCardProps> = ({
  initialFileless,
  initialPlDate,
  onChange,
}) => {
  const { translate } = useTranslate();
  const { userAccess } = useAccount();

  const [isPlDate, setIsPlDate] = useState(initialPlDate);
  const [isFileless, setIsFileless] = useState(initialFileless);

  const handleChangeFileless = (val: boolean) => {
    onChange('isFileless', val);
    setIsFileless(val);
  };

  const handleChangePlDate = (val: boolean) => {
    onChange('isIgnoredForPL', val);
    setIsPlDate(val);
  };
  return (
    <S.Wrapper vertical>
      {userAccess?.incomesExpences && (
        <S.Item gap={16} align="flex-start">
          <Flex gap={5} vertical>
            <S.Title>{translate('transaction.settings.markAsFileles')}</S.Title>
            <S.Description>
              {translate('transaction.settings.markAsFilelesDescription')}
            </S.Description>
          </Flex>
          <Switch checked={isFileless} onChange={handleChangeFileless} />
        </S.Item>
      )}

      <Flex gap={16} align="flex-start">
        <Flex gap={5} vertical>
          <S.Title>{translate('transaction.settings.excludePl')}</S.Title>
          <S.Description>
            {translate('transaction.settings.excludePlDescription')}
          </S.Description>
        </Flex>
        <Switch checked={isPlDate} onChange={handleChangePlDate} />
      </Flex>
    </S.Wrapper>
  );
};

export default SettingsCard;
