import { FC } from 'react';

import { Schemas } from '@api-client/generated/types';
import { Card } from '@components';
import { FileViewer } from '@entities';

import * as S from './styled';

type TaxPreviewProps = {
  file?: Schemas.TaxFile;
};

const TaxPreview: FC<TaxPreviewProps> = ({ file }) => (
  <Card title="Preview">
    <S.Preview overlay={!!file}>
      {file && (
        <>
          {file?.mimeType?.includes('image') && (
            <img src={file.url} alt={file.url} />
          )}

          {file?.mimeType?.includes('pdf') && (
            <FileViewer selectedFile={file} width="100%" height="622px" />
          )}

          {!file?.mimeType?.includes('image') &&
            !file?.mimeType?.includes('pdf') && <iframe src={file?.url} />}
        </>
      )}
    </S.Preview>
  </Card>
);

export default TaxPreview;
