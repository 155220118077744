import runTimeError from './assets/runTimeError.svg';
import { GenericError } from '@entities/errorBoundary/GenericError';

export const RunTimeError = ({
  fullPage,
  onClick,
}: {
  fullPage?: boolean;
  onClick?: () => void;
}) => (
  <GenericError
    description="common.errors.generic.pageDescription"
    imgSrc={runTimeError}
    title="common.errors.generic.pageTitle"
    buttonText="common.errors.generic.pageButton"
    fullPage={fullPage}
    onClick={onClick}
  />
);
