import type { ThemeConfig } from 'antd';
import { rgba } from 'emotion-rgba';

import { Theme } from '@emotion/react';

const isDev = import.meta.env.VITE_APP_MODE === 'DEV';

export const layout = {
  sidebarWidth: 105,
  fixedBarHeight: 76,
};

export const baseColors = {
  dark: '#000000',
  black: '#081D46',
  primary: '#0157FF',
  secondary: '#5B78A2',
  error: '#EC1717',
  success: '#098D36',
  white: '#FFFFFF',
};

export const textColors = {
  text500: baseColors.black,
  text400: '#3F577A',
  text300: baseColors.secondary,
  text200: '#819BC0',
  textBlue400: '#42619D',
  textLink: baseColors.primary,
  textWarning: baseColors.error,
  textSuccess: baseColors.success,
  text500Inverted: baseColors.white,
  text300Inverted: rgba(baseColors.white, 0.65),
  textDisabled: '#B3BFD0',
  devDisabled: '#9F5F9D',
};

export const backgroundColors = {
  bgDarkBlue: '#022C7E',
  bgYellow: '#FFE48A',
  bgLightYellow: '#FFF3CC',
  bgLightBlue: '#EAEFFF',
  bgBrightBlue: '#E5EBFF',
  bgLightBlue200: '#F7F9FF',
  bgGrey: '#F2F5F9',
  bgDarkGrey: '#E2E7EE',
  bgWarning: '#FFD5D5',
  bgSuccess: 'rgba(0, 146, 6, 0.08)',
  bgLightSuccess: '#E1FFE6',
  bgWhite: baseColors.white,
  bgDev: '#610F5E',
  bgDevSelected: '#3E0A3C',
};

export const iconsColors = {
  iconBlack: baseColors.black,
  iconLightGrey: baseColors.secondary,
  iconDarkGrey: '#435563',
  iconLineWarning: '#E12020',
  iconLineYellow: '#D48C00',
  iconWarning: '#FFB2B2',
  iconLineSuccess: '#00BB40',
  iconActive: baseColors.primary,
  iconAccent: '#FFFBED',
  iconTag: '#EAEFFF',
  iconWhite: baseColors.white,
  iconWhite40: rgba(baseColors.white, 0.4),
  iconTips: '#D0E0FF',
  iconGreenLight: '#00DA4B',
};

export const strokeColors = {
  strokeBlue: baseColors.primary,
  strokeGrey: '#D0DEF1',
  strokeWarning: '#E12020',
  strokeDarkGrey: '#E2E7EE',
};

export const badgeColors = {
  badgeSuccess: '#D3FFDA',
  badgeInfo: '#FFFBED',
  badgeWarning: 'rgba(238, 0, 0, 0.04)',
  badgeGrey: '#F2F5F9',
  badgeBlue: '#EAEFFF',
  badgeBlue100: '#EAF4FC',
};

export const buttonColors = {
  buttonBlue: baseColors.primary,
  buttonGrey: '#F0F4F9',
  buttonWhite: baseColors.white,
};

export const colors: Theme['colors'] = {
  ...baseColors,
  ...textColors,
  ...backgroundColors,
  ...iconsColors,
  ...strokeColors,
  ...badgeColors,
  ...buttonColors,
};

export const config: ThemeConfig = {
  cssVar: true,
  token: {
    fontFamily: 'Inter',
    borderRadius: 8,

    colorPrimary: colors.primary,
    colorLink: colors.primary,
    colorTextBase: colors.black,
    colorText: colors.black,
    colorError: colors.error,
    colorErrorBg: colors.bgWarning,
    colorSuccess: colors.success,
    colorSuccessBg: colors.bgSuccess,
    colorBgContainer: colors.white,
    colorBgLayout: colors.white,
    colorBorderSecondary: colors.strokeGrey,

    fontSizeHeading1: 36,
  },
  components: {
    Layout: {
      headerBg: isDev ? backgroundColors.bgDev : colors.bgDarkBlue,
      siderBg: isDev ? backgroundColors.bgDev : colors.bgDarkBlue,
      ...(isDev
        ? { siderActiveTab: backgroundColors.bgDevSelected }
        : { siderActiveTab: colors.black }),
      ...(isDev && { siderDisabledTabColor: colors.devDisabled }),
      headerHeight: 72,
      headerPadding: '0 24px',
      headerColor: colors.white,
    },
    Table: {
      headerBg: colors.white,
      headerColor: colors.black,
      headerSplitColor: 'transparent',
      headerBorderRadius: 0,
      rowHoverBg: colors.bgLightBlue200,
      cellPaddingBlock: 8,
    },
    Button: {
      controlHeight: 44,
      controlHeightLG: 56,
      controlHeightSM: 40,
      borderRadius: 8,
      borderRadiusLG: 8,
      borderRadiusSM: 8,
      contentFontSize: 16,
      contentFontSizeLG: 16,
      contentFontSizeSM: 16,
      fontWeight: 500,
      paddingInline: 28,
      paddingInlineLG: 28,
      paddingInlineSM: 20,
      paddingBlock: 13,
      paddingBlockLG: 18,
      paddingBlockSM: 10,
      borderColorDisabled: 'transparent',
    },
    Tag: {
      lineHeightSM: 2.6,
      borderRadiusSM: 20,
      fontSizeSM: 14,
    },
    Input: {
      borderRadiusLG: 6,
      controlHeightLG: 44,
      colorBorder: colors.strokeGrey,
      colorTextPlaceholder: colors.secondary,
      inputFontSizeLG: 14,
    },
    InputNumber: {
      colorBorder: colors.strokeGrey,
      colorTextPlaceholder: colors.secondary,
      borderRadiusLG: 6,
      fontSizeLG: 14,
    },
    Select: {
      controlHeightLG: 40,
      colorBorder: colors.strokeGrey,
      colorTextPlaceholder: colors.secondary,
      borderRadiusLG: 6,
      fontSizeLG: 14,
    },
    DatePicker: {
      colorBorder: colors.strokeGrey,
      colorTextPlaceholder: colors.secondary,
      borderRadius: 6,
      fontSizeLG: 14,
    },
    Breadcrumb: {
      separatorColor: colors.secondary,
      linkColor: colors.secondary,
      lastItemColor: colors.secondary,
      separatorMargin: 2,
    },
    Form: {
      labelColor: colors.textBlue400,
    },
    Segmented: {
      controlHeightLG: 38,
      itemColor: colors.secondary,
      trackBg: colors.bgLightBlue200,
      itemActiveBg: colors.bgLightBlue,
      itemSelectedBg: colors.bgLightBlue,
      itemSelectedColor: colors.bgDarkBlue,
    },
    Modal: {
      colorBgMask: 'rgba(0, 26, 77, 0.6)',
      titleFontSize: 20,
    },
    Tooltip: {
      colorBgSpotlight: colors.bgDarkBlue,
      colorText: colors.white,
      controlHeight: 32,
      fontSize: 12,
    },
    Badge: {
      indicatorHeight: 18,
      textFontSize: 10,
      textFontWeight: 600,
    },
    Drawer: {
      paddingLG: 32,
      colorBgMask: 'rgba(0, 26, 77, 0.6)',
    },
    Alert: {
      borderRadiusLG: 10,
      fontSize: 12,
      colorErrorBorder: colors.bgWarning,
    },
    Switch: {
      handleShadow: 'none',
    },
    Tabs: {
      inkBarColor: colors.primary,
      itemColor: colors.textBlue400,
      horizontalItemPaddingLG: '8px 4px',
      horizontalItemGutter: 24,
      horizontalMargin: '0 0 25px 0',
    },
  },
};
