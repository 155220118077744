import { Params$CategoriesController_updateOneById } from '@api-client/generated/client';
import { useApiClientMutation } from '@hooks';

const useUpdateCategory = () => {
  return useApiClientMutation<Params$CategoriesController_updateOneById>({
    method: 'CategoriesController_updateOneById',
  });
};

export default useUpdateCategory;
