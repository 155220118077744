import { Params$CompanyInviteController_create } from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';

import { useApiClientMutation } from '@hooks';

const useCreateCompanyInvite = () => {
  return useApiClientMutation<
    Params$CompanyInviteController_create,
    Schemas.CompanyInvite
  >({
    method: 'CompanyInviteController_create',
  });
};

export default useCreateCompanyInvite;
