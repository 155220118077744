import { List, type ListProps } from 'antd';
import { FC } from 'react';

import { Schemas } from '@api-client/generated/types';
import { useTranslate } from '@hooks';
import { getAddressLine } from '@utils';

import * as S from './styled';

type Contact = Schemas.Contact;

type ContactsSimpleListProps = {
  selectedId?: string | null;
  dataSource?: Schemas.Contact[];
  onSelect: (contact: Contact) => void;
} & ListProps<Contact>;

const ContactsSimpleList: FC<ContactsSimpleListProps> = ({
  selectedId,
  dataSource,
  onSelect,
  ...rest
}) => {
  const { translate } = useTranslate();

  return (
    <List
      dataSource={dataSource}
      renderItem={(item) => (
        <S.Contact
          gap={4}
          selected={selectedId === item.id}
          onClick={() => onSelect(item)}
          vertical
        >
          <S.ContactName>{item.name}</S.ContactName>

          <div>
            {translate('contactsPage.popupContacts.vatId')}: {item.vatId || '-'}
          </div>

          <div>{getAddressLine(item.billingAddress)}</div>
        </S.Contact>
      )}
      locale={{
        emptyText: <div />,
      }}
      {...rest}
    />
  );
};

export default ContactsSimpleList;
