import styled from '@emotion/styled';

import { Flex, Typography } from 'antd';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
`

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

export const Container = styled(Flex)`
  padding: 36px 40px 50px 50px;
`

export const Title = styled(Typography.Title)`
  &.ui-typography {
    margin-bottom: 0;
  }
`;