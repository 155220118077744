import styled from '@emotion/styled';

import { Typography } from 'antd';

export const Title = styled(Typography.Title)`
  text-align: center;

  &.ui-typography {
    font-size: 20px;
    margin-bottom: 8px;
  }
`;

export const Description = styled(Typography.Paragraph)`
  text-align: center;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 24px;
`;
