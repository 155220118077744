import { useContext } from 'react';

import { OnboardingContext } from '@context';

const useOnboarding = () => {
  const onboarding = useContext(OnboardingContext);

  return onboarding;
};

export default useOnboarding;
