import { useTranslate } from '@hooks';

const useTypesList = () => {
  const { translate } = useTranslate();

  return [
    { value: 'invoice', label: translate('documentsPage.types.invoice') },
    { value: 'receipt', label: translate('documentsPage.types.receipt') },
    {
      value: 'bankStatement',
      label: translate('documentsPage.types.bankStatement'),
    },
    { value: 'contract', label: translate('documentsPage.types.contract') },
    { value: 'agreement', label: translate('documentsPage.types.agreement') },
    { value: 'other', label: translate('documentsPage.types.other') },
  ];
};

export default useTypesList;
