import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { GoBack, PageMeta, ProjectContent } from '@entities';
import { Loader } from '@components';
import { useGetProjectById } from '@hooks-api';
import { useAccount, useTranslate } from '@hooks';

const ProjectDetails: FC = () => {
  const { id: projectId } = useParams();
  const { companyId } = useAccount();
  const { translate } = useTranslate();

  const { project, loading } = useGetProjectById({
    params: {
      id: projectId!,
      companyId: companyId!,
    },
  });

  if (!project || loading) {
    return <Loader />;
  }

  return (
    <>
      <PageMeta title={project.name} />

      <ProjectContent
        type="view"
        id={projectId!}
        project={project}
        headerContent={<GoBack name={translate('projects.breadcrumbs.parent')} />}
      />
    </>
  );
};

export default ProjectDetails;
