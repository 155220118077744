import { useMemo } from 'react';

import { Params$BankConnectionsController_findByRef } from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';
import { useApiClient } from '@hooks';

type Params = Params$BankConnectionsController_findByRef;

type BankConnectionsProps = {
  params: Params['parameter'];
};

const useGetBankConnections = ({ params }: BankConnectionsProps) => {
  const {
    data: bankConnections,
    loading,
    refetch,
  } = useApiClient<Params, Schemas.BankConnection>({
    method: 'BankConnectionsController_findByRef',
    params: {
      parameter: params,
    },
  });

  return useMemo(
    () => ({
      bankConnections,
      loading,
      refetch,
    }),
    [bankConnections, loading, refetch]
  );
};

export default useGetBankConnections;
