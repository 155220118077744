import styled from '@emotion/styled';

export const Preview = styled.div`
  min-width: 405px;
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Image = styled.img`
  width: 100%;
  max-width: 600px;
`;

export const Unsupported = styled.div`
  width: 400px;
  height: 340px;
`;
