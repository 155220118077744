import styled from '@emotion/styled';

import { type InputProps, Input } from 'antd';

export const InputBorderless = styled(Input)<InputProps>`
  width: auto;
  border-radius: 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.strokeGrey};
  font-size: 36px;
  font-weight: 500;
  padding: 0;
`;
