import { Flex, Table, type TableColumnType } from 'antd';
import { FC, useCallback, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { Schemas } from '@api-client/generated/types';
import { IconDownload, IconEye } from '@assets';
import { Scrollbar } from '@components';
import {
  ContactsDocsEmpty,
  FileViewerInModal,
  FilterConstructor,
  FilterControl,
} from '@entities';
import { useAccount, useTranslate } from '@hooks';
import { useGetDocumentsList } from '@hooks-api';
import { useInfiniteScroll, usePagination } from '@hooks/useInfiniteScroll';
import { downloadFile } from '@utils';

import * as S from './styled';

type DocumentFile = Schemas.Document;

const controls: { left: FilterControl[] } = {
  left: [
    {
      type: 'search',
      formName: 'term',
    },
  ],
};

const ContactsSupplementaryDocs: FC = () => {
  const { id: contactId } = useParams();
  const { search } = useLocation();

  const { companyId } = useAccount();
  const { translate } = useTranslate();

  const [filterOptions, setFilterOptions] = useState<Record<string, unknown>>(
    {}
  );
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<DocumentFile | null>(null);

  const {
    page,
    incrementPage,
    setTotalPages,
    hasNextPage,
    plainData,
    appendData,
    reset,
  } = usePagination<DocumentFile>();

  const { isFetching: isLoading, refetch } = useGetDocumentsList({
    params: {
      companyId: companyId!,
      contactIds: contactId,
      withoutMetadataOnly: true,
      withUrl: true,
      page,
      ...filterOptions,
    },
    config: {
      onSuccess: ({ records, metadata }) => {
        setTotalPages(metadata.totalPages);
        appendData(metadata.currentPage, records);
      },
    },
  });

  const { sentryRef, rootRef } = useInfiniteScroll({
    isLoading,
    hasNextPage,
    onLoadMore: incrementPage,
  });

  useEffect(() => {
    reset();
  }, [reset, search]);

  const handleViewFile = (file: DocumentFile) => {
    setIsViewModalOpen(true);
    setSelectedFile(file);
  };

  const handleCancelViewFile = () => {
    setIsViewModalOpen(false);
    setSelectedFile(null);
  };

  const columns: TableColumnType<DocumentFile>[] = [
    {
      key: 'name',
      title: translate('documentsPage.table.name'),
      render: (_, record) =>
        record.documentMetadata?.number ||
        record.contact?.name ||
        translate('documentsPage.table.noName'),
      ellipsis: true,
    },
    {
      key: 'actions',
      width: 120,
      align: 'right',
      render: (file: DocumentFile) => (
        <Flex gap={20} align="center">
          <S.ButtonAction
            type="text"
            size="small"
            icon={<IconEye />}
            onClick={() => handleViewFile(file)}
          />

          <S.ButtonAction
            type="text"
            size="small"
            icon={<IconDownload />}
            onClick={() => downloadFile(file.url, file.fileName, true)}
          />
        </Flex>
      ),
    },
  ];

  const handleOnChange = useCallback(
    (values: Record<string, unknown>) => setFilterOptions({ ...values }),
    []
  );

  return (
    <Scrollbar height="calc(100vh - 385px)" ref={rootRef}>
      <Flex gap={24} vertical>
        <FileViewerInModal
          open={isViewModalOpen}
          file={selectedFile}
          onCancel={handleCancelViewFile}
        />

        <FilterConstructor<DocumentFile>
          controls={controls}
          onChange={handleOnChange}
          onRequest={refetch}
          inversionTheme
        />

        <Flex justify="center" align="center">
          {!plainData.length && isLoading ? (
            <S.Loader />
          ) : (
            <>
              {plainData.length ? (
                <>
                  <Table<DocumentFile>
                    className="table-transparent"
                    rowKey={({ id }) => id}
                    dataSource={plainData}
                    columns={columns}
                    loading={isLoading}
                    pagination={false}
                    scroll={{ x: 720 }}
                  />
                  {hasNextPage && <div ref={sentryRef} />}
                </>
              ) : (
                <ContactsDocsEmpty />
              )}
            </>
          )}
        </Flex>
      </Flex>
    </Scrollbar>
  );
};

export default ContactsSupplementaryDocs;
