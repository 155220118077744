import styled from '@emotion/styled';
import { Flex } from 'antd';

export const AttachDropdownItemWrapper = styled(Flex)<{ width?: string, height?: string }>`
  padding: 11px 0 11px 12px;
  width: ${({ width }) => (width?.length ? width : '309px')};
  height: ${({ height }) => (height?.length ? height : 'auto')};
  box-sizing: border-box;
`;

export const AttachDropdownItemContent = styled(Flex)`
  width: 100%;
`;

export const AttachDropdownItemTitle = styled.span`
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
`;

export const AttachDropdownItemDescription = styled.div`
  height: 40px;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const AttachDropdownItemPrice = styled.span`
  font-weight: 600;
  line-height: 20px;
`;

export const AttachDropdownItemDate = styled.span`
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.text400};
`;
