import { useState } from 'react';
import { isRouteErrorResponse } from 'react-router-dom';
import { createPortal } from 'react-dom';

export const DevError = ({
  error,
  componentStack,
}: {
  error: unknown;
  componentStack?: string;
  eventId?: string;
  fullPage?: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(true);

  if (import.meta.env.MODE === 'production') {
    return null;
  }

  const message = isRouteErrorResponse(error)
    ? `${error.status} ${error.statusText}`
    : error instanceof Error
      ? error.message
      : JSON.stringify(error);

  const stack = error instanceof Error ? error.stack : componentStack;

  let element = document.getElementById('devPortal');

  if (!element) {
    element = document.createElement('div');
    element.id = 'devPortal';

    document.body.appendChild(element);
  }

  return (
    <>
      {isOpen &&
        createPortal(
          <div
            style={{
              padding: '1rem',
              position: 'absolute',
              bottom: 0,
              right: 0,
              background: '#fff',
              borderTop: '1px solid gray',
              borderLeft: '1px solid gray',
              zIndex: 1000,
              maxWidth: '100%',
            }}
          >
            <h2>Unexpected Application Error!</h2>
            <h3 style={{ fontStyle: 'italic' }}>{message}</h3>
            {stack && (
              <pre
                style={{
                  padding: '0.5rem',
                  backgroundColor: 'rgba(200,200,200, 0.5)',
                  maxWidth: '100%',
                }}
              >
                {stack}
              </pre>
            )}
            <div
              style={{
                position: 'absolute',
                top: 10,
                right: 10,
                color: '#000',
                transform: 'rotate(45deg)',
                fontSize: '30px',
                cursor: 'pointer',
              }}
              onClick={() => setIsOpen(false)}
            >
              +
            </div>
          </div>,
          element
        )}
    </>
  );
};
