import { FC } from 'react';

import { Space } from 'antd';

import { useOnboarding } from '@hooks';

import * as S from './styled';

type OnboardingStepsProps = {
  title: string;
  name?: string;
  divisionsCount: number;
};

const OnboardingSteps: FC<OnboardingStepsProps> = ({
  name,
  title,
  divisionsCount,
}) => {
  const { screens, currentScreenName } = useOnboarding();

  const currentScreenStep = screens[currentScreenName]?.step || 0;

  return (
    <S.Container>
      <S.Title>
        <Space align="center" size={8}>
          {title}
          {name && (
            <>
              <S.Name>/</S.Name>
              <S.Name>{name}</S.Name>
            </>
          )}
        </Space>
      </S.Title>

      <S.Steps>
        {[...Array(divisionsCount).keys()].map((key) => (
          <S.Step key={key} selected={key + 1 <= currentScreenStep} />
        ))}
      </S.Steps>
    </S.Container>
  );
};

export default OnboardingSteps;
