import { useMemo } from 'react';

import { Params$TaxController_findOneById } from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';
import { type QueryOptions, useApiClient } from '@hooks';

type Tax = Schemas.Tax;
type Params = Params$TaxController_findOneById;

type TaxProps = {
  params: Params['parameter'];
  config?: QueryOptions<Tax>;
};

const useGetTaxById = ({ config, params }: TaxProps) => {
  const {
    data: tax,
    loading,
    refetch,
  } = useApiClient<Params, Schemas.Tax>({
    method: 'TaxController_findOneById',
    params: {
      parameter: params,
    },
    config,
  });

  return useMemo(
    () => ({
      tax,
      loading,
      refetch,
    }),
    [tax, loading, refetch]
  );
};

export default useGetTaxById;
