import { FC, MouseEvent, useState, useEffect } from 'react';

import { Dropdown, Radio, Menu, Button, Input, Flex } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { Select } from '@components';
import { useTranslate } from '@hooks';
import { IconArrowDown } from '@assets';
import { LocalePaths } from '@locales';

import * as S from './styled';

type TransactionsImportTableConfigProps = {
  columnData: {
    columnNumber: string;
    columnFormat?: string;
  };
  onChange?: (key: string, format?: string) => void;
};

const dateFormatsPresets = [
  'DD.MM.YYYY',
  'DD.MM.YY',
  'YYYY-MM-DD',
  'DD/MM/YY',
  'DD/MM/YYYY',
];

const items = [
  { key: 'bookingDate', type: 'select' },
  { key: 'contactName', type: 'radio' },
  { key: 'details', type: 'radio' },
  { key: 'debit', type: 'radio' },
  { key: 'credit', type: 'radio' },
  { key: 'debitCredit', type: 'radio' },
  { key: 'currencyCode', type: 'radio' },
];

const TransactionsImportTableConfig: FC<TransactionsImportTableConfigProps> = ({
  columnData,
  onChange,
}) => {
  const { translate } = useTranslate();

  const [dateFormats, setDateFormats] = useState(dateFormatsPresets);
  const [formatName, setFormatName] = useState('');
  const [selectedKey, setSelectedKey] = useState<string>('');
  const [selectedDateFormat, setSelectedDateFormat] = useState<string>();

  useEffect(() => {
    if (columnData) {
      setSelectedKey(columnData.columnNumber);
      setSelectedDateFormat(columnData.columnFormat);
    }
  }, [columnData]);

  const handleChange = (key: string) => {
    if (key !== 'bookingDate') {
      setSelectedKey(key);

      onChange?.(key);
    }
  };

  const handleChangeDate = (key: string, format: string) => {
    if (format) {
      setSelectedDateFormat(format);
      setSelectedKey(key);

      onChange?.(key, format);
    }
  };

  const handleAddFormat = (e: MouseEvent, key: string) => {
    e.preventDefault();

    if (key === 'bookingDate') {
      setDateFormats((prevState) => [...prevState, formatName]);
    }

    setFormatName('');
  };

  const dropdownMenu = () => (
    <Menu>
      {items.map(({ key, type }) => (
        <S.Item key={key} onClick={() => handleChange(key)}>
          {type === 'select' && (
            <Select
              placeholder={translate(
                `transactionsImport.columnType.${key}` as LocalePaths
              )}
              options={dateFormats.map((format) => ({
                label: format,
                value: format,
              }))}
              value={selectedDateFormat}
              onChange={(format) => handleChangeDate(key, format)}
              dropdownRender={(menu) => (
                <Flex vertical>
                  {menu}

                  <S.WrapperFormat>
                    <Input
                      value={formatName}
                      onChange={(e) => setFormatName(e.target.value)}
                      onKeyDown={(e) => e.stopPropagation()}
                    />

                    <Button
                      type="link"
                      icon={<PlusOutlined />}
                      onClick={(e) => handleAddFormat(e, key)}
                      block
                    >
                      {translate('transactionsImport.columnType.buttonAdd')}
                    </Button>
                  </S.WrapperFormat>
                </Flex>
              )}
              size="large"
              withoutContainer
            />
          )}

          {type === 'radio' && (
            <>
              {translate(`transactionsImport.columnType.${key}` as LocalePaths)}
              <Radio checked={selectedKey === key} />
            </>
          )}
        </S.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown
      dropdownRender={dropdownMenu}
      trigger={['click']}
      overlayClassName="ui-dropdown-bordered"
    >
      <S.Link onClick={(e) => e.preventDefault()}>
        {translate(
          `transactionsImport.columnType.${selectedKey || 'placeholder'}` as LocalePaths
        )}
        <IconArrowDown />
      </S.Link>
    </Dropdown>
  );
};

export default TransactionsImportTableConfig;
