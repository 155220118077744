import { type TableColumnType } from 'antd';

import { Schemas } from '@api-client/generated/types';

import * as S from './styled';

export type FileMapping = Schemas.FileMapping;
export type Preview = Schemas.CSVPreview;
export type Mapping = Schemas.CSVMapping;
export type AttributeMapping = Schemas.AttributeMapping;

export type DataSource = Record<string, string | boolean>;

export type TypeDataSource = 'first' | 'last';

export type CreateDataSource = {
  rows: string[] | string[][];
  mapping: Mapping;
  type: TypeDataSource;
  lastRowsCount?: number;
};

export const isNewMapping = (mapping: Mapping) => typeof mapping === 'string';

export const getSelectedColumnNumber = (
  mapping: Mapping,
  index: number
): {
  columnNumber: string;
  columnFormat?: string;
} => {
  let columnNumber = '';
  let columnFormat;

  Object.keys(mapping).map((key) => {
    const field = mapping[key as keyof typeof mapping] as AttributeMapping;

    if (field.columnNumber === index) {
      columnNumber = key;

      if (field.columnFormat) {
        columnFormat = field.columnFormat;
      }
    }
  });

  return {
    columnNumber,
    columnFormat,
  };
};

export const createColumns = (
  rows: string[]
): TableColumnType<DataSource>[] => {
  const columns = (rows.length ? rows[0] : []) as Preview['firstRows'];

  return columns.map((_, index) => ({
    dataIndex: index,
    render: (value: string) => <S.Cell>{value || '-'}</S.Cell>,
  }));
};

export const createDataSource = ({
  rows,
  mapping,
  type,
  lastRowsCount,
}: CreateDataSource): DataSource[] => {
  const dataSource: DataSource[] = [];

  const isNew = isNewMapping(mapping);

  const topRowsSkipAmount = +mapping.topRowsSkipAmount;
  const bottomRowsSkipAmount = +mapping.bottomRowsSkipAmount;

  rows.map((row, rowIndex) => {
    const dataSourceItem: DataSource = isNew
      ? {
          isVisibleColumn: true,
        }
      : {
          isVisibleColumn:
            type === 'first'
              ? topRowsSkipAmount <= rowIndex
              : bottomRowsSkipAmount === 0
                ? bottomRowsSkipAmount <= rowIndex
                : rowIndex + bottomRowsSkipAmount <= lastRowsCount! - 1,
        };

    dataSourceItem['tableRowKey'] = `${Math.random()}`;

    (row as string[]).map((value, index) => {
      dataSourceItem[index] = value;
    });

    dataSource.push(dataSourceItem);
  });

  return dataSource;
};
