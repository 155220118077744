import { Dropdown, Typography } from 'antd';
import { FC } from 'react';

import { IconMoreVertical } from '@assets';
import styled from '@emotion/styled';

const Item = styled(Typography.Text)`
  min-width: 88px;
  display: block;
`;

const Button = styled.a`
  &:not(:hover) {
    color: ${({ theme }) => theme.colors.text400};
  }
`;

type MessageActionsProps = {
  onRemove?: () => void;
};

const MessageActions: FC<MessageActionsProps> = ({ onRemove }) => (
  <Dropdown
    menu={{
      items: [
        {
          key: 'remove',
          label: (
            <Item type="danger" onClick={onRemove}>
              {t('chatLive.buttonDelete')()}
            </Item>
          ),
        },
      ],
    }}
    placement="bottomRight"
    trigger={['click']}
  >
    <Button>
      <IconMoreVertical />
    </Button>
  </Dropdown>
);

export default MessageActions;
