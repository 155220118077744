import { Params$ProjectController_updateOneById } from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';

import { useApiClientMutation } from '@hooks';

const useUpdateProjectById = () => {
  return useApiClientMutation<
    Params$ProjectController_updateOneById,
    Schemas.ProjectDto
  >({
    method: 'ProjectController_updateOneById',
  });
};

export default useUpdateProjectById;
