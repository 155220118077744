import styled from '@emotion/styled';
import { rgba } from 'emotion-rgba';

import { Button, Flex } from 'antd';

export const Viewer = styled.div`
  position: relative;

  && {
    :hover {
      .preview_panel {
        opacity: 1;
        visibility: visible;
        transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
      }
    }
  }
`;

export const Wrapper = styled.div<{ width?: string; height?: string }>`
  width: ${({ width }) => (width ? `${width}` : '100%')};
  max-width: ${({ width }) => (width ? `${width}` : '100%')};
  height: ${({ height }) => (height ? `${height}` : '100%')};
  max-height: ${({ height }) => (height ? `${height}` : '100%')};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 20px;
`;

export const DocumentContainer = styled.div`
  height: 100%;
  overflow-x: auto;
  overflow-y: auto;
`;

export const Panel = styled(Flex)`
  opacity: 0;
  visibility: hidden;
  width: 258px;
  height: 66px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.bgGrey};
  border: 1px solid ${({ theme }) => theme.colors.bgDarkGrey};
  box-shadow: 0 3px 8px 0 ${({ theme }) => rgba(theme.colors.dark, 0.1)};
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
`;

export const PanelButton = styled(Button)`
  width: 86px;
  height: 66px;
  display: flex;
  color: ${({ theme }) => theme.colors.text400};
  flex-direction: column;
  padding: 16px 16px 12px 16px;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  gap: 4px;

  && {
    &.ui-btn {
      .ui-btn-icon {
        margin-inline-end: 0;
      }
    }

    svg {
      width: 18px;
      height: 18px;
    }
  }
`;

export const PageContainer = styled.div`
  margin-bottom: 16px;
`;
