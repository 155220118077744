import styled from '@emotion/styled';

import { Flex } from 'antd';

import { colors } from '@theme';

type ControlProps = {
  type: 'unpaid' | 'paid';
  active: boolean;
};

const getThemeByType = (type: ControlProps['type']) => {
  if (type === 'unpaid') {
    return colors.textBlue400;
  }

  if (type === 'paid') {
    return colors.iconLineSuccess;
  }
};

export const Container = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.strokeGrey};
  border-radius: 6px;
  margin-bottom: 12px;
`;

export const Control = styled.a<ControlProps>`
  width: 50%;
  background-color: ${({ theme, type, active }) =>
    active ? getThemeByType(type) : theme.colors.white};
  color: ${({ theme, active }) =>
    active ? theme.colors.white : theme.colors.text400};
  border-radius: 6px;
  font-weight: ${({ active }) => (active ? 600 : 500)};
  padding: 8px 16px;
  text-align: center;
  transition: none;

  &:hover {
    color: ${({ theme, active }) =>
      active ? theme.colors.white : theme.colors.text400};
  }
`;
