import { Link, type LinkProps } from 'react-router-dom';
import styled from '@emotion/styled';

import { Button, type ButtonProps, Typography } from 'antd';

const { Text, Paragraph } = Typography;

export const DocumentName = styled(Link)<LinkProps>`
  font-weight: 600;
`;

export const DocumentDownload = styled.a`
  line-height: 1;
  vertical-align: bottom;
`;

export const ButtonSubmit = styled(Button)<ButtonProps>`
  width: 190px;
  height: 52px;
  font-size: 16px;
  margin-top: 30px;
`;

export const Name = styled(Text)`
  font-weight: 400;
`;

export const CopyButton = styled(Paragraph)`
  && {
    margin-bottom: 0;
  }
  height: 28px;
`;
