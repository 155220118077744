import useInfiniteScrollHook from 'react-infinite-scroll-hook';
import { useCallback, useState } from 'react';

// TODO add error handling and retries
export const usePagination = <T,>(initialData: T[] = []) => {
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [data, setData] = useState<Record<number, T[]>>({ 1: initialData });

  const appendData = useCallback(
    (page: number, data: T[]) =>
      setData((prev) => {
        prev[page] = data;
        return prev;
      }),
    []
  );

  const reset = useCallback((data: T[] = []) => {
    setPage(1);
    setTotalPages(0);
    setData({ 1: data });
  }, []);

  const incrementPage = useCallback(() => setPage((prev) => prev + 1), []);

  // TODO memoize
  const plainData = Object.keys(data)
    .sort((a, b) => Number(a) - Number(b))
    .map((key) => data[Number(key)])
    .flat();

  return {
    page,
    incrementPage,
    setTotalPages,
    hasNextPage: page < totalPages,
    plainData,
    appendData,
    reset,
    UNSAFE_setData: setData,
  };
};

export const useInfiniteScroll = (
  params: Omit<
    Parameters<typeof useInfiniteScrollHook>[0],
    'loading' | 'rootMargin'
  > & {
    isLoading: boolean;
    threshold?: number;
  }
) => {
  const { isLoading, threshold = 300, ...restParams } = params;

  const [sentryRef, { rootRef }] = useInfiniteScrollHook({
    ...restParams,
    rootMargin: `0px 0px ${threshold}px 0px`,
    loading: isLoading,
  });

  return {
    rootRef,
    sentryRef,
  };
};
