import { FC } from 'react';

import { FileViewer } from '@entities';

import { Schemas } from '@api-client/generated/types';

import * as S from './styled';

type IncomeFileViewProps = {
  file: Schemas.Document | null;
};

const PaymentDocumentFileView: FC<IncomeFileViewProps> = ({ file }) => (
  <S.View>
    {file?.mimeType?.includes('image') && <img src={file.url} alt={file.url} />}

    {file?.mimeType?.includes('pdf') && (
      <FileViewer selectedFile={file} width="437px" height="622px" />
    )}

    {!file?.mimeType?.includes('image') && !file?.mimeType?.includes('pdf') && (
      <iframe src={file?.url} />
    )}
  </S.View>
);

export default PaymentDocumentFileView;
