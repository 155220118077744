import styled from '@emotion/styled';
import { Button, Flex, Upload } from 'antd';

export const MissingDocumentInfo = styled(Flex)`
  padding-top: 16px;
`;

export const PaymentDocumentsErrorWrapper = styled(Flex)`
  padding: 20px 20px 32px 20px;
  border-bottom: 2px solid ${({ theme }) => theme.colors.bgDarkGrey};
`;

export const PaymentDocumentsErrorContainer = styled(Flex)`
  background-color: ${({theme}) => theme.colors.bgWarning};
  padding: 12px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
`;

export const PotentialFilesWrapper = styled(Flex)`
  padding: 0 20px;
`;

export const PotentialFilesContainer = styled(Flex)`
  width: 100%;
`

export const FilesList = styled(Flex)<{ ismodal: string }>`
  width: ${({ ismodal }) => (ismodal.length ? '293px' : '100%')};
`;

export const PotentialFilesFooter = styled(Flex)`
  width: 100%;
`;

export const ButtonAddAnother = styled(Button)`
  && {
    font-size: 14px;
    font-weight: 600;
    display: flex;
    gap: 8px;
    padding: 0 14px;
  }
`;

export const ButtonAttach = styled(Button)`
  && {
    font-size: 14px;
    font-weight: 600;
  }
`;

export const MissingTextContainer = styled(Flex)<{ ismodal: string }>`
  width: ${({ ismodal }) => (!ismodal.length ? '100%' : 'auto')};
`;

export const BlockTitle = styled.span`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;

export const MissingDocumentsTitle = styled.span`
  font-weight: 600;
`;

export const MissingDocumentsDescription = styled.span<{ ismodal: string }>`
  width: ${({ ismodal }) => (ismodal.length ? '273px' : 'auto')};
`;

export const MissingPaymentDocumentsUpload = styled(Upload)`
  display: flex;
  justify-content: center;
  width: 100%;

  && .ui-upload {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

export const DropFileContainer = styled(Flex)`
  width: 333px;
  height: 201px;
  color: ${({ theme }) => theme.colors.primary};
`;

export const DropFilePreview = styled(Flex)<{ ismodal: string }>`
  width: ${({ ismodal }) => (ismodal.length ? '333px' : '100%')};
  padding: 40px 30px;
`;

export const DropFileBlock = styled(Flex)`
  width: min-content;
`;

export const DropFilePreviewButtons = styled(Flex)`
  && {
    width: 260px;

    .ui-btn {
      height: 32px;
      font-size: 12px;
    }
  }
`;

export const AcceptFilesTextTitle = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.text400};
`;

export const AcceptFilesText = styled.span`
  width: 241px;
  text-align: center;
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.text400};
`;

export const Actions = styled.div`
  width: 233px;
`;

export const AttachNew = styled(Flex)`
  height: 38px;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.text400};
  padding: 8px;
  border-radius: 8px;
  font-weight: 400;

  &:hover {
    background-color: ${({ theme }) => theme.colors.bgBrightBlue};
  }
`;

export const UploadWrapper = styled.div`
  && .ui-upload {
    width: 100%;
  }
`;

export const UploadExisting = styled(Flex)`
  height: 38px;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.text400};
  padding: 8px;
  border-radius: 8px;
  font-weight: 400;

  &:hover {
    background-color: ${({ theme }) => theme.colors.bgBrightBlue};
  }
`;

export const UploadContainer = styled.div`
  border: 1px dashed ${({ theme }) => theme.colors.primary};
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
`;
