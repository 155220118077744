import styled from '@emotion/styled';

export const Header = styled.div<{pathname: string}>`
  position: sticky;
  top: 70px;
  z-index: 9;
  background: ${({ theme }) => theme.colors.bgLightBlue200};
  margin: ${({ pathname }) => pathname.includes('transactions/') ? '-52px 0 0 0' : '-50px -50px 0px -50px'};
  padding: 25px 50px 20px;
  border-bottom: 2px solid ${({ theme }) => theme.colors.strokeDarkGrey};
`;

export const Divider = styled.div`
  height: 24px;
  border-right: 2px solid ${({ theme }) => theme.colors.strokeDarkGrey};
  margin: 0 16px 0 16px;
`;
