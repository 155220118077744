import { FC, useState } from 'react';

import { Typography, Button, Flex } from 'antd';

import { OnboardingCardHeader, OnboardingRadioControl } from '@entities';
import { useTranslate, useOnboarding } from '@hooks';
import {
  OnboardingRegisterCompany,
  OnboardingSwitchToAccounting,
} from '@assets';

import * as S from './styled';

const { Paragraph } = Typography;

const OnboardingWelcome: FC = () => {
  const { translate } = useTranslate();
  const { updateScreen, updateIncorporation } = useOnboarding();

  const defaultValue = 'incorporation';

  const [selectedOption, setSelectedOption] = useState<string | null>(
    defaultValue
  );

  const handleNext = () => {
    updateIncorporation({
      incorporationType: selectedOption!,
    });

    updateScreen('INCORPORATION', { active: true });
  };

  return (
    <S.Container>
      <S.Inner>
        <OnboardingCardHeader
          title={translate('onboarding.welcome.title')}
          description={
            <Paragraph>{translate('onboarding.welcome.description')}</Paragraph>
          }
        />

        <OnboardingRadioControl
          defaultValue={defaultValue}
          onSelected={setSelectedOption}
          controls={[
            {
              value: 'incorporation',
              title: translate('onboarding.welcome.register.title'),
              content: translate('onboarding.welcome.register.description'),
              rightContent: <OnboardingRegisterCompany />,
            },
            {
              value: 'accounting',
              title: translate('onboarding.welcome.switch.title'),
              content: translate('onboarding.welcome.switch.description'),
              rightContent: <OnboardingSwitchToAccounting />,
            },
          ]}
        />
      </S.Inner>

      <Flex justify="flex-end">
        <Button type="primary" size="large" onClick={handleNext}>
          {translate('onboarding.buttonContinue')}
        </Button>
      </Flex>
    </S.Container>
  );
};

export default OnboardingWelcome;
