import {
  Button,
  Flex,
  Form,
  Input,
  Modal,
  type ModalProps,
  Spin,
  Upload,
} from 'antd';
import type { UploadRequestOption } from 'rc-upload/lib/interface';
import { FC, useState } from 'react';

import { useTaskFileController_create } from '@api-client/generated/TaskFileController/create';
import { Schemas } from '@api-client/generated/types';
import { IconPlus } from '@assets';
import styled from '@emotion/styled';
import { useAccount } from '@hooks';

import FilesList from '../FilesList/FilesList';

const Inner = styled(Flex)`
  .ui-form-item {
    margin-bottom: 16px;
  }
`;

const Attach = styled.a`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  font-weight: 600;
  font-size: 16px;
  margin: 8px 0 28px 0;
`;

type TasksUploadFilesProps = {
  selectedId: string;
  actionCreateMessage: (body: string, fileIds: string[]) => void;
  onCancel: () => void;
} & ModalProps;

const UploadFiles: FC<TasksUploadFilesProps> = ({
  selectedId,
  open,
  actionCreateMessage,
  onCancel,
  ...rest
}) => {
  const { companyId } = useAccount();

  if (!companyId) {
    throw new Error('Company ID is required');
  }

  const [files, setFiles] = useState<Schemas.TaskFile[]>([]);

  const [form] = Form.useForm();

  const [uploadFile, isLoading] = useTaskFileController_create();

  const handleFileAttach = (options: UploadRequestOption) => {
    const formData = new FormData();

    formData.append('file', options.file);
    formData.append('taskId', selectedId);

    uploadFile(
      {
        parameter: {
          companyId,
        },
        requestBody: formData as any,
      },
      {
        onSuccess: (file) => setFiles((prevState) => [file, ...prevState]),
      }
    );
  };

  const handleDeleteFile = (id: string) =>
    setFiles((prevState) => prevState.filter((file) => file.id !== id));

  const handleCancel = () => {
    setFiles([]);
    form.resetFields();

    onCancel();
  };

  const handleSubmit = (values: { message: string }) => {
    actionCreateMessage(
      values.message,
      files.map((file) => file.id)
    );

    handleCancel();
  };

  return (
    <Modal
      width={504}
      open={open}
      title={t('tasks.uploadFiles.title')()}
      footer={null}
      onCancel={handleCancel}
      destroyOnClose
      centered
      {...rest}
    >
      <Inner gap={24} vertical>
        <Form
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
          requiredMark={false}
        >
          <Spin spinning={isLoading}>
            <FilesList dataSource={files} onRemove={handleDeleteFile} />
          </Spin>

          <Upload customRequest={handleFileAttach} showUploadList={false}>
            <Attach>
              <IconPlus />
              {t('tasks.uploadFiles.buttonAddFile')()}
            </Attach>
          </Upload>

          <Form.Item
            name="message"
            rules={[
              {
                required: true,
                message: t('tasks.uploadFiles.message.placeholder')(),
              },
            ]}
          >
            <Input.TextArea
              rows={4}
              placeholder={t('tasks.uploadFiles.message.placeholder')()}
              size="large"
            />
          </Form.Item>

          <Form.Item noStyle>
            <Flex justify="flex-end" gap={12}>
              <Button onClick={handleCancel}>
                {t('tasks.uploadFiles.buttonCancel')()}
              </Button>

              <Button type="primary" htmlType="submit">
                {t('tasks.uploadFiles.buttonUpload')()}
              </Button>
            </Flex>
          </Form.Item>
        </Form>
      </Inner>
    </Modal>
  );
};

export default UploadFiles;
