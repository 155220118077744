import styled from '@emotion/styled';

import { Flex, type FlexProps } from 'antd';

type InvoiceProps = {
  selected: boolean;
};

export const Invoice = styled(Flex)<FlexProps & InvoiceProps>`
  height: 76px;
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.bgDarkGrey : theme.colors.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.strokeGrey};
  cursor: pointer;
  padding: 16px 12px 16px 24px;
  position: relative;

  &:after {
    content: '';
    width: 6px;
    height: 6px;
    background-color: ${({ theme }) => theme.colors.error};
    border-radius: 50%;
    position: absolute;
    top: 23px;
    left: 12px;
  }

  &:last-child {
    border-bottom: none;
  }
`;

export const Name = styled.div`
  color: ${({ theme }) => theme.colors.error};
  font-weight: 600;
  margin-bottom: 4px;
`;

export const Details = styled.div`
  color: ${({ theme }) => theme.colors.error};
  font-size: 12px;
`;
