import { FC } from 'react';
import { Button } from 'antd';

import { PaymentDocumentImportInvoice, PaymentDocumentType } from '@entities';
import { useTranslate } from '@hooks';
import { colors } from '@theme';
import { IconUpload } from '@assets';

import { Schemas } from '@api-client/generated/types';

import * as S from './styled';

type PaymentDocumentDropFile = {
  type: PaymentDocumentType;
  onUpdate?: (file: Schemas.Document) => void;
};

const PaymentDocumentDropFile: FC<PaymentDocumentDropFile> = ({
  type,
  onUpdate,
}) => {
  const { translate } = useTranslate();

  const translationPrefix = type === 'income' ? 'income' : 'expenses';

  return (
    <PaymentDocumentImportInvoice documentType={type} onUpdate={onUpdate}>
      <S.Container>
        <IconUpload color={colors.textBlue400} />

        <S.Title>{translate(`${translationPrefix}.dropFile.title`)}</S.Title>

        <S.Description>
          {translate(`${translationPrefix}.dropFile.description`)}
        </S.Description>

        <Button size="small">
          {translate(`${translationPrefix}.dropFile.buttonSelect`)}
        </Button>
      </S.Container>
    </PaymentDocumentImportInvoice>
  );
};

export default PaymentDocumentDropFile;
