import { Flex, type TableColumnType } from 'antd';

import { Schemas } from '@api-client/generated/types';
import { LocalePaths } from '@locales';

import * as S from './styled';

type TableColumnsProps = {
  translate: (key: LocalePaths) => string;
};

export const getTableColumns = ({
  translate,
}: TableColumnsProps): TableColumnType<Schemas.InvoiceItem>[] => [
  {
    key: 'description',
    dataIndex: 'description',
    title: translate('invoiceGenerator.document.tableColumns.description'),
    render: (description, { name }) => (
      <Flex vertical>
        <S.Name>{name}</S.Name>
        <span>{description}</span>
      </Flex>
    ),
  },
  {
    key: 'unitPrice',
    dataIndex: 'unitPrice',
    title: translate('invoiceGenerator.document.tableColumns.price'),
    align: 'right',
    render: (price) => `€${price}`,
  },
  {
    key: 'taxRate',
    dataIndex: 'taxRate',
    title: translate('invoiceGenerator.document.tableColumns.rate'),
    align: 'right',
    render: (rate) => `${(rate || 0) * 100}%`,
  },
  {
    key: 'quantity',
    dataIndex: 'quantity',
    title: translate('invoiceGenerator.document.tableColumns.quantity'),
    align: 'right',
    render: (quantity, { unit }) => `${quantity} ${unit}`,
  },
  {
    key: 'totalPrice',
    dataIndex: 'totalPrice',
    title: translate('invoiceGenerator.document.tableColumns.total'),
    align: 'right',
    render: (total) => `€${total}`,
  },
];
