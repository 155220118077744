import { FC } from 'react';
import _ from 'lodash';

import { Row, Col } from 'antd';

import { useTranslate, useOnboarding } from '@hooks';

import { Schemas } from '@api-client/generated/types';

import * as S from './styled';

const OnboardingAbout: FC = () => {
  const { translate } = useTranslate();

  const { incorporationDetails } = useOnboarding();

  const details = incorporationDetails as Schemas.Incorporation;

  if (!details) {
    return null;
  }

  return (
    <S.Wrapper>
      <S.Title level={5}>
        {translate('onboarding.aboutCompany.aboutTitle')}
      </S.Title>

      <S.InfoCell>
        <Row gutter={[24, 0]}>
          {!_.isEmpty(details?.companyNames) && (
            <Col span={12}>
              <S.InfoLabel>
                {translate('onboarding.aboutCompany.name')}
              </S.InfoLabel>

              <S.InfoValue>{details.companyNames[0]}</S.InfoValue>
            </Col>
          )}

          {details.companyType && (
            <Col span={12}>
              <S.InfoLabel>
                {translate('onboarding.aboutCompany.type')}
              </S.InfoLabel>

              <S.InfoValue>{details.companyType.toUpperCase()}</S.InfoValue>
            </Col>
          )}
        </Row>
      </S.InfoCell>

      {details.companyActivity && (
        <S.InfoCell>
          <S.InfoLabel>
            {translate('onboarding.aboutCompany.activity')}
          </S.InfoLabel>

          <S.InfoValue>{details.companyActivity}</S.InfoValue>
        </S.InfoCell>
      )}

      {!_.isEmpty(details.people) && (
        <S.InfoCell>
          <Row gutter={[24, 0]}>
            <Col span={12}>
              <S.InfoLabel>
                {translate('onboarding.aboutCompany.shareholders')}
              </S.InfoLabel>

              <S.InfoValue>
                {details.people?.filter((item) => item.isShareholder).length}
              </S.InfoValue>
            </Col>

            <Col span={12}>
              <S.InfoLabel>
                {translate('onboarding.aboutCompany.managers')}
              </S.InfoLabel>

              <S.InfoValue>
                {details.people?.filter((item) => item.isDirector).length}
              </S.InfoValue>
            </Col>
          </Row>
        </S.InfoCell>
      )}

      {details.address && (
        <S.InfoCell>
          <S.InfoLabel>
            {translate('onboarding.aboutCompany.registeredAddress')}
          </S.InfoLabel>

          <S.InfoValue>{`${details.address.city}, ${details.address.addressLine}, ${details.address.houseNumber}, ${details.address.postalCode}`}</S.InfoValue>
        </S.InfoCell>
      )}
    </S.Wrapper>
  );
};

export default OnboardingAbout;
