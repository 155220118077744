import styled from '@emotion/styled';
import { IconDocuments } from '@assets';
import { Flex, Typography } from 'antd';

export const Container = styled(Flex)`
  height: 100%;
  padding: 80px;
`;

export const Icon = styled(IconDocuments)`
  color: ${({ theme }) => theme.colors.white};
  padding: 30px;
  height: 120px;
  width: 120px;
`;

export const IconContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.text200};
  height: 120px;
  width: 120px;
  border-radius: 50%;
`;

export const Title = styled(Typography.Title)`
  && {
    padding: 0;
    margin-bottom: 0;
  }
`;
