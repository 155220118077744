import { Flex } from 'antd';
import { useTranslate } from '@hooks';

const FileErrorBlock = () => {
  const { translate } = useTranslate();
  return (
    <Flex gap={7} justify="center" align="center" vertical>
      <span>{translate('legal.noCompanyDocuments')}</span>
      <span>{translate('legal.addDocumentToDisplay')}</span>
    </Flex>
  );
};

export default FileErrorBlock;
