import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { mediaWidth } from '@styles/mediaWidth';

type ContainerProps = {
  isAutoHeight?: boolean;
};

export const Container = styled.div<ContainerProps>`
  min-height: ${({ isAutoHeight }) => (isAutoHeight ? 'auto' : '748px')};
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 20px;
  box-shadow: 0 4px 24px 0 rgba(6, 24, 60, 0.08);
  display: flex;
  flex-direction: column;
  padding: 32px;
  position: relative;

  ${mediaWidth.tablet(css`
    padding: 20px;
  `)};
`;
