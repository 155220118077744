import styled from '@emotion/styled';
import { Row } from 'antd';

export const Inner = styled.div`
  .ui-form-item {
    margin-bottom: 20px;
  }
`;

export const BalanceRow = styled(Row)`
  background-color: ${({ theme }) => theme.colors.badgeGrey};
  padding: 20px;
  border-radius: 12px;
  margin-bottom: 20px;
`

export const Hint = styled.div`
  margin-bottom: 5px;
  font-size: 12px;
`;