import { Schemas } from '@api-client/generated/types';
import { useAccount, useOnboarding, useTranslate } from '@hooks';
import { useUpdateIncorporation } from '@hooks-api';
import { Flex, Form, Input, message, Typography } from 'antd';
import { FC, PropsWithChildren } from 'react';

type KYCTinProps = {
  person: Schemas.Person | Schemas.UBO;
};

const { Paragraph, Title } = Typography;

const KYCTin: FC<PropsWithChildren<KYCTinProps>> = ({ person }) => {
  const { translate, tDynamic } = useTranslate();
  const { companyId } = useAccount();

  const { incorporationDetails, updateIncorporation } = useOnboarding();

  const [form] = Form.useForm();

  const [update] = useUpdateIncorporation();

  const errorCode = person?.kycData?.tinErrorCode;

  const onFinish = () => {
    const currentKycData = person.kycData || {};
    person.kycData = {
      ...currentKycData,
      ...form.getFieldsValue(),
      tinErrorCode: null,
    };

    update(
      {
        parameter: {
          companyId: companyId!,
        },
        requestBody: {
          people: incorporationDetails!.people,
        },
      },
      {
        onSuccess: () => {
          message.success(translate('onboarding.saved'));
        },
      }
    );

    updateIncorporation({ people: incorporationDetails!.people });
  };

  return (
    <Flex gap={20} vertical>
      <div>
        <Title level={5}>{translate('onboarding.kyc.tin.title')}</Title>

        <Paragraph type="secondary">
          {translate('onboarding.kyc.tin.description')}
        </Paragraph>
      </div>

      <Form
        form={form}
        layout="vertical"
        colon={false}
        initialValues={person.kycData}
      >
        <Form.Item
          name="tin"
          label={translate('onboarding.kyc.legal.tin')}
          validateStatus={errorCode ? 'error' : ''}
          help={errorCode && tDynamic(`onboarding.kyc.errors.${errorCode}`)}
        >
          <Input size="large" onBlur={onFinish} />
        </Form.Item>
      </Form>
    </Flex>
  );
};

export default KYCTin;
