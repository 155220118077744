import {
  createContext,
  FC,
  type PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';

import { useTranslate } from '@hooks';
import { Schemas } from '@api-client/generated/types';

type Preview = Schemas.CSVPreview;
type Mapping = Schemas.CSVMapping;
type AttributeMapping = Schemas.AttributeMapping;

type StepEntity = {
  name?: string;
  status: string;
  key?: string;
  required?: boolean;
  error: string | null;
};

type Fields = {
  required: StepEntity[];
  optional: StepEntity[];
};

type FieldType = 'required' | 'optional';

type TransactionsImportContextProps = {
  fields: Fields;
  mapping: Mapping;
  reviewMapping: Mapping;
  preview: Preview;
  isFileUploaded: boolean;
  isPreviewVisible: boolean;
  statusGroupRequired: string;
  statusGroupOptional: string;
  setMapping: (
    mapping: Partial<Mapping>,
    isFirstLoading?: boolean,
    key?: string,
    columnNumber?: number
  ) => void;
  setPreview: (preview: Preview) => void;
  setPreviewVisible: (status: boolean) => void;
  setFileUploaded: (status: boolean) => void;
};

export const TransactionsImportContext =
  createContext<TransactionsImportContextProps>(
    {} as TransactionsImportContextProps
  );

const amountTypes = ['debit', 'credit', 'debitCredit'];

export const TransactionsImportProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const { translate } = useTranslate();

  const fields = {
    required: [
      {
        key: 'bookingDate',
        name: translate('transactionsImport.popup.columns.bookingDate'),
        status: 'default',
        error: null,
      },
      {
        key: 'amount',
        name: translate('transactionsImport.popup.columns.amount'),
        status: 'default',
        error: null,
      },
      {
        key: 'currencyCode',
        name: translate('transactionsImport.popup.columns.currencyCode'),
        status: 'default',
        error: null,
      },
    ],
    optional: [
      {
        key: 'details',
        name: translate('transactionsImport.popup.columns.details'),
        status: 'default',
        error: null,
      },
      {
        key: 'contactName',
        name: translate('transactionsImport.popup.columns.contactName'),
        status: 'default',
        error: null,
      },
    ],
  };

  const [fileMapping, setFileMapping] = useState<Partial<Mapping>>({
    topRowsSkipAmount: 0,
    bottomRowsSkipAmount: 0,
  });

  const [lastChangedKey, setLastChangedKey] = useState<{
    key: string;
    columnNumber: number;
  }>();

  const [filePreview, setFilePreview] = useState<Preview>();
  const [fieldsList, setFieldsList] = useState<Fields>(fields);
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);
  const [isFileUploaded, setIsFileUploaded] = useState(false);

  useEffect(() => {
    updateFieldByMapping('bookingDate', 'required');
    updateFieldByMapping('debit', 'required');
    updateFieldByMapping('credit', 'required');
    updateFieldByMapping('debitCredit', 'required');
    updateFieldByMapping('currencyCode', 'required');

    updateFieldByMapping('details', 'optional');
    updateFieldByMapping('contactName', 'optional');
  }, [fileMapping]);

  const updateFieldByMapping = (key: string, type: FieldType) => {
    const anySkipped = (
      fileMapping[key as keyof typeof fileMapping] as AttributeMapping
    )?.isSkipped;
    const debitSkipped = fileMapping.debit?.isSkipped;
    const creditSkipped = fileMapping.credit?.isSkipped;
    const debitCreditSkipped = fileMapping.debitCredit?.isSkipped;

    const hasAny = _.has(fileMapping, key) && !anySkipped;
    const hasDebit = _.has(fileMapping, 'debit') && !debitSkipped;
    const hasCredit = _.has(fileMapping, 'credit') && !creditSkipped;
    const debitCredit =
      _.has(fileMapping, 'debitCredit') && !debitCreditSkipped;

    if ((debitCredit || (hasDebit && hasCredit)) && amountTypes.includes(key)) {
      return setFieldsList((prevState) => ({
        ...prevState,
        [type]: prevState[type].map((field) =>
          field.key === 'amount'
            ? { ...field, status: 'success', error: null }
            : field
        ),
      }));
    }

    if ((hasDebit && !hasCredit) || (!hasDebit && hasCredit)) {
      return setFieldsList((prevState) => ({
        ...prevState,
        [type]: prevState[type].map((field) =>
          field.key === 'amount'
            ? {
                ...field,
                status: 'error',
                error: translate('transactionsImport.popup.errors.amount'),
              }
            : field
        ),
      }));
    }

    if (hasAny) {
      return setFieldsList((prevState) => ({
        ...prevState,
        [type]: prevState[type].map((field) =>
          field.key === key
            ? { ...field, status: 'success', error: null }
            : field
        ),
      }));
    }
  };

  const setMapping = (
    mapping: Partial<Mapping>,
    isFirstLoading: boolean = false,
    key?: string,
    columnNumber?: number
  ) => {
    const hasDebit = _.has(mapping, 'debit');
    const hasCredit = _.has(mapping, 'credit');
    const hasDebitCredit = _.has(mapping, 'debitCredit');

    if (hasDebitCredit) {
      delete fileMapping['debit'];
      delete fileMapping['credit'];
    }

    if (hasDebit || hasCredit) {
      delete fileMapping['debitCredit'];
    }

    if (
      hasDebit &&
      mapping['debit']?.columnNumber === fileMapping['credit']?.columnNumber
    ) {
      delete fileMapping['credit'];
    }

    if (
      hasCredit &&
      mapping['credit']?.columnNumber === fileMapping['debit']?.columnNumber
    ) {
      delete fileMapping['debit'];
    }

    if (isFirstLoading) {
      normalizeDataTypes(mapping);
    }

    if (key) {
      // @ts-expect-error-next-line
      setLastChangedKey({ key, columnNumber });
    }

    setFileMapping((prevState) => ({
      ...prevState,
      ...(typeof mapping === 'string' ? {} : mapping),
    }));
  };

  useEffect(() => {
    const updatedMapping = { ...fileMapping };
    for (const existingKey of Object.keys(updatedMapping)) {
      if (
        // @ts-expect-error-next-line
        updatedMapping[existingKey].columnNumber ===
          lastChangedKey?.columnNumber &&
        existingKey !== lastChangedKey?.key
      ) {
        // @ts-expect-error-next-line
        delete updatedMapping[existingKey];
      }

      setFileMapping(updatedMapping);
    }
  }, [lastChangedKey]);

  const setPreview = (preview: Preview) => setFilePreview(preview);

  const setPreviewVisible = (status: boolean) => setIsPreviewVisible(status);

  const setFileUploaded = (status: boolean) => setIsFileUploaded(status);

  const validateGroupFields = (type: FieldType) => {
    if (fieldsList[type].every((field) => field.status === 'success')) {
      return 'success';
    }

    if (fieldsList[type].map((field) => field.status).includes('error')) {
      return 'error';
    }

    return 'default';
  };

  const normalizeDataTypes = (mapping: Partial<Mapping>) =>
    Object.keys(mapping).map((key) => {
      const field = mapping[key as keyof typeof mapping] as AttributeMapping;

      if (field.isSkipped) {
        field.isSkipped = String(field.isSkipped) === 'true';
      }

      if (field.columnNumber) {
        field.columnNumber = +field.columnNumber;
      }
    });

  const reviewFields = () => {
    const mapping = { ...fileMapping };

    if (!fileMapping.debitCredit) {
      mapping['debitCredit'] = { isSkipped: true } as AttributeMapping;
    }

    if (!fileMapping.credit) {
      mapping['credit'] = { isSkipped: true } as AttributeMapping;
    }

    if (!fileMapping.debit) {
      mapping['debit'] = { isSkipped: true } as AttributeMapping;
    }

    if (!fileMapping.details) {
      mapping['details'] = { isSkipped: true } as AttributeMapping;
    }

    if (!fileMapping.contactName) {
      mapping['contactName'] = { isSkipped: true } as AttributeMapping;
    }

    return mapping;
  };

  return (
    <TransactionsImportContext.Provider
      value={{
        fields: fieldsList,
        mapping: fileMapping as Mapping,
        reviewMapping: reviewFields() as Mapping,
        preview: filePreview as Preview,
        statusGroupRequired: validateGroupFields('required'),
        statusGroupOptional: validateGroupFields('optional'),
        isFileUploaded,
        isPreviewVisible,
        setMapping,
        setPreview,
        setPreviewVisible,
        setFileUploaded,
      }}
    >
      {children}
    </TransactionsImportContext.Provider>
  );
};
