import { FC } from 'react';

import { type ModalProps, Button, Flex, Form } from 'antd';

import {
  InvoiceWrapperModal,
  InvoiceWrapperCard,
  InvoiceWrapperFormControl,
} from '@entities';

import { useTranslate } from '@hooks';

import * as S from './styled';

type InvoiceDto = any;

type InvoiceBankAddProps = {
  onSubmit?: (values: Partial<InvoiceDto>) => void;
  onCancel: () => void;
} & ModalProps;

const InvoiceBankAdd: FC<InvoiceBankAddProps> = ({
  open,
  onSubmit,
  onCancel,
}) => {
  const { translate } = useTranslate();

  const [form] = Form.useForm();

  const handleSubmit = (values: InvoiceDto) => {
    onSubmit?.(values);
  };

  return (
    <InvoiceWrapperModal
      open={open}
      title={translate('invoiceGenerator.addBank.title')}
      onCancel={onCancel}
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        requiredMark={false}
      >
        <InvoiceWrapperCard>
          <InvoiceWrapperFormControl
            type="input"
            form={{
              label: translate('invoiceGenerator.addBank.fieldIBAN.label'),
              name: 'iban',
            }}
            control={{
              placeholder: translate(
                'invoiceGenerator.addBank.fieldIBAN.placeholder'
              ),
            }}
          />

          <InvoiceWrapperFormControl
            type="input"
            form={{
              label: translate('invoiceGenerator.addBank.fieldBIC.label'),
              name: 'bic',
            }}
            control={{
              placeholder: translate(
                'invoiceGenerator.addBank.fieldBIC.placeholder'
              ),
            }}
          />
        </InvoiceWrapperCard>

        <S.Submit>
          <Form.Item noStyle>
            <Flex justify="flex-end" gap={12}>
              <Button onClick={onCancel}>
                {translate('invoiceGenerator.buttonCancel')}
              </Button>

              <Button type="primary" htmlType="submit">
                {translate('invoiceGenerator.buttonAdd')}
              </Button>
            </Flex>
          </Form.Item>
        </S.Submit>
      </Form>
    </InvoiceWrapperModal>
  );
};

export default InvoiceBankAdd;
