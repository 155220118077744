import { CSSProperties, FC } from 'react';
import * as S from './styled';
import { useTranslate } from '@hooks';

type CategoryActionInputType = {
  isSubItem: boolean;
  inputValue?: string;
  setInputValue: (value: string) => void;
  handleSubmit?: () => void;
  handleClose?: () => void;
  containerStyles?: CSSProperties;
};

const MainCategoryActionInput: FC<CategoryActionInputType> = ({
  isSubItem,
  inputValue,
  setInputValue,
  handleSubmit,
  handleClose,
  containerStyles,
}) => {
  const { translate } = useTranslate();
  return (
    <S.FlexContainer issubitem={isSubItem} style={containerStyles}>
      <S.AddGroupContainer issubitem={isSubItem}>
        <S.AddGroupInput
          issubitem={isSubItem}
          width={185}
          height={25}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
        />
        <S.AddButton onClick={handleSubmit} disabled={inputValue?.length === 0}>
          {translate('categoriesPage.save')}
        </S.AddButton>
      </S.AddGroupContainer>
      <S.CloseIcon width="14px" height="14px" onClick={handleClose} />
    </S.FlexContainer>
  );
};

export default MainCategoryActionInput;
