import styled from '@emotion/styled';

import { type SelectVisualType } from './Select';

type ContainerProps = {
  visualType?: SelectVisualType;
};

export const Container = styled.div<ContainerProps>`
  ${({ visualType, theme }) =>
    visualType === 'fill-rounded' &&
    `
      && {
        .ui-select-selector {
          background-color: ${theme.colors.badgeBlue100};
          border-color: ${theme.colors.badgeBlue100};
          border-radius: 40px;
          color: ${theme.colors.text400};
          font-weight: 500;
        }
      }
    `};

  ${({ visualType, theme }) =>
    visualType === 'default' &&
    `
      && {
        .ui-select-selector {
          background-color: ${theme.colors.bgGrey};
          border-color: ${theme.colors.bgGrey};
          border-radius: 12px;
          color: ${theme.colors.text400};
          font-weight: 500;
        }
      }
    `};
`;
