//
// Generated by @himenon/openapi-typescript-code-generator v0.27.4
//
// OpenApi : 3.0.0
//
//
import {
  Params$TaskFileController_create,
  Response$TaskFileController_create$Status$201,
  ResponseContentType$TaskFileController_create,
} from '@api-client/generated/client';
import { useApiClientMutation } from '@hooks/useApiClientMutation';

export const useTaskFileController_create = () =>
  useApiClientMutation<
    Params$TaskFileController_create,
    Response$TaskFileController_create$Status$201[ResponseContentType$TaskFileController_create]
  >({ method: 'TaskFileController_create' });
