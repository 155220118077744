import { FC } from 'react';

import { Row, Col } from 'antd';

import { OnboardingCompanyTypeCard } from '@entities';

const OnboardingCompanyType: FC = () => (
  <Row gutter={[12, 0]}>
    <Col span={12}>
      <OnboardingCompanyTypeCard type="sarlS" price={490} />
    </Col>

    <Col span={12}>
      <OnboardingCompanyTypeCard type="sarl" price={1650} />
    </Col>
  </Row>
);

export default OnboardingCompanyType;
