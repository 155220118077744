import * as S from './styled';
import { Button, Flex } from 'antd';
import { useTransactionsImport, useTranslate } from '@hooks';
import {
  TransactionImportSidebarFirstStep,
  TransactionImportSidebarSecondStep,
} from '@entities/index';
import { FC } from 'react';
import { Schemas } from '@api-client/generated/types';

type TransactionsImportSidebarType = {
  onReview: () => void;
  onImport: () => void;
  onBack: () => void;
  validatingResult: Schemas.ValidationResult | null;
  isPreviewVisible: boolean;
  loading: boolean;
};

const TransactionsImportSidebar: FC<TransactionsImportSidebarType> = ({
  onReview,
  onImport,
  onBack,
  validatingResult,
  isPreviewVisible,
  loading,
}) => {
  const { translate } = useTranslate();
  const { mapping } = useTransactionsImport();
  const { debit, credit, debitCredit, bookingDate } = mapping;

  const isDisabled =
    !bookingDate ||
    bookingDate?.isSkipped === true ||
    ((!debit ||
      debit?.isSkipped === true ||
      !credit ||
      credit?.isSkipped === true) &&
      (!debitCredit || debitCredit.isSkipped));

  return (
    <S.Aside>
      <S.FlexWrapper justify="space-between" gap={32} vertical>
        <S.InstructionSteps gap={12} vertical>
          <S.StepTitle>
            {isPreviewVisible
              ? translate('transactionsImport.sidebar.errorTitle')
              : translate('transactionsImport.sidebar.title')}
          </S.StepTitle>
          <TransactionImportSidebarFirstStep
            isPreviewVisible={isPreviewVisible}
          />
          <TransactionImportSidebarSecondStep
            validatingResult={validatingResult}
          />
        </S.InstructionSteps>

        {isPreviewVisible ? (
          <Flex justify="space-between">
            <S.PreviewButton size="small" type="default" onClick={onBack}>
              {translate('transactionsImport.sidebar.buttonBack')}
            </S.PreviewButton>
            <S.PreviewButton
              size="small"
              type="primary"
              disabled={!validatingResult?.success}
              onClick={onImport}
              loading={loading}
            >
              {translate('transactionsImport.sidebar.buttonImport')}
            </S.PreviewButton>
          </Flex>
        ) : (
          <Button
            size="small"
            type="primary"
            disabled={isDisabled}
            onClick={onReview}
            loading={loading}
          >
            {translate('transactionsImport.sidebar.buttonPreview')}
          </Button>
        )}
      </S.FlexWrapper>
    </S.Aside>
  );
};

export default TransactionsImportSidebar;
