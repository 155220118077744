import { Params$AuthController_acceptInvite } from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';

import { useApiClientMutation } from '@hooks';

const useAuthAcceptInvite = () => {
  return useApiClientMutation<
    Params$AuthController_acceptInvite,
    Schemas.AcceptCompanyInviteDto
  >({
    method: 'AuthController_acceptInvite',
  });
};

export default useAuthAcceptInvite;
