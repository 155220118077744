import type { Select } from '@components';
import { LocalePaths } from '@locales';

export type SelectOptions = NonNullable<
  Parameters<typeof Select>[0]['options']
>;

export type Control<
  TOptionValue extends string,
  TSelectorFieldName extends string,
  TOperatorFieldName extends string,
> = {
  selector: {
    type: 'select';
    uniqueValue?: boolean;
    props: Parameters<typeof Select>[0] & {
      placeholder: LocalePaths;
      options: (SelectOptions[number] & {
        value: TOptionValue;
        label: LocalePaths;
      })[];
    };
    fieldName: TSelectorFieldName;
  };
  operator: Partial<Record<TOptionValue, Operator<TOperatorFieldName>>>;
};

export type SelectOperator<TOperatorFieldName extends string> =
  | {
      type: 'select';
      fieldName: TOperatorFieldName;
      translateOptions?: false;
      props: Parameters<typeof Select>[0] & {
        placeholder: LocalePaths;
        options: SelectOptions[number];
      };
    }
  | {
      type: 'select';
      fieldName: TOperatorFieldName;
      translateOptions: true;
      props: Parameters<typeof Select>[0] & {
        placeholder: LocalePaths;
        options: (SelectOptions[number] & {
          label: LocalePaths;
        })[];
      };
    };

export type Operator<TOperatorFieldName extends string> = (
  | {
      type: 'input';
      fieldName: TOperatorFieldName;
    }
  | SelectOperator<TOperatorFieldName>
)[];

export type FormValues<
  TOptionValue extends string,
  TSelectorFieldName extends string,
  TOperatorFieldName extends string,
> = Partial<
  Record<TOperatorFieldName, string> & {
    [K in TSelectorFieldName]: TOptionValue;
  }
>;

export const operator: Operator<'operator' | 'value'> = [
  {
    type: 'select',
    translateOptions: true,
    props: {
      placeholder: 'rules.modal.placeholders.operator',
      width: 150,
      options: [
        {
          label: 'rules.modal.operators.contains',
          value: 'contains',
        },
        {
          label: 'rules.modal.operators.equals',
          value: 'eq',
        },
        {
          label: 'rules.modal.operators.startsWith',
          value: 'starts_with',
        },
      ],
    },
    fieldName: 'operator',
  },
  {
    type: 'input',
    fieldName: 'value',
  },
];
