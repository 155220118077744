import { DatePicker, type DatePickerProps } from 'antd';
import { Dayjs } from 'dayjs';

import styled from '@emotion/styled';

const BaseDatePicker: React.ComponentType<
  DatePickerProps & {
    onChange: (date: Dayjs, dateString: string | string[]) => void;
  }
> = DatePicker;

export const DatePickerHidden = styled(BaseDatePicker)`
  && {
    &.ui-picker {
      &:not(:hover) {
        background-color: transparent;
        border-color: transparent;
      }

      &.ui-picker-focused {
        background-color: ${({ theme }) => theme.colors.white};
        border-color: ${({ theme }) => theme.colors.primary};

        &:hover {
          background-color: ${({ theme }) => theme.colors.white};
          border-color: ${({ theme }) => theme.colors.primary};
        }
      }
    }
  }

  .ui-picker-suffix {
    display: none;
  }
`;
