import styled from '@emotion/styled';
import {
  Modal,
  Pagination as AntdPagination,
  Typography,
} from 'antd';

export const TableContainer = styled.div`
  .ui-table-row {
    cursor: pointer;
  }
`;

export const ModalPaper = styled(Modal)`
  && {
    .ui-modal-content {
      min-height: 550px;
      max-height: 80vh;
    }
    .ui-modal-body {
      overflow-y: auto;
    }
    .ui-modal-footer {
      position: absolute;
      bottom: 20px;
      right: 20px;
      left: 20px;
    }
    .ui-table-expanded-row-fixed {
      min-height: 400px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const Pagination = styled(AntdPagination)`
  .ui-pagination-item {
    border-radius: 50%;
  }
  .ui-pagination-item-active {
    background-color: ${({ theme }) => theme.colors.primary};
    & > a {
      color: ${({ theme }) => theme.colors.white};
    }
    & > a:hover {
      color: ${({ theme }) => theme.colors.white};
    }
  }
`;

export const Divider = styled.div`
  height: 35px;
  border-right: 2px solid ${({ theme }) => theme.colors.strokeDarkGrey};
  margin: 0 10px 0px 5px;
`;

export const Text = styled(Typography.Text)`
  color: ${({ theme }) => theme.colors.text400};
  font-weight: 500;
`;
