import styled from '@emotion/styled';
import { rgba } from 'emotion-rgba';

export const Container = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.bgDarkGrey};
  box-shadow: 0 3px 8px 0 ${({ theme }) => rgba(theme.colors.dark, 0.1)};
  border-radius: 10px;
  padding: 30px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`;

export const UploadContainer = styled.div`
  width: 100%;
  height: 608px;
  border: 1px dashed ${({ theme }) => theme.colors.primary};
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  text-align: center;
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 600;
  font-size: 16px;
  margin-top: 10px;
`;
