//
// Generated by @himenon/openapi-typescript-code-generator v0.27.4
//
// OpenApi : 3.0.0
//
//
import {
  Params$CategoryGroupController_updateOneById,
  Response$CategoryGroupController_updateOneById$Status$200,
  ResponseContentType$CategoryGroupController_updateOneById,
} from '@api-client/generated/client';
import { useApiClientMutation } from '@hooks/useApiClientMutation';

export const useCategoryGroupController_updateOneById = () =>
  useApiClientMutation<
    Params$CategoryGroupController_updateOneById,
    Response$CategoryGroupController_updateOneById$Status$200[ResponseContentType$CategoryGroupController_updateOneById]
  >({ method: 'CategoryGroupController_updateOneById' });
