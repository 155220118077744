import { Params$UserController_removeFromCompany } from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';

import { useApiClientMutation } from '@hooks';

const useRemoveUserFromCompanyById = () => {
  const response = useApiClientMutation<
    Params$UserController_removeFromCompany,
    Schemas.CompanyInvite
  >({
    method: 'UserController_removeFromCompany',
  });
  return response;
};

export default useRemoveUserFromCompanyById;
