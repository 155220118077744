import { FC } from 'react';
import { Flex, Button } from 'antd';

import { useTranslate } from '@hooks';
import { IconClose } from '@assets';
import { PaymentDocumentType } from '@entities';

import { Schemas } from '@api-client/generated/types';

import * as S from './styled';

type IncomeInvoiceProcessingProps = {
  type: PaymentDocumentType;
  file?: Schemas.Document;
  onDelete?: () => void;
};

const PaymentDocumentInvoiceProcessing: FC<IncomeInvoiceProcessingProps> = ({
  type,
  file,
  onDelete,
}) => {
  const { translate } = useTranslate();

  const translationPrefix = type === 'income' ? 'income' : 'expenses';

  return (
    <S.Invoice align="center" justify="space-between">
      <Flex vertical>
        <S.Name data-testid="file-name">{file?.name}</S.Name>
        <S.Processing>{translate(`${translationPrefix}.statuses.processing`)}</S.Processing>
      </Flex>

      <Button
        type="text"
        icon={<IconClose />}
        size="small"
        onClick={onDelete}
        data-testid="button-delete"
      />
    </S.Invoice>
  );
};

export default PaymentDocumentInvoiceProcessing;
