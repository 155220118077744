import { Flex, type FlexProps, Typography } from 'antd';

import IconEditUnderline from '@assets/icons/edit-underline-colored.svg';
import styled from '@emotion/styled';

export const Card = styled(Flex)<FlexProps>`
  width: 830px;
  min-height: 1200px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  border: 2px solid ${({ theme }) => theme.colors.bgDarkGrey};
  padding: 50px;
  font-weight: 500;

  .ui-btn-link {
    width: 20px;
    height: 20px;
  }
`;

export const Header = styled(Flex)<FlexProps>`
  margin-bottom: 12px;
`;

export const Title = styled(Typography.Title)`
  && {
    font-size: 28px;
    margin-bottom: 0;
  }

  .ui-input {
    width: 100%;
    height: 35px;
    font-size: 28px;
  }
`;

export const Name = styled.div`
  width: 130px;
`;

export const SubTitle = styled(Typography.Title)`
  && {
    &.ui-typography {
      font-size: 18px;
      margin: 0;
    }
  }
`;

export const Details = styled(Flex)<FlexProps>``;

export const DetailsTitle = styled(Typography.Title)`
  && {
    font-size: 20px;
    margin-bottom: 0;
  }
`;

export const DetailsType = styled.div`
  font-size: 16px;
  font-weight: 600;
`;

export const ButtonAddVat = styled.a`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 12px;
  font-weight: 600;
`;

export const Table = styled(Flex)<FlexProps>`
  margin: 60px 0;

  .ui-table {
    .ui-table-container {
      &:after {
        display: none;
      }
    }

    .ui-table-content {
      overflow: inherit !important;
    }

    .ui-table-thead {
      tr {
        height: 32px;

        .ui-table-cell {
          color: ${({ theme }) => theme.colors.text400};
          padding-inline: 8px;
        }
      }
    }

    .ui-table-tbody {
      font-size: 12px;

      .ui-table-row {
        cursor: pointer;
        position: relative;

        &:after {
          content: '';
          width: 24px;
          height: 24px;
          background: url(${IconEditUnderline}) no-repeat center / contain;
          position: absolute;
          top: 16px;
          right: -36px;
          transition: opacity 0.12s;
          opacity: 0;
        }

        &:hover {
          &:after {
            opacity: 1;
          }
        }

        .ui-table-cell {
          padding-inline: 8px;
        }
      }

      .ui-table-placeholder {
        display: none;
      }
    }
  }
`;

export const Control = styled(Flex)<FlexProps>`
  border-bottom: 1px solid ${({ theme }) => theme.colors.bgBrightBlue};

  .ui-input {
    padding: 0;
    resize: none;
  }
`;

export const ControlLabel = styled(Flex)<FlexProps>`
  font-weight: 600;
`;

export const Address = styled.div`
  width: 100%;
  text-align: right;
`;
