import styled from '@emotion/styled';

import { type ButtonProps, Button } from 'antd';

export const Container = styled.div`
  width: calc(100vw - 560px);
  max-height: 625px;
  
  .ui-table {
    .ui-table-thead {
      .ui-table-cell {
        padding-block: 0;
      }
    }

    .row-disabled {
      opacity: 0.4;
    }
  }
`;

export const ButtonEyeOff = styled(Button)<ButtonProps>`
  color: #6f7280;
`;

export const SkippedRows = styled.div`
  width: 1000px;
  background-color: ${({ theme }) => theme.colors.bgLightBlue};
  border-radius: 37px;
  color: ${({ theme }) => theme.colors.primary};
  padding: 18px;
  font-size: 16px;
  font-weight: 500;
  margin: 24px 0;
  text-align: center;
`;

export const Cell = styled.div`
  width: 170px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
