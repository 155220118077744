import { FC } from 'react';

import { AccountItem, AccountsListEmpty } from '@entities';
import { Loader } from '@components';

import { Schemas } from '@api-client/generated/types';

import * as S from './styled';

type Account = Schemas.Account;

type AccountsListProps = {
  accounts: Account[];
  loading: boolean;
  onEdit?: (account: Account) => void;
  onImportModalOpen?: (value: boolean) => void;
  onAccountIdChange?: (id: string) => void;
};

const AccountsList: FC<AccountsListProps> = ({
  accounts,
  loading,
  onEdit,
  onImportModalOpen,
  onAccountIdChange,
}) => {
  if (loading) {
    return (
      <S.LoaderWrapper align="center" justify="center">
        <Loader />
      </S.LoaderWrapper>
    );
  }

  return (
    <S.Grid>
      {accounts?.length
        ? accounts.map((account) => (
            <AccountItem
              key={account.id}
              logo={`./${account.connection?.bank.bic}.png`}
              account={account}
              onEdit={onEdit}
              onImportModalOpen={onImportModalOpen}
              onAccountIdChange={onAccountIdChange}
            />
          ))
        : null}

      {!accounts?.length && !loading ? <AccountsListEmpty /> : null}
    </S.Grid>
  );
};

export default AccountsList;
