import { FC, useState } from 'react';

import { Typography, Button, Flex } from 'antd';

import { OnboardingCardHeader, OnboardingRadioControl } from '@entities';
import { useTranslate, useOnboarding, useAccount } from '@hooks';

import * as S from './styled';
import { useUpdateIncorporation } from '@hooks-api';

const { Paragraph } = Typography;

const OnboardingLegalAddress: FC = () => {
  const { translate } = useTranslate();
  const { updateScreen, updateIncorporation, incorporationDetails } =
    useOnboarding();

  const [update, loading] = useUpdateIncorporation();

  const { companyId } = useAccount();

  const defaultValue = incorporationDetails?.usesOwnRegisteredAddress
    ? 'my'
    : 'finding';

  const [selectedOption, setSelectedOption] = useState<string | null>(
    defaultValue
  );

  const handleNext = () => {
    if (selectedOption === 'finding') {
      update(
        {
          parameter: {
            companyId: companyId!,
          },
          requestBody: {
            isSubmittedToKyc: true,
          },
        },
        {
          onSuccess: () => {
            updateIncorporation({ isSubmittedToKyc: true });
            updateScreen('MESSAGE_PROCESS_DOCUMENTS', { active: true });
          },
        }
      );
    }

    if (selectedOption === 'my') {
      update(
        {
          parameter: {
            companyId: companyId!,
          },
          requestBody: {
            usesOwnRegisteredAddress: true,
          },
        },
        {
          onSuccess: () => {
            updateScreen('PROVIDE_ADDRESS', { active: true });
          },
        }
      );
    }
  };

  return (
    <S.Container>
      <S.Inner>
        <OnboardingCardHeader
          title={translate('onboarding.legalAddress.title')}
          description={
            <Paragraph strong>
              {translate('onboarding.legalAddress.description')}
            </Paragraph>
          }
        />

        <OnboardingRadioControl
          defaultValue={defaultValue}
          onSelected={setSelectedOption}
          controls={[
            {
              value: 'finding',
              title: translate('onboarding.legalAddress.finding.title'),
              content: translate('onboarding.legalAddress.finding.description'),
            },
            {
              value: 'my',
              title: translate('onboarding.legalAddress.my.title'),
              content: translate('onboarding.legalAddress.my.description'),
            },
          ]}
        />
      </S.Inner>

      <Flex justify="flex-end">
        <Button
          type="link"
          onClick={() => updateScreen('REVIEW_DOCUMENTS', { active: true })}
        >
          {translate('onboarding.buttonGoBack')}
        </Button>

        <Button type="primary" onClick={handleNext} loading={loading}>
          {translate('onboarding.buttonNext')}
        </Button>
      </Flex>
    </S.Container>
  );
};

export default OnboardingLegalAddress;
