import styled from '@emotion/styled';

export const View = styled.div`
  img {
    width: 440px;
    border-radius: 12px;
    border: 4px solid ${({ theme }) => theme.colors.strokeDarkGrey};
  }

  iframe {
    width: 440px;
    height: 622px;
    border-radius: 12px;
    border: 4px solid ${({ theme }) => theme.colors.strokeDarkGrey};
  }
`;
