import { useParams } from 'react-router-dom';

import { useTaskController_findAll } from '@api-client/generated/TaskController/findAll';
import styled from '@emotion/styled';
import { PageMeta } from '@entities';
import { useAccount } from '@hooks';

import Item from './Item';
import { List } from './List';

const Wrapper = styled.div`
  display: flex;
  gap: 15px;
  flex-direction: column;
  height: 100%;
`;

export const ChatSidebar = () => {
  const { id: selectedId } = useParams();
  const { companyId } = useAccount();

  if (!companyId) {
    throw new Error('Company ID is not defined');
  }

  const { data: tasks } = useTaskController_findAll({
    params: {
      companyId,
    },
  });

  const generalChat = tasks?.find((task) => task.isGeneral);

  return (
    <>
      <PageMeta title={t('chat.title')()} />
      <Wrapper>
        {generalChat && (
          <Item
            isSelected={selectedId === generalChat.id}
            details={generalChat}
            isGeneral
          />
        )}
        <List
          items={tasks?.filter((item) => !item.isGeneral)}
          selectedId={selectedId}
        />
      </Wrapper>
    </>
  );
};
