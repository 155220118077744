import { useEffect, useState } from 'react';

import { useCategoryGroupController_findAll } from '@api-client/generated/CategoryGroupController/findAll';
import { Schemas } from '@api-client/generated/types';
import { MainCategoryBlock, PageMeta } from '@entities';
import { useAccount, useTranslate } from '@hooks';

import * as S from './styled';

export type SubItemType = {
  issubitem?: boolean;
  isdragging?: boolean;
  isempty?: boolean;
  isin?: boolean;
};

export type ActiveGroupType = {
  group: string;
  flowType: string;
  name: string;
} | null;

export type ActiveBlockType = {
  title: string;
  variant: string;
} | null;

const Categories = () => {
  const { companyId } = useAccount();
  const { translate } = useTranslate();

  const { data: categoryGroups, refetch: refetchCategoryGroups } =
    useCategoryGroupController_findAll({
      params: {
        companyId: companyId!,
      },
    });

  const groups: {
    title: string;
    group: 'operational' | 'financial' | 'investments';
    data?: {
      money_in?: Schemas.CategoryGroup[];
      money_out?: Schemas.CategoryGroup[];
    };
  }[] = [
    {
      title: translate('categoriesPage.mainCategories.operational'),
      group: 'operational',
      data: categoryGroups?.operational,
    },
    {
      title: translate('categoriesPage.mainCategories.financial'),
      group: 'financial',
      data: categoryGroups?.financial,
    },
    {
      title: translate('categoriesPage.mainCategories.investments'),
      group: 'investments',
      data: categoryGroups?.investments,
    },
  ];

  const categoryGroupVariants: Array<'in' | 'out'> = ['in', 'out'];

  const [activeBlock, setActiveBlock] = useState<ActiveBlockType>({
    title: '',
    variant: '',
  });

  const [activeGroup, setActiveGroup] = useState<ActiveGroupType>({
    group: '',
    flowType: '',
    name: '',
  });

  const [isSubItemDragged, setIsSubItemDragged] = useState(false);

  const handleDragSubItem = (value: boolean) => {
    setIsSubItemDragged(value);
  };

  const handleSetActiveBlock = (item: ActiveBlockType) => {
    setActiveBlock(item);
  };

  const handleSetActiveGroup = (item: ActiveGroupType) => {
    setActiveGroup(item);
  };

  useEffect(() => {
    if (!isSubItemDragged) {
      setActiveBlock({
        title: '',
        variant: '',
      });
    }
  }, [isSubItemDragged]);

  return (
    <>
      <PageMeta title={translate('categoriesPage.title')} />

      {groups.map((group) => (
        <S.Container key={group.title}>
          <S.Title>{group.title}</S.Title>
          <S.Variant>
            {categoryGroupVariants.map((variant) => (
              <S.VariantItem
                key={variant + group.title}
                style={{
                  boxShadow:
                    group.title.toLowerCase() ===
                      activeBlock?.title.toLowerCase() &&
                    variant === activeBlock?.variant &&
                    !isSubItemDragged
                      ? '0px 3px 8px 0px rgba(0, 0, 0, 0.40)'
                      : 'none',
                }}
              >
                <MainCategoryBlock
                  key={group.title + variant}
                  title={group.title}
                  group={group.group}
                  variant={variant}
                  inOutData={
                    variant === 'in'
                      ? group.data?.money_in || []
                      : group.data?.money_out || []
                  }
                  activeGroup={activeGroup}
                  isSubItemDragged={isSubItemDragged}
                  refetchCategories={refetchCategoryGroups}
                  handleDragSubItem={handleDragSubItem}
                  setActiveGroup={handleSetActiveGroup}
                  setActiveBlock={handleSetActiveBlock}
                />
              </S.VariantItem>
            ))}
          </S.Variant>
        </S.Container>
      ))}
    </>
  );
};

export default Categories;
