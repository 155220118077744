import { type InputNumberProps } from 'antd';
import { FC } from 'react';

import { MinusOutlined, PlusOutlined } from '@ant-design/icons';

import * as S from './styled';

type InputNumberValue = number | string | null;

export type InputNumberInlineProps = {
  onChange?: (value?: InputNumberValue) => void;
} & InputNumberProps;

const InputNumberInline: FC<InputNumberInlineProps> = ({
  min,
  max,
  value,
  onChange,
  ...rest
}) => {
  const handleChange = (value: InputNumberValue) => {
    onChange?.(value);
  };

  const handleMinus = () => {
    if (value && value !== null) {
      onChange?.(min && value <= min ? min : +value - 1);
    }
  };

  const handlePlus = () => {
    if (value && value !== null) {
      onChange?.(max && value >= max ? max : +value + 1);
    }
  };

  return (
    <S.Container align="center">
      <S.Action icon={<MinusOutlined />} onClick={handleMinus} />

      <S.InputNumberInline
        value={value}
        controls={false}
        size="large"
        onChange={handleChange}
        {...rest}
      />

      <S.Action icon={<PlusOutlined />} onClick={handlePlus} />
    </S.Container>
  );
};

export default InputNumberInline;
