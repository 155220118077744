import { FC } from 'react';

import { BrandLogotype } from '@assets';

import * as S from './styled';

const Logotype: FC = () => (
  <S.Logotype to="/transactions">
    <BrandLogotype />
  </S.Logotype>
);

export default Logotype;
