export const inverseNumberValue = (
  value: string | number,
  fullAmount?: number
) => {
  const valueNumber = parseFloat(String(value));
  const roundedValue = Math.round(valueNumber * 100) / 100;

  return fullAmount && fullAmount < 0
    ? roundedValue < 0
      ? roundedValue
      : -roundedValue
    : roundedValue > 0
      ? roundedValue
      : -roundedValue;
};
