import {
  FC,
  Fragment,
  RefCallback,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { UserOutlined } from '@ant-design/icons';
import { Schemas } from '@api-client/generated/types';
import Scrollbar from '@components/Scrollbar';
import { PopupDelete } from '@entities';
import { useAccount } from '@hooks';

import Message from './Message/Message';
import UnreadDivider from './UnreadDivider';
import * as S from './styled';
import {
  getGroupedMessagesById,
  isUnread,
  shouldShowUnreadDivider,
} from './transformData';

type ChatMessage = Schemas.ChatMessage;

type MessagesListProps = {
  selectedId: string;
  dataSource: ChatMessage[];
  onSeenMessages: (ids: string[]) => void;
  onRemove: (id: string) => void;
};

const MessagesList: FC<MessagesListProps> = ({
  selectedId,
  dataSource,
  onSeenMessages,
  onRemove,
}) => {
  const { account } = useAccount();

  if (!account) {
    throw new Error('Account is not defined');
  }

  const [isOpenPopupConfirm, setIsOpenPopupConfirm] = useState(false);
  const [selectedRemoveId, setSelectedRemoveId] = useState<string | null>(null);

  const [shouldScrollToLatestUnread, setShouldScrollToLatestUnread] =
    useState<boolean>(true);

  const scrollToLastUnread: RefCallback<HTMLDivElement> = useCallback(
    (latestUnreadMessage) => {
      if (latestUnreadMessage && shouldScrollToLatestUnread) {
        latestUnreadMessage.scrollIntoView({ behavior: 'instant' });

        setShouldScrollToLatestUnread(false);
      }
    },
    [shouldScrollToLatestUnread]
  );

  const lastMessage = dataSource.at(-1);

  const endOfList: RefCallback<HTMLDivElement> = useCallback(
    (endOfListElemeng) => {
      if (lastMessage?.author.id === account.id && endOfListElemeng) {
        endOfListElemeng.scrollIntoView({ behavior: 'smooth' });
      }
    },
    [account.id, lastMessage]
  );

  useEffect(() => {
    setShouldScrollToLatestUnread(true);
  }, [selectedId]);

  const getPositionMessage = (id: string) =>
    account.id !== id ? 'left' : 'right';

  const groupedMessages = useMemo(
    () => getGroupedMessagesById(dataSource),
    [dataSource]
  );

  const handleRemoveCancel = () => {
    setIsOpenPopupConfirm(false);
    setSelectedRemoveId(null);
  };

  const handleRemoveMessage = () => {
    setIsOpenPopupConfirm(false);

    if (selectedRemoveId) {
      onRemove(selectedRemoveId);
    }
  };

  const isRead = !!lastMessage && !isUnread(lastMessage, account.id);

  return (
    <>
      <Scrollbar height="calc(100vh - 418px)" withoutPadding>
        {groupedMessages.map(({ author, messages }, index) => (
          <S.Group
            justify={getPositionMessage(author.id)}
            key={index}
            vertical
            align={account.id !== author.id ? 'flex-start' : 'flex-end'}
          >
            {messages.map((message, messageIndex) => (
              <Fragment key={message.id}>
                {shouldShowUnreadDivider(
                  groupedMessages,
                  account.id,
                  index,
                  messageIndex
                ) && <UnreadDivider ref={scrollToLastUnread} />}
                <S.MessageWrapper files={!!message.files}>
                  {!messageIndex && (
                    <S.Header position={getPositionMessage(author.id)}>
                      {author.fullName}
                    </S.Header>
                  )}
                  <Message
                    key={message.id}
                    position={getPositionMessage(author.id)}
                    onRemove={() => {
                      setIsOpenPopupConfirm(true);
                      setSelectedRemoveId(message.id);
                    }}
                    onRead={onSeenMessages}
                    message={message}
                  />
                  {messageIndex === messages.length - 1 &&
                    account.id !== author.id && (
                      <S.Avatar size={40} icon={<UserOutlined />} />
                    )}
                </S.MessageWrapper>
              </Fragment>
            ))}
          </S.Group>
        ))}
        {isRead && <div ref={scrollToLastUnread} />}
        <div ref={endOfList} />
      </Scrollbar>
      <PopupDelete
        open={isOpenPopupConfirm}
        title={t('chatLive.deleteMessage.title')()}
        confirmParams={{
          text: t('chatLive.deleteMessage.buttonYes')(),
        }}
        onRemove={handleRemoveMessage}
        onCancel={handleRemoveCancel}
      />
    </>
  );
};

export default MessagesList;
