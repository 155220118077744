import { Flex, Form, Input, message, Select, Typography } from 'antd';
import { FC, PropsWithChildren } from 'react';

import { Schemas } from '@api-client/generated/types';
import { useAccount, useOnboarding, useTranslate } from '@hooks';
import { useUpdateIncorporation } from '@hooks-api';

type KYCFundsProps = {
  person: Schemas.Person | Schemas.UBO;
};

const { Paragraph, Title } = Typography;

const KYCFunds: FC<PropsWithChildren<KYCFundsProps>> = ({ person }) => {
  const { translate, tDynamic } = useTranslate();

  const { companyId } = useAccount();

  const { incorporationDetails, updateIncorporation } = useOnboarding();

  const [form] = Form.useForm();

  const [update] = useUpdateIncorporation();

  const errorCode = person?.kycData?.externalFundsBackgroundErrorCode;

  const onFinish = () => {
    const currentKycData = person.kycData || {};
    person.kycData = {
      ...currentKycData,
      ...form.getFieldsValue(),
      externalFundsBackgroundErrorCode: null,
    };

    update(
      {
        parameter: {
          companyId: companyId!,
        },
        requestBody: {
          people: incorporationDetails!.people,
        },
      },
      {
        onSuccess: () => {
          message.success(translate('onboarding.saved'));
        },
      }
    );

    updateIncorporation({ people: incorporationDetails!.people });
  };

  return (
    <Flex gap={20} vertical>
      <div>
        <Title level={5}>{translate('onboarding.kyc.funds.title')}</Title>

        <Paragraph type="secondary">
          {translate('onboarding.kyc.funds.description')}
        </Paragraph>
      </div>

      <Form
        form={form}
        layout="vertical"
        colon={false}
        initialValues={person.kycData}
      >
        <Form.Item
          name="hasExternalFunds"
          label={translate(
            'onboarding.kyc.legalKycForm.hasExternalFunds.label'
          )}
        >
          <Select
            placeholder={translate(
              'onboarding.kyc.legalKycForm.hasExternalFunds.placeholder'
            )}
            options={[
              {
                label: translate(
                  'onboarding.kyc.legalKycForm.hasExternalFunds.values.yes'
                ),
                value: 'yes',
              },
              {
                label: translate(
                  'onboarding.kyc.legalKycForm.hasExternalFunds.values.no'
                ),
                value: 'no',
              },
            ]}
            size="large"
            onChange={onFinish}
            virtual
          />
        </Form.Item>

        <Form.Item
          name="externalFundsBackground"
          label={translate(
            'onboarding.kyc.legalKycForm.externalFundsBackground'
          )}
          validateStatus={errorCode ? 'error' : ''}
          help={errorCode && tDynamic(`onboarding.kyc.errors.${errorCode}`)}
        >
          <Input
            size="large"
            disabled={person.kycData?.hasExternalFunds !== 'yes'}
            onBlur={onFinish}
          />
        </Form.Item>
      </Form>
    </Flex>
  );
};

export default KYCFunds;
