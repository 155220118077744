import { FC } from 'react';

import { type ListProps, List } from 'antd';

import { ContactItem, ContactsEmpty } from '@entities';

import { Schemas } from '@api-client/generated/types';

type Contact = Schemas.Contact;

type ContactsListProps = {
  selectedId: string;
  dataSource: Contact[];
  isFilterParams: boolean;
  onAdd: () => void;
} & ListProps<Contact>;

const ContactsList: FC<ContactsListProps> = ({
  selectedId,
  dataSource,
  isFilterParams,
  loading,
  onAdd,
  ...rest
}) => {
  if (!dataSource.length && !isFilterParams && !loading) {
    return <ContactsEmpty onAdd={onAdd} />;
  }

  return (
    <List
      dataSource={dataSource}
      renderItem={(item) => (
        <ContactItem selectedId={selectedId} details={item} />
      )}
      locale={{
        emptyText: <div />,
      }}
      loading={loading}
      {...rest}
    />
  );
};

export default ContactsList;
