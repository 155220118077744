import { FC, PropsWithChildren, ReactNode } from 'react';

import * as S from './styled';

type PageContainerWithBarProps = {
  fixedContent?: ReactNode;
};

const PageContainerWithBar: FC<
  PropsWithChildren<PageContainerWithBarProps>
> = ({ children, fixedContent }) => (
  <S.Container>
    {children}
    {fixedContent && <S.Fixed>{fixedContent}</S.Fixed>}
  </S.Container>
);

export default PageContainerWithBar;
