import styled from '@emotion/styled';
import { Flex, Typography } from 'antd';

export const Container = styled(Flex)`
  display: flex;
  flex-direction: column;
  max-width: 712px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.bgGrey};
  padding: 20px;
  gap: 20px;
  background: ${({ theme }) => theme.colors.white};
  margin: 15px 0 15px 3px;
  box-shadow: 0 3px 8px 0 #0000001a;
`;

export const Variant = styled(Flex)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Title = styled(Typography)`
  font-weight: 600;
  font-size: 20px;
`;

export const VariantItem = styled(Flex)`
  height: fit-content;
  padding-bottom: 10px;
`;
