import { Params$TransactionController_updateOneById } from '@api-client/generated/client';

import { useApiClientMutation } from '@hooks';
import { Schemas } from '@api-client/generated/types';

const useUpdateTransactionById = () => {
  const transaction = useApiClientMutation<
    Params$TransactionController_updateOneById,
    Schemas.Transaction
  >({
    method: 'TransactionController_updateOneById',
  });
  return transaction;
};

export default useUpdateTransactionById;
