import { useMemo } from 'react';

import { Params$IncorporationController_findOneById } from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';
import { type QueryOptions, useApiClient } from '@hooks';

type Incorporation = Schemas.Incorporation;
type Params = Params$IncorporationController_findOneById;

type IncorporationProps = {
  params: Params['parameter'];
  config?: QueryOptions<Incorporation>;
};

const useGetIncorporation = ({ config, params }: IncorporationProps) => {
  const {
    data: incorporation,
    loading,
    refetch,
  } = useApiClient<Params, Incorporation>({
    method: 'IncorporationController_findOneById',
    params: {
      parameter: params,
    },
    config,
  });

  return useMemo(
    () => ({
      incorporation,
      loading,
      refetch,
    }),
    [incorporation, loading, refetch]
  );
};

export default useGetIncorporation;
