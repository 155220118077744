import styled from '@emotion/styled';
import { rgba } from 'emotion-rgba';

import { Flex, Form, Input } from 'antd';

const { Item } = Form;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
`;

export const CompanyForm = styled(Form)`
  flex: 1;
`;

export const FullWidthFlex = styled(Flex)`
  width: 100%;
`;

export const BlockTitle = styled.span`
  color: ${({ theme }) => theme.colors.black};
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 20px;
`;

export const CompanyDetailsContainer = styled(Flex)`
  max-width: 855px;
  height: 100%;
  flex-direction: column;
  padding: 30px;
  box-shadow: 0 3px 8px 0 ${({ theme }) => rgba(theme.colors.dark, 0.1)};
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.bgGrey};
`;

export const CompanyDetailsItem = styled(Flex)`
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
`;

export const SmallFormItem = styled(Item)`
  width: 195px;
  margin-bottom: 0;
`;

export const MediumFormItem = styled(Item)`
  width: 300px;
`;

export const LargeFormItem = styled(Item)`
  && > .ui-row {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
  }
  && > .ui-row > .ui-col {
    text-align: start;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const FlexInner = styled(Flex)`
  margin: 30px 0;
`;

export const NameInput = styled(Input)`
  font-size: 36px;
  font-weight: 600;
  outline: none;
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.colors.strokeGrey};
  border-radius: 0;
  padding: 0;
`;

export const NameFormItem = styled(Item)`
  width: 290px;
  margin-top: 20px;
  margin-bottom: 30px;
`;

export const Text = styled.span`
  font-size: 14px;
  font-weight: 500;
  margin-left: 10px;
`;

export const LoaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => rgba(theme.colors.dark, 0.1)};
  height: 100%;
  width: 100%;
  z-index: 2;
`;
