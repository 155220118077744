import { Params$ContactsController_delete } from '@api-client/generated/client';

import { useApiClientMutation } from '@hooks';
import { Schemas } from '@api-client/generated/types';

const useDeleteContact = () => {
  return useApiClientMutation<
    Params$ContactsController_delete,
    Schemas.Contact
  >({
    method: 'ContactsController_delete',
  });
};

export default useDeleteContact;
