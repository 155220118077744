import { useEffect, useState } from 'react';

const usePDFScaling = (
  initialWidth: number,
  stepSize: number,
  magnifierMax: number
) => {
  const [scale, setScale] = useState(1.0);

  const minWidth = initialWidth / magnifierMax;
  const maxWidth = initialWidth * magnifierMax;

  const scaleUp = () => {
    setScale((prevScale) =>
      Math.min(prevScale + stepSize, maxWidth / initialWidth)
    );
  };

  const scaleDown = () => {
    setScale((prevScale) =>
      Math.max(prevScale - stepSize, minWidth / initialWidth)
    );
  };

  const scaleReset = () => {
    const resetScale = 1.0;
    setScale(resetScale);
  };

  useEffect(() => {
    scaleReset();
  }, []);
  return {
    scale,
    scaleUp,
    scaleDown,
    scaleReset,
  };
};

export default usePDFScaling