import styled from '@emotion/styled';
import { Button, Flex, Input } from 'antd';
import { rgba } from 'emotion-rgba';
import { SubItemType } from '../../../pages/Categories/Categories';
import { IconClose } from '@assets';

export const FlexContainer = styled(Flex)<SubItemType>`
  justify-content: space-between;
  align-items: center;
  width: ${({ issubitem }) => (issubitem ? '278px' : '326px')};
  margin-top: 8px;
  margin-left: ${({ issubitem }) => (issubitem ? '24px' : 0)};
`;

export const AddGroupContainer = styled(Flex)<SubItemType>`
  justify-content: space-between;
  align-items: center;
  width: ${({ issubitem }) => (issubitem ? '250px' : '300px')};
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  padding: 6px 8px;
  box-shadow: 0 4px 4px 0 ${({ theme }) => rgba(theme.colors.dark, 0.1)};
`;

export const AddGroupInput = styled(Input)<SubItemType>`
  width: ${({ issubitem }) => (issubitem ? '145px' : '185px')};
  padding: 0;
  background: ${({ theme }) => theme.colors.white};
  outline: none;
  border-radius: 0;
  border: none;
`;

export const AddButton = styled(Button)`
  width: 68px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CloseIcon = styled(IconClose)`
  color: ${({ theme }) => theme.colors.error};
  cursor: pointer;
`;
