import { useEffect, useState } from 'react';

import { Form, type FormInstance } from 'antd';

type FormValidateFieldsParams<T> = {
  form: FormInstance;
  disabledForm: boolean;
  valuesForm: T | null;
};

const useFormValidateFields = <T>(): FormValidateFieldsParams<T> => {
  const [form] = Form.useForm();
  const values = Form.useWatch([], form);

  const [disabledForm, setDisabledForm] = useState(true);
  const [valuesForm, setValuesForm] = useState<T | null>(null);

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then((values) => {
        setValuesForm(values);
        setDisabledForm(false);
      })
      .catch(() => {
        setDisabledForm(true);
      });
  }, [form, values]);

  return {
    form,
    disabledForm,
    valuesForm,
  };
};

export default useFormValidateFields;
