import { Flex } from 'antd';
import { FC } from 'react';

import { Schemas } from '@api-client/generated/types';
import { IconPlus } from '@assets';
import { ButtonDashed } from '@components';
import { ContactsPopupList } from '@entities';
import { useTranslate } from '@hooks';
import { getAddressLine } from '@utils';

import * as S from './styled';

type Contact = Schemas.Contact;

type InvoiceDocumentDetailsToProps = {
  contact: Contact | null;
  onSelect: (contact: Contact) => void;
};

const InvoiceDocumentDetailsTo: FC<InvoiceDocumentDetailsToProps> = ({
  contact,
  onSelect,
}) => {
  const { translate } = useTranslate();

  return (
    <S.Details gap={8} isBordered={!!contact} vertical>
      <S.DetailsTitle>
        {translate('invoiceGenerator.document.to')}
      </S.DetailsTitle>

      {contact ? (
        <Flex align="flex-start" vertical>
          <S.DetailsType>{contact.name}</S.DetailsType>

          <div>
            {translate('invoiceGenerator.document.vatId')}:{' '}
            {contact?.vatId || '-'}
          </div>

          <div>
            {translate('invoiceGenerator.document.taxNumber')}:{' '}
            {contact?.taxNumber || '-'}
          </div>

          <div>{getAddressLine(contact?.billingAddress)}</div>

          <ContactsPopupList
            placement="bottomLeft"
            selectedId={contact?.id}
            onSelect={onSelect}
          >
            <S.Edit className="document-contact-edit">
              {translate('invoiceGenerator.document.buttonEdit')}
            </S.Edit>
          </ContactsPopupList>
        </Flex>
      ) : (
        <ContactsPopupList onSelect={onSelect}>
          <ButtonDashed icon={<IconPlus />} height={115} block>
            {translate('invoiceGenerator.document.buttonAddContactInformation')}
          </ButtonDashed>
        </ContactsPopupList>
      )}
    </S.Details>
  );
};

export default InvoiceDocumentDetailsTo;
