import { FC, useState } from 'react';
import { Button, Flex, Space } from 'antd';
import { useDebouncedCallback } from 'use-debounce';

import {
  AttachSupplementaryModalItem,
  EmptyDocuments,
  PreviewFileModal,
} from '@entities';
import { useGetDocumentsList } from '@hooks-api';
import { useAccount, useTranslate } from '@hooks';
import { IconClose } from '@assets';
import { Loader } from '@components';

import { Schemas } from '@api-client/generated/types';

import * as S from './styled';

type File = Schemas.Document;

type TransactionAttachSupplementaryModalProps = {
  open: boolean;
  onCancel: () => void;
  onAttachDocuments?: (ids: string[]) => void;
  attachedFiles: File[];
};

const TransactionAttachSupplementaryModal: FC<
  TransactionAttachSupplementaryModalProps
> = ({ open, onCancel, onAttachDocuments, attachedFiles }) => {
  const { companyId } = useAccount();
  const { translate } = useTranslate();

  const [filterSearch, setFilterSearch] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [selectedDocuments, setSelectedDocuments] = useState<File[]>(
    attachedFiles.filter(
      ({ type }) => type !== 'income_document' && type !== 'expence_document'
    )
  );
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [previewFile, setPreviewFile] = useState<File | null>(null);

  const { data, loading } = useGetDocumentsList({
    params: {
      companyId: companyId!,
      term: filterSearch || undefined,
      page: currentPage,
      perPage: 8,
      withUrl: true,
    },
    config: {
      onSuccess: (data) => {
        setCurrentPage(data.metadata.currentPage);
        setTotalRecords(data.metadata.totalRecords);
      },
    },
  });

  const onChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleCheck = (file: File) => {
    setSelectedDocuments(
      selectedDocuments.map((document) => document.id).includes(file.id)
        ? selectedDocuments.filter((item) => item.id !== file.id)
        : [...selectedDocuments, file]
    );
  };

  const handleView = (file: File) => {
    setIsPreviewOpen(true);
    setPreviewFile(file);
  };

  const handleSearch = useDebouncedCallback((value) => {
    setFilterSearch(value);
    setCurrentPage(1);
  }, 200);

  const handleOk = () => {
    onAttachDocuments?.(selectedDocuments.map((document) => document.id));
    onCancel();
  };

  const handleClosePreview = () => {
    setIsPreviewOpen(false);
    setPreviewFile(null);
  };

  const footer = (
    <Flex align="center" justify="space-between">
      <Space split={<S.Divider />}>
        <S.Pagination
          current={currentPage}
          onChange={onChange}
          showSizeChanger={false}
          pageSize={8}
          total={totalRecords}
        />
        <S.Text>
          {translate('documentsPage.table.selected', {
            count: selectedDocuments.length,
          })}
        </S.Text>
      </Space>

      <Flex gap={10}>
        <Button onClick={onCancel}>
          {translate('documentsPage.table.cancel')}
        </Button>
        <Button
          onClick={handleOk}
          type="primary"
          disabled={selectedDocuments.length === 0}
        >
          {translate('documentsPage.table.attach')}
        </Button>
      </Flex>
    </Flex>
  );

  return (
    <S.ModalPaper
      open={open}
      title={translate('documentsPage.form.attachDocuments')}
      centered
      closeIcon={<IconClose />}
      width={768}
      footer={footer}
      onCancel={onCancel}
    >
      <Flex gap={16} vertical>
        <S.Search onChange={(e) => handleSearch(e.target.value)} />
        <S.TableContainer gap={8} vertical>
          {loading ? (
            <S.List>
              <Loader />
            </S.List>
          ) : data?.records.length ? (
            data?.records.map((item) => (
              <AttachSupplementaryModalItem
                item={item}
                isChecked={selectedDocuments.some(({ id }) => id === item.id)}
                onCheck={() => handleCheck(item)}
                onView={() => handleView(item)}
              />
            ))
          ) : (
            <S.List>
              <EmptyDocuments />
            </S.List>
          )}
        </S.TableContainer>
      </Flex>

      {isPreviewOpen && previewFile && (
        <PreviewFileModal
          centered
          footer={null}
          open={isPreviewOpen && !!previewFile}
          file={previewFile}
          onCancel={handleClosePreview}
        />
      )}
    </S.ModalPaper>
  );
};

export default TransactionAttachSupplementaryModal;
