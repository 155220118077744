import styled from '@emotion/styled';
import { Link, type LinkProps } from 'react-router-dom';

export const ForgotPassword = styled(Link)<LinkProps>`
  display: inline-flex;
  font-weight: 600;
  margin-bottom: 16px;
`;

export const Error = styled.div`
  margin-bottom: 20px;
  color: ${({ theme }) => theme.colors.error};
  text-align: center;
`;
