import { FC } from 'react';

import { Typography, Flex } from 'antd';

import { useTranslate } from '@hooks';
import {
  IconUser,
  IconUsers,
  IconCreditCard,
  IconUserAdd,
  IconArrowRightLink,
} from '@assets';

import * as S from './styled';
import { PageMeta } from '@entities';

const { Title } = Typography;

const Settings: FC = () => {
  const { translate } = useTranslate();

  const categories = [
    {
      name: translate('settings.personal.title'),
      to: '/settings/personal',
      icon: <IconUser />,
    },
    {
      name: translate('settings.company.title'),
      to: '/settings/company',
      icon: <IconUsers />,
    },
    {
      name: translate('settings.billing.title'),
      to: '/settings/billing',
      icon: <IconCreditCard />,
    },
    {
      name: translate('settings.teamMembers.title'),
      to: '/settings/team-members',
      icon: <IconUserAdd />,
    },
  ];

  return (
    <Flex gap={32} vertical>
      <PageMeta title={translate('settings.title')} />

      <Title>{translate('settings.title')}</Title>

      <S.Categories>
        {categories.map((company) => (
          <S.Category key={company.to} to={company.to}>
            <Flex gap={24}>
              <S.CategoryIcon>{company.icon}</S.CategoryIcon>
              <S.CategoryName>{company.name}</S.CategoryName>
            </Flex>

            <IconArrowRightLink />
          </S.Category>
        ))}
      </S.Categories>
    </Flex>
  );
};

export default Settings;
