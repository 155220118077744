import { Schemas } from '@api-client/generated/types';
import { useAccount, useCountries, useOnboarding, useTranslate } from '@hooks';
import { Flex, Form, Input, Typography, message } from 'antd';
import { FC, PropsWithChildren } from 'react';
import { useUpdateIncorporation } from '@hooks-api';
import { Select } from '@components';

type KYCNationalityProps = {
  person: Schemas.Person | Schemas.UBO;
};

const { Paragraph, Title } = Typography;

const KYCNationality: FC<PropsWithChildren<KYCNationalityProps>> = ({
  person,
}) => {
  const { translate } = useTranslate();
  const { companyId } = useAccount();
  const { countries, filterOptionCountries } = useCountries();

  const { incorporationDetails, updateIncorporation } = useOnboarding();

  const [form] = Form.useForm();

  const [update] = useUpdateIncorporation();

  const onFinish = () => {
    const currentKycData = person.kycData || {};
    person.kycData = {
      ...currentKycData,
      ...form.getFieldsValue(),
    };

    update(
      {
        parameter: {
          companyId: companyId!,
        },
        requestBody: {
          people: incorporationDetails!.people,
        },
      },
      {
        onSuccess: () => {
          message.success(translate('onboarding.saved'));
        },
      }
    );

    updateIncorporation({ people: incorporationDetails!.people });
  };

  return (
    <Flex gap={20} vertical>
      <div>
        <Title level={5}>
          {translate('onboarding.kyc.natural.nationality')}
        </Title>

        <Paragraph type="secondary">
          {translate('onboarding.kyc.naturalDetails.nationality')}
        </Paragraph>
      </div>

      <Form
        form={form}
        layout="vertical"
        colon={false}
        initialValues={person.kycData}
      >
        <Form.Item
          name="nationality"
          label={translate('onboarding.kyc.naturalKycForm.nationality.label')}
        >
          <Select
            placeholder={translate(
              'onboarding.kyc.naturalKycForm.nationality.placeholder'
            )}
            mode="multiple"
            options={countries}
            size="large"
            onChange={onFinish}
            showSearch
            virtual
            filterOption={filterOptionCountries}
          />
        </Form.Item>

        <Form.Item
          name="isUsedAnotherName"
          label={translate('onboarding.kyc.naturalKycForm.anotherName.label')}
        >
          <Select
            placeholder={translate(
              'onboarding.kyc.naturalKycForm.anotherName.placeholder'
            )}
            options={[
              {
                label: translate(
                  'onboarding.kyc.naturalKycForm.anotherName.values.yes'
                ),
                value: 'yes',
              },
              {
                label: translate(
                  'onboarding.kyc.naturalKycForm.anotherName.values.no'
                ),
                value: 'no',
              },
            ]}
            size="large"
            onChange={onFinish}
            virtual
          />
        </Form.Item>

        <Form.Item
          name="anotherNameReasons"
          label={translate('onboarding.kyc.naturalKycForm.reasons.label')}
        >
          <Input
            size="large"
            onBlur={onFinish}
            disabled={person.kycData?.isUsedAnotherName !== 'yes'}
          />
        </Form.Item>
      </Form>
    </Flex>
  );
};

export default KYCNationality;
