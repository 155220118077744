import styled from '@emotion/styled';
import { Flex, Input } from 'antd';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 24px;

  .ui-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
  }
`;

export const Wrapper = styled(Flex)`
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.bgGrey};
  border-radius: 12px;
`;

export const DisabledLikeEnabledInput = styled(Input)`
  background-color: ${({ theme }) => theme.colors.white} !important;
  border: 1px solid #d9d9d9 !important;
  color: ${({ theme }) => theme.colors.black} !important;
`;

export const Header = styled(Flex)`
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 2px solid ${({ theme }) => theme.colors.strokeGrey};
`;

export const HeaderTitle = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.black};
  margin-bottom: 7px;
`;

export const HeaderDescriptionBold = styled.span`
  font-weight: 700;
`;

export const FormDescriptionWrapper = styled.div`
  padding: 20px;
`;

export const FormDescription = styled.span`
  color: ${({ theme }) => theme.colors.text400};
  font-size: 12px;
`;
