import { useMemo } from 'react';

import { Params$IncorporationController_getInvoice } from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';
import { type QueryOptions, useApiClient } from '@hooks';

type Invoice = Schemas.Invoice;
type Params = Params$IncorporationController_getInvoice;

type IncorporationProps = {
  params: Params['parameter'];
  config?: QueryOptions<Invoice>;
};

const useGetIncorporationInvoice = ({ config, params }: IncorporationProps) => {
  const {
    data: invoice,
    loading,
    refetch,
  } = useApiClient<Params, Invoice>({
    method: 'IncorporationController_getInvoice',
    params: {
      parameter: params,
    },
    config,
  });

  return useMemo(
    () => ({
      invoice,
      loading,
      refetch,
    }),
    [invoice, loading, refetch]
  );
};

export default useGetIncorporationInvoice;
