import styled from '@emotion/styled';

export const Submit = styled.div`
  margin-top: 24px;
`;

export const Description = styled.div`
  font-size: 16px;
  font-weight: 400;
`;
