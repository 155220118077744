import {
  Params$UserController_updateUserPermissions,
} from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';

import { useApiClientMutation } from '@hooks';

type Params = Params$UserController_updateUserPermissions;

const useUpdateUserPermissions = () => {
  const permissions = useApiClientMutation<Params, Schemas.User>({
    method: 'UserController_updateUserPermissions',
  });

  return permissions;
};

export default useUpdateUserPermissions;
