import styled from '@emotion/styled';

export const Wrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'noPadding',
})<{ noPadding?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${({ noPadding }) => (noPadding ? 0 : '100px 0')};
`;
