import { FC } from 'react';

import { Row, Col } from 'antd';

import { TaxCard } from '@entities';

import { Schemas } from '@api-client/generated/types';

import * as S from './styled';

type TaxesListProps = {
  label: string;
  taxes: Schemas.Tax[];
  period?: string;
};

const TaxesList: FC<TaxesListProps> = ({ label, taxes = [] }) => (
  <S.Container>
    <S.Label>{label}</S.Label>

    <S.List>
      <Row gutter={[20, 20]}>
        {taxes.map((tax) => (
          <Col span={6} key={tax.id}>
            <TaxCard details={tax} />
          </Col>
        ))}
      </Row>
    </S.List>
  </S.Container>
);

export default TaxesList;
