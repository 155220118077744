import dayjs, { type Dayjs } from 'dayjs';
import { type EChartsOption } from 'echarts-for-react';
import { rgba } from 'emotion-rgba';
import _ from 'lodash';

import { Parameter$TransactionController_getStatsByCategory } from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';
import { colors } from '@theme';
import { formatNumber, getDateRangeByInterval } from '@utils';

type DatePeriods = {
  dateFrom: Dayjs | null;
  dateTo: Dayjs | null;
};

export type ReportRow = Schemas.CategoryReportRow;

export type BreakDown =
  Parameter$TransactionController_getStatsByCategory['breakDown'];

export type FlowType = 'in' | 'out';

const defaultColors = [
  '#131DFF',
  '#139CFF',
  '#B880FF',
  '#EBAF3A',
  '#3CCE99',
  '#FF8888',
  '#B313FF',
];

export const optionBar: EChartsOption = {
  grid: {
    top: 10,
    bottom: 0,
    right: 0,
    left: 10,
    containLabel: true,
  },
  toolbox: {
    show: false,
  },
  color: defaultColors,
  tooltip: {
    trigger: 'axis',
    textStyle: {
      color: colors.secondary,
      fontSize: 14,
    },
    axisPointer: {
      type: 'none',
    },
    valueFormatter: (value: number) => formatNumber(value),
  },
  xAxis: {
    type: 'category',
    axisTick: {
      show: false,
    },
    axisLine: {
      show: false,
    },
    axisLabel: {
      color: rgba(colors.black, 0.7),
      margin: 24,
    },
  },
  yAxis: {
    type: 'value',
    axisLine: {
      lineStyle: {
        color: rgba(colors.black, 0.7),
      },
    },
    axisLabel: {
      margin: 24,
    },
    splitLine: {
      lineStyle: {
        color: rgba(colors.black, 0.05),
      },
    },
  },
};

export const optionPie: EChartsOption = {
  color: defaultColors,
  tooltip: {
    show: false,
  },
  legend: {
    type: 'scroll',
    orient: 'vertical',
    left: 210,
    top: 'middle',
    itemWidth: 8,
    itemHeight: 8,
    itemGap: 20,
    icon: 'circle',
    inactiveColor: rgba(colors.black, 0.7),
    textStyle: {
      color: colors.secondary,
      fontSize: 14,
    },
  },
  series: [
    {
      type: 'pie',
      width: 168,
      center: ['60%', '50%'],
      radius: ['100%', '80%'],
      avoidLabelOverlap: false,
      label: {
        show: false,
      },
      labelLine: {
        show: false,
      },
    },
  ],
};

export const setXAxisDataByInterval = ({
  dateFrom,
  dateTo,
}: DatePeriods): string[] => {
  const intervalDates = getDateRangeByInterval(dateFrom, dateTo, 'month');

  return intervalDates.map((item) => dayjs(item).format('MMM, YY'));
};

export const sumNormalize = (sum: number, flowType: FlowType = 'in') =>
  sum < 0 ? (flowType === 'in' ? sum * -1 : sum) : sum;

export const groupByCategoryName = (data: ReportRow[]) =>
  _.groupBy(
    data.map((item) => ({
      ...item,
      categoryName: item.categoryName || 'Uncategorized',
    })),
    'categoryName'
  );

export const createPieSerieData = (
  data: Record<string, ReportRow[]>,
  dataOriginal: ReportRow[]
) => {
  const totalAmount = getTotalAmount(dataOriginal);

  return Object.keys(data).map((key) => {
    const totalCategory = _.sum(
      data[key].map((item) => sumNormalize(item.sum))
    );

    return {
      name: `${key}: ${formatNumber(totalCategory)} (${_.round((totalCategory / totalAmount) * 100, 2)})%`,
      value: totalCategory,
    };
  });
};

export const createBarSerieData = (data: Record<string, ReportRow[]>) =>
  Object.keys(data).map((key) => ({
    name: key,
    stack: 'total',
    data: data[key].map((item) => [
      dayjs(item.reportDate).format('MMM, YY'),
      item.sum,
    ]),
    type: 'bar',
    barMaxWidth: 24,
  }));

export const getTotalAmount = (data: ReportRow[], flowType?: FlowType) =>
  _.sum(data.map((item) => sumNormalize(item.sum, flowType)));
