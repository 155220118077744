import styled from '@emotion/styled';

import { type FlexProps, Flex, Button } from 'antd';

export const Container = styled.div`
  &:hover {
    .card-actions {
      display: flex;
    }

    .card-details {
      display: none;
    }
  }
`;

export const Inner = styled(Flex)<FlexProps>`
  min-height: 124px;
`;

export const Label = styled.div`
  color: ${({ theme }) => theme.colors.textDisabled};
  font-weight: 500;
  font-size: 9px;
  text-transform: uppercase;
`;

export const Name = styled.div`
  font-weight: 600;
  padding-right: 50px;
`;

export const Description = styled.div`
  font-size: 12px;
`;

export const Amount = styled.div`
  &.ui-typography {
    font-weight: 600;
  }
`;

export const Date = styled.div`
  color: ${({ theme }) => theme.colors.text400};
  font-size: 12px;
`;

export const Actions = styled.div`
  display: none;
  position: absolute;
  bottom: 14px;
  left: 20px;
`;

export const ViewButton = styled(Button)`
  height: 20px;
  padding: 0;
  font-weight: 600;
  line-height: 20px;
  font-size: 14px;
`;
