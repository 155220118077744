import styled from '@emotion/styled';

import { Flex } from 'antd';

export const Container = styled(Flex)`
  width: 440px;
  padding: 16px;
  background-color: ${({ theme }) => theme.colors.bgDarkGrey};
  border-radius: 12px;
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 16px;
`;

export const Description = styled.div`
  color: ${({ theme }) => theme.colors.text400};
  font-size: 12px;
  margin-bottom: 10px;
`;

export const View = styled.div`
  img {
    width: 100%;
    border-radius: 12px;
    border: 4px solid ${({ theme }) => theme.colors.white};
  }

  iframe {
    width: 100%;
    height: 430px;
    border-radius: 12px;
    border: 4px solid ${({ theme }) => theme.colors.white};
  }
`;
