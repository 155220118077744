import styled from '@emotion/styled';
import { Card as AntdCard, Input } from 'antd';

export const Card = styled(AntdCard)`
  padding: 0;

  && {
    .ui-card-head {
      background-color: ${({ theme }) => theme.colors.bgDarkGrey};
      padding: 7px 20px;
    }

    .ui-card-body {
      background-color: ${({ theme }) => theme.colors.badgeGrey};
      padding: 20px 20px 13px 20px;
    }

    .ui-form-item {
      margin-bottom: 0;
    }
  }
`;

export const TextField = styled(Input)`
  width: 390px;
  height: 40px;
`;
