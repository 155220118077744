import styled from '@emotion/styled';

export const Item = styled.a`
  min-width: 260px;
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
  padding: 6px 12px;

  && {
    .ui-radio-wrapper {
      margin-right: 0;
    }

    .ui-collapse {
      width: 100%;

      .ui-collapse-header {
        width: 100%;
        padding: 0;

        .ui-collapse-header-text {
          font-size: 14px;
          font-weight: 400;
        }
      }

      .ui-collapse-content {
        padding-top: 8px;
      }
    }
  }
`;

export const WrapperFormat = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  margin-top: 8px;
  gap: 8px;
`;

export const Link = styled.a`
  height: 54px;
  display: flex;
  align-items: center;
  gap: 10px;

  &:not(:hover) {
    color: #6f7280;
  }
`;
