import { Params$TaxController_markPaid } from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';

import { useApiClientMutation } from '@hooks';

const useMarkPaidTaxById = () => {
  return useApiClientMutation<Params$TaxController_markPaid, Schemas.Tax>({
    method: 'TaxController_markPaid',
  });
};

export default useMarkPaidTaxById;
