import apiClient from '@api-client';
import { type AxiosError, type AxiosRequestConfig } from 'axios';
import { useCallback, useState } from 'react';

type Option = {
  timeout?: AxiosRequestConfig['timeout'];
  signal?: AxiosRequestConfig['signal'];
};

type AdditionalOptions<T> = {
  onSuccess?: (res: T) => void;
  onFailure?: (error: ApiError) => void;
};

type ApiClientMutationProps = {
  // Method name as specified in api-client/generated/client.ts
  method: keyof typeof apiClient;
  // Additional options for managing API requests
  option?: Option;
  // Enabling this option allows you to cancel sending to the server.
};

type ApiErrorData = {
  error: string;
  statusCode: number;
  message: string[];
};

type ApiError = {
  message: string[];
  data: ApiErrorData;
};

/**
 * @description Hook for working with the API client and processing methods
 *
 * @example
 *
 * import { Params$AuthController_signUp } from '@api-client/generated/client';
 * import { useApiClientMutation } from '@hooks';
 *
 * const [signUp, loading, error] = useApiClientMutation<Params$AuthController_signUp>({
 *   method: 'AuthController_signUp',
 * });
 *
 * const handleRequest = () => {
 *   signUp({
 *     requestBody: {
 *       email: 'jondoe@gmail.com',
 *       password: '12345qwerty',
 *     },
 *   });
 * };
 *
 */

export const useApiClientMutation = <T = any, R = null>({
  method,
  option,
}: ApiClientMutationProps): [
  (params: T, opts?: AdditionalOptions<R>) => void,
  boolean,
  R | null,
  ApiError | null,
] => {
  const [apiResponse, setApiResponse] = useState<R | null>(null);
  const [error, setError] = useState<ApiError | null>(null);
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(
    (params: T, { onSuccess, onFailure }: AdditionalOptions<R> = {}) => {
      setLoading(true);

      apiClient[method](params as any, option)
        .then((res) => {
          setApiResponse(res as any);

          onSuccess && onSuccess(res as any);
        })
        .catch((error: AxiosError) => {
          const failure = error.response?.data as ApiError;
          setError(failure);
          setLoading(false);

          onFailure && onFailure(failure);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [method, option]
  );

  return [fetchData, loading, apiResponse, error];
};

export default useApiClientMutation;
