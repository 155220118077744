import { FC, ReactNode } from 'react';

import { GoBack } from '@entities';

import { Space } from 'antd';

import * as S from './styled';
import { useLocation } from 'react-router-dom';

type DetailsHeaderProps = {
  children?: ReactNode;
};

const DetailsHeader: FC<DetailsHeaderProps> = ({ children }) => {
  const { pathname } = useLocation();

  return (
    <S.Header pathname={pathname}>
      <Space split={<S.Divider />}>
        <GoBack />
        {children}
      </Space>
    </S.Header>
  )
};

export default DetailsHeader;
