import { FC, useState } from 'react';

import { CheckOutlined } from '@ant-design/icons';
import { TooltipPlacement } from 'antd/es/tooltip';
import { Button, Tooltip } from 'antd';
import copy from 'copy-to-clipboard';

import { colors } from '@theme';
import { useTranslate } from '@hooks';

import * as S from './styled';

type CopyButtonProps = {
  value: string | undefined;
  icon?: 'chain' | 'copy';
  tooltipPlacement?: TooltipPlacement;
  type?: 'default' | 'link';
};

const CopyButton: FC<CopyButtonProps> = ({
  value,
  icon = 'copy',
  tooltipPlacement = 'top',
  type = 'default',
}) => {
  const { translate } = useTranslate();
  const [copied, setCopied] = useState(false);

  const IconCopy = icon === 'copy' ? S.IconCopy : S.IconChain;

  const handleCopy = () => {
    copy(value!);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <Tooltip
      title={
        copied
          ? translate('common.copied')
          : type === 'link'
            ? translate('common.copyLink')
            : translate('common.copy')
      }
      placement={tooltipPlacement}
    >
      <Button
        size="small"
        type="text"
        onClick={handleCopy}
        icon={copied ? <CheckOutlined color={colors.primary} /> : <IconCopy />}
      />
    </Tooltip>
  );
};

export default CopyButton;
