import styled from '@emotion/styled';

import { IconMinus, IconPlus } from '@assets';

export const Container = styled.div`
  padding: 0 16px 16px 16px;
  
  .ui-table {
    .ui-table-thead {
      tr {
        height: 40px;

        .ui-table-cell {
          color: ${({ theme }) => theme.colors.textBlue400};
          font-weight: 600;
          padding: 8px 16px;

          &:last-child {
            background-color: ${({ theme }) => theme.colors.white};
          }
        }
      }
    }

    .ui-table-tbody {
      .ui-table-cell {
        padding-block: 16px;
      }
      
      tr[data-row-key="operational-money_in"] {
        td {
          border-bottom: 0;
        }
      }

      tr[data-row-key="financial-money_in"] {
        td {
          border-bottom: 0;
        }
      }

      tr[data-row-key="investments-money_in"] {
        td {
          border-bottom: 0;
        }
      }

      .ui-table-row {
        height: 40px;
        
        .ui-table-cell {
          padding: 8px 16px;
          
          &:last-child {
            background-color: ${({ theme }) => theme.colors.white};

            .ui-typography {
              font-weight: 700;
            }
          }
        }

        & > .ui-table-cell {
          padding-inline: 0;
        }

        &.row-with-children {
          cursor: pointer;
        }

        &.row-with-expandable,
        &.row-with-expandable-is-category {
          .ui-table-cell {
            padding-left: 17px;
          }
        }

        &.row-with-expandable-is-category {
          .ui-table-row-indent,
          .ui-table-cell {
            padding: 0 !important;
          }
        }

        &:last-child {
          .ui-table-cell {
            background-color: ${({theme}) => theme.colors.white};

            &:last-child {
              background-color: ${({theme}) => theme.colors.white};
            }
          }
        }
      }
    }
  }
`;

export const GroupCell = styled.div`
  padding-right: 4px;
  text-align: right;

  && {
    .ui-typography {
      cursor: pointer;
      color: ${({ theme }) => theme.colors.black};
      font-weight: 600;

      &:hover {
        opacity: 0.65;
      }
    }
  }
`;

export const Cell = styled.div`
  padding-right: 4px;
  text-align: right;

  && {
    .ui-typography {
      cursor: pointer;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.black};

      &:hover {
        opacity: 0.65;
      }
    }
  }
`;

export const TotalCell = styled.div`
  padding-right: 4px;
  text-align: right;

  && {
    .ui-typography {
      cursor: pointer;
      font-weight: 600;
      color: ${({ theme }) => theme.colors.black};

      &:hover {
        opacity: 0.65;
      }
    }
  }
`;

export const GroupName = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 600;
  padding: 0 16px;
`;

export const SubGroupName = styled.span<{ isTotal?: boolean }>`
  font-weight: ${({ isTotal }) => (isTotal ? 600 : 500)};
  margin-left: ${({ isTotal }) => (isTotal ? '17px' : 0)};
`;

export const ChildGroupName = styled.span`
  font-weight: 500;
`;

export const CategoryName = styled.div`
  height: 40px;
  color: ${({ theme }) => theme.colors.primary};
  display: flex;
  align-items: center;
  font-weight: 500;
  margin-left: 45px;
  padding-left: 35px;
`;

export const ExpandableIconMinus = styled(IconMinus)`
  margin-right: 12px;
  margin-left: 17px;
  position: relative;
  top: 3px;
  color: ${({ theme }) => theme.colors.text200};
  border-radius: 2px;
  border: 1px solid ${({theme}) => theme.colors.text200};
`;

export const ExpandableIconPlus = styled(IconPlus)`
  width: 18px;
  height: 18px;
  margin-right: 12px;
  margin-left: 17px;
  position: relative;
  top: 3px;
  color: ${({ theme }) => theme.colors.text200};
  border-radius: 2px;
  border: 1px solid ${({theme}) => theme.colors.text200};
`;

export const Block = styled.div`
  height: 8px;
`