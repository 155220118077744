import styled from '@emotion/styled';

import { type FlexProps, Flex } from 'antd';

export const Inner = styled(Flex)<FlexProps>`
  .ui-form-item {
    .ui-form-item-label {
      label {
        font-size: 12px;
      }
    }
  }
`;
