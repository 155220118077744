import { Schemas } from '@api-client/generated/types';
import { Scrollbar as BaseScrollbar } from '@components';
import styled from '@emotion/styled';

import Item from './Item';

const Scrollbar = styled(BaseScrollbar)`
  border-radius: 10px;
  border: 1px solid var(--Dark-Grey);
  background: var(--White);
  padding: 0;
`;

export const List = ({
  selectedId,
  items,
}: {
  selectedId?: string;
  items?: Schemas.Task[];
}) => (
  <Scrollbar>
    {items?.map((item) => (
      <Item key={item.id} isSelected={selectedId === item.id} details={item} />
    ))}
  </Scrollbar>
);
