import { Params$ProjectController_delete } from '@api-client/generated/client';

import { useApiClientMutation } from '@hooks';

const useDeleteProject = () => {
  return useApiClientMutation<Params$ProjectController_delete>({
    method: 'ProjectController_delete',
  });
};

export default useDeleteProject;
