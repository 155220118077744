import {
  Button,
  Col,
  Flex,
  Form,
  Input,
  Row,
  Spin,
  Typography,
  Upload,
} from 'antd';
import { FC, Fragment, useEffect, useState } from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import { Schemas } from '@api-client/generated/types';
import { IconRefresh, IconUpload, IconWarningTransparent } from '@assets';
import { OnboardingCardHeader, OnboardingViewDocumentModal } from '@entities';
import {
  useAccount,
  useFormValidateFields,
  useOnboarding,
  useTranslate,
} from '@hooks';
import {
  useCreateCompanyFile,
  useUpdateCompanyFileById,
  useUpdateIncorporation,
} from '@hooks-api';

import * as S from './styled';

const { Paragraph } = Typography;

const ADDRESS_PROOF = 'address_proof';

const OnboardingProvideAddress: FC = () => {
  const { translate } = useTranslate();
  const { companyId } = useAccount();

  const { incorporationDetails, updateScreen, updateIncorporation } =
    useOnboarding();

  const details = incorporationDetails as Schemas.Incorporation;

  const { form, disabledForm } = useFormValidateFields();

  const [update, loading] = useUpdateIncorporation();
  const [createCompanyFile, loadingCreate] = useCreateCompanyFile();
  const [updateCompanyFile, loadingUpdate] = useUpdateCompanyFileById();

  const [isOpenViewModal, setIsOpenViewModal] = useState(false);
  const [selectedViewFile, setSelectedViewFile] =
    useState<Schemas.CompanyFile | null>(null);

  useEffect(() => {
    if (details.address) {
      form.setFieldsValue(details.address);
    }
  }, [details.address, form]);

  const handleBack = () => {
    updateScreen('LEGAL_ADDRESS', { active: true });
  };

  const handleUploadFile = (options: any) => {
    const formData = new FormData();

    formData.append('file', options.file);
    formData.append('name', options.file.name);
    formData.append('personId', 'company');
    formData.append('type', 'address_proof');

    createCompanyFile(
      {
        parameter: {
          companyId: companyId!,
        },
        requestBody: formData as any,
      },
      {
        onSuccess: (response) =>
          updateIncorporation({
            files: {
              address_proof: [response],
            },
          }),
      }
    );
  };

  const handleUpdate = (options: any, id: string) => {
    const formData = new FormData();

    formData.append('file', options.file);
    formData.append('name', options.file.name);

    updateCompanyFile(
      {
        parameter: {
          companyId: companyId!,
          id,
        },
        requestBody: formData as any,
      },
      {
        onSuccess: (response) => {
          const files = details.files;
          files[ADDRESS_PROOF] = files[ADDRESS_PROOF].map(
            (file: Schemas.CompanyFile) =>
              file.id === response.id ? response : file
          );

          updateIncorporation({ files });
        },
      }
    );
  };

  const handleSubmit = (values: Schemas.AddressDto) => {
    update(
      {
        parameter: {
          companyId: companyId!,
        },
        requestBody: {
          address: values,
          isSubmittedToKyc: true,
          isAddressProofFailed: false,
        },
      },
      {
        onSuccess: (response) => {
          updateIncorporation({
            address: {
              ...(details?.address || {}),
              ...response,
            },
            isAddressProofFailed: false,
            isSubmittedToKyc: true,
          });
          updateScreen('MESSAGE_PROCESS_DOCUMENTS', { active: true });
        },
      }
    );
  };

  return (
    <S.Container>
      <OnboardingViewDocumentModal
        open={isOpenViewModal}
        type="company"
        step="addressProof"
        file={selectedViewFile}
        onCancel={() => {
          setIsOpenViewModal(false);
          setSelectedViewFile(null);
        }}
      />

      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        requiredMark={false}
        initialValues={{
          country: 'Luxembourg',
        }}
      >
        <S.Inner>
          <OnboardingCardHeader
            title={translate('onboarding.provideAddress.title')}
            description={
              <>
                <Paragraph>
                  {translate('onboarding.provideAddress.description')}
                </Paragraph>

                <Paragraph strong>
                  {translate('onboarding.provideAddress.descriptionImportant')}
                </Paragraph>
              </>
            }
          />

          <Row>
            <Col span={16}>
              <S.AddressFormWrapper>
                <Row gutter={[12, 0]}>
                  <S.Wrap>
                    <Col span={12}>
                      <Form.Item
                        label={translate('onboarding.uploadAddressForm.street')}
                        name="addressLine"
                        rules={[{ required: true }]}
                      >
                        <Input size="large" />
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      <Form.Item
                        label={translate('onboarding.uploadAddressForm.number')}
                        name="houseNumber"
                        rules={[{ required: true }]}
                      >
                        <Input size="large" />
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      <Form.Item
                        label={translate(
                          'onboarding.uploadAddressForm.postalCode'
                        )}
                        name="postalCode"
                        rules={[{ required: true }]}
                      >
                        <Input size="large" />
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <Form.Item
                        label={translate('onboarding.uploadAddressForm.city')}
                        name="city"
                        rules={[{ required: true }]}
                      >
                        <Input size="large" />
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <Form.Item
                        label={translate(
                          'onboarding.uploadAddressForm.country'
                        )}
                        name="country"
                        rules={[{ required: true }]}
                      >
                        <Input size="large" disabled />
                      </Form.Item>
                    </Col>
                  </S.Wrap>

                  <S.UploadFileCol span={24}>
                    <Form.Item name="file">
                      <S.FormLabel>
                        {translate('onboarding.uploadAddressForm.proof')}
                      </S.FormLabel>

                      {incorporationDetails?.files?.[ADDRESS_PROOF]?.length &&
                        (loadingUpdate ? (
                          <S.Uploading>
                            <Spin indicator={<LoadingOutlined spin />} />
                            {translate('onboarding.kyc.uploading')}
                          </S.Uploading>
                        ) : (
                          <Flex gap={12} vertical>
                            <S.FileList>
                              {incorporationDetails?.files?.[ADDRESS_PROOF].map(
                                (file: Schemas.CompanyFile) => (
                                  <Fragment key={file.id}>
                                    <S.FileItem>
                                      <S.FileName
                                        onClick={() => {
                                          setIsOpenViewModal(true);
                                          setSelectedViewFile(file);
                                        }}
                                      >
                                        {file.hasError && (
                                          <S.StepNumber status="errorsFound">
                                            <IconWarningTransparent />
                                          </S.StepNumber>
                                        )}

                                        {file.fileName || file.id}
                                      </S.FileName>

                                      <Flex align="center" gap={8}>
                                        <Upload
                                          customRequest={(opts) =>
                                            handleUpdate(opts, file.id)
                                          }
                                          showUploadList={false}
                                        >
                                          <Button
                                            type="text"
                                            icon={<IconRefresh />}
                                            size="small"
                                          />
                                        </Upload>
                                      </Flex>
                                    </S.FileItem>

                                    {file.hasError && (
                                      <S.FileItemError>
                                        {translate(
                                          // @ts-expect-error-next-line
                                          `onboarding.kyc.errors.${file.errorCode}`
                                        )}
                                      </S.FileItemError>
                                    )}
                                  </Fragment>
                                )
                              )}
                            </S.FileList>
                          </Flex>
                        ))}

                      {!incorporationDetails?.files?.[ADDRESS_PROOF]?.length &&
                        (loadingCreate ? (
                          <S.Uploading>
                            <Spin indicator={<LoadingOutlined spin />} />
                            {translate('onboarding.kyc.uploading')}
                          </S.Uploading>
                        ) : (
                          <S.UploadFile
                            customRequest={handleUploadFile}
                            showUploadList={false}
                          >
                            <S.UploadContent>
                              <S.UploadInner>
                                <IconUpload />
                                {translate(
                                  'onboarding.uploadAddressForm.dropFileText'
                                )}
                              </S.UploadInner>
                              <S.UploadButton type="default">
                                {translate(
                                  'onboarding.uploadAddressForm.selectFile'
                                )}
                              </S.UploadButton>
                            </S.UploadContent>
                          </S.UploadFile>
                        ))}
                    </Form.Item>
                  </S.UploadFileCol>
                </Row>
              </S.AddressFormWrapper>
            </Col>
          </Row>
        </S.Inner>

        <Flex justify="flex-end">
          <Button type="link" onClick={handleBack}>
            {translate('onboarding.buttonGoBack')}
          </Button>

          <Button
            type="primary"
            htmlType="submit"
            disabled={
              !!(
                disabledForm ||
                !incorporationDetails?.files?.[ADDRESS_PROOF] ||
                incorporationDetails?.files?.[ADDRESS_PROOF].find(
                  (f) => f.hasError
                )
              )
            }
            loading={loading}
          >
            {translate('onboarding.buttonNext')}
          </Button>
        </Flex>
      </Form>
    </S.Container>
  );
};

export default OnboardingProvideAddress;
