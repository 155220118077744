import { Alert, type AlertProps, Flex, type FlexProps } from 'antd';

import styled from '@emotion/styled';

export const Submit = styled.div`
  margin-top: 24px;
`;

export const Switch = styled(Flex)<FlexProps>`
  margin-bottom: 16px;
`;

export const Total = styled(Flex)<FlexProps>`
  border-top: 2px solid ${({ theme }) => theme.colors.bgDarkGrey};
  margin-top: 32px;
  padding-top: 16px;
  font-size: 18px;
  font-weight: 600;

  .ui-typography {
    font-size: 18px;
    font-weight: 600;
  }
`;

export const AlertWarning = styled(Alert)<AlertProps>`
  && {
    line-height: 1.4;
    background: ${({ theme }) => theme.colors.bgLightYellow};
    border-radius: 12px;
    border: 0;
    padding: 12px;
    font-size: 14px;
    margin-bottom: 24px;
  }
`;

export const AlertWarningTitle = styled.div`
  font-weight: 600;
  margin-bottom: 4px;
`;

export const Option = styled(Flex)<FlexProps>`
  padding: 4px;
`;

export const OptionTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
`;

export const OptionValue = styled.div`
  font-weight: 400;
  white-space: normal;
`;
