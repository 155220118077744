import { FC } from 'react';

import { ProjectItem, ProjectsListEmpty } from '@entities';
import { useProjects } from '@hooks';
import { Schemas } from '@api-client/generated/types';

import * as S from './styled';

type Project = Schemas.Project;

type ProjectsListProps = {
  projects: Project[];
  isFiltered?: boolean;
};

const ProjectsList: FC<ProjectsListProps> = ({ isFiltered, projects }) => {
  const { isEmptyList } = useProjects();

  if (isEmptyList && !isFiltered) {
    return <ProjectsListEmpty />;
  }

  return (
    <S.Grid>
      {projects.map((project) => (
        <ProjectItem key={project.id} project={project} />
      ))}
    </S.Grid>
  );
};

export default ProjectsList;
