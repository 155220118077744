import { Button, Checkbox } from 'antd';
import type { AlignType } from 'rc-table/lib/interface';
import { useMemo, useState } from 'react';

import { CloseOutlined } from '@ant-design/icons';
import { Schemas } from '@api-client/generated/types';
import { Amount } from '@entities';
import { useTranslate } from '@hooks';
import { getDateDefault } from '@utils';

const useDocumentsTransactionsColumns = (type: 'all' | 'selected') => {
  const { translate } = useTranslate();
  const [selectedTransactions, setSelectedTransactions] = useState<
    Schemas.Transaction[]
  >([]);

  const columns = useMemo(() => {
    const baseColumns = [
      {
        key: 'checkbox',
        title: '',
        width: 50,
        render: (value: Schemas.Transaction) => (
          <Checkbox
            checked={selectedTransactions
              .map((transaction) => transaction.id)
              .includes(value.id)}
            onChange={() =>
              setSelectedTransactions(
                selectedTransactions
                  .map((transaction) => transaction.id)
                  .includes(value.id)
                  ? selectedTransactions.filter((item) => item.id !== value.id)
                  : [...selectedTransactions, value]
              )
            }
          />
        ),
      },
      {
        key: 'bookingDate',
        title: translate('transactionsPage.label.date'),
        width: 110,
        render: (value: Schemas.Transaction) =>
          getDateDefault(value.bookingDate),
      },
      {
        key: 'contact',
        title: translate('transactionsPage.label.contact'),
        render: (value: Schemas.Transaction) => value.contact?.name,
        width: type === 'all' ? 176 : 356,
        ellipsis: true,
      },
      {
        key: 'details',
        title: translate('transactionsPage.label.details'),
        render: (value: Schemas.Transaction) => value.details,
        width: type === 'all' ? 226 : 356,
        ellipsis: true,
      },

      {
        key: 'account',
        title: translate('transactionsPage.label.bank'),
        render: (value: Schemas.Transaction) =>
          value.account.connection?.bank?.name || value.account.accountName,
        width: type === 'all' ? 226 : 326,
        ellipsis: true,
      },
      {
        key: 'amount',
        title: translate('transactionsPage.label.amount'),
        type: 'money',
        width: 120,
        align: 'right' as AlignType,
        render: (value: Schemas.Transaction) => {
          return (
            <Amount amount={value.amount} currencyCode={value.currencyCode} />
          );
        },
      },
      {
        key: 'remove',
        width: 50,
        render: () => (
          <Button
            data-delete-button
            type="text"
            size="small"
            icon={<CloseOutlined />}
          />
        ),
      },
    ];

    type === 'selected' ? baseColumns.shift() : baseColumns.pop();

    return baseColumns;
  }, [selectedTransactions, translate, type]);

  return { columns, selectedTransactions, setSelectedTransactions };
};

export default useDocumentsTransactionsColumns;
