import { type InputNumberProps } from 'antd';
import { FC, FocusEvent, useEffect, useState } from 'react';

import { getCurrencySymbol, inverseNumberValue } from '@utils';

import * as S from './styled';

type InputNumberValue = number | string | null;

export type InputNumberMoneyProps = {
  currencyCode?: string;
  fullAmount?: number;
  onChange: (value: number) => void;
} & InputNumberProps;

const InputNumberMoney: FC<InputNumberMoneyProps> = ({
  defaultValue,
  currencyCode,
  prefix,
  fullAmount,
  onChange,
  ...rest
}) => {
  const [value, setValue] = useState<InputNumberValue>(defaultValue || null);

  useEffect(() => {
    if (defaultValue) {
      setValue(inverseNumberValue(defaultValue, fullAmount));
    }
  }, [defaultValue, fullAmount]);

  const handleChange = (value: InputNumberValue) => {
    setValue(value);
    onChange(value);
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement, Element>) => {
    e.preventDefault();
    setValue(inverseNumberValue(e.target.value, fullAmount));
  };

  return (
    <S.InputNumberMoney
      defaultValue={defaultValue}
      value={value}
      prefix={currencyCode ? getCurrencySymbol(currencyCode) : prefix}
      controls={false}
      decimalSeparator="."
      precision={2}
      size="large"
      onChange={handleChange}
      onBlur={handleBlur}
      {...rest}
    />
  );
};

export default InputNumberMoney;
