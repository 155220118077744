import { Flex, Popover, Upload, message } from 'antd';
import type { UploadRequestOption } from 'rc-upload/lib/interface';
import { FC, useState } from 'react';

import { Schemas } from '@api-client/generated/types';
import {
  IconClip,
  IconDown,
  IconDownload,
  IconFile,
  IconTrash,
  IconUp,
  IconUpload,
} from '@assets';
import { Loader } from '@components';
import { PAYMENT_DOCUMENT_VALID_EXTENSIONS } from '@constants';
import {
  PreviewFileModal,
  TransactionAttachSupplementaryModal,
} from '@entities';
import { useTranslate } from '@hooks';
import { checkingForSupportedFile } from '@utils';

import * as S from './styled';

type FileType = Schemas.Document;

type SupplementaryDocumentsProps = {
  data: FileType[];
  isSupplementaryActionsOpen: boolean;
  isSupplementaryAdditionalActionsOpen: boolean;
  isDownloadLoading: boolean;
  selectedFile: FileType | null;
  attachedFiles: FileType[];
  disableUpload: boolean;
  onChangeSupplementaryActionsOpen: (value: boolean) => void;
  onChangeSupplementaryAdditionalActionsOpen: (value: boolean) => void;
  onChangeSelectedFile: (file: FileType | null) => void;
  onUploadRequest: (options: UploadRequestOption<any>) => void;
  onDetach: (file: FileType) => void;
  onDownloadFile: (file: FileType) => void;
  onAttach: (ids: string[]) => void;
};

const SupplementaryDocuments: FC<SupplementaryDocumentsProps> = ({
  onChangeSupplementaryActionsOpen,
  onChangeSupplementaryAdditionalActionsOpen,
  isSupplementaryActionsOpen,
  isSupplementaryAdditionalActionsOpen,
  onDownloadFile,
  disableUpload,
  selectedFile,
  isDownloadLoading,
  onUploadRequest,
  onDetach,
  data = [],
  onChangeSelectedFile,
  onAttach,
  attachedFiles,
}) => {
  const { translate } = useTranslate();
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [isAttachSupplementaryModalOpen, setIsAttachSupplementaryModalOpen] =
    useState(false);

  const handleClosePreview = () => {
    setIsViewModalOpen(false);
    onChangeSelectedFile(null);
  };

  const handleAttachExistingDocument = () => {
    setIsAttachSupplementaryModalOpen(true);
    onChangeSupplementaryActionsOpen(false);
  };

  const handleViewDocument = (file: FileType) => {
    setIsViewModalOpen(true);
    onChangeSelectedFile(file);
  };

  const handleDownloadFile = (file: FileType) => {
    onChangeSupplementaryAdditionalActionsOpen(false);
    onChangeSelectedFile(file);
    onDownloadFile(file);
  };

  const handleDetach = (file: FileType) => {
    onChangeSupplementaryAdditionalActionsOpen(false);
    onDetach(file);
  };

  const handleBeforeUpload = (file: File) => {
    const extension = `.${file.name.split('.').pop()}`.toLowerCase();

    if (!PAYMENT_DOCUMENT_VALID_EXTENSIONS.includes(extension)) {
      message.error(
        translate('income.errorExtensionsUpload', {
          formats: PAYMENT_DOCUMENT_VALID_EXTENSIONS.join(', '),
          extension,
        })
      );

      return Upload.LIST_IGNORE;
    }

    return true;
  };

  return (
    <S.SupplementaryDocuments gap={12} vertical>
      <Flex justify="space-between">
        <S.BlockTitle>
          {translate(
            'transaction.documents.supplementary.supplementaryDocuments'
          )}
        </S.BlockTitle>
        <Popover
          open={isSupplementaryActionsOpen}
          onOpenChange={onChangeSupplementaryActionsOpen}
          trigger="click"
          placement="bottomRight"
          arrow={false}
          overlayClassName="ui-popover-legal-actions"
          title={
            <S.Actions>
              <S.AttachNew onClick={handleAttachExistingDocument}>
                <IconClip />
                <span>
                  {translate('transaction.documents.attachExistingDocument')}
                </span>
              </S.AttachNew>

              <S.UploadWrapper>
                <Upload
                  accept={PAYMENT_DOCUMENT_VALID_EXTENSIONS.join(',')}
                  customRequest={onUploadRequest}
                  beforeUpload={handleBeforeUpload}
                  showUploadList={false}
                  disabled={disableUpload}
                >
                  <S.UploadExisting>
                    {disableUpload ? <Loader /> : <IconUpload />}
                    <span>
                      {translate('transaction.documents.uploadNewDocument')}
                    </span>
                  </S.UploadExisting>
                </Upload>
              </S.UploadWrapper>
            </S.Actions>
          }
        >
          <S.AttachButton
            type="link"
            onClick={() => {
              onChangeSupplementaryActionsOpen(true);
            }}
          >
            {translate('transaction.documents.attach')}
            {isSupplementaryActionsOpen ? <IconUp /> : <IconDown />}{' '}
          </S.AttachButton>
        </Popover>
      </Flex>

      <Flex vertical>
        {!data.length ? (
          <S.NoDocuments>
            {translate('transaction.documents.noDocumentsSupplementary')}
          </S.NoDocuments>
        ) : (
          data.map((file) => (
            <S.SupplementaryItem justify="space-between">
              <S.SupplementaryItemInner gap={8} align="center">
                <IconFile />

                <S.SupplementaryItemTitle ellipsis={{ rows: 1 }}>
                  {file.name || translate('transaction.noName')}
                </S.SupplementaryItemTitle>
              </S.SupplementaryItemInner>

              <S.SupplementaryItemActions className="supplementary-item-actions">
                {checkingForSupportedFile(file) && (
                  <S.ViewIcon onClick={() => handleViewDocument(file)} />
                )}
                {isDownloadLoading ? (
                  <Loader />
                ) : (
                  <Popover
                    visible={
                      isSupplementaryAdditionalActionsOpen &&
                      selectedFile?.id === file.id
                    }
                    onVisibleChange={(value) => {
                      onChangeSupplementaryAdditionalActionsOpen(value);
                      onChangeSelectedFile(file);
                    }}
                    trigger="click"
                    placement="bottomRight"
                    arrow={false}
                    overlayClassName="ui-popover-legal-actions"
                    title={
                      <S.Actions>
                        <S.AttachNew onClick={() => handleDownloadFile(file)}>
                          <IconDownload />
                          {translate('transaction.download')}
                        </S.AttachNew>

                        <S.AttachNew onClick={() => handleDetach(file)}>
                          <IconTrash />
                          <span>
                            {translate('transaction.documents.detach')}
                          </span>
                        </S.AttachNew>
                      </S.Actions>
                    }
                  >
                    <S.MoreIcon />
                  </Popover>
                )}
              </S.SupplementaryItemActions>
            </S.SupplementaryItem>
          ))
        )}
      </Flex>

      {selectedFile && isViewModalOpen && (
        <PreviewFileModal
          centered
          open={!!selectedFile && isViewModalOpen}
          footer={null}
          onCancel={handleClosePreview}
          file={selectedFile as FileType}
        />
      )}

      {isAttachSupplementaryModalOpen && (
        <TransactionAttachSupplementaryModal
          open={isAttachSupplementaryModalOpen}
          onCancel={() => {
            setIsAttachSupplementaryModalOpen(false);
          }}
          onAttachDocuments={onAttach}
          attachedFiles={attachedFiles}
        />
      )}
    </S.SupplementaryDocuments>
  );
};

export default SupplementaryDocuments;
