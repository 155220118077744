import { Params$UserController_updateCurrentUserPassword } from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';

import { useApiClientMutation } from '@hooks';

type Params = Params$UserController_updateCurrentUserPassword;

const useUpdateUserPassword = () => {
  return useApiClientMutation<Params, Schemas.User>({
    method: 'UserController_updateCurrentUserPassword',
  });
};

export default useUpdateUserPassword;
