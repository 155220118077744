import styled from '@emotion/styled';

import { Typography } from 'antd';

export const Card = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.strokeGrey};
  border-radius: 24px;

  .ui-typography {
    &.ui-typography-secondary {
      color: ${({ theme }) => theme.colors.textBlue400};
    }
  }
`;

export const Title = styled(Typography.Title)`
  &.ui-typography {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 4px;
  }
`;

export const Inner = styled.div`
  padding: 24px;
`;

export const Detail = styled.div`
  min-height: 235px;

  .ui-typography {
    margin: 12px 0 0 0;
  }
`;

export const Examples = styled.div`
  background-color: ${({ theme }) => theme.colors.bgGrey};
  padding: 16px 24px;

  .ui-typography {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 6px;
  }

  ol {
    margin: 0;
    padding-left: 16px;
  }
`;

export const DetailsList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const DetailsItem = styled.li`
  font-size: 12px;
  padding-left: 14px;
  margin-bottom: 6px;
  position: relative;

  &:before {
    content: '';
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.bgDarkBlue};
    position: absolute;
    top: 6px;
    left: 0;
  }
`;

export const Minimum = styled.div`
  margin-top: 16px;

  .ui-typography {
    &.ui-typography-secondary {
      font-size: 12px;
      margin-bottom: 0;
    }
  }
`;

export const MinimumTitle = styled(Typography.Title)`
  &.ui-typography {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 4px;
  }
`;

export const Price = styled.div`
  background-color: ${({ theme }) => theme.colors.bgGrey};
  border-radius: 10px 10px 0 0;
  padding: 24px;
`;

export const PriceTitle = styled(Typography.Title)`
  &.ui-typography {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 4px;
  }
`;

export const PriceSubtitle = styled(Typography.Paragraph)`
  &.ui-typography {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 20px;
  }
`;

export const PriceDetails = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  margin-bottom: 20px;
`;

export const PriceDetailsItem = styled.li`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
`;

export const Discount = styled(Typography.Paragraph)`
  &.ui-typography {
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 600;
    text-align: center;
    margin: 15px 0 4px 0;
  }
`;

export const ExtraText = styled(Typography.Paragraph)`
  &.ui-typography {
    text-align: center;
    margin-bottom: 0;
  }
`;

export const Notes = styled.ul`
  height: 120px;
  background-color: ${({ theme }) => theme.colors.strokeGrey};
  border-radius: 0 0 24px 24px;
  padding: 20px 24px;
  margin: 0;
`;

export const NotesItem = styled.li`
  list-style: none;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  strong {
    font-weight: 700;
  }
`;
