import styled from '@emotion/styled';

import { Layout, type LayoutProps } from 'antd';

export const Header = styled(Layout.Header)<LayoutProps>`
  line-height: 1.2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 10;
`;

export const DevText = styled.span`
  font-weight: 700;
  margin-left: 26px;
`

export const UserName = styled.span`
  font-weight: 600;
`;

export const Logout = styled.a`
  color: ${({ theme }) => theme.colors.white};
  margin-left: 80px;

  &:hover {
    opacity: 0.75;
  }
`;
