import {
  Button,
  Flex,
  Modal,
  Popover,
  Typography,
  Upload,
  message,
} from 'antd';
import type { UploadRequestOption } from 'rc-upload/lib/interface';
import { useEffect, useRef, useState } from 'react';

import { Schemas } from '@api-client/generated/types';
import { IconEdit, IconMoreVertical, IconTrash, IconUpload } from '@assets';
import { Card, Loader } from '@components';
import {
  EmptyDocumentPreivew,
  FileErrorBlock,
  FileViewer,
  PageMeta,
  SearchControl,
  UploadFile,
} from '@entities';
import { useAccount, useTranslate } from '@hooks';
import {
  useCreateCompanyFile,
  useDeleteCompanyFile,
  useDocumentUpload,
  useGetCompanyFiles,
  useUpdateCompanyFileById,
} from '@hooks-api';

import * as S from './styled';

const { Title } = Typography;

const Legal = () => {
  const { translate, tDynamic } = useTranslate();
  const { companyId } = useAccount();

  const { loading: getFilesLoading } = useGetCompanyFiles({
    params: {
      companyId: companyId!,
      type: 'legal',
      personId: companyId!,
    },
    config: {
      onSuccess: (res) => {
        setFiles(res.sort((a, b) => a.name.localeCompare(b.name)));
      },
    },
  });

  const [createFile, createFileLoading] = useCreateCompanyFile();
  const [updateFile, updateFileLoading] = useUpdateCompanyFileById();
  const [deleteFile, deleteFileLoading] = useDeleteCompanyFile();

  const [selectedFile, setSelectedFile] = useState<Schemas.CompanyFile | null>(
    null
  );
  const { uploadStatus } = useDocumentUpload(companyId!);
  const [files, setFiles] = useState<Schemas.CompanyFile[]>([]);
  const [changingFileId, setChangingFileId] = useState('');
  const [isActionsOpen, setIsActionsOpen] = useState(false);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const ref = useRef(null);

  useEffect(() => {
    if (files.length && !selectedFile && !searchValue) {
      setSelectedFile(files[0]);
    }
  }, [files, searchValue, selectedFile]);

  useEffect(() => {
    if (changingFileId && changingFileId !== selectedFile?.id) {
      setChangingFileId('');
    }
  }, [changingFileId, selectedFile]);

  const searchFiles = () =>
    files.filter((file) =>
      file.name.toLowerCase().includes(searchValue.toLowerCase())
    );

  const isTypePDF =
    selectedFile?.mimeType?.includes('pdf') ||
    selectedFile?.type.includes('pdf');

  const handleUploadFile = (options: UploadRequestOption) => {
    const formData = new FormData();
    if (typeof options.file === 'object') {
      formData.append('file', options.file);
      if ('name' in options.file) {
        formData.append('name', options.file.name);
      }
    }
    formData.append('type', 'legal');
    formData.append('personId', companyId!);

    createFile(
      {
        parameter: {
          companyId: companyId!,
        },
        requestBody: formData as any,
      },
      {
        onSuccess: (res) => {
          setFiles(
            files?.length
              ? [...files, res].sort((a, b) => a.name.localeCompare(b.name))
              : [res]
          );
          setSelectedFile(res);
        },
      }
    );
  };

  const handleUpdateFile = (name: string) => {
    if (selectedFile) {
      updateFile(
        {
          parameter: {
            companyId: companyId!,
            id: selectedFile.id,
          },
          requestBody: {
            name,
          },
        },
        {
          onSuccess: (res) => {
            setChangingFileId('');
            setFiles((prev) =>
              prev
                .map((el) => (el.id === res.id ? res : el))
                .sort((a, b) => a.name.localeCompare(b.name))
            );
            message.success(tDynamic('legal.savedFile', { name }));
          },
        }
      );
    }
  };

  const handleRemoveFile = () => {
    if (selectedFile) {
      deleteFile(
        {
          parameter: {
            companyId: companyId!,
            id: selectedFile.id,
          },
        },
        {
          onSuccess: (res) => {
            setFiles((prev) => prev.filter((el) => el.id !== res.id));
            setSelectedFile(null);
            setIsRemoveModalOpen(false);
            message.success(translate('legal.deletedFile'));
          },
        }
      );
    }
  };

  const handleChangeVisible = (
    value: boolean,
    companyFile: Schemas.CompanyFile
  ) => {
    setIsActionsOpen(value);
    setSelectedFile(companyFile);
  };

  const handleEditFile = (id: string) => {
    setChangingFileId(id);
    setIsActionsOpen(false);
  };

  const handleOpenRemoveModal = () => {
    setIsRemoveModalOpen(true);
    setIsActionsOpen(false);
  };

  const actionsLoading =
    createFileLoading || updateFileLoading || deleteFileLoading;

  return (
    <Flex gap={14} ref={ref} vertical>
      <PageMeta title={translate('legal.title')} />
      <Title>{translate('legal.title')}</Title>

      <Flex gap={28} vertical>
        <Flex justify="space-between" align="center">
          <SearchControl
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <Upload
            customRequest={handleUploadFile}
            beforeUpload={() => {}}
            showUploadList={false}
          >
            <Button
              type="default"
              size="small"
              loading={createFileLoading}
              disabled={createFileLoading}
              icon={<IconUpload />}
            >
              {translate('transaction.breadcrumbs.upload.document')}
            </Button>
          </Upload>
        </Flex>

        <Flex gap={30}>
          {searchFiles()?.length ? (
            <Flex flex={1} gap={12} vertical>
              {searchFiles()?.map((companyFile) => (
                <UploadFile
                  key={companyFile?.id}
                  isFileChanging={changingFileId === companyFile.id}
                  onFileNameSave={handleUpdateFile}
                  url={companyFile.url!}
                  name={companyFile.name}
                  loading={uploadStatus.isLoading}
                  onSelect={() => setSelectedFile(companyFile)}
                  isSelected={companyFile?.id === selectedFile?.id}
                  additionalIcon={
                    companyFile?.id === selectedFile?.id &&
                    updateFileLoading ? (
                      <Loader />
                    ) : (
                      <Popover
                        open={
                          isActionsOpen && companyFile?.id === selectedFile?.id
                        }
                        onOpenChange={(value) =>
                          handleChangeVisible(value, companyFile)
                        }
                        trigger="click"
                        placement="bottomRight"
                        arrow={false}
                        overlayClassName="ui-popover-legal-actions"
                        title={
                          <S.Actions>
                            <S.Edit
                              onClick={() => handleEditFile(companyFile?.id)}
                            >
                              <IconEdit />
                              <span>
                                {translate('legal.actions.changeName')}
                              </span>
                            </S.Edit>
                            <S.Remove onClick={handleOpenRemoveModal}>
                              <IconTrash />
                              <span>{translate('legal.actions.delete')}</span>
                            </S.Remove>
                          </S.Actions>
                        }
                      >
                        <S.MoreIcon>
                          <IconMoreVertical />
                        </S.MoreIcon>
                      </Popover>
                    )
                  }
                />
              ))}
            </Flex>
          ) : (
            <Flex flex={1}>
              <S.EmptyList align="center" justify="center" gap={32} vertical>
                {getFilesLoading ? (
                  <Loader />
                ) : (
                  <>
                    <FileErrorBlock />
                    <Upload
                      customRequest={handleUploadFile}
                      beforeUpload={() => {}}
                      showUploadList={false}
                    >
                      <Button
                        type="default"
                        size="small"
                        loading={createFileLoading}
                        disabled={createFileLoading}
                        icon={<IconUpload />}
                      >
                        {translate('transaction.breadcrumbs.upload.document')}
                      </Button>
                    </Upload>
                  </>
                )}
              </S.EmptyList>
            </Flex>
          )}
          <S.EmptyPreview>
            <Card
              title={translate('documentsPage.form.preview')}
              minHeight={554}
            >
              {!!selectedFile && isTypePDF ? (
                <FileViewer
                  selectedFile={selectedFile}
                  width="437px"
                  height="622px"
                />
              ) : (
                <>
                  {actionsLoading ? (
                    <Loader />
                  ) : (
                    <EmptyDocumentPreivew
                      unsupported={!!selectedFile && !isTypePDF}
                    />
                  )}
                </>
              )}
            </Card>
          </S.EmptyPreview>
        </Flex>
      </Flex>
      <Modal open={isRemoveModalOpen} closeIcon={null} footer={null} centered>
        <Flex gap={24} vertical>
          <Flex gap={9} vertical>
            <S.ModalTitle>{translate('legal.deleteConfirmation')}</S.ModalTitle>
            <span>{translate('legal.deleteAdditionalText')}</span>
          </Flex>

          <Flex justify="flex-end" gap={12}>
            <Button type="default" onClick={() => setIsRemoveModalOpen(false)}>
              {translate('legal.actions.cancel')}
            </Button>
            <Button
              type="primary"
              loading={deleteFileLoading}
              disabled={deleteFileLoading}
              onClick={handleRemoveFile}
            >
              {translate('legal.actions.delete')}
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </Flex>
  );
};

export default Legal;
