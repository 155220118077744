import styled from '@emotion/styled';
import { rgba } from 'emotion-rgba';

import { type SelectProps } from 'antd';

import { Select } from '@components';

export const Control = styled(Select)<SelectProps>`
  && {
    &.ui-select {
      &.ui-select-outlined {
        width: 127px;
        height: 40px;

        .ui-select-selector {
          background-color: transparent;
          border-color: ${({ theme }) => rgba(theme.colors.white, 0.4)};
          color: ${({ theme }) => theme.colors.white};

          .ui-select-selection-item {
            color: ${({ theme }) => theme.colors.white};
          }
        }

        .ui-select-arrow {
          color: ${({ theme }) => theme.colors.white};
        }

        &.ui-select-focused {
          .ui-select-selector {
            border-color: ${({ theme }) => rgba(theme.colors.white, 0.6)};
          }
        }

        &:hover {
          &.ui-select-outlined .ui-select-selector {
            border-color: ${({ theme }) => rgba(theme.colors.white, 0.6)};
          }
        }
      }
    }
  }
`;
