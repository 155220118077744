import { Flex } from 'antd';

import { useTranslate } from '@hooks';
import { LocalePaths } from '@locales';
import styled from '@emotion/styled';

export const Title = styled.span`
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
`;

export const Count = styled(Flex)<{ padding?: string }>`
  width: 24px;
  height: 24px;
  padding: 6px ${({ padding }) => `${padding}px`};
  border-radius: 24px;
  background: ${({ theme }) => theme.colors.strokeGrey};
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.text400};
`;

export const CountedTitle = ({
  count,
  titleKey,
}: {
  count?: number;
  titleKey: LocalePaths;
}) => {
  const { translate } = useTranslate();
  return (
    <Flex align="center" gap={8}>
      <Title>{translate(titleKey)}</Title>
      <Count
        padding={Number(count) < 9 ? '12' : '18'}
        justify="center"
        align="center"
      >
        {count}
      </Count>
    </Flex>
  );
};
