import { Schemas } from '@api-client/generated/types';

import { useApiClient, type QueryOptions } from '@hooks';
import { Params$CompanyInfoController_findOneByVatId } from '@api-client/generated/client';

type CompanyInfo = Schemas.CompanyInfo;
type Params = Params$CompanyInfoController_findOneByVatId;

type CompanyProps = {
  params: Params['parameter'];
  config?: QueryOptions<CompanyInfo>;
};

const useGetCompanyByVatId = ({ config, params }: CompanyProps) => {
  const {
    data: company,
    loading,
    refetch,
    ...rest
  } = useApiClient<Params, Schemas.CompanyInfo>({
    method: 'CompanyInfoController_findOneByVatId',
    params: {
      parameter: params,
    },
    config,
  });

  return {
    company,
    loading,
    refetch,
    ...rest,
  };
};

export default useGetCompanyByVatId;
