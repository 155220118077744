import styled from '@emotion/styled';
import { Typography } from 'antd';
import { IconCopyInner, IconChain as IconChainInner } from '@assets';

export const Paragraph = styled(Typography.Paragraph)`
  && {
    margin-bottom: 0;
    height: 20px;
    color: ${({ theme }) => theme.colors.text400};
  }
`;

export const IconCopy = styled(IconCopyInner)`
  color: ${({ theme }) => theme.colors.text400};
`;

export const IconChain = styled(IconChainInner)`
  color: ${({ theme }) => theme.colors.text400};
`;
