import styled from '@emotion/styled';
import { mediaWidth } from '@styles/mediaWidth';
import { css } from '@emotion/react';

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 50%);
  gap: 16px;
  margin-top: 24px;

  ${mediaWidth.laptopL(css`
    grid-template-columns: repeat(1, auto);
  `)};
`;
