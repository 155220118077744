import styled from '@emotion/styled';
import { Flex } from 'antd';

export const Wrapper = styled(Flex)<{ fullPage?: boolean }>`
  width: 100%;
  margin: 0 auto;
  height: ${({ fullPage }) => (fullPage ? '100vh' : '100%')};
`;

export const Title = styled.span`
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: center;
`;

export const Description = styled.span`
  width: 445px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
`;
