import styled from '@emotion/styled';

import { type ButtonProps, Button } from 'antd';

import { Loader as CustomLoader } from '@components';

export const ButtonAction = styled(Button)<ButtonProps>`
  && {
    color: ${({ theme }) => theme.colors.text400};
  }
`;

export const Loader = styled(CustomLoader)`
  margin-top: 150px;
`;
