import styled from '@emotion/styled';

export const Container = styled.div`
  width: 100%;
  height: 608px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  text-align: center;

  && {
    .ui-btn {
      font-size: 14px;
      font-weight: 600;
    }
  }
`;

export const Icon = styled.div`
  width: 72px;
  height: 72px;
  background-color: ${({ theme }) => theme.colors.error};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.white};
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  margin-top: 20px;
`;

export const Description = styled.div`
  color: ${({ theme }) => theme.colors.text400};
  margin-bottom: 24px;
`;
