import { useEffect, useState } from 'react';

import { BankItem } from '@entities';
import { useAccount } from '@hooks';
import { useGetBanksList, useInitializeBankConnections } from '@hooks-api';
import { Schemas } from '@api-client/generated/types';

import * as S from './styled';
import { useInfiniteScroll, usePagination } from '@hooks/useInfiniteScroll';

const BankList = ({ term }: { term: string }) => {
  const { companyId } = useAccount();

  const [selectedBank, setSelectedBank] = useState<string | null>(null);

  const [bankConnect, loading] = useInitializeBankConnections();

  const {
    page,
    incrementPage,
    setTotalPages,
    hasNextPage,
    plainData,
    appendData,
    reset,
  } = usePagination<Schemas.Bank>();

  const { isFetching: isLoading } = useGetBanksList({
    params: {
      page,
      term,
    },
    config: {
      onSuccess: ({ records, metadata }) => {
        setTotalPages(metadata.totalPages);
        appendData(metadata.currentPage, records);
      },
    },
  });

  const { sentryRef } = useInfiniteScroll({
    isLoading,
    hasNextPage,
    onLoadMore: incrementPage,
  });

  useEffect(() => {
    reset();
  }, [reset, term]);

  const handleBankConnect = (id: string) => {
    setSelectedBank(id);

    bankConnect(
      {
        parameter: {
          bankId: id,
          companyId: companyId!,
        },
      },
      {
        onSuccess: (response) => {
          window.open(response.url!, '_self');
          setSelectedBank(null);
        },
      }
    );
  };

  return (
    <S.Grid>
      {plainData.map((bank) => (
        <BankItem
          isLoading={loading}
          selectedBankId={selectedBank}
          key={bank.id}
          logo={`./${bank.bic}.png`}
          bank={bank}
          onConnect={handleBankConnect}
        />
      ))}
      {hasNextPage && <div ref={sentryRef} />}
    </S.Grid>
  );
};

export default BankList;
