import styled from '@emotion/styled';

import { Typography } from 'antd';

export const Card = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.strokeGrey};
  border-radius: 24px;

  .ui-typography {
    &.ui-typography-secondary {
      color: ${({ theme }) => theme.colors.textBlue400};
    }
  }
`;

export const Title = styled(Typography.Title)`
  &.ui-typography {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 4px;
  }
`;

export const Inner = styled.div`
  padding: 24px;
`;

export const Description = styled.div`
  &.ui-typography {
    margin-bottom: 0;
  }
`;

export const Price = styled.div`
  background-color: ${({ theme }) => theme.colors.bgGrey};
  border-radius: 10px 10px 0 0;
  padding: 24px;
`;

export const PriceTitle = styled(Typography.Title)`
  &.ui-typography {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 4px;
  }
`;

export const PriceSubtitle = styled(Typography.Paragraph)`
  &.ui-typography {
    color: ${({ theme }) => theme.colors.text200};
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 20px;
  }
`;

export const PriceDetails = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  margin-bottom: 28px;
`;

export const PriceDetailsTitle = styled(Typography.Title)`
  &.ui-typography {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
  }
`;

export const PriceDetailsItem = styled.li`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
`;

export const Soon = styled.div`
  height: 16px;
  line-height: 1;
  background-color: ${({ theme }) => theme.colors.bgYellow};
  border-radius: 16px;
  color: ${({ theme }) => theme.colors.bgDarkBlue};
  font-size: 8px;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 3px;
  padding: 0 5px;
  text-transform: uppercase;

  svg {
    width: 13px;
    height: 13px;
  }
`;

export const Notes = styled.ul`
  background-color: ${({ theme }) => theme.colors.strokeGrey};
  border-radius: 0 0 24px 24px;
  padding: 20px 24px;
`;

export const NotesList = styled.ul`
  margin: 0;
  padding: 0;
`;

export const NotesTitle = styled(Typography.Title)`
  &.ui-typography {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 8px;
  }
`;

export const NotesItem = styled.li`
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;
