import styled from '@emotion/styled';
import { rgba } from 'emotion-rgba';

export const Card = styled.div`
  border-radius: 20px;
  box-shadow: 0 4px 26px 0 ${({ theme }) => rgba(theme.colors.dark, 0.06)};
  padding: 24px;
  font-weight: 500;

  h3.ui-typography {
    font-size: 20px;
  }
`;
