import { Modal, type ModalProps } from 'antd';
import { FC } from 'react';

import { Schemas } from '@api-client/generated/types';
import { FilePreview } from '@entities';

type TaskFile = Schemas.TaskFile;

type FileViewerProps = {
  file: TaskFile;
  onCancel: () => void;
} & ModalProps;

const FileViewer: FC<FileViewerProps> = ({ open, file, onCancel, ...rest }) => (
  <Modal
    width={557}
    open={open}
    title={file.fileName || 'File'}
    footer={null}
    onCancel={onCancel}
    destroyOnClose
    centered
    {...rest}
  >
    <FilePreview file={file} />
  </Modal>
);

export default FileViewer;
