import { Flex } from 'antd';
import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Schemas } from '@api-client/generated/types';
import { IconBook, IconCreditCardRounded } from '@assets';

import * as S from './styled';

type ContactItemProps = {
  selectedId: string;
  details: Schemas.Contact;
};

const ContactItem: FC<ContactItemProps> = ({ selectedId, details }) => {
  const navigate = useNavigate();
  const { search } = useLocation();

  const handleSelectContact = (id: string) => {
    navigate(`/contacts/${id}${search}`);
  };

  return (
    <S.Contact
      gap={8}
      selected={selectedId === details.id}
      onClick={() => handleSelectContact(details.id)}
      vertical
    >
      <S.Title ellipsis={{ rows: 1 }}>{details.name}</S.Title>

      <Flex align="center" justify="space-between">
        <S.VatId>{details.vatId}</S.VatId>

        <Flex>
          <S.Stat align="center" gap={6}>
            <IconCreditCardRounded width={18} height={18} />
            {details.transactionsCount || 0}
          </S.Stat>

          <S.Stat align="center" gap={6}>
            <IconBook width={18} height={18} />
            {details.documentsCount || 0}
          </S.Stat>
        </Flex>
      </Flex>
    </S.Contact>
  );
};

export default ContactItem;
