import { FC } from 'react';
import { Flex } from 'antd';
import dayjs from 'dayjs';

import { getCurrencySymbol } from '@utils';

import { Schemas } from '@api-client/generated/types';

import * as S from './styles';

type AttachDropdownItemProps = {
  item: Schemas.Document | Schemas.Transaction;
  width?: string;
  height?: string;
  callback?: () => void;
};

const isFile = (item: Schemas.Document | Schemas.Transaction): item is Schemas.Document => {
  return 'potentialTransactions' in item;
};

const AttachDropdownItem: FC<AttachDropdownItemProps> = ({ item, width, height, callback }) => {
  const contactName = item.contact?.name;
  const displayAmount = isFile(item) ? item.documentMetadata?.amount : item.amount;
  const displayCurrencySymbol = isFile(item) ? item.documentMetadata?.currency : item.currencyCode;
  const displayDescription = isFile(item) ? item.documentMetadata?.description : item.details;
  const displayDate = isFile(item) ? item.documentMetadata?.issueDate : item.bookingDate;

  const formattedAmount = displayAmount && displayCurrencySymbol
    ? `${displayAmount < 0 ? '-' : ''}${getCurrencySymbol(displayCurrencySymbol)}${Math.abs(displayAmount).toFixed(2)}`
    : null;

  return (
    <S.AttachDropdownItemWrapper
      width={width}
      height={height}
      gap={16}
      onClick={callback}
      justify="space-between"
      vertical
    >
      <S.AttachDropdownItemContent gap={6} vertical>
        <S.AttachDropdownItemTitle>{contactName}</S.AttachDropdownItemTitle>
        <S.AttachDropdownItemDescription>{displayDescription}</S.AttachDropdownItemDescription>
      </S.AttachDropdownItemContent>

      {formattedAmount && displayDate && (
        <Flex justify="space-between">
          <S.AttachDropdownItemPrice>{formattedAmount}</S.AttachDropdownItemPrice>
          <S.AttachDropdownItemDate>{dayjs(displayDate).format('DD.MM.YYYY')}</S.AttachDropdownItemDate>
        </Flex>
      )}
    </S.AttachDropdownItemWrapper>
  );
};

export default AttachDropdownItem;
