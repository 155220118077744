import styled from '@emotion/styled';

export const Highlight = styled.span<{ type: 'condition' | 'action' }>`
  padding: 0 5px;
  font-weight: 600;
  border-radius: 3px;
  color: ${({ theme }) => theme.colors.text500};
  background-color: ${({ theme, type }) =>
    type === 'condition'
      ? theme.colors.textDisabled
      : theme.colors.badgeSuccess};
  display: inline-block;
`;
