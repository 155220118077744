import { FC } from 'react';

import { Flex, Row, Col } from 'antd';

import * as S from './styled';

const ContactsSkeleton: FC = () => (
  <Flex gap={24} vertical>
    <S.Skeleton height={54} />

    <S.Skeleton height={34} />

    <Row gutter={[30, 0]}>
      <Col span={11}>
        <S.Skeleton height={428} />
      </Col>

      <Col span={9}>
        <S.Skeleton height={160} />
      </Col>
    </Row>
  </Flex>
);

export default ContactsSkeleton;
