import styled from '@emotion/styled';
import { rgba } from 'emotion-rgba';

const cornerSize = '50px';

export const CutCornerCard = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isFaded' && prop !== 'nonInteractive',
})<{ isFaded?: boolean; nonInteractive?: boolean }>`
  width: 100%;
  min-height: 150px;
  border-radius: 10px;
  background-color: ${({ theme, isFaded }) =>
    rgba(theme.colors.white, isFaded ? 0.7 : 1)};
  border: 1px solid ${({ theme }) => theme.colors.strokeDarkGrey};
  cursor: pointer;
  padding: 12px 20px;
  clip-path: polygon(
    0 0,
    calc(100% - ${cornerSize}) 0,
    100% ${cornerSize},
    100% 100%,
    0 100%
  );
  transition: border 0.12s;
  position: relative;

  &:hover {
    border-color: ${({ theme, nonInteractive }) =>
      nonInteractive ? null : theme.colors.primary};

    &:before {
      border-color: ${({ theme, nonInteractive }) =>
        nonInteractive ? null : theme.colors.primary};
    }

    &:after {
      border-color: ${({ theme, nonInteractive }) =>
        nonInteractive ? null : theme.colors.primary};
    }
  }

  &:before {
    content: '';
    width: ${cornerSize};
    height: ${cornerSize};
    border-bottom-left-radius: 4px;
    border: 1px solid ${({ theme }) => theme.colors.strokeDarkGrey};
    border-top-width: 0;
    border-right-width: 0;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    transition: border 0.12s;
  }

  &:after {
    content: '';
    width: 70px;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.strokeDarkGrey};
    position: absolute;
    top: 24px;
    right: -11px;
    z-index: 2;
    transform: rotate(45deg);
    transition: background-color 0.12s;
  }
`;

export default CutCornerCard;
