import { FC } from 'react';
import { Trans } from 'react-i18next';

import { Button } from 'antd';

import { useTranslate } from '@hooks';

import * as S from './styled';

type ContactsEmptyProps = {
  onAdd: () => void;
};

const ContactsEmpty: FC<ContactsEmptyProps> = ({ onAdd }) => {
  const { translate } = useTranslate();

  return (
    <S.Container>
      <S.Title>{translate('contactsPage.noContacts.title')}</S.Title>

      <S.Description>
        <Trans
          i18nKey="contactsPage.noContacts.description"
          components={[<br />]}
        />
      </S.Description>

      <Button onClick={onAdd} ghost>
        {translate('contactsPage.noContacts.buttonAdd')}
      </Button>
    </S.Container>
  );
};

export default ContactsEmpty;
