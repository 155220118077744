import { Params$FileMappingController_updateOneById } from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';

import { useApiClientMutation } from '@hooks';

const useUpdateFileMappingsById = () => {
  return useApiClientMutation<
    Params$FileMappingController_updateOneById,
    Schemas.FileMapping
  >({
    method: 'FileMappingController_updateOneById',
  });
};

export default useUpdateFileMappingsById;
