import styled from '@emotion/styled';

import { layout } from '@theme';

export const Container = styled.div`
  padding-bottom: ${`${layout.fixedBarHeight}px`};
  position: relative;
`;

export const Fixed = styled.div`
  height: ${`${layout.fixedBarHeight}px`};
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.strokeGrey};
  position: fixed;
  bottom: 0;
  left: ${`${layout.sidebarWidth}px`};
  right: 0;
  padding: 16px 50px;
  z-index: 3;
`;
