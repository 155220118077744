import { Params$TaxController_findAll } from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';

import { useApiClient } from '@hooks';

type Params = Params$TaxController_findAll;

type TaxesListProps = {
  params: Params['parameter'];
};

const useGetTaxesList = ({ params }: TaxesListProps) => {
  const {
    data: taxes,
    loading,
    refetch,
  } = useApiClient<Params, Schemas.Tax[]>({
    method: 'TaxController_findAll',
    params: {
      parameter: params,
    },
  });

  return {
    taxes: taxes || [],
    loading,
    refetch,
  };
};

export default useGetTaxesList;
