import { FC } from 'react';
import { Trans } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { PageMeta, PublicCardForm, ForgotPasswordForm } from '@entities';
import { useTranslate } from '@hooks';
import { useAuthResetPassword } from '@hooks-api';
import { Schemas } from '@api-client/generated/types';

const ForgotPassword: FC = () => {
  const { translate } = useTranslate();
  const navigate = useNavigate();

  const [resetPassword, loading] = useAuthResetPassword();

  const handleSubmit = (values: Schemas.ResetPasswordDto) =>
    resetPassword(
      { requestBody: values },
      {
        onSuccess: () => {
          navigate('/forgot-password/message');
        },
      }
    );

  return (
    <>
      <PageMeta title={translate('auth.forgotPassword.title')} />

      <PublicCardForm
        title={translate('auth.forgotPassword.title')}
        subTitle={translate('auth.forgotPassword.subtitle')}
        footer={
          <Trans
            i18nKey={translate('auth.forgotPassword.footerText')}
            components={[<Link to="/login" />]}
          />
        }
      >
        <ForgotPasswordForm onSubmit={handleSubmit} isLoading={loading} />
      </PublicCardForm>
    </>
  );
};

export default ForgotPassword;
