import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { LocalePaths } from '@locales';

const useTranslate = () => {
  const { t } = useTranslation();

  const translate = useCallback(
    (key: LocalePaths, options = {}) => t(key, options),
    [t]
  );

  const tDynamic = useCallback(
    (key: LocalePaths | unknown, options = {}) =>
      t(key as LocalePaths, options),
    [t]
  );

  const tObjects = useCallback(
    (key: LocalePaths | unknown, options = {}) =>
      t(key as LocalePaths, options) as unknown as string[],
    [t]
  );

  return { translate, tDynamic, tObjects };
};

export default useTranslate;
