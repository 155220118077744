import { FC } from 'react';
import { Checkbox } from 'antd';

import { useTranslate } from '@hooks';

import { Schemas } from '@api-client/generated/types';

import * as S from './styled';

type AttachItemProps = {
  item: Schemas.Document;
  isChecked: boolean;
  onCheck: (value: boolean) => void;
  onView: () => void;
};

const AttachSupplementaryModalItem: FC<AttachItemProps> = ({
  item,
  isChecked,
  onCheck,
  onView,
}) => {
  const { translate } = useTranslate();

  const handleCheck = () => {
    onCheck(!isChecked);
  };

  const handleView = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation();
    onView();
  };

  return (
    <S.AttachItem
      align="center"
      gap={16}
      isactive={isChecked ? 'true' : ''}
      onClick={handleCheck}
    >
      <Checkbox
        checked={isChecked}
        onChange={(e) => onCheck(e.target.checked)}
      />
      <S.AttachItemName>
        {item.name || translate('transaction.noName')}
      </S.AttachItemName>
      <S.View onClick={handleView} />
    </S.AttachItem>
  );
};

export default AttachSupplementaryModalItem;
