import styled from '@emotion/styled';

export const TableContainer = styled.div<{ isEmpty?: boolean }>`
  .ui-table-row {
    cursor: pointer;
  }
  ${({ isEmpty }) =>
    isEmpty &&
    `.ui-table-tbody .ui-table-cell:last-child {
    border-bottom: none;
  }`}
`;
