import styled from '@emotion/styled';

import { type FlexProps, Flex } from 'antd';

export const Empty = styled(Flex)<FlexProps>`
  width: 100%;
  padding: 100px 0;
  text-align: center;
`;

export const EmptyTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
`;

export const EmptyDescription = styled.div``;
