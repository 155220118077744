import { FC, useState } from 'react';

import { Schemas } from '@api-client/generated/types';

import File from './File';
import FileViewer from './FileViewer';

type TaskFile = Schemas.TaskFile;

type FilesListProps = {
  dataSource: Schemas.TaskFile[];
  onRemove?: (id: string) => void;
};

const FilesList: FC<FilesListProps> = ({ dataSource, onRemove }) => {
  const [fileViewerVisible, setFileViewerVisible] = useState(false);
  const [selectedFileView, setSelectedFileView] = useState<
    TaskFile | undefined
  >();

  const handleViewFile = (file: TaskFile) => {
    setSelectedFileView(file);
    setFileViewerVisible(true);
  };

  return (
    <>
      {selectedFileView && (
        <FileViewer
          open={fileViewerVisible}
          file={selectedFileView}
          onCancel={() => setFileViewerVisible(false)}
        />
      )}

      {dataSource.map((file) => (
        <File
          key={file.id}
          file={file}
          onRemove={onRemove}
          onView={() => handleViewFile(file)}
        />
      ))}
    </>
  );
};

export default FilesList;
