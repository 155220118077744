import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from 'antd';

import { useTranslate } from '@hooks';
import { IconBook } from '@assets';

import * as S from './styled';

const ProjectsListEmpty: FC = () => {
  const { translate } = useTranslate();
  const navigate = useNavigate();

  return (
    <S.Wrapper>
      <S.Icon>
        <IconBook />
      </S.Icon>

      <S.Title level={3}>{translate('projects.emptyList.title')}</S.Title>
      <S.Description>
        {translate('projects.emptyList.description')}
      </S.Description>

      <Button onClick={() => navigate('/projects/create')} size="large">
        {translate('projects.emptyList.buttonAdd')}
      </Button>
    </S.Wrapper>
  );
};

export default ProjectsListEmpty;
