import { FC, type PropsWithChildren, createContext, useState } from 'react';

import { Schemas } from '@api-client/generated/types';

type Invite = Schemas.CompanyInvite;
type User = Schemas.User;

type TeamMembersContextProps = {
  users: User[];
  companyInvites: Invite[];
  initialUsers: (users: User[]) => void;
  initialInvites: (invites: Invite[]) => void;
  createInvite: (newInvite: Invite) => void;
  deleteUser: (id: string) => void;
  deleteInvite: (id: string) => void;
};

export const TeamMembersContext = createContext<TeamMembersContextProps>(
  {} as TeamMembersContextProps
);

export const TeamMembersProvider: FC<PropsWithChildren> = ({ children }) => {
  const [users, setUsers] = useState<User[]>([]);
  const [companyInvites, setCompanyInvites] = useState<Invite[]>([]);

  const createInvite = (newInvite: Invite) =>
    setCompanyInvites((prevState) => [...prevState, newInvite]);

  const deleteUser = (id: string) => {
    const filteredUsers = users.filter((user) => user.id !== id);

    setUsers(filteredUsers);
  };

  const deleteInvite = (id: string) => {
    const filteredInvites = companyInvites.filter((invite) => invite.id !== id);

    setCompanyInvites(filteredInvites);
  };

  return (
    <TeamMembersContext.Provider
      value={{
        users,
        companyInvites,
        initialUsers: setUsers,
        initialInvites: setCompanyInvites,
        createInvite,
        deleteUser,
        deleteInvite,
      }}
    >
      {children}
    </TeamMembersContext.Provider>
  );
};
