import styled from '@emotion/styled';

import { Typography } from 'antd';

const { Paragraph } = Typography;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Details = styled(Paragraph)`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 18px;
`;

export const Logo = styled.img`
  width: 46px;
  height: 46px;
`;

export const Name = styled.div`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 22px;
`;
