import { useContext } from 'react';

import { AccountsContext } from '@context';

const useAccounts = () => {
  const accounts = useContext(AccountsContext);

  return accounts;
};

export default useAccounts;
