import styled from '@emotion/styled';

export const Info = styled.div`
  color: ${({ theme }) => theme.colors.iconLineYellow};
  display: flex;
  gap: 4px;
`;

export const ResendLink = styled.a`
  color: ${({ theme }) => theme.colors.iconLineYellow};
  font-weight: 600;
`;

export const SentEmail = styled.span`
  color: ${({ theme }) => theme.colors.iconLineYellow};
  font-weight: 600;
`;
