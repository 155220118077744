import styled from '@emotion/styled';

import { Button } from 'antd';

export const Container = styled.div`
  && {
    .ui-form {
      .ui-form-item {
        margin-bottom: 12px;

        .ui-form-item-label {
          label {
            font-size: 12px;
          }
        }
      }
    }
  }
`;

export const ButtonSubmit = styled(Button)`
  margin-top: 12px;
`;
