import styled from '@emotion/styled';

import { Form, Button, type FormItemProps, type ButtonProps } from 'antd';

export const FormItem = styled(Form.Item)<FormItemProps>`
  && {
    margin-bottom: 4px;
  }
`;

export const ButtonDelete = styled(Button)<ButtonProps>`
  gap: 8px;
  padding-inline: 0;
`;
