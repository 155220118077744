import styled from '@emotion/styled';

import { Typography } from 'antd';

export const Title = styled(Typography.Title)`
  &.ui-typography {
    font-size: 18px;
    margin-bottom: 0;
  }
`;

export const Details = styled(Typography.Paragraph)`
  &.ui-typography {
    color: ${({ theme }) => theme.colors.text400};
    margin: 8px 0 0 0;
  }
`;

export const Download = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.strokeDarkGrey};
  margin: 18px 0;
  padding-bottom: 18px;
`;

export const File = styled.div`
  background-color: ${({ theme }) => theme.colors.bgGrey};
  border: 1px solid ${({ theme }) => theme.colors.strokeDarkGrey};
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 20px;
`;

export const FileName = styled.div`
  color: ${({ theme }) => theme.colors.text400};
  font-size: 16px;
  font-weight: 600;
`;

export const InfoCell = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const InfoCellLabel = styled.div`
  color: ${({ theme }) => theme.colors.text400};
  font-size: 16px;
  font-weight: 600;
`;

export const InfoCellValue = styled.div`
  font-size: 24px;
  font-weight: 600;
`;
