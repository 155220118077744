import { FC, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Trans } from 'react-i18next';

import { Form, Input, Button, message } from 'antd';

import { PageMeta, PublicCardForm } from '@entities';
import { Loader } from '@components';
import { useTranslate } from '@hooks';
import { useAuthAcceptInvite, useGetCompanyInviteByToken } from '@hooks-api';
import { Schemas } from '@api-client/generated/types';

type AcceptCompanyInvite = Schemas.AcceptCompanyInviteDto;

const AcceptInvitation: FC = () => {
  const [searchParams] = useSearchParams();
  const { translate } = useTranslate();
  const navigate = useNavigate();

  const inviteToken = searchParams.get('token');

  const [acceptInvite, loadingAcceptInvite] = useAuthAcceptInvite();
  const [passwordError, setPasswordError] = useState<string | null>();

  const { invite, loading, isError, failureCount } = useGetCompanyInviteByToken(
    {
      params: {
        token: inviteToken!,
      },
    }
  );

  const handleAcceptInvitation = (values: AcceptCompanyInvite) =>
    acceptInvite(
      {
        requestBody: {
          ...values,
          inviteToken: inviteToken!,
        },
      },
      {
        onSuccess: () => {
          message.open({
            type: 'success',
            content: translate('auth.acceptInvitation.successAccept'),
          });

          navigate('/login');
        },
        onFailure: (error) => {
          for (const message of error.message) {
            if (message.startsWith('password.')) {
              setPasswordError(message);
            }
          }
        },
      }
    );

  const handleSubmit = (values: AcceptCompanyInvite) =>
    handleAcceptInvitation(values);

  if (isError || failureCount !== 0) {
    return (
      <PublicCardForm
        title={translate('auth.acceptInvitation.title')}
        subTitle={translate('auth.acceptInvitation.subtitleExpired')}
      />
    );
  }

  if (!invite || loading) {
    return <Loader />;
  }

  if (invite.existingUser) {
    return (
      <>
        <PageMeta title={translate('auth.acceptInvitation.title')} />

        <PublicCardForm
          title={translate('auth.acceptInvitation.title')}
          subTitle={
            <Trans
              i18nKey={translate('auth.acceptInvitation.subtitleExistingUser')}
              components={[<span>{invite.company.name}</span>]}
            />
          }
        >
          <Button
            type="primary"
            size="large"
            shape="round"
            onClick={() => handleAcceptInvitation({} as AcceptCompanyInvite)}
            block
          >
            {translate('auth.acceptInvitation.buttonAccept')}
          </Button>
        </PublicCardForm>
      </>
    );
  }

  return (
    <>
      <PageMeta title={translate('auth.acceptInvitation.title')} />

      <PublicCardForm
        title={translate('auth.acceptInvitation.title')}
        subTitle={
          <Trans
            i18nKey={translate('auth.acceptInvitation.subtitle')}
            components={[<span>{invite.company.name}</span>]}
          />
        }
      >
        <Form
          onFinish={handleSubmit}
          layout="vertical"
          requiredMark={false}
          initialValues={{
            email: invite.email,
          }}
        >
          <Form.Item
            label={translate('auth.acceptInvitation.email.label')}
            name="email"
            rules={[
              {
                required: true,
                message: translate('auth.acceptInvitation.email.error'),
              },
            ]}
          >
            <Input
              placeholder={translate('auth.acceptInvitation.email.placeholder')}
              size="large"
              disabled
            />
          </Form.Item>
          <Form.Item
            label={translate('auth.acceptInvitation.name.label')}
            name="fullName"
            rules={[
              {
                required: true,
                message: translate('auth.acceptInvitation.name.error'),
              },
            ]}
          >
            <Input
              placeholder={translate('auth.acceptInvitation.name.placeholder')}
              size="large"
            />
          </Form.Item>

          <Form.Item
            label={translate('auth.acceptInvitation.password.label')}
            name="password"
            rules={[
              {
                required: true,
                message: translate('auth.acceptInvitation.password.error'),
              },
            ]}
            validateStatus={passwordError ? 'error' : ''}
            help={passwordError && translate(`common.errors.password.weak`)}
          >
            <Input.Password
              placeholder={translate(
                'auth.acceptInvitation.password.placeholder'
              )}
              size="large"
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              shape="round"
              loading={loadingAcceptInvite}
              block
            >
              {translate('auth.acceptInvitation.buttonRegisterAccept')}
            </Button>
          </Form.Item>
        </Form>
      </PublicCardForm>
    </>
  );
};

export default AcceptInvitation;
