import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Inner = styled.div`
  flex: 1;
  margin-bottom: 24px;
`;
