import { useCategoriesController_findAll } from '@api-client/generated/CategoriesController/findAll';
import { useContactsController_findAll } from '@api-client/generated/ContactsController/findAll';
import { useProjectController_findAll } from '@api-client/generated/ProjectController/findAll';
import { useAccount } from '@hooks';

export const useLinkedData = () => {
  const { companyId } = useAccount();

  const { data: { records: contacts = [] } = {} } =
    useContactsController_findAll({
      params: {
        companyId: companyId!,
      },
    });

  const { data: projects = [] } = useProjectController_findAll({
    params: {
      companyId: companyId!,
    },
  });

  const { data: categories = [] } = useCategoriesController_findAll({
    params: {
      companyId: companyId!,
    },
  });

  return { categories, contacts, projects };
};