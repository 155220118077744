import { FC } from 'react';

import { type SelectProps, Select } from '@components';

import * as S from './styled';

type DeafSelectProps = {
  onClick: () => void;
} & SelectProps;

const DeafSelect: FC<DeafSelectProps> = ({ onClick, ...rest }) => (
  <S.DeafSelect onClick={onClick}>
    <Select size="large" {...rest} />
  </S.DeafSelect>
);

export default DeafSelect;
