import { FC, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import _ from 'lodash';

import { Flex, Tabs, Switch } from 'antd';

import { Select } from '@components';
import { SearchControl, PageMeta, TaxesList, TaxesEmpty } from '@entities';
import { getDateRangeByInterval } from '@utils';
import { monthsList } from '@constants';

import { useGetTaxesList } from '@hooks-api';
import { useTranslate, useAccount } from '@hooks';

import { Schemas } from '@api-client/generated/types';

import * as S from './styled';

type Tax = Schemas.Tax;

const Taxes: FC = () => {
  const { translate } = useTranslate();
  const { companyId } = useAccount();

  const [taxesList, setTaxesList] = useState<Tax[]>([]);

  const { taxes } = useGetTaxesList({
    params: {
      companyId: companyId!,
    },
  });

  useEffect(() => {
    if (taxes) {
      setTaxesList(taxes);
    }
  }, [taxes]);

  const handleSearch = (value: string) => {
    setTaxesList(
      value.length > 0
        ? taxes.filter((tax: Tax) =>
            tax.details.reference.toLowerCase().match(value)
          )
        : taxes
    );
  };

  const handleFilterByUnpaid = (isUnpaid: boolean) => {
    setTaxesList(isUnpaid ? taxes.filter((tax: Tax) => !tax.paidAt) : taxes);
  };

  const handleFilterByType = (type: string) => {
    setTaxesList(
      type !== 'all' ? taxes.filter((tax: Tax) => tax.type === type) : taxes
    );
  };

  const getTabsContent = () => {
    const monthList = getDateRangeByInterval(
      dayjs().subtract(12, 'months'),
      dayjs(),
      'months'
    );

    const monthNames = monthList.map((month) => dayjs(month).format('MMMM'));

    const periods = taxesList
      .map((tax) => tax.period)
      .map((period) => {
        if (period.includes('Q')) {
          return period.replace(/^[^ ]* /, '');
        }

        if (monthNames.includes(period)) {
          return dayjs().format('YYYY');
        }

        return period;
      });

    return _.uniq(periods)
      .sort()
      .map((year) => ({
        key: year,
        label: year,
      }));
  };

  const getTaxesByPeriod = (period: string) => {
    const currentYear = dayjs().format('YYYY');

    return taxesList.filter((tax) => {
      if (tax.periodType === 'quarter') {
        if (tax.period === period) {
          return tax;
        }
      }

      if (tax.periodType === 'month') {
        const quarterByMonth = dayjs().month(monthsList[tax.period]).quarter();

        if (`Q${quarterByMonth} ${currentYear}` === period) {
          return tax;
        }
      }

      if (tax.periodType === 'year') {
        if (`total_${tax.period}` === period) {
          return tax;
        }
      }
    });
  };

  const tabsList = getTabsContent();

  return (
    <Flex gap={30} vertical>
      <PageMeta title={translate('taxes.title')} />

      <S.Title>{translate('taxes.title')}</S.Title>

      <Flex align="center" justify="space-between">
        <Flex align="center" gap={24}>
          <SearchControl onChange={(e) => handleSearch(e.target.value)} />

          <S.SwitchControl>
            <Switch onChange={handleFilterByUnpaid} />{' '}
            {translate('taxes.filter.unpaidTaxes')}
          </S.SwitchControl>
        </Flex>

        <Select
          width={188}
          placeholder={translate('taxes.filter.typeTax')}
          options={[
            {
              label: translate('taxes.types.all'),
              value: 'all',
            },
            {
              label: translate('taxes.types.vat'),
              value: 'vat',
            },
            {
              label: translate('taxes.types.corporate'),
              value: 'corporate',
            },
            {
              label: translate('taxes.types.social'),
              value: 'social',
            },
          ]}
          size="large"
          onChange={handleFilterByType}
          showSearch
        />
      </Flex>

      <Tabs
        defaultActiveKey={dayjs().format('YYYY')}
        size="large"
        items={tabsList.map((tab) => ({
          key: tab.key,
          label: tab.label,
          children: (
            <Flex gap={16} vertical>
              {getTaxesByPeriod(`Q1 ${tab.key}`).length ||
              getTaxesByPeriod(`Q2 ${tab.key}`).length ||
              getTaxesByPeriod(`Q3 ${tab.key}`).length ||
              getTaxesByPeriod(`Q4 ${tab.key}`).length ||
              getTaxesByPeriod(`total_${tab.key}`).length ? (
                <>
                  {getTaxesByPeriod(`Q1 ${tab.key}`).length ? (
                    <TaxesList
                      label={`Q1 ${tab.label}`}
                      taxes={getTaxesByPeriod(`Q1 ${tab.key}`)}
                      period={`Q1 ${tab.key}`}
                    />
                  ) : null}

                  {getTaxesByPeriod(`Q2 ${tab.key}`).length ? (
                    <TaxesList
                      label={`Q2 ${tab.label}`}
                      taxes={getTaxesByPeriod(`Q2 ${tab.key}`)}
                      period={`Q2 ${tab.key}`}
                    />
                  ) : null}

                  {getTaxesByPeriod(`Q3 ${tab.key}`).length ? (
                    <TaxesList
                      label={`Q3 ${tab.label}`}
                      taxes={getTaxesByPeriod(`Q3 ${tab.key}`)}
                      period={`Q3 ${tab.key}`}
                    />
                  ) : null}

                  {getTaxesByPeriod(`Q4 ${tab.key}`).length ? (
                    <TaxesList
                      label={`Q4 ${tab.label}`}
                      taxes={getTaxesByPeriod(`Q4 ${tab.key}`)}
                      period={`Q4 ${tab.key}`}
                    />
                  ) : null}

                  {getTaxesByPeriod(`total_${tab.key}`).length ? (
                    <TaxesList
                      label={`Total annual ${tab.label}`}
                      taxes={getTaxesByPeriod(`total_${tab.key}`)}
                    />
                  ) : null}
                </>
              ) : (
                <TaxesEmpty />
              )}
            </Flex>
          ),
        }))}
      />
    </Flex>
  );
};

export default Taxes;
