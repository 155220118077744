import { FC, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { Dropdown, message, Modal, Table, TableProps } from 'antd';

import { Card } from '@components';
import { TeamMembersAccessModal } from '@entities';
import { useAccount, useTeamMembers, useTranslate } from '@hooks';
import {
  useCreateCompanyInvite,
  useDeleteCompanyInvite,
  useGetUserData,
  useRemoveUserFromCompanyById,
  useUpdateCompanyInvite,
  useUpdateUserPermissions,
} from '@hooks-api';
import { colors } from '@theme';

import { Schemas } from '@api-client/generated/types';

import * as S from './styled';
import { IconMoreVertical } from '@assets';

type TeamMembersProps = {
  users: Schemas.User[];
  invites: Schemas.CompanyInvite[];
  loading: boolean;
  isAccessModalOpen: boolean;
  onOpenAccessModal: (value: boolean) => void;
};

const TeamMembers: FC<TeamMembersProps> = ({
  users,
  invites,
  loading,
  onOpenAccessModal,
  isAccessModalOpen,
}) => {
  const [modal, contextHolder] = Modal.useModal();

  const { translate, tDynamic } = useTranslate();
  const { companyId, setUserAccess, account } = useAccount();
  const { deleteUser, deleteInvite: removeInvite } = useTeamMembers();

  const [usersList, setUsersList] = useState<Schemas.User[]>([]);
  const [invitesList, setInvitesList] = useState<Schemas.CompanyInvite[]>([]);
  const [editableUser, setEditableUser] = useState<Schemas.User | null>(null);
  const [isCanCallUser, setIsCanCallUser] = useState(false);

  useGetUserData({
    enabled: isCanCallUser,
    onSuccess: (res) => {
      if (res?.companies?.length) {
        setUserAccess(res?.companies[0]?.userAccessRights);
        setIsCanCallUser(false);
      }
    },
  });
  const [removeUserFromCompany, removeUserLoading] =
    useRemoveUserFromCompanyById();
  const [updateUserPermissions, updateUserLoading] = useUpdateUserPermissions();
  const [updateUserInvite] = useUpdateCompanyInvite();
  const [inviteUser, inviteUserLoading] = useCreateCompanyInvite();
  const [deleteInvite] = useDeleteCompanyInvite();

  useEffect(() => {
    setUsersList(users);
    setInvitesList(invites);
  }, [users, invites]);

  const usersColumns: TableProps<Schemas.User>['columns'] = [
    {
      key: 'fullName',
      dataIndex: 'fullName',
      title: (
        <S.ColumnTitle isname="true">
          {translate('settings.teamMembers.users.name')}
        </S.ColumnTitle>
      ),
      render: (text, record) => {
        return (
          <S.UserName isinvite={text ? text?.toString() : ''} gap={2} vertical>
            <S.Name>{text || record.email}</S.Name>
            <S.Email>
              {text ? record?.email : translate('settings.teamMembers.pending')}
            </S.Email>
          </S.UserName>
        );
      },
    },
    {
      key: 'transactions',
      dataIndex: 'transactions',
      title: (
        <S.ColumnTitle isbordered="true">
          {translate('settings.teamMembers.users.transactions')}
        </S.ColumnTitle>
      ),
      render: (_, record) => {
        return (
          <S.CheckWrapper>
            <S.Check
              isdone={record?.accessRights?.transactions ? 'true' : ''}
            />
          </S.CheckWrapper>
        );
      },
    },
    {
      key: 'companyData',
      dataIndex: 'companyData',
      title: (
        <S.ColumnTitle isbordered="true">
          {translate('settings.teamMembers.users.companyData')}
        </S.ColumnTitle>
      ),
      render: (_, record) => {
        return (
          <S.CheckWrapper>
            <S.Check isdone={record?.accessRights?.companyData ? 'true' : ''} />
          </S.CheckWrapper>
        );
      },
    },
    {
      key: 'userManagement',
      dataIndex: 'userManagement',
      title: (
        <S.ColumnTitle isbordered="true">
          {translate('settings.teamMembers.users.userManagement')}
        </S.ColumnTitle>
      ),
      render: (_, record) => {
        return (
          <S.CheckWrapper>
            <S.Check
              isdone={record?.accessRights?.userManagement ? 'true' : ''}
            />
          </S.CheckWrapper>
        );
      },
    },
    {
      key: 'reports',
      dataIndex: 'reports',
      title: (
        <S.ColumnTitle isbordered="true">
          {translate('settings.teamMembers.users.reports')}
        </S.ColumnTitle>
      ),
      render: (_, record) => {
        return (
          <S.CheckWrapper>
            <S.Check isdone={record?.accessRights?.reports ? 'true' : ''} />
          </S.CheckWrapper>
        );
      },
    },
    {
      key: 'payroll',
      dataIndex: 'payroll',
      title: (
        <S.ColumnTitle isbordered="true">
          {translate('settings.teamMembers.users.payroll')}
        </S.ColumnTitle>
      ),
      render: (_, record) => {
        return (
          <S.CheckWrapper>
            <S.Check isdone={record?.accessRights?.payroll ? 'true' : ''} />
          </S.CheckWrapper>
        );
      },
    },
    {
      key: 'incomesExpences',
      dataIndex: 'incomesExpences',
      title: (
        <S.ColumnTitle isbordered="true">
          {translate('settings.teamMembers.users.incomesExpences')}
        </S.ColumnTitle>
      ),
      render: (_, record) => {
        return (
          <S.CheckWrapper>
            <S.Check
              isdone={record?.accessRights?.incomesExpences ? 'true' : ''}
            />
          </S.CheckWrapper>
        );
      },
    },
    {
      key: 'actions',
      dataIndex: 'actions',
      title: '',
      render: (_, record) => {
        if (account?.id !== record.id) {
          return (
            <Dropdown
              menu={{
                items: [
                  {
                    key: 'edit',
                    onClick: () => {
                      setEditableUser(record);
                      onOpenAccessModal(true);
                    },
                    label: (
                      <S.DropdownItem data-testid="edit-button">
                        {translate('chat.actions.edit')}
                      </S.DropdownItem>
                    ),
                  },
                  {
                    key: 'remove',
                    label: (
                      <S.DropdownItem
                        onClick={() => {
                          setEditableUser(record);
                          handleDeleteUser(record);
                        }}
                      >
                        {translate('chat.actions.remove')}
                      </S.DropdownItem>
                    ),
                  },
                ],
              }}
              placement="bottomRight"
              trigger={['click']}
            >
              <S.More
                data-testid={
                  record.email !== account?.email
                    ? `dropdown-${record.email}`
                    : ''
                }
              >
                <IconMoreVertical />
              </S.More>
            </Dropdown>
          );
        }
      },
    },
  ];

  const handleDeleteUser = (user: Schemas.User) => {
    modal.confirm({
      title: (
        <Trans
          i18nKey={tDynamic('settings.teamMembers.modal.removeUserTitle', {
            name: user?.fullName || user?.email,
          })}
          components={[<S.Boldest />]}
        />
      ),
      content: translate('settings.teamMembers.modal.removeUserDescription'),
      icon: null,
      okText: translate('settings.teamMembers.modal.deleteButton'),
      okButtonProps: { style: { background: colors.error } },
      width: 504,
      onOk: () =>
        editableUser?.fullName
          ? removeUserFromCompany(
              {
                parameter: {
                  companyId: companyId!,
                  id: user.id,
                },
              },
              {
                onSuccess: (res) => {
                  deleteUser(user!.id);
                  setUsersList((prev) =>
                    prev.filter((user) => user.id !== res?.id)
                  );
                  setEditableUser(null);
                  onOpenAccessModal(false);
                  message.success(
                    translate('settings.teamMembers.users.deletedUserMessage')
                  );
                },
              }
            )
          : deleteInvite(
              {
                parameter: {
                  companyId: companyId!,
                  id: user!.id,
                },
              },
              {
                onSuccess: (res) => {
                  removeInvite(user.id);
                  setInvitesList((prev) =>
                    prev.filter((invite) => invite.id !== res?.id)
                  );
                  setEditableUser(null);
                  onOpenAccessModal(false);
                  message.success(
                    translate('settings.teamMembers.users.deletedUserMessage')
                  );
                },
              }
            ),
      autoFocusButton: null,
    });
  };

  const handleSubmit = (values: any) => {
    if (editableUser) {
      if (editableUser.fullName) {
        updateUserPermissions(
          {
            parameter: {
              companyId: companyId!,
              id: editableUser?.id,
            },
            requestBody: {
              accessRights: values,
            },
          },
          {
            onSuccess: () => {
              setUsersList((prev) =>
                prev.map((user) =>
                  user.id === editableUser?.id
                    ? {
                        ...user,
                        accessRights: values,
                      }
                    : user
                )
              );
              setEditableUser(null);
              onOpenAccessModal(false);
              setIsCanCallUser(true);
            },
          }
        );
      } else {
        updateUserInvite(
          {
            parameter: {
              companyId: companyId!,
              id: editableUser?.id,
            },
            requestBody: {
              accessRights: values,
            },
          },
          {
            onSuccess: () => {
              setInvitesList((prev) =>
                prev.map((user) =>
                  user.id === editableUser?.id
                    ? {
                        ...user,
                        accessRights: values,
                      }
                    : user
                )
              );
              setEditableUser(null);
              onOpenAccessModal(false);
              message.success(
                translate('settings.teamMembers.users.invitedUserMessage')
              );
            },
          }
        );
      }
    } else {
      inviteUser(
        {
          parameter: {
            companyId: companyId!,
          },
          requestBody: {
            email: values?.email,
            accessRights: values?.accessRights,
          },
        },
        {
          onSuccess: (res) => {
            setInvitesList((prev) => [...prev, res]);
            onOpenAccessModal(false);
          },
        }
      );
    }
  };

  return (
    <Card>
      {contextHolder}

      <S.Users>
        <Table
          rowKey={({ id }) => id}
          dataSource={[...usersList, ...invitesList]}
          // @ts-expect-error-next-line
          columns={usersColumns}
          pagination={false}
          loading={
            loading ||
            removeUserLoading ||
            updateUserLoading ||
            inviteUserLoading
          }
        />
      </S.Users>

      {isAccessModalOpen && (
        <TeamMembersAccessModal
          open={isAccessModalOpen}
          isNew={!editableUser}
          editableUser={editableUser}
          onChangeEditableUser={setEditableUser}
          onDeleteEditableUser={handleDeleteUser}
          onSubmit={handleSubmit}
          onClose={() => onOpenAccessModal(false)}
        />
      )}
    </Card>
  );
};

export default TeamMembers;
