import { Button, Flex } from 'antd';
import dayjs from 'dayjs';
import { FC } from 'react';

import { Schemas } from '@api-client/generated/types';
import { IconDownload } from '@assets';
import { Card } from '@components';
import { StatusCircle } from '@entities';
import { useTranslate } from '@hooks';

import * as S from './styled';

type TaxReviewProps = {
  period?: string;
  tax: Schemas.Tax | null;
};

const TaxReview: FC<TaxReviewProps> = ({ tax, period }) => {
  const { translate } = useTranslate();

  if (!tax) {
    return null;
  }

  return (
    <Card>
      <Flex align="center" gap={12}>
        <StatusCircle number={1} active />
        <S.Title>{translate('taxes.review.name', { date: period })}</S.Title>
      </Flex>

      <S.Details>
        {translate('taxes.review.details', { date: period })}
      </S.Details>

      <S.Download>
        <S.File>
          <S.FileName>{tax.files ? tax.files[0].name : '-'}</S.FileName>

          <a
            href={tax.files ? tax.files[0].url : ''}
            download="file"
            target="_blank"
            rel="noreferrer"
          >
            <Button type="text" icon={<IconDownload />} size="small" />
          </a>
        </S.File>
      </S.Download>

      <Flex align="center" justify="space-between">
        <S.InfoCell>
          <S.InfoCellLabel>
            {translate('taxes.review.paymentAmount')}
          </S.InfoCellLabel>

          <S.InfoCellValue>€{tax.amount}</S.InfoCellValue>
        </S.InfoCell>

        <S.InfoCell>
          <S.InfoCellLabel>
            {translate('taxes.review.paymentDate')}
          </S.InfoCellLabel>

          <S.InfoCellValue>
            {dayjs(tax.dueDate).format('DD.MM.YYYY')}
          </S.InfoCellValue>
        </S.InfoCell>
      </Flex>
    </Card>
  );
};

export default TaxReview;
