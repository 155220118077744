import styled from '@emotion/styled';
import { Flex } from 'antd';

type IncorporationServicesProps = {
  bordered?: boolean;
};

export const IncorporationServices = styled.div<IncorporationServicesProps>`
  padding: 20px;
  background-color: ${({ theme, bordered }) =>
    bordered ? theme.colors.white : theme.colors.badgeGrey};
  border: ${({ bordered }) => (bordered ? '1px' : 0)} solid
    ${({ theme }) => theme.colors.strokeGrey};
  border-radius: 12px;
`;

export const IncorporationHeader = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.black};
`;

export const IncorporationFooterText = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.black};
`;

export const List = styled(Flex)`
  padding: 20px 0;
  margin: 20px 0;
  border-top: 2px solid ${({ theme }) => theme.colors.strokeGrey};
  border-bottom: 2px solid ${({ theme }) => theme.colors.strokeGrey};
`;

export const Amount = styled.span`
  font-weight: 600;
`;
