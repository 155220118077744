import { FC } from 'react';

import { Typography } from 'antd';

import * as S from './styled';

type TransactionButtonCopyProps = {
  id: string;
};

const { Paragraph } = Typography;

const TransactionButtonCopy: FC<TransactionButtonCopyProps> = ({ id }) => {
  const splittedId = id.split('-');

  return (
    <S.Wrapper>
      <Paragraph>ID#{splittedId[splittedId.length - 1]}</Paragraph>
    </S.Wrapper>
  );
};

export default TransactionButtonCopy;
