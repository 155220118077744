import { AccountsProvider, TransactionsImportProvider } from '@context';
import TransactionsImport from './TransactionsImport';

export default () => (
  <TransactionsImportProvider>
    <AccountsProvider>
      <TransactionsImport />
    </AccountsProvider>
  </TransactionsImportProvider>
);
