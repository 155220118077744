import { ReactNode } from 'react';
import _ from 'lodash';

import { Schemas } from '@api-client/generated/types';
import { RouteObject } from 'react-router-dom';

export const filteredRoutes = (
  userAccess: Schemas.AccessRights | null,
  appRoutes: RouteObject[],
  removePaths: Record<string, string[]>,
  fallbackElement: ReactNode
): RouteObject[] => {
  const routes = _.cloneDeep(appRoutes);

  if (!userAccess) {
    return routes;
  }

  (Object.keys(userAccess) as (keyof Schemas.AccessRights)[]).forEach(
    (path) => {
      if (!userAccess[path]) {
        routes.forEach((route) => {
          if (route.children) {
            route.children = route.children.map((childRoute) => {
              const matchingPath = removePaths[path].some((removePath) =>
                childRoute.path?.includes(removePath)
              );
              return matchingPath
                ? { ...childRoute, element: fallbackElement }
                : childRoute;
            });
          }
        });
      }
    }
  );

  return routes;
};
