import styled from '@emotion/styled';
import { Flex } from 'antd';

export const FilelessTitle = styled.span`
  font-size: 16px;
  line-height: 24px;
`

export const FilelessDescription = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`

export const FilelessCard = styled(Flex)`
  color: ${({theme}) => theme.colors.text400};
  height: 100%;
`