import { FC } from 'react';
import { Button, Col, Flex, Form, Row } from 'antd';

import { useAccount, useOnboarding, useTranslate } from '@hooks';
import { CopyButton } from '@components';
import countries from '@data/countries.json';

import { useUpdateIncorporation } from '@hooks-api';

import { Schemas } from '@api-client/generated/types';

import * as S from './styled';

type FormValuesType = {
  country?: string;
  companyName?: string;
  iban?: string;
  bic?: string;
  address?: string;
  reference?: string;
};

const OnboardingTransferShare: FC = () => {
  const { translate } = useTranslate();

  const { companyId } = useAccount();

  const [form] = Form.useForm();

  const { incorporationDetails, updateScreen, updateIncorporation } =
    useOnboarding();

  const [update] = useUpdateIncorporation();

  const details = incorporationDetails as Schemas.Incorporation;

  const getCountryByCountryCode = (countryCode: string) => {
    const country = countries.find((country) => country.code === countryCode);
    return country && country.name;
  };

  const handleContinue = () => {
    const body = {
      isTransferedShareCapital: true,
    };

    update(
      {
        parameter: {
          companyId: companyId!,
        },
        requestBody: body,
      },
      {
        onSuccess: () => {
          updateIncorporation(body);
        },
      }
    );

    updateScreen('MESSAGE_PROCESS_PAYMENT_TO_RCS', { active: true });
  };

  return (
    <S.Container>
      <S.Wrapper vertical>
        <S.Header vertical>
          <S.HeaderTitle>
            {translate('onboarding.transferShare.form.title')}
          </S.HeaderTitle>
          <span>{translate('onboarding.transferShare.form.description')}</span>
          <S.HeaderDescriptionBold>
            {translate('onboarding.transferShare.form.descriptionSecond')}
          </S.HeaderDescriptionBold>
        </S.Header>

        <Row gutter={17}>
          <Col span={14}>
            {details && (
              <Form<FormValuesType>
                layout="vertical"
                name="shareForm"
                requiredMark={false}
                initialValues={{
                  ...details.bankAccountData,
                  country: getCountryByCountryCode(
                    details.bankAccountData?.countryCode
                  ),
                }}
                form={form}
              >
                <Form.Item
                  label={translate('onboarding.transferShare.form.country')}
                  name="country"
                >
                  <S.DisabledLikeEnabledInput
                    suffix={
                      <CopyButton
                        value={getCountryByCountryCode(
                          details.bankAccountData?.countryCode
                        )}
                      />
                    }
                    size="large"
                    disabled
                  />
                </Form.Item>

                <Form.Item
                  label={translate('onboarding.transferShare.form.companyName')}
                  name="companyName"
                >
                  <S.DisabledLikeEnabledInput
                    suffix={
                      <CopyButton
                        value={details.bankAccountData?.companyName}
                      />
                    }
                    size="large"
                    disabled
                  />
                </Form.Item>

                <Form.Item
                  label={translate('onboarding.transferShare.form.iban')}
                  name="iban"
                >
                  <S.DisabledLikeEnabledInput
                    suffix={
                      <CopyButton value={details.bankAccountData?.iban} />
                    }
                    size="large"
                    disabled
                  />
                </Form.Item>

                <Form.Item
                  label={translate('onboarding.transferShare.form.bic')}
                  name="bic"
                >
                  <S.DisabledLikeEnabledInput
                    suffix={<CopyButton value={details.bankAccountData?.bic} />}
                    size="large"
                    disabled
                  />
                </Form.Item>

                <Form.Item
                  label={translate('onboarding.transferShare.form.address')}
                  name="addressLine"
                >
                  <S.DisabledLikeEnabledInput
                    suffix={
                      <CopyButton
                        value={details.bankAccountData?.addressLine}
                      />
                    }
                    size="large"
                    disabled
                  />
                </Form.Item>

                <Form.Item
                  label={translate('onboarding.transferShare.form.reference')}
                  name="reference"
                >
                  <S.DisabledLikeEnabledInput
                    suffix={
                      <CopyButton value={details.bankAccountData?.reference} />
                    }
                    size="large"
                    disabled
                  />
                </Form.Item>
              </Form>
            )}
          </Col>

          <Col span={10}>
            <S.FormDescriptionWrapper>
              <S.FormDescription>
                {translate('onboarding.transferShare.form.willAccess')}
              </S.FormDescription>
            </S.FormDescriptionWrapper>
          </Col>
        </Row>
      </S.Wrapper>

      <Flex justify="flex-end">
        <Button type="primary" onClick={handleContinue}>
          {translate('onboarding.transferShare.form.buttonNext')}
        </Button>
      </Flex>
    </S.Container>
  );
};

export default OnboardingTransferShare;
