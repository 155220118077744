import { Button, Col, Flex, Form, Input, Row, Typography } from 'antd';
import { FC } from 'react';
import { Trans } from 'react-i18next';

import { Schemas } from '@api-client/generated/types';
import { OnboardingCardHeader } from '@entities';
import {
  useAccount,
  useFormValidateFields,
  useOnboarding,
  useTranslate,
} from '@hooks';
import { useUpdateIncorporation } from '@hooks-api';

import * as S from './styled';

const { Paragraph } = Typography;

const OnboardingShareholdersInvite: FC = () => {
  const { translate, tDynamic } = useTranslate();

  const { form, disabledForm } = useFormValidateFields();

  const [updateIncorporationApi] = useUpdateIncorporation();

  const {
    incorporationDetails,
    updateScreen,
    updateIncorporation,
    allPeopleHaveEmails,
  } = useOnboarding();

  const { companyId } = useAccount();

  const handleSubmit = (value: Schemas.Incorporation) => {
    const companyItself: Schemas.Person[] =
      incorporationDetails?.people?.filter(
        (person) => person.isCompanyItself
      ) || [];

    const people = companyItself.concat(value.people || []);

    if (incorporationDetails && value.people) {
      updateIncorporationApi(
        {
          parameter: {
            companyId: companyId!,
          },
          requestBody: {
            people: people,
          },
        },
        {
          onSuccess: (response) => {
            updateIncorporation({ people: response.people });
            updateScreen('MESSAGE_PROCESS_VERIFY_DOCUMENTS', { active: true });
          },
        }
      );
    }
  };

  const goBack = () => {
    updateScreen('INCORPORATION', { active: true });
  };

  const people =
    incorporationDetails?.people?.filter((person) => !person.isCompanyItself) ||
    [];

  return (
    <S.Container>
      <Form
        layout="vertical"
        requiredMark={false}
        form={form}
        onFinish={handleSubmit}
        initialValues={{
          people: people,
        }}
      >
        <S.Inner>
          <OnboardingCardHeader
            title={tDynamic(
              `onboarding.inviteShareholders.title_${incorporationDetails?.incorporationType}`
            )}
            description={
              <Paragraph>
                <Trans
                  i18nKey={tDynamic(
                    `onboarding.inviteShareholders.subDescription_${incorporationDetails?.incorporationType}`
                  )}
                  components={[<strong />, <Paragraph />]}
                />
              </Paragraph>
            }
          />
          <Form.List name="people">
            {(fields) => (
              <>
                <S.FormInner>
                  <Row gutter={[30, 30]}>
                    <Col span={6}>
                      <S.FormLabel>
                        {tDynamic('onboarding.shareholders.name')}
                      </S.FormLabel>
                    </Col>
                    <Col span={14}>
                      <S.FormLabel>
                        {tDynamic('onboarding.shareholders.email')}
                      </S.FormLabel>
                    </Col>
                    <Col span={4}>
                      <S.FormLabel>
                        {allPeopleHaveEmails() &&
                          tDynamic('onboarding.shareholders.status')}
                      </S.FormLabel>
                    </Col>
                  </Row>

                  {fields.map(({ name, key, ...field }) => (
                    <S.FormItem key={key}>
                      <Row gutter={30} align="middle">
                        <Col span={6}>
                          <S.Bold>{people[key]?.name}</S.Bold>
                        </Col>

                        <Col span={14}>
                          <Form.Item
                            {...field}
                            name={[name, 'email']}
                            noStyle
                            rules={[{ required: true }]}
                          >
                            <Input
                              placeholder={tDynamic(
                                'onboarding.shareholders.email'
                              )}
                              size="large"
                              disabled={allPeopleHaveEmails()}
                            />
                          </Form.Item>
                        </Col>

                        {allPeopleHaveEmails() && (
                          <Col span={4}>
                            <S.Status
                              status={
                                (people as Schemas.Person[])[key]
                                  ?.signatureStatus
                              }
                            >
                              {tDynamic(
                                `onboarding.inviteShareholders.status.${(people as Schemas.Person[])[key]?.signatureStatus || 'waiting'}`
                              )}
                            </S.Status>
                          </Col>
                        )}
                      </Row>
                    </S.FormItem>
                  ))}
                </S.FormInner>
              </>
            )}
          </Form.List>
        </S.Inner>

        {!allPeopleHaveEmails() && (
          <Flex justify="flex-end">
            <Button type="primary" htmlType="submit" disabled={disabledForm}>
              {translate('onboarding.buttonNext')}
            </Button>
          </Flex>
        )}

        {allPeopleHaveEmails() && (
          <Flex justify="flex-end">
            <Button type="link" onClick={goBack}>
              {translate('onboarding.buttonGoBack')}
            </Button>
          </Flex>
        )}
      </Form>
    </S.Container>
  );
};

export default OnboardingShareholdersInvite;
