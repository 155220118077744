import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  .ui-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
  }
`;

export const Inner = styled.div`
  margin-bottom: 24px;
`;

export const FormInner = styled.div`
  background-color: ${({ theme }) => theme.colors.bgGrey};
  border-radius: 8px;
  padding: 14px 20px;
`;

export const FormItem = styled.div`
  padding-bottom: 14px;
  margin-bottom: 14px;
  margin-top: 12px;

  &:not(:last-child) {
    border-bottom: 2px solid ${({ theme }) => theme.colors.strokeDarkGrey};
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const FormLabel = styled.div`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 12px;
  padding: 0;
`;

export const Bold = styled.span`
  font-weight: 700;
`;

export const Status = styled.span<{ status?: string }>`
  padding: 4px 12px;
  border-radius: 100px;
  background-color: ${({ theme, status }) =>
    status === 'signed'
      ? theme.colors.badgeSuccess
      : theme.colors.badgeWarning};
  color: ${({ theme, status }) =>
    status === 'signed' ? theme.colors.success : theme.colors.error};
  font-weight: 600;
  font-size: 12px;
  text-transform: capitalize;
`;
