//
// Generated by @himenon/openapi-typescript-code-generator v0.27.4
//
// OpenApi : 3.0.0
//
//
import {
  Params$IncorporationController_updateOneById,
  Response$IncorporationController_updateOneById$Status$200,
  ResponseContentType$IncorporationController_updateOneById,
} from '@api-client/generated/client';
import { useApiClientMutation } from '@hooks/useApiClientMutation';

export const useIncorporationController_updateOneById = () =>
  useApiClientMutation<
    Params$IncorporationController_updateOneById,
    Response$IncorporationController_updateOneById$Status$200[ResponseContentType$IncorporationController_updateOneById]
  >({ method: 'IncorporationController_updateOneById' });
