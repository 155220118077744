import styled from '@emotion/styled';

import { Typography } from 'antd';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  .ui-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
  }
`;

export const Inner = styled.div`
  margin-bottom: 24px;
`;

export const FormInner = styled.div`
  background-color: ${({ theme }) => theme.colors.bgGrey};
  border-radius: 8px;
  padding: 14px 22px;

  .ui-form-item {
    margin-bottom: 8px;
  }
`;

export const FormLabel = styled.div`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 12px;
  margin-bottom: 12px;
`;

export const ExtraText = styled(Typography.Paragraph)`
  .ui-typography {
    margin: 0;
  }
`;
