//
// Generated by @himenon/openapi-typescript-code-generator v0.27.4
//
// OpenApi : 3.0.0
//
//
import {
  Params$IncorporationController_create,
  Response$IncorporationController_create$Status$201,
  ResponseContentType$IncorporationController_create,
} from '@api-client/generated/client';
import { useApiClientMutation } from '@hooks/useApiClientMutation';

export const useIncorporationController_create = () =>
  useApiClientMutation<
    Params$IncorporationController_create,
    Response$IncorporationController_create$Status$201[ResponseContentType$IncorporationController_create]
  >({ method: 'IncorporationController_create' });
