import { FC } from 'react';

import { useTranslate } from '@hooks';

import * as S from './styled';

const ContactsDocsEmpty: FC = () => {
  const { translate } = useTranslate();

  return (
    <S.Empty gap={12} vertical>
      <S.EmptyTitle>
        {translate('contactsPage.paymentDocuments.empty.title')}
      </S.EmptyTitle>

      <S.EmptyDescription>
        {translate('contactsPage.paymentDocuments.empty.description')}
      </S.EmptyDescription>
    </S.Empty>
  );
};

export default ContactsDocsEmpty;
