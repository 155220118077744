import styled from '@emotion/styled';

import { Tag as TagAntd } from 'antd';

export const Tag = styled(TagAntd)`
  && {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    color: ${({ theme }) => theme.colors.text400};
    border-color: ${({ theme }) => theme.colors.badgeBlue100};
    background-color: ${({ theme }) => theme.colors.badgeBlue100};
  }
`;
