import { Flex, Typography } from 'antd';

import styled from '@emotion/styled';

import { SubItemType } from '../../../pages/Categories/Categories';

export const Wrapper = styled(Flex)`
  flex-direction: column;
  margin-top: 8px;
  padding-bottom: 8px;
`;

export const ListItemContent = styled(Flex)`
  flex-direction: column;
  margin-left: 24px;
  border-left: 1px solid ${({ theme }) => theme.colors.strokeGrey};
`;

export const EmptyContainer = styled(Flex)`
  padding: 6px;
  margin-left: 8px;
  cursor: default;
`;

export const EmptyText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isempty',
})<SubItemType>`
  color: ${({ theme, isempty }) =>
    isempty ? theme.colors.textDisabled : theme.colors.primary};
`;
