import { Flex, type FlexProps, Typography } from 'antd';

import styled from '@emotion/styled';

const { Text } = Typography;

type InvoiceProps = {
  reviewed?: boolean;
  selected?: boolean;
};

export const Invoice = styled(Flex, {
  shouldForwardProp: (prop) => prop !== 'reviewed',
})<FlexProps & InvoiceProps>`
  min-height: 90px;
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.bgDarkGrey : theme.colors.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.strokeGrey};
  cursor: pointer;
  padding: 16px 12px 16px 24px;
  position: relative;

  &:after {
    content: '';
    width: 6px;
    height: 6px;
    background-color: ${({ theme, reviewed }) =>
      !reviewed ? theme.colors.primary : 'transparent'};
    border-radius: 50%;
    position: absolute;
    top: 23px;
    left: 12px;
  }

  &:last-child {
    border-bottom: none;
  }
`;

export const Name = styled.div`
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Description = styled(Text)`
  font-size: 12px;
  line-height: 20px;
  width: 215px;
  height: 46px;
`;

export const Amount = styled.div`
  .ui-typography {
    font-weight: 600;
  }
`;

export const Date = styled.div`
  color: ${({ theme }) => theme.colors.text400};
  font-size: 12px;
`;

export const Missing = styled.div`
  color: ${({ theme }) => theme.colors.textDisabled};
  font-size: 12px;
`;

export const Status = styled.div<{ paid: boolean }>`
  color: ${({ theme, paid }) =>
    paid ? theme.colors.success : theme.colors.text400};
  font-size: 12px;
  font-weight: ${({ paid }) => (paid ? 600 : 400)};
`;
