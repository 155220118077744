import styled from '@emotion/styled';
import { Typography } from 'antd';

import { Loader as CustomLoader } from '@components';

const { Text } = Typography;

export const TableContainer = styled.div`
  .ui-table-row {
    cursor: pointer;
  }
`;

export const Amount = styled(Text)`
  font-weight: 600;
  white-space: nowrap;
`;

export const Loader = styled(CustomLoader)`
  margin-top: 150px;
`;

export const ExcludedTransaction = styled.span`
  color: ${({ theme }) => theme.colors.text200};
`;
