import { FC, ReactNode, useState } from 'react';

import { Flex } from 'antd';

import * as S from './styled';

type RadioControl = {
  value: string;
  title: string;
  content?: ReactNode;
  rightContent?: ReactNode;
};

type OnboardingRadioControlProps = {
  controls: RadioControl[];
  defaultValue?: RadioControl['value'];
  onSelected?: (value: string | null) => void;
};

const OnboardingRadioControl: FC<OnboardingRadioControlProps> = ({
  defaultValue = null,
  controls,
  onSelected,
}) => {
  const [selectedValue, setSelectedValue] = useState<string | null>(
    defaultValue
  );

  const handleSelect = (value: string | null) => {
    setSelectedValue(value);
    onSelected && onSelected(value);
  };

  return (
    <S.Container>
      {controls.map(({ value, content, rightContent, ...control }) => (
        <S.Control
          key={value}
          onClick={() => handleSelect(value)}
          selected={selectedValue === value}
          rightContent={!!rightContent}
        >
          <S.ControlIcon selected={selectedValue === value} />

          <Flex gap={16}>
            <Flex vertical>
              <S.Title>{control.title}</S.Title>

              {content && <S.Content>{content}</S.Content>}
            </Flex>
          </Flex>

          {rightContent && <S.RightContent>{rightContent}</S.RightContent>}
        </S.Control>
      ))}
    </S.Container>
  );
};

export default OnboardingRadioControl;
