import { Layout } from 'antd';
import { FC, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';

import { Header, Sidebar } from '@entities';
import { useAccount, useOnboarding } from '@hooks';
import { useGetUserData } from '@hooks-api';

import * as S from './styled';

const LayoutDefault: FC = () => {
  const { steps } = useOnboarding();
  const { isLogged, account, setAccount, company, userAccess, setUserAccess } =
    useAccount();
  const { user } = useGetUserData();
  const navigate = useNavigate();

  const { boot } = useIntercom();

  const { pathname } = useLocation();

  useEffect(() => {
    if (user && !account) {
      setAccount(user);
    }

    if (user) {
      setUserAccess(user.companies[0]?.userAccessRights);
    }

    if (account) {
      if (account.intercomHash) {
        boot({
          email: account.email,
          name: account.fullName,
          userId: account.id,
          userHash: account.intercomHash,
        });
      }
    }
  }, [user, account, setAccount, setUserAccess, boot]);

  useEffect(() => {
    if (isLogged) {
      if (company?.status === 'in_service') {
        navigate(userAccess?.transactions ? '/transactions' : '/documents');
      } else {
        if (pathname !== '/start') {
          navigate('/start');
        }
      }
    }
  }, [company?.status, isLogged, navigate, pathname, userAccess?.transactions]);

  if (!steps.COMPANY_NAME.active) {
    return (
      <S.Container withPadding>
        <Sidebar />

        <Layout>
          <Header />

          <S.Content>
            <Outlet />
          </S.Content>
        </Layout>
      </S.Container>
    );
  }

  return (
    <S.Container withPadding={!steps.COMPANY_NAME}>
      <Outlet />
    </S.Container>
  );
};

export default LayoutDefault;
