import styled from '@emotion/styled';
import { Col, Form, Alert } from 'antd';

export const ButtonAdd = styled.a`
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 600;

  .ui-icon {
    font-size: 16px;
  }
`;

export const ButtonRemove = styled.a`
  color: ${({ theme }) => theme.colors.secondary};
  display: flex;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
`;

export const FormItem = styled(Form.Item)`
  && label {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.textBlue400};
  }
`

export const IncomeCol = styled(Col)`
  background-color: ${({ theme }) => theme.colors.strokeDarkGrey};
  padding: 15px 20px;
  border-radius: 12px;
  margin-left: 12px;
  margin-bottom: 20px;
`

export const DeleteCol = styled(Col)`
  padding: 0px important!;
`

export const UploadAlert = styled(Alert)`
  margin-bottom: 12px;
`