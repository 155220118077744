import { Flex } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { BankDefaultLogotype } from '@assets';
import { Loader } from '@components';
import { useAccount, useTranslate } from '@hooks';
import { useFinalizeBankConnections, useGetBankConnections } from '@hooks-api';

import * as S from './styled';

const ConnectBank: FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { companyId } = useAccount();
  const { translate } = useTranslate();

  const [isErrorLogo, setIsErrorLogo] = useState(false);

  const ref = searchParams.get('ref');

  const { bankConnections } = useGetBankConnections({
    params: {
      companyId: companyId!,
      ref: ref!,
    },
  });

  const [finalizeBank] = useFinalizeBankConnections();

  useEffect(() => {
    if (bankConnections && ref) {
      finalizeBank(
        {
          parameter: {
            companyId: companyId!,
            ref: ref!,
          },
        },
        {
          onSuccess: () => {
            navigate('/accounts');
          },
        }
      );
    }
  }, [bankConnections, companyId, finalizeBank, navigate, ref]);

  if (!bankConnections) {
    return <Loader />;
  }

  return (
    <S.Container>
      <Flex align="center" gap={16} vertical>
        <S.Logo
          src={
            isErrorLogo
              ? BankDefaultLogotype
              : `./${bankConnections.bank.bic}.png`
          }
          alt={bankConnections.bank.name}
          onError={() => setIsErrorLogo(true)}
        />

        <S.Name>{bankConnections.bank.name}</S.Name>
      </Flex>

      <S.Details>{translate('accounts.accountConnected')}</S.Details>
    </S.Container>
  );
};

export default ConnectBank;
