import { memo } from 'react';

import { Schemas } from '@api-client/generated/types';
import { IconArrowRight } from '@assets';
import styled from '@emotion/styled';

import { CornerCard } from '../components/CornerCard/CornerCard';
import { TransactionCardDetails } from '../components/TransactionCardDetails/TransactionCardDetails';
import { actionsToProps, conditionsToProps } from '../utils';
import { RuleMetadata } from './RuleMetadata';

const Wrapper = styled.li<{ isArchived?: boolean }>`
  display: flex;
  padding: 20px 30px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.strokeDarkGrey};
  background-color: ${({ theme, isArchived }) =>
    isArchived ? theme.colors.bgWarning : theme.colors.bgGrey};

  &:not(:first-of-type) {
    margin-top: 16px;
  }

  & > :nth-last-of-type(2) {
    margin-right: 60px;
  }

  & > :last-child {
    margin-left: auto;
  }
`;

const Icon = styled(IconArrowRight)`
  width: 24px;
  min-width: 24px;
  margin: 0 24px;
`;

export const Rule = memo(
  ({
    item,
    archiveRule,
    restoreRule,
    projects = [],
    categories = [],
    contacts = [],
  }: {
    item: Schemas.Rule;
    archiveRule: (id: string) => void;
    restoreRule: (id: string) => void;
    projects?: Schemas.Project[];
    categories?: Schemas.Category[];
    contacts?: Schemas.Contact[];
  }) => {
    const Component =
      item.entityType === 'transaction' ? TransactionCardDetails : CornerCard;

    return (
      <Wrapper key={item.id} isArchived={!!item.archivedOn}>
        <Component
          type="condition"
          isArchived={!!item.archivedOn}
          {...conditionsToProps(item.conditions)}
        />
        <Icon />
        <Component
          type="action"
          isArchived={!!item.archivedOn}
          {...actionsToProps(item.actions, {
            projects,
            categories,
            contacts,
          })}
        />
        <RuleMetadata
          createdAt={item.createdAt}
          createdBy={item.author.fullName}
          archivedAt={item.archivedOn}
          archivedBy={item.archivedBy?.fullName}
          entityType={item.entityType}
          affectedDocumentsCount={item.affectedDocumentsCount}
          affectedTransactionsCount={item.affectedTransactionsCount}
          lastAppliedAt={item.lastAppliedAt}
          onArchive={archiveRule}
          onRestore={restoreRule}
          id={item.id}
        />
      </Wrapper>
    );
  }
);
