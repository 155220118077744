import { Params$TransactionController_getStatsByCategory } from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';

import { useApiClient, type QueryOptions } from '@hooks';

type ReportRow = Schemas.CategoryReportRow;
type Params = Params$TransactionController_getStatsByCategory;

type TransactionsStatsProps = {
  params: Params['parameter'];
  config?: QueryOptions<ReportRow[]>;
};

const useGetTransactionsStatsByCategory = ({
  params,
  config,
}: TransactionsStatsProps) => {
  const {
    data: statsCategory,
    loading,
    refetch,
  } = useApiClient<Params, ReportRow[]>({
    method: 'TransactionController_getStatsByCategory',
    params: {
      parameter: params,
    },
    config,
  });

  return {
    statsCategory: (statsCategory || []) as ReportRow[],
    loading,
    refetch,
  };
};

export default useGetTransactionsStatsByCategory;
