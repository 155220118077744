import { useMemo } from 'react';

import { Schemas } from '@api-client/generated/types';
import { QueryOptions, useApiClient } from '@hooks';

type User = Schemas.User;

const useGetUserData = (config?: QueryOptions<User>) => {
  const {
    data: user,
    loading,
    refetch,
  } = useApiClient<unknown, User>({
    method: 'UserController_getCurrentUser',
    config,
  });

  return useMemo(
    () => ({
      user,
      loading,
      refetch,
    }),
    [refetch, user, loading]
  );
};

export default useGetUserData;
