import { useEffect } from 'react';

type Props = {
  scaleUp: () => void;
  scaleDown: () => void;
  scaleReset: () => void;
};

type ZoomActions = 'zoom-in' | 'zoom-out' | 'zoom-reset';

const useElectronAPI = ({ scaleUp, scaleDown, scaleReset }: Props) => {
  useEffect(() => {
    const handleZoom = (_event: any, action: ZoomActions) => {
      switch (action) {
        case 'zoom-in':
          scaleUp();
          break;
        case 'zoom-out':
          scaleDown();
          break;
        case 'zoom-reset':
          scaleReset();
          break;
      }
    };

    const electronAPI = (window as any).electronAPI;

    if (electronAPI) {
      electronAPI.onMenuAction(handleZoom);
    }

    return () => {
      if (electronAPI) {
        electronAPI.removeMenuActionListener(handleZoom);
      }
    };
  }, [scaleUp, scaleDown, scaleReset]);
};

export default useElectronAPI
