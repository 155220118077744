import styled from '@emotion/styled';

import { type FlexProps, type ButtonProps, Flex, Button } from 'antd';

export const Container = styled(Flex)<FlexProps>`
  background-color: ${({ theme }) => theme.colors.bgDarkGrey};
  border-radius: 10px;
  padding: 10px 20px;
`;

export const Label = styled.div`
  font-size: 12px;
`;

export const ButtonDelete = styled(Button)<ButtonProps>`
  && {
    height: auto;
    font-size: 12px;
    padding: 0;
  }
`;
