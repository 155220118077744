import { Schemas } from '@api-client/generated/types';
import { Flex, Typography } from 'antd';
import { FC, useState } from 'react';
import { useDrag } from 'react-dnd';
import * as S from './styled';
import { HolderOutlined } from '@ant-design/icons';
import { useTranslate } from '@hooks';

type MainCategoryBlockListItemProps = {
  item: Schemas.CategoryGroup;
  onEditGroup?: (value: string, item: Schemas.CategoryGroup) => void;
  onRemoveGroup?: (id: string) => void;
  onAddSubItem: () => void;
};

const MainCategoryBlockListItem: FC<MainCategoryBlockListItemProps> = ({
  item,
  onEditGroup,
  onRemoveGroup,
  onAddSubItem,
}) => {
  const { translate } = useTranslate();

  const [{ isDragging }, dragRef] = useDrag({
    type: `${item.group}`,
    item: item,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [isHovered, setIsHovered] = useState(false);

  return (
    <>
      {!item.name ? (
        <S.EmptyContainer>
          <S.EmptyText isempty={true}>
            {translate('categoriesPage.empty')}
          </S.EmptyText>
        </S.EmptyContainer>
      ) : (
        <S.Wrapper ref={dragRef} isdragging={isDragging}>
          <S.Container
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <S.TitleWrapper>
              <S.DotsWrapper>{isHovered && <HolderOutlined />}</S.DotsWrapper>
              <Typography>{item.name}</Typography>
            </S.TitleWrapper>

            <Flex>
              {isHovered && (
                <S.SubItemActionsContainer>
                  <S.PlusIcon onClick={onAddSubItem} />
                  <S.EditIcon onClick={() => onEditGroup?.(item.name, item)} />
                  <S.DeleteIcon onClick={() => onRemoveGroup?.(item.id)} />
                </S.SubItemActionsContainer>
              )}
            </Flex>
          </S.Container>
        </S.Wrapper>
      )}
    </>
  );
};

export default MainCategoryBlockListItem;
