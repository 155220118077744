import { IconChevronRight } from '@assets';
import { OnboardingPayment, PageMeta } from '@entities';
import { useTranslate } from '@hooks';
import { Breadcrumb, Flex, Typography } from 'antd';
import { FC } from 'react';
import { Link } from 'react-router-dom';

const { Title } = Typography;

const SettingsBilling: FC = () => {
  const { translate } = useTranslate();

  return (
    <Flex gap={32} vertical>
      <PageMeta title={translate('settings.billing.title')} />

      <Breadcrumb
        separator={<IconChevronRight />}
        items={[
          {
            title: <Link to="/settings">{translate('settings.title')}</Link>,
          },
          {
            title: translate('settings.billing.title'),
          },
        ]}
      />

      <Title>{translate('settings.billing.title')}</Title>
      <OnboardingPayment />
    </Flex>
  );
};

export default SettingsBilling;
