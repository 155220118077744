import { FC } from 'react';

import { FileViewer } from '@entities';

import { useTranslate } from '@hooks';

import { IconEyeOff } from '@assets';

import { Schemas } from '@api-client/generated/types';

import * as S from './styled';

type Document = Schemas.Document;
type TaskFile = Schemas.TaskFile;
type CompanyFile = Schemas.CompanyFile;

type FilePreviewProps = {
  file: Document | TaskFile | CompanyFile;
};

const FilePreview: FC<FilePreviewProps> = ({ file }) => {
  const { translate } = useTranslate();

  const isTypeImage = file?.mimeType.includes('image');
  const isTypePDF = file?.mimeType.includes('pdf');

  if (!file) {
    return (
      <S.Preview>
        <S.PreviewContent />
      </S.Preview>
    );
  }

  return (
    <S.Preview>
      <S.PreviewContent>
        {isTypePDF && (
          <FileViewer selectedFile={file} width="437px" height="622px" />
        )}

        {isTypeImage && <img src={file?.url} alt={file?.fileName} />}

        {!isTypePDF && !isTypeImage && (
          <S.Empty>
            <S.EmptyIcon>
              <IconEyeOff width={32} height={32} />
            </S.EmptyIcon>

            <S.EmptyTitle>{translate('common.preview.title')}</S.EmptyTitle>

            <S.EmptyDescription>
              {translate('common.preview.description')}
            </S.EmptyDescription>
          </S.Empty>
        )}
      </S.PreviewContent>
    </S.Preview>
  );
};

export default FilePreview;
