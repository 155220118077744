import { PropsWithChildren, forwardRef } from 'react';

import * as S from './styled';

export type ScrollbarProps = {
  height?: number | string;
  maxHeight?: number;
  withoutPadding?: boolean;
  className?: string;
};

const Scrollbar = forwardRef<HTMLDivElement, PropsWithChildren<ScrollbarProps>>(
  ({ height, maxHeight, children, withoutPadding, className }, ref) => (
    <S.Container
      className={className}
      ref={ref}
      height={height}
      maxHeight={maxHeight}
      withoutPadding={withoutPadding}
    >
      {children}
    </S.Container>
  )
);

export default Scrollbar;
