import { Schemas } from '@api-client/generated/types';
import { useAccount, useOnboarding, useTranslate } from '@hooks';
import { Flex, Form, Input, Typography, message } from 'antd';
import { FC, PropsWithChildren } from 'react';
import { KYCFiles } from '@entities';
import { useUpdateIncorporation } from '@hooks-api';
import { Select } from '@components';

type KYCCvProps = {
  person: Schemas.Person | Schemas.UBO;
  selectedType: string | null;
  selectedStepValue: string;
};

const { Paragraph, Title } = Typography;

const KYCCv: FC<PropsWithChildren<KYCCvProps>> = ({
  person,
  selectedType,
  selectedStepValue,
}) => {
  const { translate } = useTranslate();
  const { companyId } = useAccount();

  const { incorporationDetails, updateIncorporation } = useOnboarding();

  const [form] = Form.useForm();

  const [update] = useUpdateIncorporation();

  const onFinish = () => {
    const currentKycData = person.kycData || {};
    person.kycData = {
      ...currentKycData,
      ...form.getFieldsValue(),
    };

    update(
      {
        parameter: {
          companyId: companyId!,
        },
        requestBody: {
          people: incorporationDetails!.people,
        },
      },
      {
        onSuccess: () => {
          message.success(translate('onboarding.saved'));
        },
      }
    );

    updateIncorporation({ people: incorporationDetails!.people });
  };

  return (
    <Flex gap={20} vertical>
      <div>
        <Title level={5}>{translate('onboarding.kyc.natural.cv')}</Title>

        <Paragraph type="secondary">
          {translate('onboarding.kyc.naturalDetails.cv')}
        </Paragraph>
      </div>

      <Form
        form={form}
        layout="vertical"
        colon={false}
        initialValues={person.kycData}
      >
        <Form.Item
          name="pep"
          label={translate('onboarding.kyc.naturalKycForm.pep.label')}
        >
          <Select
            placeholder={translate(
              'onboarding.kyc.naturalKycForm.pep.placeholder'
            )}
            options={[
              {
                label: translate(
                  'onboarding.kyc.naturalKycForm.pep.values.yes'
                ),
                value: 'yes',
              },
              {
                label: translate('onboarding.kyc.naturalKycForm.pep.values.no'),
                value: 'no',
              },
            ]}
            size="large"
            onChange={onFinish}
            virtual
          />
        </Form.Item>

        <Form.Item
          name="pepInfo"
          label={translate('onboarding.kyc.naturalKycForm.pepInfo.label')}
        >
          <Input
            size="large"
            onBlur={onFinish}
            disabled={person.kycData?.pep !== 'yes'}
          />
        </Form.Item>

        <Form.Item
          name="blacklist"
          label={translate('onboarding.kyc.naturalKycForm.blacklist.label')}
        >
          <Select
            placeholder={translate(
              'onboarding.kyc.naturalKycForm.blacklist.placeholder'
            )}
            options={[
              {
                label: translate(
                  'onboarding.kyc.naturalKycForm.blacklist.values.yes'
                ),
                value: 'yes',
              },
              {
                label: translate(
                  'onboarding.kyc.naturalKycForm.blacklist.values.no'
                ),
                value: 'no',
              },
            ]}
            size="large"
            onChange={onFinish}
            virtual
          />
        </Form.Item>

        <Form.Item
          name="blacklistInfo"
          label={translate('onboarding.kyc.naturalKycForm.blacklistInfo.label')}
        >
          <Input
            size="large"
            onBlur={onFinish}
            disabled={person.kycData?.blacklist !== 'yes'}
          />
        </Form.Item>

        <Form.Item
          name="personalEmail"
          label={translate('onboarding.kyc.naturalKycForm.personalEmail.label')}
        >
          <Input size="large" onBlur={onFinish} />
        </Form.Item>

        <Form.Item
          name="personalPhone"
          label={translate('onboarding.kyc.naturalKycForm.personalPhone.label')}
        >
          <Input size="large" onBlur={onFinish} />
        </Form.Item>

        <Form.Item
          name="activitySector"
          label={translate(
            'onboarding.kyc.naturalKycForm.activitySector.label'
          )}
        >
          <Input size="large" onBlur={onFinish} />
        </Form.Item>
      </Form>

      <KYCFiles
        person={person}
        selectedType={selectedType}
        selectedStepValue={selectedStepValue}
      />
    </Flex>
  );
};

export default KYCCv;
