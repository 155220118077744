import styled from '@emotion/styled';
import { Checkbox, Menu as MenuContainer } from 'antd';

export const Item = styled(Checkbox)`
  flex-direction: row-reverse;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: ${({ theme }) => theme.colors.text400};
`;

export const Menu = styled(MenuContainer)`
  && {
    .ui-menu-item {
      width: 100%;
      color: ${({ theme }) => theme.colors.text400};
      display: flex;
      align-items: center;
      &:hover {
        background-color: ${({ theme }) => theme.colors.bgGrey};
        border-radius: 6px;
      }
    }
    .ui-menu-title-content {
      width: 100%;
    }
    .ui-menu-item-selected {
      background-color: transparent;
      border-radius: 6px;
    }
  }
`;
