import styled from '@emotion/styled';
import { Link, type LinkProps } from 'react-router-dom';

export const Wrapper = styled.div`
  height: 180px;
  border: 1px solid ${({ theme }) => theme.colors.strokeGrey};
  border-radius: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  position: relative;

  &:hover {
    border-color: ${({ theme }) => theme.colors.primary};

    .ui-btn {
      opacity: 1;
      pointer-events: auto;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Name = styled(Link)<LinkProps>`
  width: 310px;
  color: ${({ theme }) => theme.colors.black};
  font-size: 20px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    color: ${({ theme }) => theme.colors.black};
    text-decoration: underline;
  }
`;

export const Count = styled.div`
  color: ${({ theme }) => theme.colors.text400};
`;

export const Remove = styled.div`
  position: absolute;
  top: 18px;
  right: 18px;
`;
