import styled from '@emotion/styled';
import { Layout, LayoutProps } from 'antd';
import { config } from '@theme';
import { CustomThemeConfig } from '@styles/GlobalStyles';



export const Sidebar = styled(Layout.Sider)<LayoutProps>`
  &.ui-layout-sider {
    height: 100vh;
    background-color: ${config?.components?.Layout?.siderBg};
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;

    && ul {
      &&.ui-menu-item, .ui-menu-item-selected {
        background-color: ${(config as CustomThemeConfig)?.components?.Layout?.siderActiveTab};
      }

      &&.ui-menu-item, .ui-menu-item-disabled {
        color: ${(config as CustomThemeConfig)?.components?.Layout?.siderDisabledTabColor};
      }

      &&.ui-menu-item, .ui-menu-item-active {
        background-color: ${(config as CustomThemeConfig)?.components?.Layout?.siderActiveTab};
      }
    }
  }

  .ui-layout-sider-children {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;