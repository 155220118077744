import { FC, useState } from 'react';

import { Flex } from 'antd';

import {
  Logotype,
  OnboardingSteps,
  OnboardingCard,
  OnboardingOverview,
  OnboardingCompanyName,
  OnboardingDescribe,
  OnboardingCreateAccount,
  OnboardingWelcome,
  OnboardingWelcomeLanguage,
  OnboardingVerifyAccount,
  OnboardingCompanyType,
  OnboardingShareholders,
  OnboardingMessageCompletedCompany,
  OnboardingMessageProcessOnline,
  OnboardingMessageProcessDocuments,
  OnboardingMessageErrorDocuments,
  OnboardingMessageCompletedDocuments,
  OnboardingMessageProcessVerifyDocuments,
  OnboardingMessageAccountOpened,
  OnboardingMessageProcessPaymentSent,
  OnboardingMessageProcessPaymentRcs,
  OnboardingAddManagers,
  OnboardingTransferShare,
  OnboardingShareholdersInvite,
  OnboardingLegalAddress,
  OnboardingReviewServices,
  OnboardingPayment,
  OnboardingPaymentDetails,
  OnboardingProvideAddress,
  OnboardingUploadDocuments,
  OnboardingAbout,
  OnboardingDeclarationOfHonor,
  OnboardingMessageSarlSRcs,
  OnboardingCompanyTypeAccounting,
  OnboardingMessageCompletedAccounting,
} from '@entities';
import { Loader } from '@components';

import { useTranslate, useOnboarding, useAccount } from '@hooks';
import { useGetIncorporation } from '@hooks-api';

import * as S from './styled';

const Onboarding: FC = () => {
  // for test purposes, will be removed once sentry is set
  if ((window as any).throwError) {
    ('foo' as any as () => void)();
  }

  const { translate, tDynamic } = useTranslate();

  const { companyId } = useAccount();

  const {
    incorporationDetails,
    steps,
    screens,
    currentScreenName,
    initialIncorporation,
    isIncorporation,
  } = useOnboarding();

  const isVisibleSteps = screens[currentScreenName]?.visibleSteps;

  const [isNewIncorporation, setIsNewIncorporation] = useState(false);

  const { loading } = useGetIncorporation({
    params: {
      companyId: companyId!,
    },
    config: {
      onSuccess: (response) => {
        initialIncorporation(response);
        setIsNewIncorporation(false);
      },
      onError: () => {
        setIsNewIncorporation(true);
      },
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !isNewIncorporation,
    },
  });

  if (loading) {
    return <Loader />;
  }

  return (
    <S.Container>
      {steps.COMPANY_NAME.active && (
        <S.Header>
          <S.HeaderLeft>
            <Logotype />
          </S.HeaderLeft>
        </S.Header>
      )}

      <S.Inner withPadding={steps.COMPANY_NAME.active}>
        {steps.COMPANY_NAME.active && (
          <S.Content
            width={screens.VERIFY_ACCOUNT.active ? 645 : 791}
            centering
          >
            <OnboardingCard isAutoHeight={screens.VERIFY_ACCOUNT.active}>
              {isVisibleSteps && (
                <OnboardingSteps
                  title={`1. ${tDynamic(`onboarding.steps.companyName.name_${incorporationDetails?.incorporationType}`)}`}
                  name={tDynamic(
                    `onboarding.steps.companyName.${currentScreenName}`
                  )}
                  divisionsCount={3}
                />
              )}

              {screens.WELCOME_LANGUAGE.active && <OnboardingWelcomeLanguage />}

              {screens.WELCOME.active && <OnboardingWelcome />}

              {screens.INCORPORATION.active && <OnboardingOverview />}

              {screens.COMPANY_NAME.active && <OnboardingCompanyName />}

              {screens.DESCRIBE.active && <OnboardingDescribe />}

              {screens.CREATE_ACCOUNT.active && <OnboardingCreateAccount />}

              {screens.VERIFY_ACCOUNT.active && <OnboardingVerifyAccount />}

              {screens.MESSAGE_COMPLETED_COMPANY.active && (
                <OnboardingMessageCompletedCompany />
              )}
            </OnboardingCard>
          </S.Content>
        )}

        {steps.SHAREHOLDERS.active && (
          <Flex align="flex-start" gap={40}>
            <S.Content width={823}>
              <OnboardingCard>
                {isVisibleSteps && (
                  <OnboardingSteps
                    title={`2. ${tDynamic(`onboarding.steps.shareholders.name_${incorporationDetails?.incorporationType}`)}`}
                    name={tDynamic(
                      `onboarding.steps.shareholders.${currentScreenName}`
                    )}
                    divisionsCount={3}
                  />
                )}

                {screens.INCORPORATION.active && <OnboardingOverview />}

                {screens.COMPANY_TYPE.active && <OnboardingCompanyType />}

                {screens.ACCOUNTING_PLAN.active && (
                  <OnboardingCompanyTypeAccounting />
                )}

                {screens.SHAREHOLDERS.active && <OnboardingShareholders />}

                {screens.MANAGERS.active && <OnboardingAddManagers />}

                {screens.MESSAGE_PROCESS_ONLINE_KYC.active && (
                  <OnboardingMessageProcessOnline />
                )}
              </OnboardingCard>
            </S.Content>

            {screens.INCORPORATION.active && isIncorporation() && (
              <OnboardingAbout />
            )}
          </Flex>
        )}

        {steps.ONLINE_KYC.active && (
          <Flex align="flex-start" gap={40}>
            <S.Content
              width={
                screens.UPLOAD_DOCUMENTS.active ||
                screens.REVIEW_DOCUMENTS.active
                  ? '100%'
                  : 823
              }
            >
              <OnboardingCard>
                {isVisibleSteps && (
                  <OnboardingSteps
                    title={`3. ${translate('onboarding.steps.onlineKyc.name')}`}
                    name={tDynamic(
                      `onboarding.steps.onlineKyc.${currentScreenName}`
                    )}
                    divisionsCount={4}
                  />
                )}

                {screens.INCORPORATION.active && <OnboardingOverview />}

                {(screens.UPLOAD_DOCUMENTS.active ||
                  screens.REVIEW_DOCUMENTS.active) && (
                  <OnboardingUploadDocuments />
                )}

                {screens.LEGAL_ADDRESS.active && <OnboardingLegalAddress />}

                {screens.PROVIDE_ADDRESS.active && <OnboardingProvideAddress />}

                {screens.MESSAGE_PROCESS_DOCUMENTS.active && (
                  <OnboardingMessageProcessDocuments />
                )}

                {screens.MESSAGE_ERROR_DOCUMENTS.active && (
                  <OnboardingMessageErrorDocuments />
                )}

                {screens.MESSAGE_COMPLETED_DOCUMENTS.active && (
                  <OnboardingMessageCompletedDocuments />
                )}
              </OnboardingCard>
            </S.Content>

            {screens.INCORPORATION.active && isIncorporation() && (
              <OnboardingAbout />
            )}
          </Flex>
        )}

        {steps.VERIFY_DOCUMENTS.active && (
          <Flex align="flex-start" gap={40}>
            <S.Content width={823}>
              <OnboardingCard>
                {isVisibleSteps && (
                  <OnboardingSteps
                    title={`4. ${translate('onboarding.steps.verifyDocuments.name')}`}
                    name={tDynamic(
                      `onboarding.steps.verifyDocuments.${currentScreenName}`
                    )}
                    divisionsCount={2}
                  />
                )}

                {screens.INCORPORATION.active && <OnboardingOverview />}

                {screens.INVITE_SHAREHOLDERS_MANAGERS.active && (
                  <OnboardingShareholdersInvite />
                )}

                {screens.REVIEW_SERVICES.active && <OnboardingReviewServices />}

                {screens.MESSAGE_PROCESS_VERIFY_DOCUMENTS.active && (
                  <OnboardingMessageProcessVerifyDocuments />
                )}
              </OnboardingCard>
            </S.Content>

            {screens.INCORPORATION.active && isIncorporation() && (
              <OnboardingAbout />
            )}
          </Flex>
        )}

        {steps.BANK_TRANSFERRING.active && (
          <Flex align="flex-start" gap={40}>
            <S.Content width={823}>
              <OnboardingCard>
                {isVisibleSteps && (
                  <OnboardingSteps
                    title={`5. ${translate('onboarding.steps.bankTransferring.name')}`}
                    name={tDynamic(
                      `onboarding.steps.bankTransferring.${currentScreenName}`
                    )}
                    divisionsCount={2}
                  />
                )}

                {screens.COMPLETED_ACCOUNTING.active && (
                  <OnboardingMessageCompletedAccounting />
                )}
                {screens.INCORPORATION.active && <OnboardingOverview />}

                {screens.PAYMENT.active && <OnboardingPayment />}

                {screens.PAYMENT_DETAILS.active && <OnboardingPaymentDetails />}

                {screens.MESSAGE_PROCESS_ACCOUNT_OPENED.active && (
                  <OnboardingMessageAccountOpened />
                )}

                {screens.MESSAGE_PROCESS_PAYMENT_SENT.active && (
                  <OnboardingMessageProcessPaymentSent />
                )}

                {screens.TRANSFER_SHARE.active && <OnboardingTransferShare />}

                {screens.MESSAGE_PROCESS_PAYMENT_TO_RCS.active && (
                  <OnboardingMessageProcessPaymentRcs />
                )}

                {screens.HONOR_DECLARATION.active && (
                  <OnboardingDeclarationOfHonor />
                )}

                {screens.MESSAGE_PROCESS_SARL_S_RCS.active && (
                  <OnboardingMessageSarlSRcs />
                )}
              </OnboardingCard>
            </S.Content>

            {screens.INCORPORATION.active && <OnboardingAbout />}
          </Flex>
        )}

        {steps.REGISTRATION_IN_RCS.active && (
          <Flex align="flex-start" gap={40}>
            <S.Content width={823}>
              <OnboardingCard>
                {screens.INCORPORATION.active && <OnboardingOverview />}
              </OnboardingCard>
            </S.Content>

            {screens.INCORPORATION.active && <OnboardingAbout />}
          </Flex>
        )}

        {steps.INCORPORATED.active && (
          <Flex align="flex-start" gap={40}>
            <S.Content width={823}>
              <OnboardingCard>
                {screens.INCORPORATION.active && <OnboardingOverview />}
              </OnboardingCard>
            </S.Content>

            {screens.INCORPORATION.active && <OnboardingAbout />}
          </Flex>
        )}
      </S.Inner>
    </S.Container>
  );
};

export default Onboarding;
