import { rgba } from 'emotion-rgba';
import { FC } from 'react';

import { Global, css } from '@emotion/react';
import { mediaHeight } from '@styles/mediaWidth';
import { colors, config, layout } from '@theme';

import './theme.css';

export type CustomThemeConfig = typeof config & {
  components: {
    Layout: {
      siderActiveTab?: string;
      siderDisabledTabColor?: string;
    };
  };
};

export const GlobalStyles: FC = () => (
  <Global
    styles={css`
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      html,
      body,
      div,
      span,
      applet,
      object,
      iframe,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      blockquote,
      pre,
      a,
      abbr,
      acronym,
      address,
      big,
      cite,
      code,
      del,
      dfn,
      em,
      img,
      ins,
      kbd,
      q,
      s,
      samp,
      small,
      strike,
      strong,
      sub,
      sup,
      tt,
      var,
      b,
      u,
      i,
      center,
      dl,
      dt,
      dd,
      ol,
      ul,
      li,
      fieldset,
      form,
      label,
      legend,
      table,
      caption,
      tbody,
      tfoot,
      thead,
      tr,
      th,
      td,
      article,
      aside,
      canvas,
      details,
      embed,
      figure,
      figcaption,
      footer,
      header,
      hgroup,
      menu,
      nav,
      output,
      ruby,
      section,
      summary,
      time,
      mark,
      audio,
      video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
      }

      /* HTML5 display-role reset for older browsers */

      article,
      aside,
      details,
      figcaption,
      figure,
      footer,
      header,
      hgroup,
      menu,
      nav,
      section {
        display: block;
      }

      body {
        line-height: 1;
      }

      ol,
      ul {
        list-style: none;
      }

      blockquote,
      q {
        quotes: none;
      }

      blockquote:before,
      blockquote:after,
      q:before,
      q:after {
        content: '';
        content: none;
      }

      table {
        border-collapse: collapse;
        border-spacing: 0;
      }

      #root {
        min-height: 100vh;
      }

      .hiddenCanvasElement {
        display: none;
      }

      .ui-typography {
        &.ui-typography-secondary {
          color: ${colors.text400};
        }
      }

      .ui-table-wrapper {
        width: 100%;

        &.table-transparent {
          .ui-table {
            background: transparent;

            .ui-table-thead,
            .ui-table-tbody {
              .ui-table-cell {
                background: transparent;
              }
            }
          }
        }

        .ui-table {
          thead {
            tr {
              .column_not_selected {
                background: ${colors.bgLightYellow};
              }

              .cell-error {
                background: ${colors.bgWarning};
              }
            }
          }

          tbody {
            tr {
              .cell-error {
                background: ${colors.bgWarning};
              }
            }
          }

          tr,
          .ui-table-row {
            height: 54px;

            &.highlight-row {
              background-color: ${colors.bgLightBlue};
            }

            .ui-table-cell {
              padding-block: 0;
            }
          }
        }
      }

      .ui-btn {
        &.ui-btn-default {
          color: ${colors.primary};
          border-color: ${colors.primary};
          border-width: 2px;
        }

        &.ui-btn-text {
          &.ui-btn-icon-only {
            &.ui-btn-sm {
              width: 28px;
              height: 28px;
              border-radius: 4px;

              &:hover {
                background-color: ${colors.bgLightBlue};
                color: ${colors.primary};
              }
            }
          }
        }

        &.ui-btn-secondary {
          background-color: ${colors.bgGrey};
          color: ${colors.text400};

          &:hover {
            background-color: ${colors.primary};
            color: ${colors.white};
          }
        }

        &.ui-btn-icon-only {
          border-radius: 12px;
        }
      }

      .ui-avatar {
        background-color: ${colors.bgBrightBlue};

        .ui-avatar-string {
          color: ${colors.black};
          font-size: 14px;
          font-weight: 500;
        }
      }

      .ui-tag {
        padding-inline: 10px;
        font-weight: 600;
      }

      .ui-input-password {
        .ui-input-suffix {
          .ui-input-password-icon {
            color: ${colors.black};
            font-size: 16px;
          }
        }
      }

      .ui-otp-input {
        width: 48px;
        height: 48px;
        font-size: 16px;
      }

      .ui-select {
        min-width: 88px;

        .ui-select-selection-item {
          font-weight: 500;
        }

        .ui-select-arrow {
          color: ${colors.iconDarkGrey};
        }

        &.ui-select-filled {
          .ui-select-selector {
            border-radius: 12px;
            color: ${colors.text400};
          }
        }
      }

      .ui-attach-select-dropdown {
        margin: -4px;

        .ui-select-item {
          border-radius: 0;
          border-bottom: 1px solid ${colors.strokeGrey};

          :last-child {
            border-bottom: none;
          }
        }
      }

      .ui-breadcrumb {
        ol {
          align-items: center;
        }

        .ui-breadcrumb-link {
          font-weight: 500;
        }

        .ui-breadcrumb-separator {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .ui-segmented {
        padding: 0;

        .ui-segmented-item {
          font-weight: 600;
          text-transform: uppercase;

          &.ui-segmented-item-selected {
            box-shadow: none;
          }
        }
      }

      .ui-dropdown {
        .ui-dropdown-menu {
          padding: 8px;

          .ui-dropdown-menu-item {
            font-weight: 500;
            padding: 8px;

            &:hover {
              background-color: ${colors.bgBrightBlue};
            }

            &.ui-dropdown-menu-item-selected {
              background-color: transparent;
              color: ${colors.black};
            }
          }
        }

        &.ui-dropdown-bordered {
          .ui-dropdown-menu {
            border: 1px solid ${colors.strokeGrey};
          }
        }
      }

      .ui-btn {
        display: inline-flex;
        align-items: center;
        justify-content: center;

        &.ui-btn-lg {
          .ui-btn-icon {
            svg {
              width: 20px;
              height: 20px;
            }
          }
        }

        &.ui-btn-icon-only {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &.ui-btn-primary {
          &:disabled,
          &.ui-btn-disabled {
            background-color: ${colors.buttonGrey};
            color: ${colors.textDisabled};
          }
        }
      }

      .ui-picker {
        width: 100%;

        .ui-picker-suffix {
          .ui-icon {
            color: ${colors.primary};
            font-size: 15px;
          }
        }

        &.ui-picker-large {
          height: 40px;
          padding-block: 9px;
        }
      }

      .ui-checkbox-wrapper {
        & > span {
          color: ${colors.text400};
        }

        .ui-checkbox {
          .ui-checkbox-input,
          .ui-checkbox-inner {
            width: 20px;
            height: 20px;

            &:after {
              top: 45%;
              inset-inline-start: 30%;
            }
          }
        }
      }

      .ui-menu,
      .ui-menu-submenu {
        ${mediaHeight.mobileL(css`
          font-size: 10px;

          svg {
            width: 15px;
            height: 15px;
          }
        `)};

        &.ui-menu-dark {
          &.ui-menu-root,
          .ui-menu-sub {
            background-color: transparent;
            border-radius: 0;

            .ui-menu-item,
            .ui-menu-submenu-title {
              width: 100%;
              height: 76px;
              line-height: 1.6;
              display: flex;
              border-radius: 0;
              color: ${colors.white};
              flex-direction: column;
              align-items: center;
              justify-content: center;
              gap: 4px;
              margin: 0;
              font-size: 12px;
              font-weight: 500;

              ${mediaHeight.mobileL(css`
                font-size: 10px;
                height: 52px;
              `)};

              .ui-menu-title-content {
                margin-inline-start: 0;
              }

              .ui-menu-submenu-arrow {
                display: none;
              }

              &.ui-menu-item-selected {
                background-color: ${(config as CustomThemeConfig)?.components
                  ?.Layout?.siderActiveTab};
                color: ${colors.bgYellow};
              }

              &.ui-menu-item-disabled {
                cursor: default;
                color: ${colors.textBlue400};
              }

              &:hover {
                background-color: ${colors.black};
              }
            }

            .ui-menu-submenu-title {
              padding-inline-end: 16px;
            }
          }

          .ui-menu-submenu-selected {
            .ui-menu-submenu-title {
              background-color: ${(config as CustomThemeConfig)?.components
                ?.Layout?.siderActiveTab} !important;
            }
          }
        }
      }

      &&.ui-menu-submenu-title {
        background-color: ${config?.components?.Layout?.siderBg};

        &&:hover {
          background-color: ${(config as CustomThemeConfig)?.components?.Layout
            ?.siderActiveTab} !important;
        }
      }

      .ui-menu-submenu {
        ${mediaHeight.mobileL(css`
          font-size: 10px;

          svg {
            width: 15px;
            height: 15px;
          }
        `)};

        background-color: ${config?.components?.Layout?.siderBg};

        &.ui-menu-dark {
          &.ui-menu-submenu-placement-rightTop,
          &.ui-menu-submenu-placement-rightBottom {
            padding-inline-start: 0;
          }

          .ui-menu-sub {
            min-width: ${`${layout.sidebarWidth}px`};
            background-color: ${config.components?.Layout?.siderBg};
            border-radius: 0 8px 8px 0;

            &&.ui-menu-item,
            .ui-menu-item-active {
              background-color: ${(config as CustomThemeConfig)?.components
                ?.Layout?.siderActiveTab} !important;
            }
          }
        }

        &:hover {
          background-color: ${(config as CustomThemeConfig)?.components?.Layout
            ?.siderActiveTab};
        }
      }

      .ui-modal {
        &.ui-modal-confirm {
          .ui-modal-confirm-btns {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: 24px;
          }
        }

        &.ui-modal-top {
          top: 20px;

          .ui-modal-header {
            height: 81px;
            margin-bottom: 0;
          }

          .ui-modal-body {
            padding: 0;
            margin-top: 0;
          }

          .ui-modal-content {
            border-radius: 20px;
            padding: 0;
          }
        }

        .ui-modal-header {
          margin-bottom: 20px;
        }

        .ui-modal-close {
          width: 24px;
          height: 24px;
          top: 30px;
          inset-inline-end: 30px;

          .ui-modal-close-x {
            font-size: 18px;
          }
        }

        .ui-modal-content {
          border-radius: 20px;
          padding: 30px;
        }
      }

      .ui-form {
        .ui-form-item-extra {
          margin-top: 4px;
        }

        .ui-form-item {
          .ui-input-number {
            width: 100%;
          }

          .ui-form-item-label {
            padding: 0 0 4px;
          }
        }
      }

      .ui-collapse {
        &.ui-collapse-ghost {
          .ui-collapse-header {
            padding: 16px 0;
            font-size: 16px;
            font-weight: 600;
          }

          .ui-collapse-expand-icon {
            .ui-collapse-arrow {
              font-size: 16px;

              svg {
                transform: rotate(90deg);
              }
            }
          }

          .ui-collapse-item {
            &.ui-collapse-item-active {
              .ui-collapse-arrow {
                svg {
                  transform: rotate(-90deg) !important;
                }
              }
            }
          }

          .ui-collapse-content {
            .ui-collapse-content-box {
              color: ${colors.text400};
              padding: 0;
            }
          }
        }
      }

      .ui-dropdown-darken {
        background-color: ${config?.components?.Layout?.headerBg};
        border: 1px solid ${colors.white};
        box-shadow: none;
        padding-top: 10px;

        .ui-select-item {
          color: ${colors.white};
          padding: 10px;

          &.ui-select-item-option-selected {
            background-color: ${rgba(colors.dark, 0.2)};
            color: ${colors.white};

            &:hover,
            &:focus {
              background-color: ${rgba(colors.dark, 0.2)};
              color: ${colors.white};
            }
          }
        }
      }

      .ui-popover-legal-actions {
        .ui-popover-title {
          margin-bottom: 0;
        }
      }

      .ui-popover-transactions-filter {
        background: ${colors.bgDarkBlue};
        border-radius: 8px;

        .ui-popover-inner {
          background: ${colors.bgDarkBlue};

          .ui-popover-title {
            width: 142px;
            font-size: 12px;
            font-weight: 500;
            background: ${colors.bgDarkBlue};
            color: ${colors.white};
            margin-bottom: 0;
            word-wrap: break-word;
          }
        }

        .ui-popover-arrow {
          :before {
            background: ${colors.bgDarkBlue};
          }

          :after {
            background: ${colors.bgDarkBlue};
          }
        }
      }

      .ui-alert {
        &.ui-alert-with-description {
          padding: 10px 20px;
        }

        .ui-alert-description {
          .ui-typography {
            font-size: 12px;
          }

          a {
            font-weight: 600;
          }
        }
      }

      .ui-switch {
        background: ${colors.strokeDarkGrey};

        &:not(.ui-switch-disabled) {
          &:hover {
            background: ${colors.strokeDarkGrey};
          }
        }
      }

      .ui-tabs {
        .ui-tabs-tab {
          &.ui-tabs-tab-active {
            font-weight: 500;
          }
        }
      }

      .ui-list {
        width: 100%;

        .ui-list-empty-text {
          color: ${colors.black};
        }
      }
    `}
  />
);
