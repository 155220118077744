import { Flex, Typography } from 'antd';

import styled from '@emotion/styled';

import { SubItemType } from '../../../pages/Categories/Categories';

export const EmptyContainer = styled(Flex)`
  padding: 6px;
  margin-left: 16px;
  cursor: default;
`;

export const EmptyText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isempty',
})<SubItemType>`
  color: ${({ theme, isempty }) =>
    isempty ? theme.colors.textDisabled : theme.colors.primary};
`;
