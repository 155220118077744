import { FC } from 'react';

import { PaymentDocumentImportInvoice, PaymentDocumentType } from '@entities';
import { useTranslate } from '@hooks';
import { colors } from '@theme';
import { IconUpload } from '@assets';

import { Schemas } from '@api-client/generated/types';

import * as S from './styled';

type PaymentDocumentDropFileOverlayProps = {
  type: PaymentDocumentType;
  onUpdate?: (file: Schemas.Document) => void;
};

const PaymentDocumentDropFileOverlay: FC<
  PaymentDocumentDropFileOverlayProps
> = ({ type, onUpdate }) => {
  const { translate } = useTranslate();

  return (
    <S.Container>
      <PaymentDocumentImportInvoice documentType={type} onUpdate={onUpdate}>
        <S.UploadContainer>
          <IconUpload color={colors.primary} />

          <S.Title>{translate('income.dropFile.title')}</S.Title>
        </S.UploadContainer>
      </PaymentDocumentImportInvoice>
    </S.Container>
  );
};

export default PaymentDocumentDropFileOverlay;
