import { Flex } from 'antd';

import styled from '@emotion/styled';

export const Wrapper = styled.div`
  width: 388px;
  height: 150px;
  position: relative;
`;

export const CornerCardAdditionalTitle = styled.span`
  font-size: 9px;
  font-weight: 600;
  line-height: 12px;
  color: ${({ theme }) => theme.colors.textDisabled};
  width: 85%;
  text-transform: uppercase;
`;

export const CornerCardTitle = styled.span`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.black};
  text-transform: capitalize;
  width: 85%;
  height: 20px;

  text-overflow: ellipsis;
  word-break: break-all;
  overflow: hidden;
`;

export const CornerCardDescription = styled.span`
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.black};
  width: 100%;
  height: 40px;
  max-height: 40px;
  flex: 1;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  word-break: break-all;
`;

export const Footer = styled(Flex)`
  width: 100%;
  margin-top: auto;
`;

export const CornerCardPrice = styled.span`
  font-weight: 600;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.black};
`;

export const CornerCardDate = styled.span`
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.text400};
`;
