import { Params$IncorporationController_claimSuccessfulPayment } from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';

import { useApiClientMutation } from '@hooks';

const useClaimSuccessfulPaymentIntent = () => {
  return useApiClientMutation<
    Params$IncorporationController_claimSuccessfulPayment,
    Schemas.Incorporation
  >({
    method: 'IncorporationController_claimSuccessfulPayment',
  });
};

export default useClaimSuccessfulPaymentIntent;
