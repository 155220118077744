import styled from '@emotion/styled';
import { Upload as UploadAntd } from 'antd';

export const Upload = styled(UploadAntd)`
  && {
    width: 100%;

    .ui-upload {
      width: 100%;
    }
  }
`;
