import { useMemo } from 'react';

import { Typography } from 'antd';
import styled from '@emotion/styled';

import countries from '@data/countries.json';

import { type DefaultOptionType } from 'antd/es/select';

const Option = styled.div`
  min-width: 100px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
`;

const Image = styled.img`
  width: 20px;
  height: 20px;
`;

const useCountries = () => {
  const filterOptionCountries = (input: string, option?: DefaultOptionType) => {
    if (typeof option?.name === 'string') {
      return (option?.name.toLowerCase() ?? '').includes(
        input.toLocaleLowerCase()
      );
    }
    return false;
  };

  return {
    countries: useMemo(
      () =>
        countries.map((country) => ({
          key: country.code,
          value: country.code,
          name: country.name,
          label: (
            <Option>
              <Image src={country.image} alt={country.name} />
              <Typography>{country.name}</Typography>
            </Option>
          ),
          image: country.image,
        })),
      []
    ),
    filterOptionCountries,
  };
};

export default useCountries;
