import styled from '@emotion/styled';
import { Button } from 'antd';

export const SelectContainer = styled.div`
  position: relative;
  
  #project {
    max-width: 55%;
  }
`

export const AddProjectButton = styled(Button)`
  height: 28px;
  width: 113px;
  position: absolute;
  right: 7px;
  bottom: 6px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  z-index: 5;
`