import { FC } from 'react';

import { PageMeta, PublicCardForm } from '@entities';
import { useTranslate } from '@hooks';

const ForgotPasswordMessage: FC = () => {
  const { translate } = useTranslate();

  return (
    <>
      <PageMeta title={translate('auth.forgotPassword.title')} />
      <PublicCardForm
        title={translate('auth.forgotPassword.title')}
        subTitle={translate('auth.forgotPassword.subTitleNext')}
      />
    </>
  );
};

export default ForgotPasswordMessage;
