import { FC, ReactNode } from 'react';

import { Modal, Button, type ModalProps, type ButtonProps } from 'antd';

import { useTranslate } from '@hooks';

import * as S from './styled';

type PopupConfirmProps = {
  title?: ReactNode;
  description?: ReactNode;
  confirmParams: { text: string } & ButtonProps;
  onConfirm: () => void;
  onCancel: () => void;
} & ModalProps;

const PopupConfirm: FC<PopupConfirmProps> = ({
  open,
  title,
  description,
  confirmParams,
  onConfirm,
  onCancel,
}) => {
  const { translate } = useTranslate();

  if (!open) {
    return null;
  }

  return (
    <Modal
      width={504}
      open={open}
      footer={null}
      onCancel={onCancel}
      closeIcon={null}
      destroyOnClose
      centered
    >
      {title && <S.Title level={3}>{title}</S.Title>}

      {description && <S.Description>{description}</S.Description>}

      <S.Actions>
        <Button type="primary" onClick={onConfirm} block {...confirmParams}>
          {confirmParams.text}
        </Button>

        <Button onClick={onCancel} block>
          {translate('common.actions.cancel')}
        </Button>
      </S.Actions>
    </Modal>
  );
};

export default PopupConfirm;
