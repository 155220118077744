import { Language } from '@constants';

import accountsTranslations from './en-GB/accounts.json';
import authTranslations from './en-GB/auth.json';
import bankAccountsPageTranslations from './en-GB/bankAccountPage.json';
import categoriesPageTranslations from './en-GB/categoriesPage.json';
import chatTranslations from './en-GB/chat.json';
import chatLiveTranslations from './en-GB/chatLive.json';
import commonTranslations from './en-GB/common.json';
import contactPageTranslations from './en-GB/contactPage.json';
import contactsPageTranslations from './en-GB/contactsPage.json';
import documentsPageTranslations from './en-GB/documentsPage.json';
import eventsPageTranslations from './en-GB/events.json';
import expensesTranslations from './en-GB/expenses.json';
import filterTranslations from './en-GB/filter.json';
import filterCalendarTranslations from './en-GB/filterCalendar.json';
import importTransactionsOnboardingPageTranslations from './en-GB/importTransactionsOnboardingPage.json';
import importTransactionsPageTranslations from './en-GB/importTransactionsPage.json';
import incomeTranslations from './en-GB/income.json';
import invoiceGeneratorTranslations from './en-GB/invoiceGenerator.json';
import invoicesPageTranslations from './en-GB/invoicesPage.json';
import legalPageTranslations from './en-GB/legal.json';
import onboardingTranslations from './en-GB/onboarding.json';
import projectsTranslations from './en-GB/projects.json';
import reportTranslations from './en-GB/report.json';
import rulesGeneratorTranslations from './en-GB/rules.json';
import settingsTranslations from './en-GB/settings.json';
import tasksTranslations from './en-GB/tasks.json';
import taxesTranslations from './en-GB/taxes.json';
import transactionTranslations from './en-GB/transaction.json';
import transactionsImportTranslations from './en-GB/transactionsImport.json';
import transactionsPageTranslations from './en-GB/transactionsPage.json';
import frAccountsTranslations from './fr-FR/accounts.json';
import frAuthTranslations from './fr-FR/auth.json';
import frBankAccountsPageTranslations from './fr-FR/bankAccountPage.json';
import frCategoriesPageTranslations from './fr-FR/categoriesPage.json';
import frChatTranslations from './fr-FR/chat.json';
import frCommonTranslations from './fr-FR/common.json';
import frContactPageTranslations from './fr-FR/contactPage.json';
import frContactsPageTranslations from './fr-FR/contactsPage.json';
import frDocumentsPageTranslations from './fr-FR/documentsPage.json';
import frEventsPageTranslations from './fr-FR/events.json';
import frFilterCalendarTranslations from './fr-FR/filterCalendar.json';
import frImportTransactionsOnboardingPageTranslations from './fr-FR/importTransactionsOnboardingPage.json';
import frImportTransactionsPageTranslations from './fr-FR/importTransactionsPage.json';
import frInvoicesPageTranslations from './fr-FR/invoicesPage.json';
import frOnboardingTranslations from './fr-FR/onboarding.json';
import frProjectsTranslations from './fr-FR/projects.json';
import frReportTranslations from './fr-FR/report.json';
import frSettingsTranslations from './fr-FR/settings.json';
import frTaxesPageTranslations from './fr-FR/taxesPage.json';
import frTransactionTranslations from './fr-FR/transaction.json';
import frTransactionsImportTranslations from './fr-FR/transactionsImport.json';
import frTransactionsPageTranslations from './fr-FR/transactionsPage.json';

const enLocale = {
  invoiceGenerator: invoiceGeneratorTranslations,
  filter: filterTranslations,
  tasks: tasksTranslations,
  chatLive: chatLiveTranslations,
  income: incomeTranslations,
  expenses: expensesTranslations,
  bankAccountPage: bankAccountsPageTranslations,
  categoriesPage: categoriesPageTranslations,
  common: commonTranslations,
  contactsPage: contactsPageTranslations,
  contactPage: contactPageTranslations,
  importTransactionsOnboardingPage:
    importTransactionsOnboardingPageTranslations,
  importTransactionsPage: importTransactionsPageTranslations,
  invoicesPage: invoicesPageTranslations,
  taxes: taxesTranslations,
  transactionsPage: transactionsPageTranslations,
  events: eventsPageTranslations,
  transaction: transactionTranslations,
  chat: chatTranslations,
  documentsPage: documentsPageTranslations,
  filterCalendar: filterCalendarTranslations,
  legal: legalPageTranslations,
  accounts: accountsTranslations,
  auth: authTranslations,
  report: reportTranslations,
  settings: settingsTranslations,
  onboarding: onboardingTranslations,
  transactionsImport: transactionsImportTranslations,
  projects: projectsTranslations,
  rules: rulesGeneratorTranslations,
};

const frLocale: Locales = {
  bankAccountPage: frBankAccountsPageTranslations,
  // @ts-expect-error-next-line
  categoriesPage: frCategoriesPageTranslations,
  // @ts-expect-error-next-line
  common: frCommonTranslations,
  // @ts-expect-error-next-line
  contactsPage: frContactsPageTranslations,
  contactPage: frContactPageTranslations,
  importTransactionsOnboardingPage:
    frImportTransactionsOnboardingPageTranslations,
  importTransactionsPage: frImportTransactionsPageTranslations,
  invoicesPage: frInvoicesPageTranslations,
  taxesPage: frTaxesPageTranslations,
  // @ts-expect-error-next-line
  transactionsPage: frTransactionsPageTranslations,
  events: frEventsPageTranslations,
  // @ts-expect-error-next-line
  transaction: frTransactionTranslations,
  // @ts-expect-error-next-line
  chat: frChatTranslations,
  // @ts-expect-error-next-line
  documentsPage: frDocumentsPageTranslations,
  filterCalendar: frFilterCalendarTranslations,
  // @ts-expect-error-next-line
  accounts: frAccountsTranslations,
  auth: frAuthTranslations,
  report: frReportTranslations,
  // @ts-expect-error-next-line
  settings: frSettingsTranslations,
  // @ts-expect-error-next-line
  onboarding: frOnboardingTranslations,
  // @ts-expect-error-next-line
  transactionsImport: frTransactionsImportTranslations,
  // @ts-expect-error-next-line
  projects: frProjectsTranslations,
};

export const translations: Record<Language, { translation: Locales }> = {
  [Language.En]: {
    translation: enLocale,
  },
  [Language.Fr]: {
    translation: frLocale,
  },
};

export type Locales = typeof enLocale;

type Join<K, P> = K extends string | number
  ? P extends string | number
    ? `${K}${'' extends P ? '' : '.'}${P}`
    : never
  : never;

type Paths<T> = T extends object
  ? {
      [K in keyof T]-?: K extends string | number
        ? Join<K, Paths<T[K]>>
        : never;
    }[keyof T]
  : '';

export type LocalePaths = Paths<Locales>;
