import { Params$BankController_getBanks } from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';

import { QueryOptions, useApiClient } from '@hooks';

type Props = {
  params: Params$BankController_getBanks['parameter'];
  config?: QueryOptions<Schemas.PaginatedBankResponse>;
};

const useGetBanksList = ({ params, config }: Props) =>
  useApiClient<Params$BankController_getBanks, Schemas.PaginatedBankResponse>({
    method: 'BankController_getBanks',
    params: {
      parameter: params,
    },
    config,
  });

export default useGetBanksList;
