import styled from '@emotion/styled';

export const DeafSelect = styled.div`
  cursor: pointer;

  .ui-select {
    pointer-events: none;

    .ui-select-arrow {
      transform: rotate(-90deg);
    }
  }
`;
