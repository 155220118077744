import { Flex } from 'antd';
import { IconCreditCardRounded, IconPlus } from '@assets';
import * as S from './styled';
import { useTranslate } from '@hooks';
import { useNavigate } from 'react-router-dom';

const EmptyTransactions = () => {
  const { translate } = useTranslate();
  const navigate = useNavigate();
  return (
    <S.Wrapper align="center" gap={24} vertical>
      <S.CreditCard>
        <IconCreditCardRounded />
      </S.CreditCard>
      <Flex align="center" gap={12} vertical>
        <S.Title>{translate('transaction.empty.title')}</S.Title>
        <S.Description>
          {translate('transaction.empty.description')}
        </S.Description>
      </Flex>
      <S.AddButton
        size="small"
        type="primary"
        onClick={() => navigate('/accounts')}
      >
        <IconPlus />
        {translate('transaction.empty.add')}
      </S.AddButton>
    </S.Wrapper>
  );
};

export default EmptyTransactions;
