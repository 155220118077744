import { FC } from 'react';
import { Trans } from 'react-i18next';

import { Typography, Button, Flex } from 'antd';

import { OnboardingMessage } from '@entities';
import { useTranslate, useOnboarding } from '@hooks';

import { MessageProcessPaymentOpened } from '@assets';

import * as S from './styled';

const { Paragraph, Text } = Typography;

const OnboardingMessageAccountPaymentOpened: FC = () => {
  const { translate } = useTranslate();
  const { updateScreen } = useOnboarding();

  const handleContinue = () => {
    updateScreen('TRANSFER_SHARE', { active: true });
  };

  return (
    <S.Container>
      <OnboardingMessage
        picture={<MessageProcessPaymentOpened />}
        title={translate('onboarding.messages.processPaymentOpened.title')}
        description={
          <Trans
            i18nKey={translate(
              'onboarding.messages.processPaymentOpened.description'
            )}
            components={[<Paragraph />, <Text strong />]}
          />
        }
      />

      <Flex justify="flex-end">
        <Button type="primary" onClick={handleContinue}>
          {translate('onboarding.buttonContinue')}
        </Button>
      </Flex>
    </S.Container>
  );
};

export default OnboardingMessageAccountPaymentOpened;
