import styled from '@emotion/styled';

import { Button, Flex, type FlexProps, Form } from 'antd';

export const Transaction = styled(Flex)<FlexProps & {isselected: string}>`
  border: 1px solid ${({ theme, isselected }) => isselected.length ? theme.colors.primary : theme.colors.strokeGrey};
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.white};
  margin-bottom: 8px;
  cursor: pointer;
`;

export const Header = styled.div`
  background: ${({theme}) => theme.colors.bgDarkGrey};
  padding: 16px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`

export const HeaderInner = styled(Flex)`
  margin-bottom: 32px;
`


export const Content = styled.div`
  width: 100%;
  padding: 16px;
`

export const ProjectsContainer = styled.div`
  margin-top: 8px;
`

export const Item = styled(Form.Item)`
  margin-bottom: 16px;

  &&.ui-col,
  .ui-form-item-label {
    padding-bottom: 4px;
  }
`;


export const Logo = styled.img`
  width: 32px;
  height: 32px;
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 4px;
  width: max-content;
  
  :hover {
    color: ${({theme}) => theme.colors.primary};
    opacity: 0.7;
    cursor: pointer;
  }
`;

export const DetachButton = styled(Button)`
  color: ${({theme}) => theme.colors.error};
  cursor: pointer;
  padding: 0 6px;
  font-size: 12px;
  height: 18px;
`

export const Description = styled.div`
  font-size: 12px;
`;

export const Amount = styled.div`
  && {
    .ui-typography {
      font-size: 16px;
    }
  }
`;

export const Date = styled.div`
  color: ${({ theme }) => theme.colors.text400};
  font-size: 12px;
`;
