import { FC } from 'react';

import { GoBack, PageMeta, ProjectContent } from '@entities';
import { useTranslate } from '@hooks';

const ProjectCreate: FC = () => {
  const { translate } = useTranslate();

  return (
    <>
      <PageMeta title={translate('projects.newProject')} />
      <ProjectContent
        type="create"
        project={null}
        headerContent={<GoBack name={translate('projects.breadcrumbs.parent')} />}
      />
    </>
  );
};

export default ProjectCreate;
