import { FC } from 'react';

import { useTranslate } from '@hooks';

import * as S from './styled';

type ContactVatIdMessageProps = {
  isNotValid: boolean;
  isError: boolean;
};

const getTranslateKeysByStatus = (isNotValid: boolean, isError: boolean) => {
  if (isNotValid) {
    return {
      title: 'contactsPage.newContact.fieldVatId.extraNotValid.title',
      description:
        'contactsPage.newContact.fieldVatId.extraNotValid.description',
    };
  }

  if (isError) {
    return {
      title: 'contactsPage.newContact.fieldVatId.extraError.title',
      description: 'contactsPage.newContact.fieldVatId.extraError.description',
    };
  }

  return {
    title: 'contactsPage.newContact.fieldVatId.extra.title',
    description: 'contactsPage.newContact.fieldVatId.extra.description',
  };
};

const ContactVatIdMessage: FC<ContactVatIdMessageProps> = ({
  isNotValid,
  isError,
}) => {
  const { tDynamic } = useTranslate();

  const keys = getTranslateKeysByStatus(isNotValid, isError);

  return (
    <S.Message gap={6} vertical>
      <S.MessageTitle>{tDynamic(keys.title)}</S.MessageTitle>
      <S.MessageDescription>{tDynamic(keys.description)}</S.MessageDescription>
    </S.Message>
  );
};

export default ContactVatIdMessage;
