import { Params$TransactionController_delete } from '@api-client/generated/client';

import { useApiClientMutation } from '@hooks';

const useRemoveTransactionById = () => {
  return useApiClientMutation<Params$TransactionController_delete>({
    method: 'TransactionController_delete',
  });
};

export default useRemoveTransactionById;
