import styled from '@emotion/styled';

export const Wrapper = styled.div`
  background-color: #e2e7ee;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 20px 25px;
`;

export const Label = styled.div`
  font-weight: 600;
`;
