import { Params$UserController_updateCurrentUser } from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';

import { useApiClientMutation } from '@hooks';

type Params = Params$UserController_updateCurrentUser;

const useUpdateUserData = () => {
  return useApiClientMutation<Params, Schemas.User>({
    method: 'UserController_updateCurrentUser',
  });
};

export default useUpdateUserData;
