import { Button, Col, Flex, Form, Input, Row, Typography } from 'antd';
import _ from 'lodash';
import { FC } from 'react';
import { Trans } from 'react-i18next';

import { PlusOutlined } from '@ant-design/icons';
import { Schemas } from '@api-client/generated/types';
import { IconTrash } from '@assets';
import { Select } from '@components';
import { OnboardingCardHeader } from '@entities';
import {
  useAccount,
  useFormValidateFields,
  useOnboarding,
  useTranslate,
} from '@hooks';
import { useUpdateIncorporation } from '@hooks-api';

import * as S from './styled';

const { Paragraph } = Typography;

const OnboardingShareholders: FC = () => {
  const { translate, tDynamic } = useTranslate();
  const { companyId } = useAccount();

  const { form, disabledForm, valuesForm } = useFormValidateFields<{
    people: Schemas.PersonDto[];
  }>();

  const {
    incorporationDetails,
    updateScreen,
    updateIncorporation,
    isIncorporation,
  } = useOnboarding();

  const [update, loading] = useUpdateIncorporation();

  const getSumPercentage = () =>
    valuesForm ? _.sum(valuesForm.people.map((item) => item.shares)) : 0;

  const shareHoldersOrEmpty = () =>
    incorporationDetails?.people?.filter((person) => person.isShareholder) || [
      { type: undefined, name: '', shares: undefined, people: [''] } as any,
    ];

  const handleSubmit = (values: Schemas.Incorporation) => {
    const filteredValues =
      values.people?.map((item) => {
        if (item.type === 'natural') {
          item.people = [];
          return item;
        }

        return item;
      }) || [];

    filteredValues.map((person) => (person.isShareholder = true));

    const nonShareholders =
      incorporationDetails?.people?.filter(
        (person) => !person.isShareholder && !person.isCompanyItself
      ) || [];

    const companyItself =
      incorporationDetails?.people?.filter(
        (person) => person.isCompanyItself
      ) || [];

    const people = companyItself.concat(filteredValues).concat(nonShareholders);

    update(
      {
        parameter: {
          companyId: companyId!,
        },
        requestBody: {
          people,
        },
      },
      {
        onSuccess: (response) => {
          updateIncorporation({ people: response.people });
          updateScreen('MANAGERS', { active: true });
        },
      }
    );
  };

  const isSelectedLegalPerson = (index: number) => {
    const people = form.getFieldValue('people');

    return people[index] && people[index].type === 'legal';
  };

  const currentPercentage = getSumPercentage();

  return (
    <S.Container>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        requiredMark={false}
        initialValues={{
          people: shareHoldersOrEmpty(),
        }}
      >
        <S.Inner>
          <OnboardingCardHeader
            title={tDynamic(
              `onboarding.shareholders.title_${incorporationDetails?.incorporationType}`
            )}
            description={
              <Row align="bottom" gutter={[20, 0]}>
                <Col span={15}>
                  <Paragraph>
                    <Trans
                      i18nKey={tDynamic(
                        `onboarding.shareholders.description_${incorporationDetails?.companyType || incorporationDetails?.incorporationType}`
                      )}
                      components={[<strong />, <Paragraph />]}
                    />
                  </Paragraph>
                </Col>

                <Col span={9}>
                  <S.ExtraText type="secondary">
                    <Trans
                      i18nKey={tDynamic('onboarding.shareholders.extraText')}
                      components={[<strong />]}
                    />
                  </S.ExtraText>
                </Col>
              </Row>
            }
          />

          <Form.List name="people">
            {(people, { add, remove }) => (
              <>
                <S.FormInner>
                  <Row gutter={16}>
                    <Col span={8}>
                      <S.FormLabel>
                        {tDynamic('onboarding.shareholders.type')}
                      </S.FormLabel>
                    </Col>

                    <Col span={10}>
                      <S.FormLabel>
                        {tDynamic('onboarding.shareholders.name')}
                      </S.FormLabel>
                    </Col>

                    <Col span={4}>
                      <S.FormLabel>
                        {tDynamic('onboarding.shareholders.shares')}
                      </S.FormLabel>
                    </Col>
                  </Row>

                  {people.map(({ name, key, ...person }, index) => (
                    <S.FormItem key={key}>
                      <Form.Item required={false} noStyle>
                        <Row gutter={16} align="middle">
                          <Col span={8}>
                            <Form.Item
                              {...person}
                              name={[name, 'type']}
                              validateTrigger={['onChange', 'onBlur']}
                              rules={[{ required: true }]}
                              initialValue={
                                (incorporationDetails?.companyType ===
                                  'sarl-s' &&
                                  'natural') ||
                                undefined
                              }
                              noStyle
                            >
                              <Select
                                options={[
                                  {
                                    label: tDynamic(
                                      'onboarding.shareholders.select.labelNatural'
                                    ),
                                    value: 'natural',
                                  },
                                  {
                                    label: tDynamic(
                                      'onboarding.shareholders.select.labelLegal'
                                    ),
                                    value: 'legal',
                                  },
                                ]}
                                placeholder={tDynamic(
                                  'onboarding.shareholders.select.placeholder'
                                )}
                                size="large"
                                disabled={
                                  incorporationDetails?.companyType === 'sarl-s'
                                }
                              />
                            </Form.Item>
                          </Col>

                          <Col span={10}>
                            <Form.Item
                              {...person}
                              name={[name, 'name']}
                              validateTrigger={['onChange', 'onBlur']}
                              rules={[{ required: true }]}
                              noStyle
                            >
                              <Input
                                placeholder={tDynamic(
                                  'onboarding.shareholders.namePlaceholder'
                                )}
                                size="large"
                              />
                            </Form.Item>
                          </Col>

                          <Col span={6}>
                            <Flex align="center" gap={16}>
                              <Form.Item
                                {...person}
                                name={[name, 'shares']}
                                validateTrigger={['onChange', 'onBlur']}
                                rules={[{ required: true }]}
                                noStyle
                              >
                                <S.NumberInput
                                  size="large"
                                  placeholder="%"
                                  suffix="%"
                                />
                              </Form.Item>

                              {people.length > 1 ? (
                                <S.ButtonRemove onClick={() => remove(name)}>
                                  <IconTrash />
                                </S.ButtonRemove>
                              ) : null}
                            </Flex>
                          </Col>
                        </Row>
                      </Form.Item>

                      {isSelectedLegalPerson(index) && (
                        <Form.List name={[name, 'people']}>
                          {(subPeople, { add, remove }) => (
                            <>
                              <S.FormSubInner>
                                {subPeople.map(
                                  ({ name, key, ...subPerson }) => (
                                    <Form.Item
                                      key={key}
                                      required={false}
                                      noStyle
                                    >
                                      <Row gutter={16} align="middle">
                                        <Col span={8}>
                                          <S.FormSubLabel>
                                            {tDynamic(
                                              'onboarding.shareholders.labelUBO'
                                            )}
                                          </S.FormSubLabel>
                                        </Col>

                                        <Col span={10}>
                                          <Form.Item
                                            {...subPerson}
                                            name={[name, 'name']}
                                            validateTrigger={[
                                              'onChange',
                                              'onBlur',
                                            ]}
                                            rules={[{ required: true }]}
                                            noStyle
                                          >
                                            <Input
                                              placeholder={tDynamic(
                                                'onboarding.shareholders.namePlaceholder'
                                              )}
                                              size="large"
                                            />
                                          </Form.Item>
                                        </Col>

                                        <Col span={6}>
                                          {subPeople.length > 1 ? (
                                            <S.ButtonRemove
                                              onClick={() => remove(name)}
                                            >
                                              <IconTrash />
                                            </S.ButtonRemove>
                                          ) : null}
                                        </Col>
                                      </Row>
                                    </Form.Item>
                                  )
                                )}
                              </S.FormSubInner>

                              <Row>
                                <Col offset={8}>
                                  <S.Action>
                                    <S.ButtonAdd onClick={() => add()}>
                                      <PlusOutlined />
                                      {tDynamic(
                                        'onboarding.shareholders.addUBO'
                                      )}
                                    </S.ButtonAdd>
                                  </S.Action>
                                </Col>
                              </Row>
                            </>
                          )}
                        </Form.List>
                      )}
                    </S.FormItem>
                  ))}
                </S.FormInner>

                <Flex justify="space-between">
                  <Flex align="center" gap={28}>
                    <S.ButtonAdd
                      onClick={() =>
                        add({
                          type: undefined,
                          name: '',
                          shares: undefined,
                          people: [''],
                        })
                      }
                    >
                      <PlusOutlined />
                      {tDynamic('onboarding.shareholders.addShareholder')}
                    </S.ButtonAdd>

                    <S.Total>
                      {tDynamic('onboarding.shareholders.totalShareholders', {
                        value: people.length,
                      })}
                    </S.Total>
                  </Flex>

                  {currentPercentage > 0 && currentPercentage !== 100 ? (
                    <S.ErrorText>
                      {tDynamic('onboarding.shareholders.sharesPercentage', {
                        value: currentPercentage,
                      })}
                    </S.ErrorText>
                  ) : null}
                </Flex>
              </>
            )}
          </Form.List>
        </S.Inner>

        <Flex justify="flex-end">
          <Button
            type="link"
            onClick={() =>
              updateScreen(
                isIncorporation() ? 'COMPANY_TYPE' : 'ACCOUNTING_PLAN',
                { active: true }
              )
            }
          >
            {translate('onboarding.buttonGoBack')}
          </Button>

          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            disabled={disabledForm || currentPercentage !== 100}
          >
            {translate('onboarding.buttonNext')}
          </Button>
        </Flex>
      </Form>
    </S.Container>
  );
};

export default OnboardingShareholders;
