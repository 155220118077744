import { FC, PropsWithChildren, ReactNode } from 'react';

import * as S from './styled';

export type ButtonDashedProps = {
  height?: number;
  icon?: ReactNode;
  block?: boolean;
  onClick?: () => void;
};

const ButtonDashed: FC<PropsWithChildren<ButtonDashedProps>> = ({
  icon,
  children,
  ...rest
}) => (
  <S.Button {...rest}>
    {icon}
    {children}
  </S.Button>
);

export default ButtonDashed;
