import { IconChevronRight } from '@assets';
import { Loader } from '@components';
import { PageMeta, PersonalInfo, PersonalSecurity } from '@entities';
import { useAccount, useTranslate } from '@hooks';
import { Breadcrumb, Col, Flex, Row, Typography } from 'antd';
import { FC } from 'react';
import { Link } from 'react-router-dom';

const { Title } = Typography;

const SettingsPersonal: FC = () => {
  const { translate } = useTranslate();
  const { account } = useAccount();

  if (!account) {
    return <Loader />;
  }

  return (
    <Flex gap={32} vertical>
      <PageMeta title={translate('settings.title')} />

      <Breadcrumb
        separator={<IconChevronRight />}
        items={[
          {
            title: <Link to="/settings">{translate('settings.title')}</Link>,
          },
          {
            title: translate('settings.personal.title'),
          },
        ]}
      />

      <Title>{translate('settings.personal.title')}</Title>

      <Row gutter={[32, 0]}>
        <Col span={15}>
          <Flex gap={32} vertical>
            <PersonalInfo account={account} />
            <PersonalSecurity />
          </Flex>
        </Col>
      </Row>
    </Flex>
  );
};

export default SettingsPersonal;
