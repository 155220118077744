import styled from '@emotion/styled';

import { Layout, type LayoutProps } from 'antd';

export const Container = styled(Layout.Content)<LayoutProps>`
  min-height: 100vh;
  background-color: ${({ theme }) => theme.colors.bgLightBlue200};
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;
