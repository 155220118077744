import { useMemo } from 'react';

import { Params$UserController_getUsers } from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';
import { useApiClient } from '@hooks';

type Params = Params$UserController_getUsers;

type UsersListProps = {
  params: Params['parameter'];
};

const useGetUsersList = ({ params }: UsersListProps) => {
  const {
    data: users,
    loading,
    refetch,
  } = useApiClient<Params, Schemas.User[]>({
    method: 'UserController_getUsers',
    params: {
      parameter: params,
    },
  });

  return useMemo(
    () => ({
      users: users || [],
      loading,
      refetch,
    }),
    [users, loading, refetch]
  );
};

export default useGetUsersList;
