import styled from '@emotion/styled';

export const Container = styled.div<{ error: boolean }>`
  background-color: ${({ theme, error }) =>
    error ? theme.colors.bgWarning : theme.colors.strokeGrey};
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 12px;

  div.ui-form-item {
    margin-bottom: 0;
  }
`;

export const Inner = styled.div`
  width: 100%;
  position: relative;
`;

export const Valid = styled.div`
  position: absolute;
  bottom: 4px;
  right: 12px;
`;

export const Processing = styled.div`
  position: absolute;
  bottom: 8px;
  right: 12px;
`;
