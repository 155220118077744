import { FC, useState } from 'react';
import { Flex } from 'antd';

import { useTranslate } from '@hooks';
import { Loader } from '@components';
import { BankDefaultLogotype } from '@assets';

import { Schemas } from '@api-client/generated/types';

import * as S from './styled';

type BankItemProps = {
  isLoading: boolean;
  selectedBankId: string | null;
  logo: string;
  bank: Schemas.Bank;
  onConnect?: (id: string) => void;
};

const BankItem: FC<BankItemProps> = ({
  isLoading,
  logo,
  selectedBankId,
  bank,
  onConnect,
}) => {
  const { translate } = useTranslate();

  const [isErrorLogo, setIsErrorLogo] = useState(false);

  return (
    <S.Wrapper onClick={() => onConnect && onConnect(bank.id)}>
      <Flex align="center" gap={16}>
        <S.Logo
          src={isErrorLogo ? BankDefaultLogotype : logo}
          alt={bank.name}
          onError={() => setIsErrorLogo(true)}
        />

        <S.Name className="bankName">{bank.name}</S.Name>
      </Flex>

      <S.ConnectBlock>
        {selectedBankId === bank.id && isLoading ? (
          <Loader />
        ) : (
          <>
            <S.ButtonConnect
              type="link"
              size="small"
              className='connectButton'
              onClick={() => onConnect && onConnect(bank.id)}
            >
              {translate('accounts.banks.buttonConnect')}
            </S.ButtonConnect>
            <S.BankBic className='bankBic' ellipsis>{bank.bic}</S.BankBic>
          </>
        )}
      </S.ConnectBlock>
    </S.Wrapper>
  );
};

export default BankItem;
