import { useMemo } from 'react';

import { Params$TransactionController_getStatsByTime } from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';
import { type QueryOptions, useApiClient } from '@hooks';

type ReportRow = Schemas.TimeReportRow;
type Params = Params$TransactionController_getStatsByTime;

type TransactionsStatsProps = {
  params: Params['parameter'];
  config?: QueryOptions<ReportRow[]>;
};

const useGetTransactionsStatsByTime = ({
  config,
  params,
}: TransactionsStatsProps) => {
  const {
    data: statsTime,
    loading,
    refetch,
  } = useApiClient<Params, ReportRow[]>({
    method: 'TransactionController_getStatsByTime',
    params: {
      parameter: params,
    },
    config,
  });

  return useMemo(
    () => ({
      statsTime: (statsTime || []) as ReportRow[],
      loading,
      refetch,
    }),
    [statsTime, loading, refetch]
  );
};

export default useGetTransactionsStatsByTime;
