import { Select } from '@components';
import useTranslate from '@hooks/useTranslate';
import { LocalePaths } from '@locales';

import {
  Control,
  FormValues,
  Operator,
  SelectOperator,
  SelectOptions,
} from '../utils';
import * as S from './styled';

const SelectField = <TFieldName extends string>({
  props,
  onChange,
  value,
  disabledValues,
}: {
  props: SelectOperator<TFieldName>;
  value?: string;
  onChange: (value: string) => void;
  disabledValues?: SelectOptions[number]['value'][];
}) => {
  const { translate } = useTranslate();

  const translateOption = (
    option: SelectOptions[number] & {
      label: LocalePaths;
    }
  ): SelectOptions[number] => ({
    ...option,
    label: translate(option.label),
    disabled:
      option.disabled ||
      (!!option.value && disabledValues?.includes(option.value)),
  });

  const selectProps: Parameters<typeof Select>[0] = {
    ...props.props,
    placeholder: translate(props.props.placeholder),
    options: props.translateOptions
      ? props.props.options.map<SelectOptions[number]>(translateOption)
      : props.props.options,
    disabled:
      props.props.disabled ||
      disabledValues?.length === props.props.options.length,
  };

  return (
    <Select size="large" {...selectProps} onChange={onChange} value={value} />
  );
};

const RulesFormControlsList = <
  TOptionValue extends string,
  TSelectorFieldName extends string,
  TOperatorFieldName extends string,
>({
  controls,
  values,
  onChange,
  disabledSelectorValues,
}: {
  controls: Control<TOptionValue, TSelectorFieldName, TOperatorFieldName>;
  values: FormValues<TOptionValue, TSelectorFieldName, TOperatorFieldName>;
  onChange: (field: string, value: string) => void;
  disabledSelectorValues: string[];
}) => {
  const operator: Operator<TOperatorFieldName> | undefined =
    controls.operator[values[controls.selector.fieldName]];

  const selectorValue: TOptionValue | undefined =
    values[controls.selector.fieldName];

  return (
    <>
      <SelectField<TSelectorFieldName>
        props={{ ...controls.selector, translateOptions: true }}
        onChange={(value) => onChange(controls.selector.fieldName, value)}
        value={selectorValue}
        disabledValues={disabledSelectorValues}
      />
      {operator?.map((control, index) => {
        const value: string | undefined = values[control.fieldName];

        switch (control.type) {
          case 'input':
            return (
              <S.TextField
                key={index}
                onChange={(event) =>
                  onChange(control.fieldName, event.target.value)
                }
                value={value}
              />
            );
          case 'select': {
            return (
              <SelectField
                props={control}
                key={index}
                onChange={(value) => onChange(control.fieldName, value)}
                value={value}
              />
            );
          }
        }
      })}
    </>
  );
};

export default RulesFormControlsList;
