import { FC, PropsWithChildren } from 'react';

import { Modal, type ModalProps } from 'antd';

import * as S from './styled';

const InvoiceWrapperModal: FC<PropsWithChildren<ModalProps>> = ({
  children,
  ...rest
}) => (
  <Modal width={575} footer={null} destroyOnClose centered {...rest}>
    <S.Inner gap={24} vertical>
      {children}
    </S.Inner>
  </Modal>
);

export default InvoiceWrapperModal;
