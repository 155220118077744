import styled from '@emotion/styled';

type StatusProps = {
  small?: boolean;
  bgColor: string;
  textColor: string;
};

export const Status = styled.span<StatusProps>`
  height: ${({ small }) => (small ? '24px' : '32px')};
  background-color: ${({ theme, bgColor }) => bgColor || theme.colors.bgGrey};
  color: ${({ theme, textColor }) => textColor || theme.colors.black};
  border-radius: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: ${({ small }) => (small ? '12px' : '14px')};
  padding: 4px 12px;
  white-space: nowrap;
`;
