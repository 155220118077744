import { Flex } from 'antd';

import { Schemas } from '@api-client/generated/types';
import { IconArrowRight } from '@assets';
import styled from '@emotion/styled';
import { useTranslate } from '@hooks';

import { CornerCard } from '../components/CornerCard/CornerCard';
import { TransactionCardDetails } from '../components/TransactionCardDetails/TransactionCardDetails';
import { actionsToProps, conditionsToProps } from '../utils';

const Wrapper = styled.div`
  display: flex;
  padding: 24px 28px;
  margin: 0 -30px 18px -30px;
  border-top: 1px solid ${({ theme }) => theme.colors.strokeDarkGrey};
  border-bottom: 1px solid ${({ theme }) => theme.colors.strokeDarkGrey};
  background-color: ${({ theme }) => theme.colors.bgGrey};
`;

const Icon = styled(IconArrowRight)`
  width: 24px;
  min-width: 24px;
  margin: 0 24px;
`;

const Title = styled('h6', {
  shouldForwardProp: (propName) => propName !== 'success',
})<{ success?: boolean }>`
  color: ${({ theme, success }) =>
    success ? theme.colors.success : theme.colors.text400};
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
`;

export const RulePreview = ({
  item,
  projects,
  categories,
  contacts,
}: {
  item: Schemas.RuleDto;
  projects: Schemas.Project[];
  categories: Schemas.Category[];
  contacts: Schemas.Contact[];
}) => {
  const { translate } = useTranslate();

  const Component =
    item.entityType === 'transaction' ? TransactionCardDetails : CornerCard;

  return (
    <Wrapper>
      <Flex gap={12} vertical>
        <Title>{translate('rules.rulePreview.before')}</Title>
        <Component type="condition" {...conditionsToProps(item.conditions)} />
      </Flex>
      <Icon />
      <Flex gap={12} vertical>
        <Title success>{translate('rules.rulePreview.after')}</Title>
        <Component
          type="action"
          {...actionsToProps(item.actions, {
            projects,
            categories,
            contacts,
          })}
        />
      </Flex>
    </Wrapper>
  );
};
