import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { Language } from '@constants';

import { translations } from './';

window.t = (key) => () => i18n.t(key);

export const initI18n = () => {
  i18n.use(initReactI18next).init({
    resources: translations,
    lng: Language.En,
    fallbackLng: Language.En,
    interpolation: {
      escapeValue: false,
    },
  });

  return i18n;
};
