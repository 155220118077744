import { Schemas } from '@api-client/generated/types';
import { FC, useState } from 'react';
import { useDrag } from 'react-dnd';
import * as S from './styled';
import { HolderOutlined } from '@ant-design/icons';
import { Flex } from 'antd';

type MainCategoryBlockSubItemType = {
  item?: Schemas.Category;
  title?: string;
  groupName?: string;
  isEmpty?: boolean;
  onEditCategory?: () => void;
  onRemoveCategory?: () => void;
  handleDragSubItem?: (value: boolean) => void;
};

const MainCategoryBlockListSubItem: FC<MainCategoryBlockSubItemType> = ({
  item,
  groupName,
  title,
  isEmpty,
  onEditCategory,
  onRemoveCategory,
  handleDragSubItem,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const [{ isDragging }, dragRef] = useDrag({
    type: `${groupName}`,
    item: item,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <>
      {isEmpty ? (
        <S.EmptyContainer>
          <S.EmptyText isempty={isEmpty}>{title}</S.EmptyText>
        </S.EmptyContainer>
      ) : (
        <S.SubItem
          onDragStart={() => handleDragSubItem?.(true)}
          onDragEnd={() => handleDragSubItem?.(false)}
          isdragging={isDragging}
          ref={dragRef}
        >
          <S.SubItemContainer
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <S.SubItemTitle>
              <S.DotsWrapper>
                {isHovered ? <HolderOutlined /> : <S.DotsContainer />}
              </S.DotsWrapper>
              <S.Title>{title}</S.Title>
            </S.SubItemTitle>

            <Flex>
              {isHovered && !!title && (
                <S.SubItemActionsContainer>
                  <S.EditIcon onClick={onEditCategory} />
                  <S.DeleteIcon onClick={onRemoveCategory} />
                </S.SubItemActionsContainer>
              )}
            </Flex>
          </S.SubItemContainer>
        </S.SubItem>
      )}
    </>
  );
};

export default MainCategoryBlockListSubItem;
