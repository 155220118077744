import styled from '@emotion/styled';
import { Button, Flex, FlexProps } from 'antd';

import { IconCornerCard } from '@assets';

export const Wrapper = styled.div<{type: string}>`
  width: 100%;
  height: 150px;
  position: relative;
  cursor: pointer;

  svg {
    path {
      stroke: ${({ theme, type }) => type === 'selected' ? theme.colors.primary : ''};
    }
  }

  :hover {
    border-color: ${({ theme, type }) => type === 'hovering' ? theme.colors.primary : theme.colors.strokeDarkGrey};

    svg {
      path {
        stroke: ${({ theme, type }) => type === 'hovering' ? theme.colors.primary : ''};
      }
    }

    .cornerCard {
      border-color: ${({ theme, type }) => type === 'hovering' ? theme.colors.primary : theme.colors.strokeDarkGrey};
      stroke: ${({ theme, type }) => type === 'hovering' ? theme.colors.primary : theme.colors.strokeDarkGrey};
    }

    .actions {
      display: ${({ type }) => type === 'hovering' ? 'flex' : 'none'};
      gap: 16px;
    }

    .price {
      display: ${({ type }) => type === 'hovering' ? 'none' : 'flex'};
    }

    .date {
      display: ${({ type }) => type === 'hovering' ? 'none' : 'flex'};
    }
  }
`;

export const CornerCard = styled(IconCornerCard)<{type: string}>`
  position: absolute;
`;

export const TextBlock = styled(Flex)`
  width: 95%;
  height: 100%;
  position: absolute;
  padding-left: 20px;
  padding-top: 13px;
  padding-bottom: 20px;
`;

export const CornerCardAdditionalTitle = styled.span`
  font-size: 9px;
  font-weight: 600;
  line-height: 12px;
  color: ${({ theme }) => theme.colors.textDisabled};
  width: 85%;
  text-transform: uppercase;
`;

export const CornerCardTitle = styled.span`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.black};
  text-transform: capitalize;
  width: 85%;
  height: 20px;

  text-overflow: ellipsis;
  word-break: break-all;
  overflow: hidden;
`;

export const CornerCardDescription = styled.span`
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.black};
  width: 100%;
  height: 40px;
  max-height: 40px;
  flex: 1;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  word-break: break-all;
`;

export const Footer = styled(Flex)`
  width: 100%;
`;

export const CornerCardPrice = styled.span`
  font-weight: 600;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.black};
`;

export const CornerCardDate = styled.span`
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.text400};
`;

export const ViewButton = styled(Button)`
  height: 20px;
  padding: 0;
  font-weight: 600;
  line-height: 20px;
  font-size: 14px;
`;

export const DetachButton = styled(Button)`
  && {
    height: 20px;
    padding: 0;
    font-weight: 600;
    line-height: 20px;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.error};
    gap: 8px;

    && {
      :hover {
        color: ${({ theme }) => theme.colors.error};
        opacity: 0.6;
      }
    }
  }
`;

export const Actions = styled.div`
  display: none;
`;

export const Container = styled.div`
  position: relative;
`;

export const Content = styled(Flex)<FlexProps & { hidden: boolean }>`
  opacity: ${({ hidden }) => (hidden ? 0 : 1)};
`;

export const Loader = styled.div`
  width: 100%;
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
`;