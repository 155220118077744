import { FC } from 'react';
import { Trans } from 'react-i18next';

import { Typography, Button, Flex } from 'antd';

import { OnboardingMessage } from '@entities';
import { useTranslate, useOnboarding } from '@hooks';

import { MessageProcessPaymentSent } from '@assets';

import * as S from './styled';

const { Paragraph, Text } = Typography;

const OnboardingMessageProcessPaymentSent: FC = () => {
  const { translate, tDynamic } = useTranslate();
  const { updateScreen, incorporationDetails } = useOnboarding();

  const handleContinue = () => {
    if (incorporationDetails?.companyType === 'sarl-s') {
      updateScreen('HONOR_DECLARATION', { active: true });
    } else {
      updateScreen('INCORPORATION', { active: true });
    }
  };

  return (
    <S.Container>
      <OnboardingMessage
        picture={<MessageProcessPaymentSent />}
        title={tDynamic(
          incorporationDetails?.isPaid
            ? `onboarding.messages.processPaymentSent.titlePaid_${incorporationDetails.companyType}`
            : `onboarding.messages.processPaymentSent.titlePaying_${incorporationDetails?.companyType}`
        )}
        description={
          <Trans
            i18nKey={tDynamic(
              `onboarding.messages.processPaymentSent.description_${incorporationDetails?.companyType}`
            )}
            components={[<Paragraph />, <Text strong />]}
          />
        }
      />

      <Flex justify="flex-end">
        <Button type="primary" onClick={handleContinue}>
          {translate('onboarding.buttonContinue')}
        </Button>
      </Flex>
    </S.Container>
  );
};

export default OnboardingMessageProcessPaymentSent;
