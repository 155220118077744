import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { Flex, Space } from 'antd';

import { CompaniesSelect } from '@entities';
import { IconLogout } from '@assets';
import { useAccount, useTranslate } from '@hooks';

import * as S from './styled';

const Header: FC = () => {
  const navigate = useNavigate();
  const {translate} = useTranslate()

  const isDev = import.meta.env.VITE_APP_MODE === 'DEV';

  const { account, company, companies, clearAccount } = useAccount();

  const handleLogout = () => {
    clearAccount();
    navigate('/login');
  };

  return (
    <S.Header>
      <S.DevText>{isDev && translate('common.header.devText')}</S.DevText>

      <Flex align="center">
        <Flex gap={32}>
          <Space size={8}>
            <S.UserName>{account?.fullName}</S.UserName>
          </Space>

          {company && (
            <Space size={8}>
              {companies.length > 1 ? (
                <CompaniesSelect />
              ) : (
                <S.UserName>{company?.name}</S.UserName>
              )}
            </Space>
          )}
        </Flex>

        <S.Logout onClick={handleLogout}>
          <IconLogout />
        </S.Logout>
      </Flex>
    </S.Header>
  );
};

export default Header;
