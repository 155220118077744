//
// Generated by @himenon/openapi-typescript-code-generator v0.27.4
//
// OpenApi : 3.0.0
//
//
import {
  Params$RuleController_delete,
  Response$RuleController_delete$Status$200,
  ResponseContentType$RuleController_delete,
} from '@api-client/generated/client';
import { useApiClientMutation } from '@hooks/useApiClientMutation';

export const useRuleController_delete = () =>
  useApiClientMutation<
    Params$RuleController_delete,
    Response$RuleController_delete$Status$200[ResponseContentType$RuleController_delete]
  >({ method: 'RuleController_delete' });
