import {
  Button,
  Col,
  Flex,
  Form,
  Input,
  InputNumber,
  Modal,
  type ModalProps,
  Row,
} from 'antd';
import { FC, useEffect } from 'react';
import { Trans } from 'react-i18next';

import { Schemas } from '@api-client/generated/types';
import { Select } from '@components';
import { AccountType, currencyCodes } from '@constants';
import { useAccount, useAccounts, useTranslate } from '@hooks';
import { useCreateAccount, useUpdateAccountById } from '@hooks-api';

import * as S from './styled';

type AccountAddModalProps = {
  account?: Schemas.Account | null;
  id?: string | null;
  onCancel: () => void;
} & ModalProps;

const AccountAddModal: FC<AccountAddModalProps> = ({
  account,
  open,
  onCancel,
  ...rest
}) => {
  const { translate, tDynamic } = useTranslate();
  const { companyId } = useAccount();

  const [form] = Form.useForm();

  const { createAccount, updateAccount } = useAccounts();

  const [createNewAccount, loading] = useCreateAccount();
  const [updateAccountById, loadingUpdate] = useUpdateAccountById();

  useEffect(() => {
    if (account) {
      form.setFieldsValue({
        accountName: account.accountName,
        accountType: account.accountType,
        currencyCode: account.currencyCode,
        initialBalance: account.balances[0].balanceAmount.amount,
      });
    }
  }, [account, form]);

  const handleClose = () => {
    form.resetFields();
    onCancel();
  };

  const handleSubmit = (
    values: Schemas.AccountDto & {
      initialBalance: number;
    }
  ) => {
    const requestBody = {
      accountName: values.accountName,
      accountType: values.accountType,
      currencyCode: values.currencyCode,
      balances: [
        {
          balanceAmount: {
            amount: values.initialBalance,
            currency: values.currencyCode,
          },
        },
      ],
    };

    if (account) {
      updateAccountById(
        {
          parameter: {
            id: account.id,
            companyId: companyId!,
          },
          requestBody,
        },
        {
          onSuccess: (response) => {
            updateAccount(response);
            handleClose();
          },
        }
      );
    } else {
      createNewAccount(
        {
          parameter: {
            companyId: companyId!,
          },
          requestBody,
        },
        {
          onSuccess: (response) => {
            createAccount(response);
            handleClose();
          },
        }
      );
    }
  };

  return (
    <Modal
      width={630}
      closeIcon={null}
      open={open}
      onCancel={handleClose}
      footer={null}
      title={
        account
          ? translate('accounts.accountEdit.title')
          : translate('accounts.accountAdd.title')
      }
      destroyOnClose
      centered
      {...rest}
    >
      <S.Inner>
        <Form
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
          requiredMark={false}
          initialValues={{
            accountType: AccountType.Cash,
            currencyCode: 'EUR',
          }}
        >
          <Flex gap={20} vertical>
            <div>
              <Form.Item
                label={translate('accounts.accountAdd.name.label')}
                name="accountName"
                rules={[
                  {
                    required: true,
                    message: translate('accounts.accountAdd.name.error'),
                  },
                ]}
              >
                <Input size="large" />
              </Form.Item>

              <Row gutter={[20, 0]}>
                <Col span={12}>
                  <Form.Item
                    label={translate('accounts.accountAdd.type.label')}
                    name="accountType"
                    rules={[
                      {
                        required: true,
                        message: translate('accounts.accountAdd.type.error'),
                      },
                    ]}
                  >
                    <Select
                      placeholder={translate(
                        'accounts.accountAdd.type.placeholder'
                      )}
                      options={[
                        {
                          label: tDynamic(
                            `accounts.types.${AccountType.Current}`
                          ),
                          value: AccountType.Current,
                        },
                        {
                          label: tDynamic(`accounts.types.${AccountType.Cash}`),
                          value: AccountType.Cash,
                        },
                        {
                          label: tDynamic(
                            `accounts.types.${AccountType.Other}`
                          ),
                          value: AccountType.Other,
                        },
                      ]}
                      size="large"
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label={translate('accounts.accountAdd.currency.label')}
                    name="currencyCode"
                    rules={[
                      {
                        required: true,
                        message: translate(
                          'accounts.accountAdd.currency.error'
                        ),
                      },
                    ]}
                  >
                    <Select
                      placeholder={translate(
                        'accounts.accountAdd.currency.placeholder'
                      )}
                      options={currencyCodes.map((code) => ({
                        label: code,
                        value: code,
                      }))}
                      size="large"
                      showSearch
                    />
                  </Form.Item>
                </Col>
              </Row>

              <S.BalanceRow gutter={[20, 0]}>
                <Col span={12}>
                  <Trans
                    i18nKey="accounts.accountAdd.initialBalance.hint"
                    components={[<S.Hint />, <S.Hint />]}
                  />
                </Col>

                <Col span={12}>
                  <Form.Item
                    label={translate(
                      'accounts.accountAdd.initialBalance.label'
                    )}
                    name="initialBalance"
                    rules={[
                      {
                        required: true,
                        message: translate(
                          'accounts.accountAdd.initialBalance.error'
                        ),
                      },
                    ]}
                  >
                    <InputNumber placeholder="0.00" size="large" />
                  </Form.Item>
                </Col>
              </S.BalanceRow>
            </div>

            <Form.Item noStyle>
              <Flex justify="flex-end" gap={12}>
                <Button onClick={handleClose} size="large">
                  {translate('accounts.accountAdd.buttonCancel')}
                </Button>

                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  loading={loading || loadingUpdate}
                >
                  {account
                    ? translate('accounts.accountEdit.buttonEdit')
                    : translate('accounts.accountAdd.buttonAdd')}
                </Button>
              </Flex>
            </Form.Item>
          </Flex>
        </Form>
      </S.Inner>
    </Modal>
  );
};

export default AccountAddModal;
