import { Button, type ButtonProps } from 'antd';

import styled from '@emotion/styled';

export const Title = styled.div`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
`;

export const Card = styled.div`
  width: 400px;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.bgDarkGrey};
  border-radius: 16px;
  padding: 24px;
  flex: 1;
`;

export const Submit = styled.div`
  margin-top: 40px;
`;

export const ButtonDelete = styled(Button)<ButtonProps>`
  opacity: 0.35;

  &:hover {
    opacity: 1;
  }
`;
