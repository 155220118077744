import styled from '@emotion/styled';
import { rgba } from 'emotion-rgba';

import { Typography } from 'antd';

export const Header = styled.div`
  text-align: center;
`;

export const Inner = styled.div`
  width: 100%;
  max-width: 371px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 21px 0 ${({ theme }) => rgba(theme.colors.dark, 0.14)};
  padding: 30px;
`;

export const Logo = styled(Typography.Paragraph)`
  && {
    color: ${({ theme }) => theme.colors.primary};
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 8px;
  }
`;

export const Title = styled(Typography.Title)`
  && {
    &.ui-typography {
      font-size: 32px;
      margin-top: 0;
      margin-bottom: 12px;
    }
  }
`;

export const Subtitle = styled(Typography.Paragraph)`
  && {
    font-size: 16px;
    margin-bottom: 15px;
  }
`;

export const Form = styled.div`
  .ui-btn-primary {
    height: 52px;
  }
`;

export const Footer = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 500;
  text-align: center;
`;
