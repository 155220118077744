import { Highlight } from '../Highlight';
import * as S from './styled';
import { IconBank, IconCheck } from '@assets';
import styled from '@emotion/styled';
import { useTranslate } from '@hooks';
import { Input as BaseInput, Col, Flex, Row } from 'antd';

const Flag = styled.span`
  padding: 0 5px;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  border-radius: 3px;
  color: ${({ theme }) => theme.colors.text500};
  background-color: ${({ theme }) => theme.colors.badgeSuccess};
  display: flex;
  align-items: center;
  height: 20px;

  & + & {
    margin-left: 10px;
  }
`;

const Input = styled(BaseInput, {
  shouldForwardProp: (prop) => prop !== 'isHighlighted',
})<{ isHighlighted?: boolean }>`
  && {
    color: ${({ theme, isHighlighted }) =>
      isHighlighted ? theme.colors.black : theme.colors.text400};
    background-color: ${({ theme, isHighlighted }) =>
      isHighlighted ? theme.colors.badgeSuccess : null};
  }
`;

type Props = {
  account?: string;
  contact?: string;
  description?: string;
  category?: string;
  project?: string;
  fileless?: boolean;
  excludedFromReport?: boolean;
  type: 'condition' | 'action';
  isArchived?: boolean;
};

export const TransactionCardDetails = ({
  account,
  contact,
  description,
  category,
  project,
  fileless,
  excludedFromReport,
  type,
  isArchived,
}: Props) => {
  const { translate } = useTranslate();

  return (
    <S.Card isArchived={isArchived}>
      <S.Details gap={40} vertical>
        <Flex gap={16} align="flex-start">
          <S.FullWidth gap={6} vertical>
            <S.Breadcrumbs>
              {account ? (
                <Highlight type={type}>{account}</Highlight>
              ) : (
                translate('rules.rule.anyAccount')
              )}
            </S.Breadcrumbs>
            <S.Title>
              {contact ? (
                <Highlight type={type}>{contact}</Highlight>
              ) : (
                translate('rules.rule.anyContact')
              )}
            </S.Title>
            <S.Description>
              {description ? (
                <Highlight type={type}>{description}</Highlight>
              ) : (
                translate('rules.rule.anyDescription')
              )}
            </S.Description>
          </S.FullWidth>
          <S.LogoWrapper align="center" justify="center">
            <IconBank width={22} />
          </S.LogoWrapper>
        </Flex>
        <Flex align="center">
          <S.Amount>€XXX</S.Amount>
          {fileless && (
            <Flag>
              <IconCheck width="10" />
              &nbsp;{translate('rules.rule.fileless')}
            </Flag>
          )}
          {excludedFromReport && (
            <Flag>
              <IconCheck width="10" />
              &nbsp;{translate('rules.rule.excludedFromReport')}
            </Flag>
          )}
          <S.Date>DD.MM.YYYY</S.Date>
        </Flex>
      </S.Details>
      <S.Content>
        <Row gutter={[12, 0]}>
          <Col span={8}>
            <S.Item
              label={translate('transaction.form.category.label')}
              labelAlign="left"
            >
              <Input
                size="large"
                value={category || translate('rules.rule.anyValue')}
                disabled
                isHighlighted={!!category}
              />
            </S.Item>
          </Col>

          <Col span={8}>
            <S.Item
              label={translate('transaction.form.plDate.label')}
              labelAlign="left"
            >
              <Input
                size="large"
                value={translate('rules.rule.anyValue')}
                disabled
              />
            </S.Item>
          </Col>

          <Col span={8}>
            <S.Item
              label={translate('transaction.form.project.label')}
              labelAlign="left"
            >
              <Input
                size="large"
                value={project || translate('rules.rule.anyValue')}
                disabled
                isHighlighted={!!project}
              />
            </S.Item>
          </Col>
        </Row>
      </S.Content>
    </S.Card>
  );
};
