import { Button, Col, Flex, Row, Typography } from 'antd';
import _ from 'lodash';
import { FC, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';

import { Schemas } from '@api-client/generated/types';
import {
  IconCheck,
  IconLegal,
  IconUser,
  IconWarningTransparent,
} from '@assets';
import { ScreenName } from '@context';
import {
  KYCAddressProof,
  KYCAnnualAccounts,
  KYCAoa,
  KYCBiometric,
  KYCCountry,
  KYCCv,
  KYCExtract,
  KYCFunds,
  KYCIds,
  KYCNationality,
  KYCOwnershipChart,
  KYCTax,
  KYCTin,
  KYCWealth,
  OnboardingCardHeader,
} from '@entities';
import { useAccount, useOnboarding, useTranslate } from '@hooks';
import { useUpdateIncorporation } from '@hooks-api';

import * as S from './styled';

type Person = Schemas.Person;
type CompanyFile = Schemas.CompanyFile;
type UBO = Schemas.UBO;

type StepWithFormErrors = 'tin' | 'wealth';

const { Paragraph, Text } = Typography;

const legalMinimumSteps = [
  'country',
  'extract',
  'aoa',
  'annual_accounts',
  'ownership_chart',
  'tin',
];

const legalAllSteps = legalMinimumSteps.concat(['ids']);

const naturalSteps = [
  'nationality',
  'id',
  'address_proof',
  'wealth',
  'cv',
  'funds',
  'tax',
];
const naturalDirectorSteps = ['id', 'address_proof'];
const nonUboSteps = ['nationality', 'id', 'address_proof', 'tax'];

const OnboardingUploadDocuments: FC = () => {
  const { translate, tDynamic } = useTranslate();
  const { companyId } = useAccount();

  const {
    incorporationDetails,
    updateIncorporation,
    updateScreen,
    updateStep,
    isIncorporation,
  } = useOnboarding();

  const details = incorporationDetails as Schemas.Incorporation;

  const [selectedType, setSelectedType] = useState<string | null>(null);
  const [selectedPerson, setSelectedPerson] = useState<Person | UBO | null>(
    null
  );

  const [selectedStepValue, setSelectedStepValue] = useState('country');

  const [update, loading] = useUpdateIncorporation();

  const getLegalSteps = (person: Person) =>
    person.isCompanyItself ? legalMinimumSteps : legalAllSteps;

  useEffect(() => {
    if (incorporationDetails?.people) {
      const firstPerson = incorporationDetails?.people[0];

      handleSelectPerson(firstPerson as Person);
    }
  }, []);

  const handleSelectPerson = (person: Person) => {
    setSelectedPerson(person);
    setSelectedType(person.type);

    if (person.type === 'legal') {
      setSelectedStepValue(legalMinimumSteps[0]);
      return;
    }

    if (person.type === 'natural' && !person.isShareholder) {
      setSelectedStepValue(naturalDirectorSteps[0]);
      return;
    }

    if (person.type === 'natural' && person.isShareholder) {
      setSelectedStepValue(naturalSteps[0]);
      return;
    }
  };

  const handleSelectPersonUBO = (person: Schemas.UBO) => {
    setSelectedPerson(person);
    setSelectedType('natural');
    setSelectedStepValue(naturalSteps[0]);
  };

  const handleNext = () => {
    let isSubmittedToKyc = false;
    let nextStep: ScreenName = 'LEGAL_ADDRESS';

    if (
      !isIncorporation() ||
      (details.isKycFailed && !details.isAddressProofFailed)
    ) {
      isSubmittedToKyc = true;
      nextStep = 'MESSAGE_PROCESS_DOCUMENTS';
    }

    if (details.isKycFailed && details.isAddressProofFailed) {
      isSubmittedToKyc = false;
      nextStep = 'PROVIDE_ADDRESS';
    }

    update(
      {
        parameter: {
          companyId: companyId!,
        },
        requestBody: {
          isSubmittedToKyc,
          isKycFailed: false,
        },
      },
      {
        onSuccess: (response) => {
          updateIncorporation(response);
          updateScreen(nextStep, { active: true });
        },
      }
    );
  };

  const getPersonRole = (person: Person) => {
    const roles: string[] = [];

    if (person.isShareholder) {
      roles.push(translate('onboarding.kyc.shareholder'));
    }

    if (person.isDirector) {
      roles.push(translate('onboarding.kyc.manager'));
    }

    return roles.join(', ');
  };

  const hasErrorStep = (step: string) => {
    if (step === 'id' && selectedPerson?.kycData?.hasFailedBiometricCheck) {
      return true;
    }

    if (step === 'wealth') {
      const incomeWithFileRequestedButNoFileOrError =
        selectedPerson?.kycData?.incomes?.find((income) => {
          if (income) {
            const step = `wealth_${income.type}`;
            return (
              income.isRequiredConfimation === 'yes' &&
              (!selectedPerson.files[step] ||
                selectedPerson.files[step].length === 0 ||
                selectedPerson.files[step].some(
                  (file: CompanyFile) => file.hasError
                ))
            );
          }
        });

      if (incomeWithFileRequestedButNoFileOrError) {
        return true;
      }
    }

    if (['tin', 'wealth'].includes(step) && selectedPerson?.kycData) {
      return selectedPerson.kycData[`${step as StepWithFormErrors}ErrorCode`];
    }

    const errors = (selectedPerson?.files[step] || [])
      .map((file: CompanyFile) => file.hasError)
      .filter((status: boolean) => status);

    return !!errors.length;
  };

  const hasErrorPerson = (person: Person | UBO) => {
    const incomeWithFileRequestedButNoFile = person?.kycData?.incomes?.find(
      (income) => {
        if (income) {
          const step = `wealth_${income.type}`;
          return (
            income.isRequiredConfimation === 'yes' &&
            (!person.files[step] || person.files[step].length === 0)
          );
        }
      }
    );

    if (incomeWithFileRequestedButNoFile) {
      return true;
    }

    if (
      person?.kycData?.tinErrorCode ||
      person?.kycData?.wealthErrorCode ||
      person?.kycData?.hasFailedBiometricCheck
    ) {
      return true;
    }

    const errors = _.flattenDeep(_.values(person?.files))
      .map((file: CompanyFile) => file.hasError)
      .filter((status: boolean) => status);

    return !!errors.length;
  };

  const hasFilesWithErrors = () =>
    !!details.people.filter((person) => hasErrorPerson(person)).length;

  const goToShareholders = () => {
    updateIncorporation({ group: 'shareholders' });
    updateStep('SHAREHOLDERS', { active: true, status: 'inProgress' });
    updateScreen('SHAREHOLDERS', { active: true });
  };

  const isStepComplete = (step: string, person: Person | UBO | null) => {
    switch (step) {
      case 'country':
        return !!person?.kycData?.countryCode;
      case 'extract':
        return (
          (person?.kycData?.hasExtract === 'yes' &&
            person?.files[step]?.length > 0) ||
          (person?.kycData?.hasExtract === 'no' &&
            person?.kycData?.hasRequestedExtract === 'yes' &&
            !!person?.kycData?.rcsNumber?.length)
        );
      case 'tin':
        return !!person?.kycData?.tin;
      case 'nationality':
        return !!person?.kycData?.nationality;
      case 'id':
        return (
          person?.kycData?.hasCompletedBiometricCheck ||
          person?.kycData?.hasSentBiometricCheckLink
        );
      case 'address_proof':
        return (
          !!person?.kycData?.countryCode &&
          (person?.kycData?.address || '').length > 0 &&
          (person?.kycData?.zip || '').length > 0 &&
          (person?.kycData?.city || '').length > 0 &&
          person?.files[step]?.length > 0
        );
      case 'wealth': {
        const incomeWithFileRequestedButNoFile = person?.kycData?.incomes?.find(
          (income) => {
            if (income) {
              const step = `wealth_${income.type}`;
              return (
                income.isRequiredConfimation === 'yes' &&
                (!person.files[step] || person.files[step].length === 0)
              );
            }
          }
        );

        return (
          !incomeWithFileRequestedButNoFile &&
          (person?.kycData?.totalWealth || '').length > 0 &&
          person?.kycData?.incomes &&
          person?.kycData?.incomes.length > 0 &&
          !person?.kycData?.wealthErrorCode
        );
      }
      case 'cv':
        return (
          (person?.kycData?.pep === 'no' ||
            (person?.kycData?.pep === 'yes' &&
              (person?.kycData?.pepInfo || '').length > 0)) &&
          (person?.kycData?.blacklist === 'no' ||
            (person?.kycData?.blacklist === 'yes' &&
              (person?.kycData?.blacklistInfo || '').length > 0)) &&
          person?.files[step]?.length > 0
        );
      case 'tax':
        return (
          !!person?.kycData?.taxResidenceCountryCode &&
          (person?.kycData?.taxNumber || '').length > 0 &&
          !!person?.kycData?.professionalIncomeCountryCode &&
          (person?.kycData?.usaLink === 'no' ||
            (person?.kycData?.usaLink === 'yes' &&
              !!person?.kycData?.usaLinkType))
        );
      case 'annual_accounts':
        return (
          person?.kycData?.hasFiledAnnualAccounts === 'no' ||
          (person?.kycData?.hasFiledAnnualAccounts === 'yes' &&
            person?.files[step]?.length > 0)
        );
      case 'funds':
        return (
          person?.kycData?.hasExternalFunds === 'no' ||
          (person?.kycData?.hasExternalFunds === 'yes' &&
            (person?.kycData?.externalFundsBackground || '').length > 0)
        );
      default:
        return !!person?.files?.[step]?.length;
    }
  };

  const stepsForType = (person: Person | UBO | null) => {
    if (!person) {
      return [];
    }

    const isPerson = (person as Person).type !== undefined;

    if (isPerson) {
      const personAsPerson = person as Person;

      if (personAsPerson.type === 'legal') {
        return getLegalSteps(personAsPerson);
      }

      if (personAsPerson.isShareholder) {
        if (personAsPerson.shares >= 10) {
          return naturalSteps;
        } else {
          return nonUboSteps;
        }
      } else {
        return naturalDirectorSteps;
      }
    } else {
      return naturalSteps;
    }
  };

  const personStepsCompletedCount = (person: Person | UBO) => {
    const steps = stepsForType(person);
    return steps.map((step) => isStepComplete(step, person)).filter((v) => v)
      .length;
  };

  const isEqualSteps = (person: Person | UBO) => {
    const completedSteps = personStepsCompletedCount(person);
    const requiredStepsCount = stepsForType(person).length;
    return completedSteps === requiredStepsCount;
  };

  const notAllDataPresent = () =>
    details.people.filter((person) => !isEqualSteps(person)).length > 0 ||
    details.people
      .flatMap((person) => person.people || [])
      .filter((person) => !isEqualSteps(person)).length > 0;

  return (
    <Flex gap={24} vertical>
      <OnboardingCardHeader
        title={translate('onboarding.kyc.title')}
        description={
          <Paragraph>
            <Trans
              i18nKey={tDynamic(
                `onboarding.kyc.description_${incorporationDetails?.incorporationType}`
              )}
              components={[<Paragraph />, <strong />]}
            />
          </Paragraph>
        }
      />

      <Flex gap={24}>
        <S.Categories>
          <Flex align="center" justify="space-between">
            <S.CategoriesTitle level={4}>
              {translate('onboarding.kyc.titleList')}
            </S.CategoriesTitle>

            <S.CategoriesAction onClick={goToShareholders}>
              {translate('onboarding.kyc.modify')}
            </S.CategoriesAction>
          </Flex>

          <S.People>
            {(details?.people || []).map((person) => (
              <S.PeopleWrap key={person.id}>
                <S.Person
                  onClick={() => handleSelectPerson(person)}
                  selected={selectedPerson?.id === person.id}
                >
                  <Flex gap={16}>
                    {person.type === 'legal' ? <IconLegal /> : <IconUser />}

                    <Flex vertical>
                      <S.PersonName>{person.name}</S.PersonName>
                      <S.PersonDetails>{getPersonRole(person)}</S.PersonDetails>
                    </Flex>
                  </Flex>

                  <Flex align="center" gap={4}>
                    <S.PersonFilesCount
                      selected={selectedPerson?.id === person.id}
                    >
                      {`${personStepsCompletedCount(person)}/${stepsForType(person).length}`}
                    </S.PersonFilesCount>

                    {isEqualSteps(person) && !hasErrorPerson(person) && (
                      <S.StepNumber status="completed">
                        <IconCheck />
                      </S.StepNumber>
                    )}

                    {hasErrorPerson(person) && (
                      <S.StepNumber status="errorsFound">
                        <IconWarningTransparent />
                      </S.StepNumber>
                    )}
                  </Flex>
                </S.Person>

                {(person?.people || []).map((subPerson) => (
                  <S.Person
                    key={subPerson.id}
                    onClick={() => handleSelectPersonUBO(subPerson)}
                    selected={selectedPerson?.id === subPerson.id}
                    type="sub"
                  >
                    <Flex gap={16}>
                      <IconUser />

                      <Flex vertical>
                        <S.PersonName>{subPerson.name}</S.PersonName>
                        <S.PersonDetails>
                          {tDynamic('onboarding.shareholders.labelUBO')}
                        </S.PersonDetails>
                      </Flex>
                    </Flex>

                    <Flex align="center" gap={4}>
                      <S.PersonFilesCount
                        selected={selectedPerson?.id === subPerson.id}
                      >
                        {`${personStepsCompletedCount(subPerson)}/${stepsForType(person).length}`}
                      </S.PersonFilesCount>

                      {isEqualSteps(subPerson) &&
                        !hasErrorPerson(subPerson) && (
                          <S.StepNumber status="completed">
                            <IconCheck />
                          </S.StepNumber>
                        )}

                      {hasErrorPerson(subPerson) && (
                        <S.StepNumber status="errorsFound">
                          <IconWarningTransparent />
                        </S.StepNumber>
                      )}
                    </Flex>
                  </S.Person>
                ))}
              </S.PeopleWrap>
            ))}
          </S.People>
        </S.Categories>

        <S.Files>
          <Row gutter={[24, 0]}>
            <Col span={11}>
              <S.Steps>
                {stepsForType(selectedPerson).map((step, stepIndex) => (
                  <S.Step
                    key={step}
                    selected={selectedStepValue === step}
                    onClick={() => setSelectedStepValue(step)}
                  >
                    {isStepComplete(step, selectedPerson) &&
                      !hasErrorStep(step) && (
                        <S.StepNumber
                          status="completed"
                          selected={selectedStepValue === step}
                        >
                          <IconCheck />
                        </S.StepNumber>
                      )}

                    {hasErrorStep(step) && (
                      <S.StepNumber status="errorsFound">
                        <IconWarningTransparent />
                      </S.StepNumber>
                    )}

                    {!isStepComplete(step, selectedPerson) &&
                      !hasErrorStep(step) && (
                        <S.StepNumber
                          status={null}
                          selected={selectedStepValue === step}
                        >
                          {stepIndex + 1}
                        </S.StepNumber>
                      )}

                    <S.StepName>
                      <Trans
                        i18nKey={tDynamic(
                          `onboarding.kyc.${selectedType}.${step}`
                        )}
                        components={[<Text type="secondary" />]}
                      />
                    </S.StepName>
                  </S.Step>
                ))}
              </S.Steps>
            </Col>

            <Col span={13} key={selectedPerson?.id}>
              {selectedPerson && (
                <S.StepContent>
                  {selectedStepValue === 'country' && (
                    <KYCCountry person={selectedPerson!} />
                  )}

                  {selectedStepValue === 'tin' && (
                    <KYCTin person={selectedPerson!} />
                  )}

                  {selectedStepValue === 'extract' && (
                    <KYCExtract
                      person={selectedPerson!}
                      selectedType={selectedType}
                      selectedStepValue={selectedStepValue}
                    />
                  )}

                  {selectedStepValue === 'aoa' && (
                    <KYCAoa
                      person={selectedPerson!}
                      selectedType={selectedType}
                      selectedStepValue={selectedStepValue}
                    />
                  )}

                  {selectedStepValue === 'annual_accounts' && (
                    <KYCAnnualAccounts
                      person={selectedPerson!}
                      selectedType={selectedType}
                      selectedStepValue={selectedStepValue}
                    />
                  )}

                  {selectedStepValue === 'ownership_chart' && (
                    <KYCOwnershipChart
                      person={selectedPerson!}
                      selectedType={selectedType}
                      selectedStepValue={selectedStepValue}
                    />
                  )}

                  {selectedStepValue === 'funds' && (
                    <KYCFunds person={selectedPerson!} />
                  )}

                  {selectedStepValue === 'ids' && (
                    <KYCIds
                      person={selectedPerson!}
                      selectedType={selectedType}
                      selectedStepValue={selectedStepValue}
                    />
                  )}

                  {selectedStepValue === 'nationality' && (
                    <KYCNationality person={selectedPerson!} />
                  )}

                  {selectedStepValue === 'id' && (
                    <KYCBiometric person={selectedPerson!} />
                  )}

                  {selectedStepValue === 'address_proof' && (
                    <KYCAddressProof
                      person={selectedPerson!}
                      selectedType={selectedType}
                      selectedStepValue={selectedStepValue}
                    />
                  )}

                  {selectedStepValue === 'wealth' && (
                    <KYCWealth person={selectedPerson!} />
                  )}

                  {selectedStepValue === 'cv' && (
                    <KYCCv
                      person={selectedPerson!}
                      selectedType={selectedType}
                      selectedStepValue={selectedStepValue}
                    />
                  )}

                  {selectedStepValue === 'tax' && (
                    <KYCTax person={selectedPerson!} />
                  )}
                </S.StepContent>
              )}
            </Col>
          </Row>
        </S.Files>
      </Flex>

      <Flex align="center" justify="flex-end" gap={24}>
        <S.LastChance type="secondary">
          {translate('onboarding.kyc.lastChanceLabel')}
        </S.LastChance>

        <Button
          type="primary"
          loading={loading}
          disabled={hasFilesWithErrors() || notAllDataPresent()}
          onClick={handleNext}
        >
          {translate('onboarding.buttonSubmitToKyc')}
        </Button>
      </Flex>
    </Flex>
  );
};

export default OnboardingUploadDocuments;
