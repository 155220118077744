import { FC } from 'react';

import { IconAttention, IconCheck, IconEyeOff } from '@assets';
import { useTransactionsImport, useTranslate } from '@hooks';

import * as S from '../../styled';

type TransactionImportSidebarFirstStepProps = {
  isPreviewVisible: boolean;
};

const TransactionImportSidebarFirstStep: FC<
  TransactionImportSidebarFirstStepProps
> = ({ isPreviewVisible }) => {
  const { translate } = useTranslate();
  const { mapping } = useTransactionsImport();

  const isDone =
    Number(mapping.bottomRowsSkipAmount) + Number(mapping.topRowsSkipAmount) !==
    0;

  return (
    <S.StepCard gap={10} vertical>
      <S.StepCardHeader justify="flex-start" gap={8}>
        <S.Count
          isError={isPreviewVisible && !isDone}
          isDone={isDone}
          justify="center"
          align="center"
        >
          {isPreviewVisible && !isDone ? (
            <IconAttention />
          ) : isDone ? (
            <IconCheck />
          ) : (
            1
          )}
        </S.Count>
        <S.StepCardHeaderTitle>
          {translate('transactionsImport.sidebar.firstStep.title')}
        </S.StepCardHeaderTitle>
      </S.StepCardHeader>

      <S.StepCardContent gap={5} vertical>
        <S.Text>
          {translate('transactionsImport.sidebar.firstStep.description')}
        </S.Text>
        <S.List>
          <S.Li>{translate('transactionsImport.sidebar.firstStep.first')}</S.Li>
          <S.Li>
            {translate('transactionsImport.sidebar.firstStep.second')}
          </S.Li>
          <S.Li>{translate('transactionsImport.sidebar.firstStep.third')}</S.Li>
        </S.List>
      </S.StepCardContent>

      <S.StepCardFooter gap={8} align="center">
        <S.Text>
          {translate('transactionsImport.sidebar.firstStep.footer')}
        </S.Text>
        <S.Icon justify="center" align="center">
          <IconEyeOff />
        </S.Icon>
      </S.StepCardFooter>
    </S.StepCard>
  );
};

export default TransactionImportSidebarFirstStep;
