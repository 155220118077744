//
// Generated by @himenon/openapi-typescript-code-generator v0.27.4
//
// OpenApi : 3.0.0
//
//
import {
  Params$CompanyFileController_create,
  Response$CompanyFileController_create$Status$201,
  ResponseContentType$CompanyFileController_create,
} from '@api-client/generated/client';
import { useApiClientMutation } from '@hooks/useApiClientMutation';

export const useCompanyFileController_create = () =>
  useApiClientMutation<
    Params$CompanyFileController_create,
    Response$CompanyFileController_create$Status$201[ResponseContentType$CompanyFileController_create]
  >({ method: 'CompanyFileController_create' });
