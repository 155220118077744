import { FC } from 'react';

import { type ModalProps, Modal } from 'antd';

import { useTranslate } from '@hooks';

import { FilePreview } from '@entities';

import { Schemas } from '@api-client/generated/types';

type File = Schemas.TaskFile | Schemas.Document | Schemas.CompanyFile;

type FileViewerInModalProps = {
  file: (File & { name: string }) | null;
} & ModalProps;

const FileViewerInModal: FC<FileViewerInModalProps> = ({ file, ...rest }) => {
  const { translate } = useTranslate();

  return (
  <Modal
    width={557}
    title={file?.fileName || file?.name || translate('common.fileViewer.file')}
    footer={null}
    destroyOnClose
    centered
    {...rest}
  >
    {file && <FilePreview file={file} />}
  </Modal>
)};

export default FileViewerInModal;
