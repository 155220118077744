import { Schemas } from '@api-client/generated/types';

const formatCondition = (condition: Schemas.Condition): string => {
  const result = [];

  const attributeToLabel = {
    details: 'Description',
    contact_name: 'Contact',
  } as const;

  const operatorToLabel = {
    eq: 'is',
    starts_with: 'starts with',
    contains: 'contains',
  } as const;

  result.push(attributeToLabel[condition.attribute]);

  result.push(operatorToLabel[condition.operator]);

  result.push(`"${condition.value}"`);

  return result.join(' ');
};

export const conditionsToProps = (
  conditions: Schemas.Condition[]
): {
  contact?: string;
  description?: string;
} => {
  const attributeToProp = {
    details: 'description',
    contact_name: 'contact',
  } as const;

  const result: ReturnType<typeof conditionsToProps> = {};

  conditions.forEach((condition) => {
    result[attributeToProp[condition.attribute]] = formatCondition(condition);
  });

  return result;
};

export const actionsToProps: (
  actions: Schemas.Action[],
  data: {
    projects: Schemas.Project[];
    categories: Schemas.Category[];
    contacts: Schemas.Contact[];
  }
) => {
  contact?: string;
  category?: string;
  project?: string;
  fileless?: boolean;
  excludedFromReport?: boolean;
} = (actions, data) => {
  const attributeToProp = {
    assign_contact: 'contact',
    assign_category: 'category',
    assign_project: 'project',
    mark_as_fileless: 'fileless',
    exclude_from_pl_report: 'excludedFromReport',
  } as const;

  const result: ReturnType<typeof actionsToProps> = {};

  actions.forEach((action) => {
    switch (action.name) {
      case 'assign_contact':
        result[attributeToProp[action.name]] = data.contacts.find(
          (contact) => contact.id === action.value
        )?.name;
        break;

      case 'assign_category':
        result[attributeToProp[action.name]] = data.categories.find(
          (category) => category.id === action.value
        )?.name;
        break;

      case 'assign_project':
        result[attributeToProp[action.name]] = data.projects.find(
          (project) => project.id === action.value
        )?.name;
        break;

      case 'mark_as_fileless':
      case 'exclude_from_pl_report':
        result[attributeToProp[action.name]] = true;
        break;
    }
  });

  return result;
};
