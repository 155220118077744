import styled from '@emotion/styled';
import { Flex } from 'antd';

export const EmptyList = styled(Flex)`
  width: 100%;
  height: 554px;
  border-radius: 10px;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
  padding: 30px;
`;

export const EmptyPreview = styled.div`
  width: 497px;
  height: 622px;
  position: relative;
`;

export const MoreIcon = styled.div`
  color: ${({ theme }) => theme.colors.text400};

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const Actions = styled.div`
  width: 192px;
`;

export const Edit = styled(Flex)`
  height: 38px;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 8px;
  border-radius: 8px;
  font-weight: 400;

  &:hover {
    background-color: ${({ theme }) => theme.colors.bgBrightBlue};
  }
`;

export const Remove = styled(Flex)`
  height: 38px;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.error};
  padding: 8px;
  border-radius: 8px;
  font-weight: 400;

  &:hover {
    background-color: ${({ theme }) => theme.colors.bgBrightBlue};
  }
`;

export const ModalTitle = styled.span`
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
`;
