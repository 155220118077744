import styled from '@emotion/styled';

import { colors } from '@theme';

import { Upload } from 'antd';

type Status = 'inProgress' | 'completed' | 'errorsFound' | null;

type StepProps = {
  status: Status;
  selected: boolean;
};

const getBadgeColorByStatus = (status?: Status, selected?: boolean) => {
  if (status === 'completed' || status === 'inProgress' || selected) {
    return {
      bg: colors.primary,
      color: colors.white,
    };
  }

  if (status === 'errorsFound') {
    return {
      bg: colors.error,
      color: colors.white,
    };
  }

  return {
    bg: colors.strokeGrey,
    color: colors.textBlue400,
  };
};

export const Uploading = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.strokeGrey};
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  gap: 12px;
  padding: 12px 16px;

  .ui-spin {
    .ui-spin-dot {
      font-size: 24px;
    }
  }
`;

export const Dragger = styled(Upload.Dragger)`
  &.ui-upload-wrapper {
    .ui-upload {
      background-color: ${({ theme }) => theme.colors.white};
      border-color: ${({ theme }) => theme.colors.textBlue400};

      .ui-typography {
        &.ui-typography-secondary {
          font-size: 14px;
          font-weight: 600;
          margin: 0;
        }
      }

      svg {
        color: ${({ theme }) => theme.colors.textBlue400};
      }
    }
  }
`;

export const FileList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const FileItem = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.strokeGrey};
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  gap: 12px;
  padding: 12px;
`;

export const FileName = styled.a`
  width: calc(100% - 80px);
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const FileNameOverflow = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const FileItemError = styled.div`
  color: ${({ theme }) => theme.colors.error};
  font-size: 12px;
  padding-left: 12px;
`;

export const UploadMore = styled.a`
  && {
    font-size: 14px;
    font-weight: 600;
  }
`;

export const StepNumber = styled.div<Partial<StepProps>>`
  min-width: 20px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${({ selected, status }) =>
    getBadgeColorByStatus(status, selected).bg};
  color: ${({ selected, status }) =>
    getBadgeColorByStatus(status, selected).color};
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
`;
