import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  .ui-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
  }
`;

export const Inner = styled.div`
  margin-bottom: 24px;
`;

export const FormInner = styled.div`
  background-color: ${({ theme }) => theme.colors.bgGrey};
  border-radius: 8px;
  padding: 8px 0;
  margin-bottom: 16px;
`;

export const FormItem = styled.div`
  padding: 14px 20px;

  &:not(:last-child) {
    border-bottom: 2px solid ${({ theme }) => theme.colors.strokeDarkGrey};
  }
`;

export const FormLabel = styled.div`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 12px;
  padding: 8px 20px 0 20px;
`;

export const ButtonAdd = styled.a`
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 600;

  .ui-icon {
    font-size: 16px;
  }
`;

export const Total = styled.div`
  color: ${({ theme }) => theme.colors.secondary};
`;

export const ButtonRemove = styled.a`
  color: ${({ theme }) => theme.colors.secondary};
  display: flex;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
`;
