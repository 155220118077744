import { Col, DatePicker, Flex, Form, Row, Tooltip, message } from 'antd';
import dayjs from 'dayjs';
import { FC, useEffect } from 'react';

import { Schemas } from '@api-client/generated/types';
import { BankDefaultLogotype } from '@assets';
import { Card } from '@components';
import { FilterCategories, FilterProjects } from '@entities';
import { useAccount, useTranslate } from '@hooks';
import { useUpdateTransactionById } from '@hooks-api';
import { getCurrencySymbol } from '@utils';

import * as S from './styled';

type TransactionCardDetailsProps = {
  icon?: string;
  transaction: Schemas.Transaction;
  isPlDateDisabled?: boolean;
  childTransactionsCount?: number;
  onRefresh?: () => void;
  onUpdateTransaction?: (transaction: Schemas.Transaction) => void;
};

const TransactionCardDetails: FC<TransactionCardDetailsProps> = ({
  icon = BankDefaultLogotype,
  transaction,
  isPlDateDisabled,
  childTransactionsCount,
  onRefresh,
  onUpdateTransaction,
}) => {
  const { companyId } = useAccount();
  const { translate } = useTranslate();

  const [form] = Form.useForm();

  const [updateTransaction] = useUpdateTransactionById();

  useEffect(() => {
    form.setFieldsValue({
      plDate: dayjs(transaction?.plDate),
      category: transaction?.category ? transaction?.category?.id : null,
      notes: transaction?.notes,
      project: transaction?.project ? transaction?.project?.id : null,
    });
  }, [form, transaction]);

  useEffect(() => {
    if (transaction.isIgnoredForPL) {
      form.setFieldValue('plDate', undefined);
    }
  }, [form, transaction]);

  const onFinish = (key: string, value: any) => {
    updateTransaction(
      {
        parameter: {
          id: transaction.id!,
          companyId: companyId!,
        },
        requestBody: {
          [key]: value,
        },
      },
      {
        onSuccess: (res) => {
          onUpdateTransaction?.(res);
          message.success(translate('transaction.success.update'));
          onRefresh?.();
        },
      }
    );
  };

  const amount = transaction.amount;

  return (
    <Card padding={0} minHeight={1}>
      <S.Details gap={40} vertical>
        <Flex gap={16} align="flex-start">
          <S.FullWidth gap={6} vertical>
            <S.Breadcrumbs>
              {`${translate('transaction.transactionCaps')} • ${transaction.account?.name}`}
            </S.Breadcrumbs>
            <S.Title>{transaction.contact?.name}</S.Title>
            <S.Description>{transaction.details}</S.Description>
          </S.FullWidth>
          <S.LogoWrapper align="center" justify="center">
            <S.Logo src={icon} alt={transaction?.account?.name} />
          </S.LogoWrapper>
        </Flex>
        <Flex justify="space-between">
          <S.Amount>{`${amount < 0 ? '-' + getCurrencySymbol(transaction.currencyCode) + Math.abs(amount).toFixed(2) : getCurrencySymbol(transaction.currencyCode) + amount}`}</S.Amount>
          <S.Date>{dayjs(transaction.bookingDate).format('DD.MM.YYYY')}</S.Date>
        </Flex>
      </S.Details>
      <S.Content>
        <Form layout="vertical" colon={false} requiredMark={false} form={form}>
          <Row gutter={[12, 0]}>
            <Col span={8}>
              <S.Item
                name="category"
                label={translate('transaction.form.category.label')}
              >
                <FilterCategories
                  variant="outlined"
                  placeholder={translate(
                    'transaction.form.category.placeholder'
                  )}
                  onChange={(value) => onFinish('category', { id: value })}
                  withoutContainer
                  withGroups
                />
              </S.Item>
            </Col>

            <Col span={8}>
              <Tooltip
                placement="bottom"
                title={
                  isPlDateDisabled ? (
                    <S.TooltipTitleBox>
                      {translate('transaction.settings.ecludedPlDate')}
                    </S.TooltipTitleBox>
                  ) : null
                }
                destroyTooltipOnHide
              >
                <S.Item
                  name="plDate"
                  label={translate('transaction.form.plDate.label')}
                >
                  <DatePicker
                    placeholder={translate(
                      'transaction.form.plDate.placeholder'
                    )}
                    size="large"
                    picker="month"
                    format="MMM YYYY"
                    onChange={(value) => onFinish('plDate', value)}
                    disabled={!!childTransactionsCount || isPlDateDisabled}
                  />
                </S.Item>
              </Tooltip>
            </Col>

            <Col span={8}>
              <S.Item
                name="project"
                label={translate('transaction.form.project.label')}
              >
                <FilterProjects
                  variant="outlined"
                  placeholder={translate(
                    'transaction.form.project.placeholder'
                  )}
                  onChange={(value) => onFinish('project', { id: value })}
                  withoutContainer
                />
              </S.Item>
            </Col>
          </Row>
        </Form>
      </S.Content>
    </Card>
  );
};

export default TransactionCardDetails;
