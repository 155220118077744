import { FC } from 'react';
import { Modal, type ModalProps } from 'antd';

import { EmptyDocumentPreivew, FileViewer } from '@entities';
import { checkingForSupportedFile } from '@utils';

import { Schemas } from '@api-client/generated/types';

import * as S from './styled';

type Document = Schemas.Document;

type PreviewModalProps = {
  open: boolean;
  onCancel: () => void;
  file: Document;
  width?: string;
  height?: string;
  modalWidth?: string | number;
} & ModalProps;

const PreviewFileModal: FC<PreviewModalProps> = ({
  open,
  onCancel,
  file,
  width = '38vw',
  height = '80vh',
  modalWidth = 'auto',
  ...rest
}) => {
  if (!open) {
    return null;
  }

  const isPdf = file?.mimeType?.toLowerCase()?.includes('pdf');

  return (
    <Modal open={open} width={modalWidth} onCancel={onCancel} {...rest}>
      <S.Preview>
        {checkingForSupportedFile(file) ? (
          <>
            {isPdf ? (
              <FileViewer
                width={width}
                height={height}
                selectedFile={file as Document}
              />
            ) : (
              <S.Image alt="image" src={file.url} />
            )}
          </>
        ) : (
          <S.Unsupported>
            <EmptyDocumentPreivew unsupported />
          </S.Unsupported>
        )}
      </S.Preview>
    </Modal>
  );
};

export default PreviewFileModal;
