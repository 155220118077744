import { FC } from 'react';

import { IconTaxes } from '@assets';
import { useTranslate } from '@hooks';

import * as S from './styled';

const TaxesEmpty: FC = () => {
  const { translate } = useTranslate();

  return (
    <S.Container>
      <S.Icon>
        <IconTaxes />
      </S.Icon>

      <S.Title>{translate('taxes.empty.title')}</S.Title>
      <S.Details>{translate('taxes.empty.details')}</S.Details>
    </S.Container>
  );
};

export default TaxesEmpty;
