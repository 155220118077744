import styled from '@emotion/styled';
import { Button, Flex } from 'antd';

export const Wrapper = styled(Flex)`
  padding: 32px 0;
`

export const CreditCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: ${({theme}) => theme.colors.text200};
  color: ${({theme}) => theme.colors.white};
`;

export const Title = styled.span`
  font-size: 24px;
  font-weight: 600;
`

export const Description = styled.span`
  font-size: 16px;
`

export const AddButton = styled(Button)`
  width: 176px;
  display: flex;
  justify-content: space-between;
`

