import { Schemas } from '@api-client/generated/types';
import { useTranslate } from '@hooks';
import { Flex, Typography } from 'antd';
import { FC, PropsWithChildren } from 'react';
import { KYCFiles } from '@entities';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

type KYCAoaProps = {
  person: Schemas.Person | Schemas.UBO;
  selectedType: string | null;
  selectedStepValue: string;
};

const { Paragraph, Title } = Typography;

const KYCAoa: FC<PropsWithChildren<KYCAoaProps>> = ({
  person,
  selectedType,
  selectedStepValue,
}) => {
  const { translate } = useTranslate();

  return (
    <Flex gap={20} vertical>
      <div>
        <Title level={5}>{translate('onboarding.kyc.legal.aoa')}</Title>

        <Paragraph type="secondary">
          <Trans
            i18nKey={translate('onboarding.kyc.legalDetails.aoa')}
            components={[
              <br />,
              <strong />,
              <Link
                to="https://www.lbr.lu/mjrcs-resa/jsp/IndexActionNotSecured.action"
                target="_blank"
              />,
            ]}
          />
        </Paragraph>

      </div>

      <KYCFiles
        person={person}
        selectedType={selectedType}
        selectedStepValue={selectedStepValue}
      />
    </Flex>
  );
};

export default KYCAoa;
