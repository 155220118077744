import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { Layout, type LayoutProps } from 'antd';

import { mediaWidth } from '@styles/mediaWidth';

type ContentProps = {
  width: number | string;
  centering?: boolean;
};

type InnerProps = {
  withPadding: boolean;
};

export const Container = styled.div``;

export const Header = styled(Layout.Header)<LayoutProps>`
  line-height: 1.2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 3;

  ${mediaWidth.tablet(css`
    height: 56px;
    padding: 0 16px;
  `)};
`;

export const HeaderLeft = styled.div`
  width: 92px;
`;

export const Inner = styled.div<InnerProps>`
  padding: ${({ withPadding }) => (withPadding ? '40px' : 0)};

  ${mediaWidth.tablet(css`
    padding: 16px;
  `)};
`;

export const Content = styled.div<ContentProps>`
  width: 100%;
  max-width: ${({ width }) =>
    typeof width === 'string' ? width : `${width}px`};
  margin: ${({ centering }) => (centering ? '0 auto' : 0)};
`;
