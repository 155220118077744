import { Button, Flex } from 'antd';
import { FC, useEffect, useState } from 'react';

import { IconPlus } from '@assets';
import { PageMeta, TeamMembers } from '@entities';
import { useAccount, useTeamMembers, useTranslate } from '@hooks';
import { useGetCompanyInvitesList, useGetUsersList } from '@hooks-api';

import * as S from './styled';

const SettingsTeamMembers: FC = () => {
  const { translate } = useTranslate();

  const {
    companyInvites,
    users: usersList,
    initialInvites,
    initialUsers,
  } = useTeamMembers();
  const { companyId } = useAccount();

  const { users, loading: usersLoading } = useGetUsersList({
    params: {
      companyId: companyId!,
    },
  });

  const { invites, loading: invitesLoading } = useGetCompanyInvitesList({
    params: {
      companyId: companyId!,
    },
  });

  const [isAccessModalOpen, setIsAccessModalOpen] = useState(false);

  useEffect(() => {
    if (invites.length) {
      initialInvites(invites);
    }
  }, [initialInvites, invites]);

  useEffect(() => {
    if (users.length) {
      initialUsers(users);
    }
  }, [initialUsers, users]);

  useEffect(() => {
    return () => {
      initialInvites([]);
      initialUsers([]);
    };
  }, [initialInvites, initialUsers]);

  return (
    <Flex gap={32} vertical>
      <PageMeta title={translate('settings.title')} />

      <S.Container>
        <Flex gap={24} vertical>
          <Flex justify="space-between">
            <S.Title>{translate('settings.teamMembers.title')}</S.Title>
            <Button
              size="small"
              data-testid="invite-button"
              onClick={() => setIsAccessModalOpen(true)}
              icon={<IconPlus />}
            >
              {translate('settings.teamMembers.inviteMembers')}
            </Button>
          </Flex>

          <TeamMembers
            users={usersList}
            invites={companyInvites}
            loading={usersLoading && invitesLoading}
            isAccessModalOpen={isAccessModalOpen}
            onOpenAccessModal={setIsAccessModalOpen}
          />
        </Flex>
      </S.Container>
    </Flex>
  );
};

export default SettingsTeamMembers;
