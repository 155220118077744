import { FC, ReactNode, PropsWithChildren } from 'react';

import * as S from './styled';

type PublicCardFormProps = {
  title?: string;
  subTitle?: ReactNode;
  footer?: ReactNode;
};

const PublicCardForm: FC<PropsWithChildren<PublicCardFormProps>> = ({
  title,
  subTitle,
  children,
  footer,
}) => (
  <S.Inner>
    <S.Header>
      <S.Logo>EasyBiz</S.Logo>

      <S.Title>{title}</S.Title>
      <S.Subtitle>{subTitle}</S.Subtitle>
    </S.Header>

    <S.Form>{children}</S.Form>

    {footer && <S.Footer>{footer}</S.Footer>}
  </S.Inner>
);

export default PublicCardForm;
