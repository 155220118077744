import { Button, Checkbox, Flex, Form, Input } from 'antd';
import { FC, useState } from 'react';

import { useTranslate } from '@hooks';

import { Schemas } from '@api-client/generated/types';

import * as S from './styled';

export type TeamMembersAccessModalProps = {
  open: boolean;
  isNew: boolean;
  editableUser: Schemas.User | null;
  onChangeEditableUser: (user: Schemas.User | null) => void;
  onDeleteEditableUser: (user: Schemas.User) => void;
  onSubmit: (values: any) => void;
  onClose: () => void;
};

const TeamMembersAccessModal: FC<TeamMembersAccessModalProps> = ({
  open,
  isNew,
  onChangeEditableUser,
  onDeleteEditableUser,
  editableUser,
  onSubmit,
  onClose,
}) => {
  const [form] = Form.useForm();
  const [email, setEmail] = useState('');

  const { translate } = useTranslate();

  const handleSubmit = () => {
    onSubmit(
      isNew
        ? { accessRights: form.getFieldsValue(), email }
        : form.getFieldsValue()
    );
  };

  const handleClose = () => {
    onChangeEditableUser(null);
    onClose();
  };

  return (
    <S.AccessModal
      open={open}
      width={630}
      onCancel={handleClose}
      footer={
        <Flex justify={isNew ? 'flex-end' : 'space-between'}>
          {!isNew && (
            <S.RemoveButton
              type="default"
              onClick={() => onDeleteEditableUser(editableUser!)}
              danger
            >
              {translate('settings.teamMembers.modal.removeFromTeam')}
            </S.RemoveButton>
          )}
          <Flex gap={12}>
            <Button type="default" onClick={handleClose}>
              {translate('settings.security.twoStep.buttonCancel')}
            </Button>
            <Button
              type="primary"
              data-testid="submit-modal-access-button"
              disabled={isNew && !email}
              onClick={handleSubmit}
            >
              {translate(
                isNew
                  ? 'settings.teamMembers.users.buttonInvite'
                  : 'settings.personal.buttonSave'
              )}
            </Button>
          </Flex>
        </Flex>
      }
      closable
    >
      <S.ContentWrapper gap={32} data-testid="access-modal" vertical>
        <S.FormTitle>
          {translate(
            isNew
              ? 'settings.teamMembers.setNewTeamMember'
              : 'settings.teamMembers.editUserAccess'
          )}
        </S.FormTitle>

        {isNew ? (
          <S.EmailContainer gap={4} vertical>
            <S.Label>{translate('settings.teamMembers.emailAddress')}</S.Label>
            <Input
              size="large"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </S.EmailContainer>
        ) : (
          <S.Container
            justify="flex-start"
            align="flex-start"
            gap={2}
            data-testid="modal-user-name-and-email"
            isbordered="true"
            vertical
          >
            <S.Name>{editableUser?.fullName}</S.Name>
            <S.Email>{editableUser?.email}</S.Email>
          </S.Container>
        )}

        <Form
          form={form}
          layout="vertical"
          initialValues={{
            transactions: !!editableUser?.accessRights?.transactions,
            companyData: !!editableUser?.accessRights?.companyData,
            userManagement: !!editableUser?.accessRights?.userManagement,
            reports: !!editableUser?.accessRights?.reports,
            payroll: !!editableUser?.accessRights?.payroll,
            incomesExpences: !!editableUser?.accessRights?.incomesExpences,
          }}
        >
          <S.Item name="transactions" valuePropName="checked">
            <Flex gap={12} align="flex-start">
              <Checkbox
                data-testid="transactions-checkbox"
                defaultChecked={!!editableUser?.accessRights?.transactions}
              />
              <Flex gap={6} vertical>
                <S.FormItemTitle>
                  {translate('settings.teamMembers.users.transactions')}
                </S.FormItemTitle>
                <span>
                  {translate('settings.teamMembers.popup.transactions')}
                </span>
              </Flex>
            </Flex>
          </S.Item>

          <S.Item name="companyData" valuePropName="checked">
            <Flex gap={12} align="flex-start">
              <Checkbox
                data-testid="companyData-checkbox"
                defaultChecked={!!editableUser?.accessRights?.companyData}
              />
              <Flex gap={6} vertical>
                <S.FormItemTitle>
                  {translate('settings.teamMembers.users.companyData')}
                </S.FormItemTitle>
                <span>
                  {translate('settings.teamMembers.popup.companyData')}
                </span>
              </Flex>
            </Flex>
          </S.Item>

          <S.Item name="userManagement" valuePropName="checked">
            <Flex gap={12} align="flex-start">
              <Checkbox
                data-testid="userManagement-checkbox"
                defaultChecked={!!editableUser?.accessRights?.userManagement}
              />
              <Flex gap={6} vertical>
                <S.FormItemTitle>
                  {translate('settings.teamMembers.users.userManagement')}
                </S.FormItemTitle>
                <span>
                  {translate('settings.teamMembers.popup.userManagement')}
                </span>
              </Flex>
            </Flex>
          </S.Item>

          <S.Item name="reports" valuePropName="checked">
            <Flex gap={12} align="flex-start">
              <Checkbox
                data-testid="reports-checkbox"
                defaultChecked={!!editableUser?.accessRights?.reports}
              />
              <Flex gap={6} vertical>
                <S.FormItemTitle>
                  {translate('settings.teamMembers.users.reports')}
                </S.FormItemTitle>
                <span>{translate('settings.teamMembers.popup.reports')}</span>
              </Flex>
            </Flex>
          </S.Item>

          <S.Item name="payroll" valuePropName="checked">
            <Flex gap={12} align="flex-start">
              <Checkbox
                data-testid="payroll-checkbox"
                defaultChecked={!!editableUser?.accessRights?.payroll}
              />
              <Flex gap={6} vertical>
                <S.FormItemTitle>
                  {translate('settings.teamMembers.users.payroll')}
                </S.FormItemTitle>
                <span>{translate('settings.teamMembers.popup.payroll')}</span>
              </Flex>
            </Flex>
          </S.Item>

          <S.Item name="incomesExpences" valuePropName="checked">
            <Flex gap={12} align="flex-start">
              <Checkbox
                data-testid="incomesExpences-checkbox"
                defaultChecked={!!editableUser?.accessRights?.incomesExpences}
              />
              <Flex gap={6} vertical>
                <S.FormItemTitle>
                  {translate('settings.teamMembers.users.incomesExpences')}
                </S.FormItemTitle>
                <span>
                  {translate('settings.teamMembers.popup.incomesExpences')}
                </span>
              </Flex>
            </Flex>
          </S.Item>
        </Form>
      </S.ContentWrapper>
    </S.AccessModal>
  );
};

export default TeamMembersAccessModal;
