export const getOriginalPathname = (pathname: string) => {
  const str = pathname.split('/');

  return str.length ? `/${str.at(1)}` : pathname;
};

export const historyReplace = (url: string) =>
  window.history.replaceState(null, '', url);

export const getNormalizePathname = (pathname: string): string =>
  pathname.replace('/', '');
