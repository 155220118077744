import { FC } from 'react';

import { useTranslate } from '@hooks';
import { LocalePaths } from '@locales';
import { colors } from '@theme';

import * as S from './styled';

type Status =
  | 'primary'
  | 'inProgress'
  | 'pending'
  | 'connected'
  | 'completed'
  | 'disconnected'
  | 'active'
  | 'inactive'
  | 'manually'
  | 'automatically'
  | 'error'
  | 'errorsFound'
  | 'onReview'
  | 'finished';

type StatusTagProps = {
  label?: string | null;
  status?: Status | string | null;
  small?: boolean;
};

type ParamsByStatus = {
  label?: string | null;
  bgColor: string;
  textColor: string;
};

const StatusTag: FC<StatusTagProps> = ({ small, label, status }) => {
  const { translate } = useTranslate();

  if (!status) return;

  const getParamsByStatus = (status: Status): ParamsByStatus => {
    switch (status) {
      case 'primary':
      case 'inProgress':
      case 'onReview':
        return {
          label: translate(`common.statuses.${status}` as LocalePaths),
          bgColor: colors.bgBrightBlue,
          textColor: colors.primary,
        };

      case 'inactive':
      case 'pending':
        return {
          label: translate(`common.statuses.${status}` as LocalePaths),
          bgColor: colors.badgeInfo,
          textColor: colors.iconLineYellow,
        };

      case 'connected':
      case 'automatically':
      case 'completed':
      case 'active':
        return {
          label: translate(`common.statuses.${status}` as LocalePaths),
          bgColor: colors.badgeSuccess,
          textColor: colors.success,
        };

      case 'finished':
        return {
          label: translate(`common.statuses.${status}` as LocalePaths),
          bgColor: colors.bgGrey,
          textColor: colors.text400,
        };
      case 'manually':
        return {
          label: translate(`common.statuses.${status}` as LocalePaths),
          bgColor: colors.badgeGrey,
          textColor: colors.text200,
        };

      case 'error':
      case 'errorsFound':
      case 'disconnected':
        return {
          label: translate(`common.statuses.${status}` as LocalePaths),
          bgColor: colors.bgWarning,
          textColor: colors.error,
        };

      default:
        return {
          label,
          bgColor: colors.bgGrey,
          textColor: colors.textBlue400,
        };
    }
  };

  const params = getParamsByStatus(status as Status);

  return (
    <S.Status
      small={small}
      bgColor={params.bgColor}
      textColor={params.textColor}
    >
      {label || params.label}
    </S.Status>
  );
};

export default StatusTag;
