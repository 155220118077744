import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Flex } from 'antd';

import { IconClose } from '@assets';
import { PaymentDocumentType } from '@entities';

import { Schemas } from '@api-client/generated/types';

import * as S from './styled';

type IncomeInvoiceErrorProps = {
  type: PaymentDocumentType;
  selectedId?: string;
  file: Schemas.Document;
  details?: string;
  onSelectedInvoice?: (id: string) => void;
  onDelete?: () => void;
};

const PaymentDocumentInvoiceError: FC<IncomeInvoiceErrorProps> = ({
  type,
  selectedId,
  file,
  details,
  onDelete,
  onSelectedInvoice,
}) => {
  const navigate = useNavigate();

  const handleSelectInvoice = (id: string) => {
    navigate(`/${type}/${id}`);
    onSelectedInvoice?.(id);
  };

  return (
    <S.Invoice
      align="center"
      justify="space-between"
      selected={selectedId === file.id}
      onClick={() => handleSelectInvoice?.(file.id)}
    >
      <Flex vertical>
        <S.Name>{file.name}</S.Name>
        {details && <S.Details>{details}</S.Details>}
      </Flex>

      <Button
        type="text"
        icon={<IconClose />}
        size="small"
        onClick={onDelete}
      />
    </S.Invoice>
  );
};

export default PaymentDocumentInvoiceError;
