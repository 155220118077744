import { FC } from 'react';

import { type InputProps } from 'antd';

import * as S from './styled';

const InputBorderless: FC<InputProps> = ({ ...rest }) => (
  <S.InputBorderless variant="borderless" size="large" {...rest} />
);

export default InputBorderless;
