import styled from '@emotion/styled';

export const Container = styled.div`
  width: 100%;
  height: 600px;
  background-color: ${({ theme }) => theme.colors.bgGrey};
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 30px;
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 8px;
`;

export const Description = styled.div`
  color: ${({ theme }) => theme.colors.text400};
  font-size: 12px;
  margin-bottom: 24px;
`;
