import { Button, Flex, Modal, Tooltip, Upload, message } from 'antd';
import _ from 'lodash';
import { FC, useEffect, useMemo, useState } from 'react';
import { DropTargetMonitor, useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useDocumentController_create } from '@api-client/generated/DocumentController/create';
import { useDocumentController_delete } from '@api-client/generated/DocumentController/delete';
import { useDocumentController_findAll } from '@api-client/generated/DocumentController/findAll';
import { useDocumentController_findOneById } from '@api-client/generated/DocumentController/findOneById';
import { useDocumentController_updateOneById } from '@api-client/generated/DocumentController/updateOneById';
import { Params$DocumentController_findAll } from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';
import { IconPlus, IconTrash, IconUpload } from '@assets';
import { Loader } from '@components';
import { PAYMENT_DOCUMENT_VALID_EXTENSIONS } from '@constants';
import {
  FilterConstructor,
  FilterConstructorDrawer,
  type FilterControl,
  PageMeta,
  PaymentDocumentDetailsForm,
  PaymentDocumentDropFile,
  PaymentDocumentDropFileOverlay,
  PaymentDocumentError,
  PaymentDocumentFileView,
  PaymentDocumentInvoices,
  PaymentDocumentNoInvoices,
  PaymentDocumentPotentialDuplicate,
  PaymentDocumentSkeleton,
} from '@entities';
import { useAccount, useTranslate } from '@hooks';
import useSocketClient from '@hooks/useSocketClient';
import { DATE_ISO_FORMAT, featureFlag, getDateDefault } from '@utils';

import * as S from './styled';

type FilesWithMeta = Schemas.PaginatedDocumentResponse;
type PaymentDocumentFile = Schemas.Document;
type FilterParameter = Params$DocumentController_findAll['parameter'];

const isFeatureInvoiceGeneratorActive = featureFlag('INVOICE_GENERATOR');

const initialFilesState: FilesWithMeta = {
  records: [],
  metadata: {} as FilesWithMeta['metadata'],
};

export type PaymentDocumentType = 'income' | 'expenses';

type PaymentDocumentProps = {
  type: PaymentDocumentType;
};

const filterControls = ['contactIds', 'status', 'onlyWithoutTransactions'];

const PaymentDocument: FC<PaymentDocumentProps> = ({ type }) => {
  const paymentDocumentQueryType =
    type === 'income' ? 'income_document' : 'expence_document';
  const paymentDocumentTranslateKey = type === 'income' ? 'income' : 'expenses';

  const { id: paymentDocumentId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [modal, contextHolder] = Modal.useModal();

  const { translate } = useTranslate();
  const { companyId } = useAccount();

  const [selectedTabKey, setSelectedTabKey] = useState('all');
  const [selectedFileId, setSelectedFileId] = useState<string | null>(null);

  const [allFiles, setAllFiles] = useState<FilesWithMeta>(initialFilesState);
  const [reviewFiles, setReviewFiles] =
    useState<FilesWithMeta>(initialFilesState);
  const [errorFiles, setErrorFiles] =
    useState<FilesWithMeta>(initialFilesState);

  const [currentPageForAll, setCurrentPageForAll] = useState(0);
  const [currentPageForReview, setCurrentPageForReview] = useState(0);
  const [currentPageForError, setCurrentPageForError] = useState(0);

  const [filterOptions, setFilterOptions] = useState<Record<string, unknown>>(
    {}
  );
  const [filterDrawerOptions, setFilterDrawerOptions] = useState<
    Record<string, unknown> | string[] | null
  >(null);

  const { socket } = useSocketClient({
    namespace: 'documents',
  });

  const commonRequestParams = {
    companyId: companyId!,
    perPage: 10,
    type: paymentDocumentQueryType,
    ...filterOptions,
  } as FilterParameter;

  const { loading: loadingAll, isFetching } = useDocumentController_findAll({
    params: {
      page: currentPageForAll + 1,
      ...commonRequestParams,
    },
    config: {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (response) => {
        setAllFiles((prevState) => ({
          records: currentPageForAll
            ? [...prevState.records, ...response.records]
            : response.records,
          metadata: response.metadata,
        }));
      },
    },
  });

  const { loading: loadingReview } = useDocumentController_findAll({
    params: {
      page: currentPageForReview + 1,
      toReview: true,
      ...commonRequestParams,
    },
    config: {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (response) =>
        setReviewFiles((prevState) => ({
          records: currentPageForReview
            ? [...prevState.records, ...response.records]
            : response.records,
          metadata: response.metadata,
        })),
    },
  });

  const { loading: loadingError } = useDocumentController_findAll({
    params: {
      page: currentPageForError + 1,
      withErrors: true,
      ...commonRequestParams,
    },
    config: {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (response) =>
        setErrorFiles((prevState) => ({
          records: currentPageForError
            ? [...prevState.records, ...response.records]
            : response.records,
          metadata: response.metadata,
        })),
    },
  });

  const {
    data: fileDetails,
    loading: isLoadingFile,
    refetch: refetchFile,
  } = useDocumentController_findOneById({
    params: {
      companyId: companyId!,
      id: selectedFileId!,
    },
    config: {
      enabled: !!selectedFileId,
      refetchOnWindowFocus: false,
    },
  });

  const [createDocument] = useDocumentController_create();
  const [deleteDocument] = useDocumentController_delete();
  const [updateDocument] = useDocumentController_updateOneById();

  useEffect(() => {
    socket.on('document:processed', (response) => {
      setAllFiles((prevState) => ({
        ...prevState,
        records: prevState.records.map((record) => {
          if (record.id === response.data.id) {
            return {
              ...record,
              ...response.data,
            };
          }

          return record;
        }),
      }));

      refetchFile();
    });
  }, [refetchFile, socket]);

  useEffect(() => {
    if (paymentDocumentId) {
      setSelectedFileId(paymentDocumentId);
    }
  }, [location, paymentDocumentId]);

  useEffect(() => {
    handleResetPagination();
  }, [filterOptions]);

  const handleResetPagination = () => {
    setCurrentPageForAll(0);
    setCurrentPageForReview(0);
    setCurrentPageForError(0);
  };

  const handleUpdateFiles = (
    files: FilesWithMeta,
    file: PaymentDocumentFile
  ) => ({
    records: [file, ...files.records],
    metadata: {
      ...files.metadata,
      totalRecords: files.metadata.totalRecords + 1,
    },
  });

  const handleCompareFiles = (
    files: FilesWithMeta,
    file: PaymentDocumentFile
  ) => ({
    ...files,
    records: files.records.map((record) => {
      if (record.id === file.id) {
        return {
          ...record,
          ...file,
        };
      }

      return record;
    }),
  });

  const handleFilterFiles = (files: FilesWithMeta, id: string) => ({
    records: files.records.filter((item) => item.id !== id),
    metadata: {
      ...files.metadata,
      totalRecords: files.metadata.totalRecords - 1,
    },
  });

  const handleBeforeUpload = (file: File) => {
    const extension = `.${file.name.split('.').pop()}`.toLowerCase();

    if (!PAYMENT_DOCUMENT_VALID_EXTENSIONS.includes(extension)) {
      message.error(
        translate(`${paymentDocumentTranslateKey}.errorExtensionsUpload`, {
          formats: PAYMENT_DOCUMENT_VALID_EXTENSIONS.join(', '),
          extension,
        })
      );

      return Upload.LIST_IGNORE;
    }

    return true;
  };

  const handleImportInvoice = (options: any) => {
    const formData = new FormData();

    formData.append('file', options.file);
    formData.append('name', options.file.name);
    formData.append('type', paymentDocumentQueryType);

    createDocument(
      {
        parameter: {
          companyId: companyId!,
        },
        requestBody: formData as any,
      },
      {
        onSuccess: (file) => {
          handleUpdateAllFiles(file);
        },
      }
    );
  };

  const handleUpdateAllFiles = (file: PaymentDocumentFile) => {
    setAllFiles((prevState) => handleUpdateFiles(prevState, file));

    setReviewFiles((prevState) => handleUpdateFiles(prevState, file));
  };

  const handleUpdateSelectedFile = (file: PaymentDocumentFile | null) => {
    if (file) {
      setAllFiles((prevState) => handleCompareFiles(prevState, file));

      if (reviewFiles.records.map((record) => record.id).includes(file.id)) {
        setReviewFiles((prevState) => handleFilterFiles(prevState, file.id));
      }

      refetchFile();
    }
  };

  const handleDeleteFile = (id?: string) => {
    if (id) {
      modal.confirm({
        title: translate(`${paymentDocumentTranslateKey}.messageRemove`),
        onOk: () =>
          deleteDocument(
            {
              parameter: {
                companyId: companyId!,
                id,
              },
            },
            {
              onSuccess: () => {
                setAllFiles((prevState) => handleFilterFiles(prevState, id));

                if (
                  reviewFiles.records.map((record) => record.id).includes(id)
                ) {
                  setReviewFiles((prevState) =>
                    handleFilterFiles(prevState, id)
                  );
                }

                if (errorFiles.metadata.totalRecords) {
                  setErrorFiles((prevState) =>
                    handleFilterFiles(prevState, id)
                  );
                }

                setSelectedFileId(null);
              },
            }
          ),
        autoFocusButton: null,
        okButtonProps: {
          size: 'small',
        },
        cancelButtonProps: {
          size: 'small',
        },
      });
    }
  };

  const handleUpdateFile = (file: PaymentDocumentFile) => {
    const formData = new FormData();
    const { documentMetadata } = file;

    if (documentMetadata) {
      if (documentMetadata.status) {
        formData.append(
          'documentMetadata[status]',
          file.documentMetadata.status!
        );
      }

      if (documentMetadata.number) {
        formData.append(
          'documentMetadata[number]',
          file.documentMetadata.number!
        );
      }

      if (documentMetadata.description) {
        formData.append(
          'documentMetadata[description]',
          file.documentMetadata.description!
        );
      }

      if (documentMetadata.amount) {
        formData.append(
          'documentMetadata[amount]',
          String(file.documentMetadata.amount)
        );
      }

      if (documentMetadata.currency) {
        formData.append(
          'documentMetadata[currency]',
          file.documentMetadata.currency!
        );
      }

      if (documentMetadata.issueDate) {
        formData.append(
          'documentMetadata[issueDate]',
          getDateDefault(file.documentMetadata.issueDate!, DATE_ISO_FORMAT)
        );
      }

      if (documentMetadata.dueDate) {
        formData.append(
          'documentMetadata[dueDate]',
          getDateDefault(file.documentMetadata.dueDate!, DATE_ISO_FORMAT)
        );
      }
    }

    if (file.contact) {
      formData.append('contactId', file.contact.id);
    }

    formData.append('documentMetadata[ignoreDuplicate]', JSON.stringify(true));

    updateDocument(
      {
        parameter: {
          companyId: companyId!,
          id: file.id,
        },
        requestBody: formData as any,
      },
      {
        onSuccess: (updatedFile) => {
          handleUpdateSelectedFile(updatedFile);
        },
      }
    );
  };

  const handleChangeTab = (key: string) => {
    setSelectedFileId(null);
    setSelectedTabKey(key);
  };

  const handleNoFilter = () => !_.isEmpty(filterOptions);

  const handleCreateInvoice = () => {
    const formData = new FormData();

    formData.append('type', 'income_document');

    createDocument(
      {
        parameter: {
          companyId: companyId!,
        },
        requestBody: formData as any,
      },
      {
        onSuccess: (document) => {
          navigate(`/income/new/${document.id}`);
        },
      }
    );
  };

  const tabItems = [
    {
      key: 'all',
      label: translate(`${paymentDocumentTranslateKey}.tabs.allInvoices`),
      children:
        !allFiles.records.length && handleNoFilter() ? (
          <PaymentDocumentNoInvoices type={paymentDocumentTranslateKey} />
        ) : (
          <PaymentDocumentInvoices
            type={paymentDocumentTranslateKey}
            page={currentPageForAll}
            isLoading={loadingAll}
            selectedId={fileDetails?.id}
            files={allFiles}
            onSelectedInvoice={setSelectedFileId}
            onLoadMore={() =>
              setCurrentPageForAll((prevState) => prevState + 1)
            }
            onDelete={handleDeleteFile}
          />
        ),
    },
    {
      key: 'toReview',
      label: translate(`${paymentDocumentTranslateKey}.tabs.toReview`, {
        count: reviewFiles.metadata.totalRecords,
      }),
      children:
        !reviewFiles.records.length && handleNoFilter() ? (
          <PaymentDocumentNoInvoices type={paymentDocumentTranslateKey} />
        ) : (
          <PaymentDocumentInvoices
            type={paymentDocumentTranslateKey}
            page={currentPageForReview}
            isLoading={loadingReview}
            selectedId={fileDetails?.id}
            files={reviewFiles}
            onSelectedInvoice={setSelectedFileId}
            onLoadMore={() =>
              setCurrentPageForReview((prevState) => prevState + 1)
            }
            onDelete={handleDeleteFile}
          />
        ),
    },
  ];

  if (errorFiles.metadata.totalRecords) {
    tabItems.push({
      key: 'error',
      label: translate(`${paymentDocumentTranslateKey}.tabs.error`, {
        count: errorFiles.metadata.totalRecords,
      }),
      children:
        !errorFiles.records.length && handleNoFilter() ? (
          <PaymentDocumentNoInvoices type={paymentDocumentTranslateKey} />
        ) : (
          <PaymentDocumentInvoices
            type={paymentDocumentTranslateKey}
            page={currentPageForError}
            isLoading={loadingError}
            selectedId={fileDetails?.id}
            files={errorFiles}
            onSelectedInvoice={setSelectedFileId}
            onLoadMore={() =>
              setCurrentPageForError((prevState) => prevState + 1)
            }
            onDelete={handleDeleteFile}
          />
        ),
    });
  } else {
    const tabKeys = tabItems.map((item) => item.key);

    if (tabKeys.includes('error')) {
      tabItems.pop();
    }
  }

  const [{ canDrop }, dropRef] = useDrop(
    {
      accept: [NativeTypes.FILE],
      collect: (monitor: DropTargetMonitor) => ({
        canDrop: monitor.canDrop(),
      }),
    },
    []
  );

  const controls = useMemo<{ left: FilterControl[] }>(
    () => ({
      left: [
        {
          type: 'search',
          formName: 'term',
          params: {
            value: paymentDocumentId,
          },
        },
        { type: 'divider' },
        {
          type: 'range-picker',
          formName: 'date',
        },
      ],
    }),
    [paymentDocumentId]
  );

  return (
    <Flex gap={24} vertical>
      <PageMeta title={translate(`${paymentDocumentTranslateKey}.title`)} />

      {contextHolder}

      <S.Title isincome={type === 'income' ? 'true' : ''}>
        {translate(`${paymentDocumentTranslateKey}.title`)}
      </S.Title>

      <FilterConstructor<PaymentDocumentFile>
        externalParameters={filterDrawerOptions}
        controls={controls}
        filterControls={filterControls}
        actions={
          <Flex align="center" gap={16}>
            <Upload
              accept={PAYMENT_DOCUMENT_VALID_EXTENSIONS.join(',')}
              beforeUpload={handleBeforeUpload}
              customRequest={handleImportInvoice}
              showUploadList={false}
              multiple
            >
              <Button icon={<IconUpload />}>
                {translate(`${paymentDocumentTranslateKey}.buttonImport`)}
              </Button>
            </Upload>

            {isFeatureInvoiceGeneratorActive && type === 'income' && (
              <Button
                type="primary"
                icon={<IconPlus />}
                onClick={handleCreateInvoice}
              >
                {translate('income.buttonCreateInvoice')}
              </Button>
            )}
          </Flex>
        }
        onChange={setFilterOptions}
        withSearchParams
      />

      <S.Container ref={dropRef}>
        {canDrop && (
          <PaymentDocumentDropFileOverlay
            type={paymentDocumentTranslateKey}
            onUpdate={handleUpdateAllFiles}
          />
        )}

        <S.Content hidden={canDrop} gap={32}>
          <S.WrapperLeft>
            {!allFiles.records.length && !handleNoFilter() ? (
              <>
                {loadingAll && (
                  <S.Loader>
                    <Loader />
                  </S.Loader>
                )}

                {!isFetching && (
                  <PaymentDocumentDropFile
                    type={paymentDocumentTranslateKey}
                    onUpdate={handleUpdateAllFiles}
                  />
                )}
              </>
            ) : (
              <S.TabsInvoices
                activeKey={selectedTabKey}
                size="large"
                items={tabItems}
                onChange={handleChangeTab}
                tabBarExtraContent={
                  <FilterConstructorDrawer
                    initialParams={filterOptions}
                    controls={[
                      {
                        type: 'list-contacts',
                        formName: 'contactIds',
                        label: translate(
                          `${paymentDocumentTranslateKey}.filter.contacts.label`
                        ),
                      },
                      {
                        type: 'switch',
                        formName: 'onlyWithoutTransactions',
                        label: translate(
                          `${paymentDocumentTranslateKey}.filter.showInvoices`
                        ),
                      },
                    ]}
                    buttonParams={{
                      type: 'text',
                      size: 'small',
                    }}
                    onSubmit={setFilterDrawerOptions}
                  />
                }
              />
            )}
          </S.WrapperLeft>

          <S.WrapperRight>
            {fileDetails?.hasError ? (
              <PaymentDocumentError
                type={paymentDocumentTranslateKey}
                onDelete={() => handleDeleteFile(fileDetails?.id)}
              />
            ) : (
              <>
                {fileDetails && !fileDetails?.isRecognitionCompleted ? (
                  <>
                    <PaymentDocumentSkeleton />
                    <S.RecognitionWrapper
                      align="center"
                      justify="center"
                      gap={6}
                      vertical
                    >
                      <S.RecognitionTitle>
                        {translate('income.detailsProcessed.title')}
                      </S.RecognitionTitle>
                      <S.RecognitionDescription>
                        {translate('income.detailsProcessed.description')}
                      </S.RecognitionDescription>
                    </S.RecognitionWrapper>
                  </>
                ) : (
                  <>
                    {fileDetails ? (
                      <>
                        <PaymentDocumentFileView file={fileDetails} />

                        {fileDetails?.potentialDuplicate &&
                        !fileDetails?.documentMetadata.ignoreDuplicate ? (
                          <PaymentDocumentPotentialDuplicate
                            type={paymentDocumentTranslateKey}
                            file={fileDetails?.potentialDuplicate}
                            onProceed={() => handleUpdateFile(fileDetails)}
                            onCancel={() => handleDeleteFile(fileDetails?.id)}
                          />
                        ) : (
                          <>
                            <S.FormWrapper>
                              <S.FormHeader
                                align="center"
                                justify="space-between"
                              >
                                <S.TitleDetails level={3}>
                                  {translate(
                                    `${paymentDocumentTranslateKey}.details.title`
                                  )}
                                </S.TitleDetails>

                                {fileDetails?.adminStatus === 'processed' ? (
                                  <Tooltip
                                    title={translate(
                                      `${paymentDocumentTranslateKey}.tooltipByDelete`
                                    )}
                                    placement="left"
                                  >
                                    <Button
                                      type="link"
                                      icon={<IconTrash />}
                                      size="small"
                                      disabled
                                      danger
                                    />
                                  </Tooltip>
                                ) : (
                                  <Button
                                    type="link"
                                    icon={<IconTrash />}
                                    size="small"
                                    onClick={() =>
                                      handleDeleteFile(fileDetails?.id)
                                    }
                                    danger
                                  />
                                )}
                              </S.FormHeader>

                              <PaymentDocumentDetailsForm
                                type={paymentDocumentTranslateKey}
                                details={fileDetails}
                                onUpdate={handleUpdateSelectedFile}
                                onAttachTransaction={handleUpdateSelectedFile}
                                onRefreshFile={refetchFile}
                                hasSwitches={false}
                              />
                            </S.FormWrapper>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {isLoadingFile ? (
                          <S.Loader>
                            <Loader />
                          </S.Loader>
                        ) : (
                          <PaymentDocumentSkeleton />
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </S.WrapperRight>
        </S.Content>
      </S.Container>
    </Flex>
  );
};

export default PaymentDocument;
