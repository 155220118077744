import { useEffect } from 'react';
import { captureException } from '@sentry/react';
import { RunTimeError } from '@entities/errorBoundary/RunTimeError';
import { DevError } from '@entities/errorBoundary/DevError';

export const ErrorFallback = ({
  error,
  componentStack,
  eventId,
  fullPage,
  onClick,
}: {
  error: unknown;
  componentStack?: string;
  eventId?: string;
  fullPage?: boolean;
  onClick?: () => void;
}) => {
  useEffect(() => {
    captureException(error, { level: 'fatal' });
  }, [error]);

  console.error('Error:', eventId, error);

  return (
    <>
      <RunTimeError fullPage={fullPage} onClick={onClick} />
      <DevError error={error} componentStack={componentStack} />
    </>
  );
};
