import { Button, Flex, Form, type ModalProps, Row, Switch } from 'antd';
import { FC, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { Schemas } from '@api-client/generated/types';
import { IconTrash } from '@assets';
import {
  InvoiceItemUnit,
  InvoiceZeroVATReason,
  VATPercents,
  VAT_DEFAULT_PERCENT,
  VAT_MIDDLE_PERCENT,
} from '@constants';
import {
  Amount,
  InvoiceWrapperCard,
  InvoiceWrapperFormControl,
  InvoiceWrapperModal,
} from '@entities';
import { useTranslate } from '@hooks';

import * as S from './styled';

type InvoiceItem = Schemas.InvoiceItem;

type InvoiceActionItemProps = {
  type: 'add' | 'edit';
  item: InvoiceItem | null;
  onSubmit: (values: InvoiceItem) => void;
  onCancel: () => void;
  onRemove: (id: string) => void;
} & ModalProps;

const defaultPercent = VAT_DEFAULT_PERCENT / 100;
const middlePercent = VAT_MIDDLE_PERCENT / 100;

const getUnitPriceWithTaxRate = (unitPrice: number, taxRate: number) =>
  getPriceWithRounding(unitPrice * (1 + taxRate));

const getPriceWithRounding = (price: number) => +price.toFixed(2);

const getUnitPriceWithoutTaxRate = (unitPrice: number, taxRate: number) =>
  getPriceWithRounding(unitPrice / (1 + taxRate));

const getTotalWithTaxInluded = (unitPrice: number, quantity: number) =>
  getPriceWithRounding(unitPrice * quantity);

const getTotalWithoutTaxInluded = (
  unitPrice: number,
  quantity: number,
  taxRate: number
) => getPriceWithRounding(unitPrice * quantity * (1 + taxRate));

const getTotalAmount = (values: InvoiceItem | null): number => {
  if (!values) {
    return 0;
  }

  const { unitPrice, quantity, taxRate, settings } = values;

  if (settings?.isUnitPriceWithTaxInluded) {
    return getTotalWithTaxInluded(unitPrice, quantity);
  } else {
    return getTotalWithoutTaxInluded(unitPrice, quantity, taxRate);
  }
};

const InvoiceActionItem: FC<InvoiceActionItemProps> = ({
  item,
  type,
  open,
  onSubmit,
  onCancel,
  onRemove,
}) => {
  const { translate, tDynamic } = useTranslate();

  const [form] = Form.useForm();

  const [values, setValues] = useState<InvoiceItem | null>(null);

  useEffect(() => {
    if (item) {
      const { unitPrice, taxRate, settings } = item;

      const params = {
        ...item,
        taxRate: taxRate || 0,
        unitPrice: settings.isUnitPriceWithTaxInluded
          ? getUnitPriceWithTaxRate(unitPrice, taxRate)
          : unitPrice,
      };

      form.setFieldsValue(params);
      setValues(params);
    }
  }, [form, item]);

  const handleCancel = () => {
    setValues(null);
    onCancel();
    form.resetFields();
  };

  const handleRemoveItem = (id: string) => {
    onRemove(id);
    handleCancel();
  };

  const handleSubmit = (values: InvoiceItem) => {
    const { unitPrice, quantity, taxRate, settings } = values;

    onSubmit({
      ...values,
      id: uuidv4(),
      unitPrice: settings.isUnitPriceWithTaxInluded
        ? getUnitPriceWithoutTaxRate(unitPrice, taxRate)
        : getPriceWithRounding(unitPrice),
      totalPrice: settings.isUnitPriceWithTaxInluded
        ? getTotalWithTaxInluded(unitPrice, quantity)
        : getTotalWithoutTaxInluded(unitPrice, quantity, taxRate),
    });

    handleCancel();
  };

  return (
    <InvoiceWrapperModal
      open={open}
      title={
        type === 'add'
          ? translate('invoiceGenerator.addItem.title')
          : translate('invoiceGenerator.addItem.titleEdit')
      }
      onCancel={handleCancel}
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        onValuesChange={(_, values) => setValues(values)}
        layout="vertical"
        requiredMark={false}
        initialValues={{
          quantity: 1,
          unit: 'item',
          taxRate: defaultPercent,
          settings: {
            isUnitPriceWithTaxInluded: false,
          },
        }}
      >
        <InvoiceWrapperCard>
          <InvoiceWrapperFormControl
            type="input"
            form={{
              label: translate('invoiceGenerator.addItem.fieldName.label'),
              name: 'name',
              rules: [{ required: true }],
            }}
          />

          <InvoiceWrapperFormControl
            type="textarea"
            form={{
              label: translate(
                'invoiceGenerator.addItem.fieldDescription.label'
              ),
              name: 'description',
            }}
            control={{
              rows: 3,
            }}
          />
        </InvoiceWrapperCard>

        <InvoiceWrapperCard>
          <Row gutter={[12, 0]}>
            <InvoiceWrapperFormControl
              type="input-number"
              span={6}
              form={{
                label: translate('invoiceGenerator.addItem.fieldPrice.label'),
                name: 'unitPrice',
                rules: [{ required: true, message: ' ' }],
              }}
            />

            <InvoiceWrapperFormControl
              type="input-number-inline"
              span={7}
              form={{
                label: translate(
                  'invoiceGenerator.addItem.fieldQuantity.label'
                ),
                name: 'quantity',
                rules: [{ required: true }],
              }}
              control={{
                type: 'inline',
                min: 0,
              }}
            />

            <InvoiceWrapperFormControl
              type="select"
              span={6}
              form={{
                label: translate('invoiceGenerator.addItem.fieldUnit.label'),
                name: 'unit',
                rules: [{ required: true }],
              }}
              control={{
                placeholder: translate(
                  'invoiceGenerator.addItem.fieldUnit.placeholder'
                ),
                options: [
                  {
                    label: translate(
                      'invoiceGenerator.addItem.fieldUnit.options.day'
                    ),
                    value: InvoiceItemUnit.Day,
                  },
                  {
                    label: translate(
                      'invoiceGenerator.addItem.fieldUnit.options.month'
                    ),
                    value: InvoiceItemUnit.Month,
                  },
                  {
                    label: translate(
                      'invoiceGenerator.addItem.fieldUnit.options.item'
                    ),
                    value: InvoiceItemUnit.Item,
                  },
                ],
              }}
            />

            <InvoiceWrapperFormControl
              type="select"
              span={5}
              form={{
                label: translate('invoiceGenerator.addItem.fieldRate.label'),
                name: 'taxRate',
                rules: [{ required: true }],
              }}
              control={{
                placeholder: translate(
                  'invoiceGenerator.addItem.fieldRate.placeholder'
                ),
                options: VATPercents.map((percent) => ({
                  label: `${percent}%`,
                  value: percent / 100,
                })),
              }}
            />
          </Row>

          {values?.taxRate === 0 || values?.taxRate === middlePercent ? (
            <S.AlertWarning
              type="warning"
              description={
                <>
                  <S.AlertWarningTitle>
                    {translate('invoiceGenerator.addItem.alertWarning.title')}
                  </S.AlertWarningTitle>

                  <Trans
                    i18nKey="invoiceGenerator.addItem.alertWarning.description"
                    components={[
                      <a href="https://guichet.public.lu" target="_blank" />,
                    ]}
                  />
                </>
              }
            />
          ) : null}

          {values?.taxRate === 0 ? (
            <InvoiceWrapperFormControl
              type="select"
              form={{
                label: translate(
                  'invoiceGenerator.addItem.fieldZeroVATReason.label'
                ),
                name: ['settings', 'zeroVATReason'],
                rules: [{ required: true }],
              }}
              control={{
                placeholder: translate(
                  'invoiceGenerator.addItem.fieldZeroVATReason.placeholder'
                ),
                labelRender: ({ label }) =>
                  tDynamic(
                    `invoiceGenerator.addItem.fieldZeroVATReason.options.${label}.title`
                  ),
                optionRender: ({ label }) => (
                  <S.Option gap={10} vertical>
                    <S.OptionTitle>
                      {tDynamic(
                        `invoiceGenerator.addItem.fieldZeroVATReason.options.${label}.title`
                      )}
                    </S.OptionTitle>

                    <S.OptionValue>
                      {tDynamic(
                        `invoiceGenerator.addItem.fieldZeroVATReason.options.${label}.description`
                      )}
                    </S.OptionValue>
                  </S.Option>
                ),
                options: [
                  {
                    label: 'exempt',
                    value: InvoiceZeroVATReason.Exempt,
                  },
                  {
                    label: 'intraCommunityReverseCharge',
                    value: InvoiceZeroVATReason.IntraCommunityReverseCharge,
                  },
                ],
              }}
            />
          ) : null}

          <S.Switch gap={12}>
            <Form.Item
              name={['settings', 'isUnitPriceWithTaxInluded']}
              valuePropName="checked"
              noStyle
            >
              <Switch />
            </Form.Item>

            <span>
              {translate('invoiceGenerator.addItem.fieldIncludedPrice.label')}
            </span>
          </S.Switch>
        </InvoiceWrapperCard>

        {type === 'add' && (
          <S.Total align="center" justify="flex-end" gap={12}>
            <span>{translate('invoiceGenerator.addItem.total')}:</span>
            <Amount
              currencyCode="EUR"
              amount={getTotalAmount(values)}
              withoutType
            />
          </S.Total>
        )}

        {type === 'edit' && (
          <S.Total align="center" justify="space-between">
            <span>{translate('invoiceGenerator.addItem.total')}:</span>
            <Amount
              currencyCode="EUR"
              amount={getTotalAmount(values)}
              withoutType
            />
          </S.Total>
        )}

        <S.Submit>
          <Flex
            justify={type === 'add' ? 'flex-end' : 'space-between'}
            align="center"
          >
            {type === 'edit' && item && (
              <Button
                type="primary"
                icon={<IconTrash />}
                onClick={() => handleRemoveItem(item.id!)}
                danger
              >
                {translate('invoiceGenerator.addItem.buttonDelete')}
              </Button>
            )}

            <Form.Item noStyle>
              <Flex justify="flex-end" gap={12}>
                <Button onClick={onCancel}>
                  {translate('invoiceGenerator.buttonCancel')}
                </Button>

                <Button type="primary" htmlType="submit">
                  {translate('invoiceGenerator.buttonSave')}
                </Button>
              </Flex>
            </Form.Item>
          </Flex>
        </S.Submit>
      </Form>
    </InvoiceWrapperModal>
  );
};

export default InvoiceActionItem;
