import { TableColumnType, Tooltip } from 'antd';

import { Schemas } from '@api-client/generated/types';
import { IconMissedFile } from '@assets';
import { Amount } from '@entities';
import { LocalePaths } from '@locales';
import { colors } from '@theme';
import { getDateDefault } from '@utils';

type Transaction = Schemas.Transaction;

const getColumnsConfig = (
  translate: (key: LocalePaths) => string
): TableColumnType<Transaction>[] => {
  return [
    {
      key: 'file',
      title: '',
      width: 46,
      align: 'center',
      render: (row) =>
        !row.files?.length &&
        !row.isFileless && (
          <Tooltip
            title={translate('transactionsPage.missedPaymentDocument')}
            placement="right"
          >
            <IconMissedFile color={colors.error} />
          </Tooltip>
        ),
      onHeaderCell: () => ({
        style: {
          paddingInline: 0,
        },
      }),
      onCell: () => ({
        style: {
          paddingInline: 0,
        },
      }),
    },
    {
      key: 'amount',
      dataIndex: 'amount',
      title: translate('transactionsPage.label.amount'),
      align: 'right',
      width: 126,
      render: (amount, record) => (
        <Amount amount={amount} currencyCode={record.currencyCode} />
      ),
    },
    {
      key: 'contact',
      dataIndex: 'contact',
      title: translate('transactionsPage.label.contact'),
      width: 150,
      render: (contact) => contact?.name,
      ellipsis: true,
    },
    {
      key: 'details',
      dataIndex: 'details',
      width: 300,
      title: translate('transactionsPage.label.details'),
      ellipsis: true,
    },
    {
      key: 'category',
      dataIndex: 'category',
      title: translate('transactionsPage.label.category'),
      width: 120,
      render: (category) => category?.name,
      ellipsis: true,
    },
    {
      key: 'account',
      dataIndex: 'account',
      title: translate('transactionsPage.label.bank'),
      render: (account: Schemas.Account) =>
        account?.connection?.bank?.name || account?.accountName,
      width: 109,
      ellipsis: true,
    },
    {
      key: 'date',
      dataIndex: 'bookingDate',
      title: translate('transactionsPage.label.date'),
      width: 110,
      align: 'right',
      render: (date) => getDateDefault(date),
    },
  ];
};

export default getColumnsConfig;
