import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Typography } from 'antd';

import { PageMeta, TransactionsTable } from '@entities';

import { useTranslate } from '@hooks';

import { IconCSV } from '@assets';

const { Title } = Typography;

const Transactions: FC = () => {
  const navigate = useNavigate();
  const { translate } = useTranslate();

  return (
    <>
      <PageMeta title={translate('transactionsPage.title')} />

      <TransactionsTable
        title={<Title>{translate('transactionsPage.title')}</Title>}
        filterParams={{
          controls: {
            left: [
              {
                type: 'search',
                formName: 'term',
              },
              {
                type: 'divider',
              },
              {
                type: 'module-filter',
                controls: [
                  {
                    label: translate('filter.category.label'),
                    type: 'list-categories',
                    formName: 'categoryIds',
                  },
                  {
                    label: translate('filter.contacts.label'),
                    type: 'list-contacts',
                    formName: 'contactIds',
                  },
                  {
                    label: translate('filter.bank.label'),
                    type: 'list-banks',
                    formName: 'accountIds',
                  },
                  {
                    label: translate('filter.project.label'),
                    type: 'list-projects',
                    formName: 'projectIds',
                  },
                  {
                    // TODO fix issue with false parsed as a string on FE and producing filtered result after toggling on and the toggling off
                    label: translate('transactionsPage.label.withoutDocuments'),
                    type: 'switch',
                    formName: 'onlyWithoutDocuments',
                  },
                  {
                    // TODO fix issue with false parsed as a string on FE and producing filtered result after toggling on and the toggling off
                    label: translate('transactionsPage.label.byPlDate'),
                    type: 'switch',
                    formName: 'showByPlDate',
                    isBoolean: true,
                  },
                ],
              },
              {
                type: 'range-picker',
                formName: 'date',
              },
            ],
            right: [
              {
                type: 'module-sort',
              },
              {
                type: 'module-columns-config',
              },
              {
                type: 'custom-component',
                component: (
                  <Button
                    icon={<IconCSV />}
                    type="secondary"
                    size="small"
                    onClick={() => navigate('/accounts')}
                  />
                ),
              },
            ],
          },
        }}
      />
    </>
  );
};

export default Transactions;
