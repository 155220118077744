import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { mediaWidth, breakpoints } from '@styles/mediaWidth';

type ControlProps = {
  selected?: boolean;
  rightContent?: boolean;
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Control = styled.div<ControlProps>`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid
    ${({ theme, selected }) =>
      selected ? theme.colors.primary : theme.colors.strokeGrey};
  border-radius: 20px;
  display: flex;
  align-items: flex-start;
  gap: 16px;
  cursor: pointer;
  padding: 24px 24px 24px 64px;
  position: relative;

  @media (max-width: ${breakpoints.tablet}px) {
    flex-direction: column-reverse;
    padding-left: ${({ rightContent }) => (rightContent ? '24px' : '64px')};
  }
`;

export const Title = styled.div<ControlProps>`
  font-weight: 600;
  font-size: 18px;
  margin: 0;
`;

export const Content = styled.div`
  margin-top: 12px;
`;

export const RightContent = styled.div`
  ${mediaWidth.tablet(css`
    width: 100%;
    display: flex;
    justify-content: center;
  `)};
`;

export const ControlIcon = styled.div<ControlProps>`
  min-width: 24px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid
    ${({ theme, selected }) =>
      selected ? theme.colors.primary : theme.colors.strokeGrey};
  position: absolute;
  top: 24px;
  left: 24px;

  &:after {
    content: '';
    width: 16px;
    height: 16px;
    background-color: ${({ theme, selected }) =>
      selected ? theme.colors.primary : 'transparent'};
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
