import { Button, Col, Flex, Form, Input, Row, Switch, Typography } from 'antd';
import { FC } from 'react';
import { Trans } from 'react-i18next';

import { PlusOutlined } from '@ant-design/icons';
import { Schemas } from '@api-client/generated/types';
import { IconTrash } from '@assets';
import { Select } from '@components';
import { OnboardingCardHeader } from '@entities';
import {
  useAccount,
  useFormValidateFields,
  useOnboarding,
  useTranslate,
} from '@hooks';
import { useUpdateIncorporation } from '@hooks-api';

import * as S from './styled';

const { Paragraph } = Typography;

const OnboardingAddManagers: FC = () => {
  const { translate, tDynamic } = useTranslate();
  const { companyId } = useAccount();

  const { form, disabledForm } = useFormValidateFields();

  const { incorporationDetails, updateScreen, updateIncorporation } =
    useOnboarding();

  const [update, loading] = useUpdateIncorporation();

  const isShareholderOnIndex = (index: number) =>
    incorporationDetails?.people?.filter((person) => !person.isCompanyItself)[
      index
    ]?.isShareholder;

  const handleSubmit = (value: Schemas.Incorporation) => {
    const companyItself: Schemas.Person[] =
      incorporationDetails?.people?.filter(
        (person) => person.isCompanyItself
      ) || [];

    const people = companyItself.concat(value.people || []);

    if (incorporationDetails && value.people) {
      update(
        {
          parameter: {
            companyId: companyId!,
          },
          requestBody: {
            people: people,
          },
        },
        {
          onSuccess: (response) => {
            updateIncorporation({ people: response.people });
            updateScreen('MESSAGE_PROCESS_ONLINE_KYC', { active: true });
          },
        }
      );
    }
  };

  const getValidStatus = () => {
    const fields: Schemas.PersonDto[] = form.getFieldsValue().people || [];

    const isDirector = fields.filter((item) => item && item.isDirector);
    const isSigner = fields.filter((item) => item && item.isSigner);

    return fields ? !!(isDirector.length && isSigner.length) : false;
  };

  return (
    <S.Container>
      <Form
        form={form}
        onFinish={handleSubmit}
        initialValues={{
          people: incorporationDetails?.people?.filter(
            (person) => !person.isCompanyItself
          ),
        }}
      >
        <S.Inner>
          <OnboardingCardHeader
            title={tDynamic(
              `onboarding.addManagers.title_${incorporationDetails?.incorporationType}`
            )}
            description={
              <Row align="bottom" gutter={[20, 0]}>
                <Col span={15}>
                  <Paragraph>
                    <Trans
                      i18nKey={tDynamic(
                        `onboarding.addManagers.description_${incorporationDetails?.companyType || incorporationDetails?.incorporationType}`
                      )}
                      components={[<strong />, <Paragraph />]}
                    />
                  </Paragraph>
                </Col>

                <Col span={9}>
                  <Flex gap={8} vertical>
                    <S.ExtraText type="secondary">
                      <Trans
                        i18nKey={tDynamic(
                          'onboarding.addManagers.extraTextManager'
                        )}
                        components={[<strong />]}
                      />
                    </S.ExtraText>

                    <S.ExtraText type="secondary">
                      <Trans
                        i18nKey={tDynamic(
                          'onboarding.addManagers.extraTextAuthorized'
                        )}
                        components={[<strong />]}
                      />
                    </S.ExtraText>
                  </Flex>
                </Col>
              </Row>
            }
          />

          <Form.List name="people">
            {(fields, { add, remove }) => (
              <>
                <S.FormInner>
                  <Row gutter={16}>
                    <Col span={7}>
                      <S.FormLabel>
                        {tDynamic('onboarding.addManagers.type')}
                      </S.FormLabel>
                    </Col>

                    <Col span={9}>
                      <S.FormLabel>
                        {tDynamic('onboarding.addManagers.name')}
                      </S.FormLabel>
                    </Col>

                    <Col span={4}>
                      <S.FormLabel align="center">
                        {tDynamic('onboarding.addManagers.managerRole')}
                      </S.FormLabel>
                    </Col>

                    <Col span={4}>
                      <S.FormLabel align="center">
                        {tDynamic('onboarding.addManagers.autorizedPerson')}
                      </S.FormLabel>
                    </Col>
                  </Row>

                  {fields.map(({ name, key, ...field }) => (
                    <S.FormItem key={key}>
                      <Form.Item required={false} noStyle>
                        <Row gutter={16} align="middle">
                          <Col span={7}>
                            <Form.Item
                              {...field}
                              name={[name, 'type']}
                              validateTrigger={['onChange', 'onBlur']}
                              noStyle
                              rules={[{ required: true }]}
                              initialValue={
                                (incorporationDetails?.companyType ===
                                  'sarl-s' &&
                                  'natural') ||
                                undefined
                              }
                            >
                              <Select
                                variant={
                                  isShareholderOnIndex(key)
                                    ? 'borderless'
                                    : 'outlined'
                                }
                                options={[
                                  {
                                    label: tDynamic(
                                      'onboarding.shareholders.select.labelNatural'
                                    ),
                                    value: 'natural',
                                  },
                                  {
                                    label: tDynamic(
                                      'onboarding.shareholders.select.labelLegal'
                                    ),
                                    value: 'legal',
                                  },
                                ]}
                                placeholder={tDynamic(
                                  'onboarding.shareholders.select.placeholder'
                                )}
                                size="large"
                                disabled={
                                  incorporationDetails?.companyType === 'sarl-s'
                                }
                              />
                            </Form.Item>
                          </Col>

                          <Col span={9}>
                            <Form.Item
                              {...field}
                              name={[name, 'name']}
                              validateTrigger={['onChange']}
                              noStyle
                              rules={[{ required: true }]}
                            >
                              <Input
                                variant={
                                  isShareholderOnIndex(key)
                                    ? 'borderless'
                                    : 'outlined'
                                }
                                placeholder={tDynamic(
                                  'onboarding.shareholders.namePlaceholder'
                                )}
                                size="large"
                              />
                            </Form.Item>
                          </Col>

                          <Col span={4}>
                            <Flex>
                              <Form.Item
                                {...field}
                                name={[name, 'isDirector']}
                                validateTrigger={['onChange']}
                                noStyle
                              >
                                <Switch />
                              </Form.Item>
                            </Flex>
                          </Col>

                          <Col span={4}>
                            <Flex align="center">
                              <Form.Item
                                {...field}
                                name={[name, 'isSigner']}
                                validateTrigger={['onChange']}
                                noStyle
                              >
                                <Switch />
                              </Form.Item>

                              <S.Wrapper>
                                {!isShareholderOnIndex(key) ? (
                                  <S.ButtonRemove onClick={() => remove(name)}>
                                    <IconTrash />
                                  </S.ButtonRemove>
                                ) : null}
                              </S.Wrapper>
                            </Flex>
                          </Col>
                        </Row>
                      </Form.Item>
                    </S.FormItem>
                  ))}
                </S.FormInner>

                <Flex justify="space-between">
                  <Flex align="center" gap={28}>
                    <S.ButtonAdd onClick={() => add()}>
                      <PlusOutlined />
                      {tDynamic('onboarding.addManagers.addManager')}
                    </S.ButtonAdd>

                    <S.Total>
                      {tDynamic('onboarding.addManagers.totalManagers', {
                        value: fields.length,
                      })}
                    </S.Total>
                  </Flex>
                </Flex>
              </>
            )}
          </Form.List>
        </S.Inner>

        <Flex justify="flex-end">
          <Button
            type="link"
            onClick={() => updateScreen('SHAREHOLDERS', { active: true })}
          >
            {translate('onboarding.buttonGoBack')}
          </Button>

          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            disabled={disabledForm || !getValidStatus()}
          >
            {translate('onboarding.buttonNext')}
          </Button>
        </Flex>
      </Form>
    </S.Container>
  );
};

export default OnboardingAddManagers;
