import dayjs, { type Dayjs, type UnitTypeLong, type QUnitType } from 'dayjs';

export const DATE_ISO_FORMAT = 'YYYY-MM-DD';

export const getDateDefault = (
  date: Date | Dayjs | string | undefined,
  format: string = 'DD.MM.YYYY'
): string =>
  dayjs(date || dayjs())
    .format(format);

export const getDateFromNow = (date: Date | string) =>
  dayjs(date || dayjs())
    .fromNow();

export const getDateStartOf = (
  date: Date | string,
  unit: UnitTypeLong
): Dayjs =>
  dayjs(date || dayjs())
    .startOf(unit);

export const getDateEndOf = (date: Date | string, unit: UnitTypeLong): Dayjs =>
  dayjs(date || dayjs())
    .endOf(unit);

export const getDateRangeByInterval = (
  start: Dayjs | null,
  end: Dayjs | null,
  interval: UnitTypeLong | QUnitType,
  asUnix = false
): (number | Date | Dayjs)[] => {
  const from = dayjs(start);
  const to = dayjs(end);
  const additionalCount = from.month() !== to.month() && to.diff(from, 'day') < 31 ? 2 : 1
  const diffInUnits = to.diff(from, interval) + additionalCount;

  return [...Array(diffInUnits).keys()].map((key) =>
    asUnix
      ? from.add(key, interval).valueOf()
      : from.add(key, interval).toDate()
  );
};
