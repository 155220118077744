import { ChangeEvent, KeyboardEvent, FC } from 'react';

import { Row, Col, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { ContactsWrapperFormControl, ContactVatIdMessage } from '@entities';

import { useTranslate } from '@hooks';

import { VATIdRegex } from '@constants';

import { IconCheck } from '@assets';
import { colors } from '@theme';

import * as S from './styled';

type ContactVatIdControlProps = {
  isValid: boolean;
  isError: boolean;
  isNotValid: boolean;
  processing: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: (e: KeyboardEvent<HTMLInputElement>) => void;
};

const ContactVatIdControl: FC<ContactVatIdControlProps> = ({
  isValid,
  isError,
  isNotValid,
  processing,
  onChange,
  onKeyPress,
}) => {
  const { translate } = useTranslate();

  return (
    <S.Container error={isError || isNotValid}>
      <Row gutter={[12, 0]}>
        <Col span={10}>
          <S.Inner>
            <ContactsWrapperFormControl
              type="input"
              form={{
                name: 'vatId',
                label: translate(
                  'contactsPage.newContact.fieldVatId.control.label'
                ),
                rules: [{ pattern: VATIdRegex, message: '' }],
              }}
              control={{
                placeholder: translate(
                  'contactsPage.newContact.fieldVatId.control.placeholder'
                ),
                onChange,
                onKeyPress,
              }}
            />

            {isValid && !processing && (
              <S.Valid>
                <IconCheck color={colors.success} width={20} height={20} />
              </S.Valid>
            )}

            {processing && (
              <S.Processing>
                <Spin indicator={<LoadingOutlined spin />} />
              </S.Processing>
            )}
          </S.Inner>
        </Col>

        <Col span={14}>
          <ContactVatIdMessage isNotValid={isNotValid} isError={isError} />
        </Col>
      </Row>
    </S.Container>
  );
};

export default ContactVatIdControl;
