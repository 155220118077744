import styled from '@emotion/styled';

import { Flex } from 'antd';

export const Invoice = styled(Flex)`
  height: 76px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.strokeGrey};
  padding: 16px 12px 16px 24px;

  &:last-child {
    border-bottom: none;
  }
`;

export const Name = styled.div`
  color: ${({ theme }) => theme.colors.text200};
  font-weight: 600;
  margin-bottom: 4px;
`;

export const Processing = styled.div`
  color: ${({ theme }) => theme.colors.text200};
  font-size: 12px;
`;
