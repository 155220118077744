import styled from '@emotion/styled';
import { rgba } from 'emotion-rgba';

import { Typography } from 'antd';

import { colors } from '@theme';

type HeaderProps = {
  status: string;
};

const getHeaderColorByStatus = (status: string) => {
  if (status === 'paid' || status === 'received') {
    return {
      bg: colors.badgeSuccess,
      color: colors.success,
    };
  }

  if (status === 'payment') {
    return {
      bg: colors.strokeDarkGrey,
      color: colors.text400,
    };
  }

  if (status === 'overdue') {
    return {
      bg: colors.bgWarning,
      color: colors.error,
    };
  }

  return {
    bg: colors.strokeDarkGrey,
    color: colors.text400,
  };
};

export const Card = styled.div`
  height: 210px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 12px;
  box-shadow: 0 3px 8px 0 ${({ theme }) => rgba(theme.colors.dark, 0.1)};
`;

export const Header = styled.div<HeaderProps>`
  height: 50px;
  background-color: ${({ status }) => getHeaderColorByStatus(status).bg};
  color: ${({ status }) => getHeaderColorByStatus(status).color};
  border-radius: 12px 12px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
`;

export const SubTitle = styled.div`
  color: ${({ theme }) => theme.colors.text400};
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 4px;
`;

export const Amount = styled(Typography.Title)`
  &.ui-typography {
    font-size: 24px;
    margin-bottom: 24px;
  }
`;

export const Check = styled.div`
  width: 40px;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.bgGrey};
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 18px;
    height: 18px;
  }
`;
