import { Spin, Button } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import {
  PaymentDocumentInvoice,
  PaymentDocumentInvoiceProcessing,
  PaymentDocumentInvoiceError,
  PaymentDocumentType,
} from '@entities';
import { Scrollbar } from '@components';
import { useTranslate } from '@hooks';

import { Schemas } from '@api-client/generated/types';

import { useInfiniteScroll } from '@hooks/useInfiniteScroll';

type IncomeInvoicesProps = {
  type: PaymentDocumentType;
  page: number;
  isLoading: boolean;
  selectedId?: string;
  files: Schemas.PaginatedDocumentResponse;
  onLoadMore: () => void;
  onSelectedInvoice?: (id: string) => void;
  onDelete?: (id: string) => void;
};

const PaymentDocumentInvoices = ({
  type,
  page,
  isLoading,
  selectedId,
  files,
  onLoadMore,
  onSelectedInvoice,
  onDelete,
}: IncomeInvoicesProps) => {
  const { translate } = useTranslate();

  const translationPrefix = type === 'income' ? 'income' : 'expenses';

  const hasNextPage =
    page + 1 !== files.metadata.totalPages && files.metadata.totalPages !== 0;

  const { sentryRef, rootRef } = useInfiniteScroll({
    isLoading,
    hasNextPage,
    onLoadMore,
  });

  return (
    <Spin spinning={isLoading} indicator={<LoadingOutlined spin />}>
      <Scrollbar maxHeight={600} ref={rootRef}>
        <>
          {files.records.map((file) =>
            file.hasError || file.potentialDuplicate ? (
              <PaymentDocumentInvoiceError
                key={file.id}
                type={type}
                selectedId={selectedId}
                file={file}
                details={
                  file.potentialDuplicate
                    ? translate(`${translationPrefix}.possibleDuplicate`)
                    : translate(`${translationPrefix}.corruptedFile`)
                }
                onSelectedInvoice={onSelectedInvoice}
                onDelete={() => onDelete?.(file.id)}
              />
            ) : !file.documentMetadata ? (
              <PaymentDocumentInvoiceProcessing
                key={file.id}
                type={type}
                file={file}
                onDelete={() => onDelete?.(file.id)}
              />
            ) : (
              <PaymentDocumentInvoice
                key={file.id}
                type={type}
                selectedId={selectedId}
                file={file}
                onSelectedInvoice={onSelectedInvoice}
              />
            )
          )}
          {isLoading
            ? 'Loading...'
            : hasNextPage && (
                <div ref={sentryRef}>
                  <Button
                    type="text"
                    size="small"
                    onClick={onLoadMore}
                    loading={isLoading}
                    block
                  >
                    {translate('common.loadMore')}
                  </Button>
                </div>
              )}
        </>
      </Scrollbar>
    </Spin>
  );
};

export default PaymentDocumentInvoices;
