import { Button, Flex, Table, type TableColumnType, Typography } from 'antd';
import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Schemas } from '@api-client/generated/types';
import { IconPlus } from '@assets';
import { FilterConstructor, type FilterControl, PageMeta } from '@entities';
import { useAccount, useTranslate } from '@hooks';
import { useGetDocumentsList } from '@hooks-api';
import { useInfiniteScroll, usePagination } from '@hooks/useInfiniteScroll';
import { LocalePaths } from '@locales';
import { getDateDefault } from '@utils';

import * as S from './styled';

type DocumentFile = Schemas.Document;

const { Title } = Typography;

const controls: Record<'left' | 'right', FilterControl[]> = {
  left: [
    {
      type: 'list-types',
      formName: 'type',
    },
    {
      type: 'search',
      formName: 'term',
    },
  ],
  right: [
    {
      type: 'range-picker',
      formName: 'date',
    },
    {
      type: 'module-columns-config',
    },
    {
      type: 'module-filter',
      controls: [
        {
          type: 'list-contacts',
          formName: 'contactIds',
        },
      ],
    },
  ],
};

const Documents: FC = () => {
  const { companyId } = useAccount();
  const { translate } = useTranslate();
  const navigate = useNavigate();

  const [filterParamsReady, setFilterParamsReady] = useState(false);
  const [filterOptions, setFilterOptions] = useState<Record<string, unknown>>(
    {}
  );

  const [tableColumns, setTableColumns] = useState<
    TableColumnType<DocumentFile>[]
  >([]);

  const {
    page,
    incrementPage,
    setTotalPages,
    hasNextPage,
    plainData,
    appendData,
    reset,
  } = usePagination<DocumentFile>();

  const { isFetching: isLoading, refetch } = useGetDocumentsList({
    params: {
      companyId: companyId!,
      page,
      ...filterOptions,
    },
    config: {
      enabled: filterParamsReady,
      onSuccess: ({ records, metadata }) => {
        setTotalPages(metadata.totalPages);
        appendData(metadata.currentPage, records);
      },
    },
  });

  const { sentryRef } = useInfiniteScroll({
    isLoading,
    hasNextPage,
    onLoadMore: incrementPage,
  });

  useEffect(() => {
    reset();
  }, [reset, filterOptions]);

  const columns: TableColumnType<DocumentFile>[] = useMemo(
    () => [
      {
        key: 'name',
        dataIndex: 'name',
        title: translate('documentsPage.table.name'),
        width: 250,
        render: (name) => name || translate('documentsPage.table.noName'),
        ellipsis: true,
      },
      {
        key: 'createdAt',
        dataIndex: 'createdAt',
        title: translate('documentsPage.table.date'),
        width: 150,
        render: (date) => getDateDefault(date),
      },
      {
        key: 'type',
        dataIndex: 'type',
        title: translate('documentsPage.table.type'),
        width: 250,
        render: (type) =>
          type && translate(`documentsPage.types.${type}` as LocalePaths),
        ellipsis: true,
      },
      {
        key: 'contract',
        dataIndex: 'contact',
        title: translate('documentsPage.table.contact'),
        width: 250,
        render: (contact) => contact && contact.name,
        ellipsis: true,
      },
    ],
    [translate]
  );

  return (
    <Flex gap={24} vertical>
      <PageMeta title={translate('documentsPage.breadcrumbs.documents')} />

      <Title>{translate('documentsPage.breadcrumbs.documents')}</Title>

      <FilterConstructor<DocumentFile>
        columns={columns}
        controls={controls}
        actions={
          <Button
            type="primary"
            size="small"
            icon={<IconPlus />}
            onClick={() => navigate('/documents/new')}
          >
            {translate('documentsPage.addOne')}
          </Button>
        }
        onChange={setFilterOptions}
        onChangeColumns={setTableColumns}
        onRequest={refetch}
        onReady={setFilterParamsReady}
        withSearchParams
      />

      <S.TableContainer>
        <Table
          rowKey={({ id }) => id}
          dataSource={plainData}
          columns={tableColumns}
          loading={isLoading}
          pagination={false}
          scroll={{ x: 720 }}
          onRow={(record) => ({
            onClick: () => {
              navigate(record.id);
            },
          })}
        />
        {hasNextPage && <div ref={sentryRef} />}
      </S.TableContainer>
    </Flex>
  );
};

export default Documents;
