import styled from '@emotion/styled';
import { Flex } from 'antd';

export const Container = styled.div`
  margin-top: 28px;
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
`;

export const Description = styled.div`
  width: 70%;
  color: ${({ theme }) => theme.colors.text400};
  margin-bottom: 16px;
`;

export const Transactions = styled(Flex)`
  margin: 12px 0 24px 0;
`;

export const ButtonLink = styled.a`
  font-weight: 600;
`;