import { useMemo } from 'react';

import { Params$CompanyInviteController_findAll } from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';
import { useApiClient } from '@hooks';

type Params = Params$CompanyInviteController_findAll;

type CompanyInvitesProps = {
  params: Params['parameter'];
};

const useGetCompanyInvitesList = ({ params }: CompanyInvitesProps) => {
  const {
    data: invites,
    loading,
    refetch,
  } = useApiClient<Params, Schemas.CompanyInvite[]>({
    method: 'CompanyInviteController_findAll',
    params: {
      parameter: params,
    },
  });

  return useMemo(
    () => ({
      invites: invites || [],
      loading,
      refetch,
    }),
    [invites, loading, refetch]
  );
};

export default useGetCompanyInvitesList;
