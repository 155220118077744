import styled from '@emotion/styled';
import { Flex } from 'antd';

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(344px, 1fr));
  grid-gap: 16px;
`;

export const LoaderWrapper = styled(Flex)`
  width: 344px;
  height: 369px;
`
