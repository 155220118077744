//
// Generated by @himenon/openapi-typescript-code-generator v0.27.4
//
// OpenApi : 3.0.0
//
//
import {
  Params$DocumentController_findOneById,
  Response$DocumentController_findOneById$Status$200,
  ResponseContentType$DocumentController_findOneById,
} from '@api-client/generated/client';
import { QueryOptions, useApiClient } from '@hooks/useApiClient';

export const useDocumentController_findOneById = (options: {
  params: Params$DocumentController_findOneById['parameter'];
  config?: QueryOptions<
    Response$DocumentController_findOneById$Status$200[ResponseContentType$DocumentController_findOneById]
  >;
}) =>
  useApiClient<
    Params$DocumentController_findOneById,
    Response$DocumentController_findOneById$Status$200[ResponseContentType$DocumentController_findOneById]
  >({
    method: 'DocumentController_findOneById',
    params: { parameter: options.params },
    config: options.config,
  });
