import { Params$BankConnectionsController_callback } from '@api-client/generated/client';

import { useApiClientMutation } from '@hooks';

const useFinalizeBankConnections = () => {
  return useApiClientMutation<Params$BankConnectionsController_callback>({
    method: 'BankConnectionsController_callback',
  });
};

export default useFinalizeBankConnections;
