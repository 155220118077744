import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { type FlexProps, Flex } from 'antd';

import { mediaWidth } from '@styles/mediaWidth';

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`;

export const Inner = styled.div`
  margin-bottom: 24px;
`;

export const LoginHint = styled.div`
  align-self: center;
  font-size: 16px;
  font-weight: 600;
`;

export const Actions = styled(Flex)<FlexProps>`
  ${mediaWidth.tablet(css`
    flex-direction: column-reverse;
  `)};
`;
