import { type Dayjs } from 'dayjs';
import ReactECharts from 'echarts-for-react';
import { FC, useEffect, useMemo, useRef } from 'react';

import { useTranslate } from '@hooks';

import * as config from './config';
import * as S from './styled';

type ReportOverviewProps = {
  loading?: boolean;
  dateFrom: Dayjs | null;
  dateTo: Dayjs | null;
  filterPeriod: config.BreakDown;
  dataSource: config.ReportRow[];
};

const ReportOverview: FC<ReportOverviewProps> = ({
  dateFrom,
  dateTo,
  filterPeriod,
  dataSource,
  loading,
}) => {
  const { translate } = useTranslate();

  const ref = useRef<ReactECharts | null>(null);

  const instance = ref.current?.getEchartsInstance();

  const option = useMemo(
    () =>
      config.createOption({
        translate,
      }),
    [translate]
  );

  useEffect(() => {
    if (instance && filterPeriod) {
      instance.setOption({
        xAxis: {
          data: config.setXAxisDataByInterval({
            unit: filterPeriod,
            dateFrom,
            dateTo,
          }),
        },
      });
    }
  }, [instance, filterPeriod, dateFrom, dateTo]);

  useEffect(() => {
    if (instance && dataSource) {
      const differenceData = config.getDifferenceData(dataSource);

      instance.setOption({
        series: [
          {
            data: config.createSerieDifferenceData(
              differenceData,
              filterPeriod
            ),
          },
          {
            data: config.createSerieBarData(dataSource, 0, filterPeriod),
          },
          {
            data: config.createSerieBarData(dataSource, 1, filterPeriod),
          },
        ],
      });
    }
  }, [instance, dataSource, filterPeriod]);

  const Chart = useMemo(
    () => (
      <ReactECharts
        ref={(e) => (ref.current = e)}
        showLoading={loading}
        option={option}
        style={{ width: '100%', height: '300px' }}
      />
    ),
    [loading, option]
  );

  return <S.Card>{Chart}</S.Card>;
};

export default ReportOverview;
