import { Schemas } from '@api-client/generated/types';
import { Select } from '@components';
import { useAccount, useCountries, useOnboarding, useTranslate } from '@hooks';
import { useUpdateIncorporation } from '@hooks-api';
import { Flex, Form, message, Typography } from 'antd';
import { FC, PropsWithChildren } from 'react';

type KYCCountryProps = {
  person: Schemas.Person | Schemas.UBO;
};

const { Paragraph, Title } = Typography;

const KYCCountry: FC<PropsWithChildren<KYCCountryProps>> = ({ person }) => {
  const { countries, filterOptionCountries } = useCountries();
  const { translate } = useTranslate();
  const { companyId } = useAccount();

  const { incorporationDetails, updateIncorporation } = useOnboarding();

  const [form] = Form.useForm();

  const [update] = useUpdateIncorporation();

  const onFinish = () => {
    const currentKycData = person.kycData || {};
    person.kycData = {
      ...currentKycData,
      ...form.getFieldsValue(),
    };

    update(
      {
        parameter: {
          companyId: companyId!,
        },
        requestBody: {
          people: incorporationDetails!.people,
        },
      },
      {
        onSuccess: () => {
          message.success(translate('onboarding.saved'));
        },
      }
    );

    updateIncorporation({ people: incorporationDetails!.people });
  };

  return (
    <Flex gap={20} vertical>
      <div>
        <Title level={5}>
          {translate('onboarding.kyc.registeredCountry.title')}
        </Title>

        <Paragraph type="secondary">
          {translate('onboarding.kyc.registeredCountry.description')}
        </Paragraph>
      </div>

      <Form
        form={form}
        layout="vertical"
        colon={false}
        initialValues={person.kycData}
      >
        <Form.Item
          name="countryCode"
          label={translate('onboarding.kyc.legal.country')}
        >
          <Select
            placeholder={translate('onboarding.kyc.legal.country')}
            options={countries}
            size="large"
            onChange={onFinish}
            showSearch
            virtual
            filterOption={filterOptionCountries}
          />
        </Form.Item>
      </Form>
    </Flex>
  );
};

export default KYCCountry;
