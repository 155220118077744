import { Input } from 'antd';

import styled from '@emotion/styled';

import { type SearchControlProps } from './';

export const Control = styled(Input, {
  shouldForwardProp: (prop) => prop !== 'fitWidth',
})<SearchControlProps>`
  border-color: transparent;

  && {
    width: ${({ width, fitWidth }) =>
      fitWidth ? '100%' : `${width || 285}px`};
    background-color: ${({ theme }) => theme.colors.bgGrey};
    border-radius: 20px;
    box-shadow: none;
    padding-inline: 24px;

    &:hover,
    &:focus,
    &:active {
      background-color: ${({ theme }) => theme.colors.bgGrey};
    }

    .ui-input-prefix {
      margin-inline-end: 10px;
    }
  }
`;
