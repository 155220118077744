import { useMemo } from 'react';

import { Params$ProjectController_findOneById } from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';
import { useApiClient } from '@hooks';

type Params = Params$ProjectController_findOneById;

type ProjectProps = {
  params: Params['parameter'];
};

const useGetProjectById = ({ params }: ProjectProps) => {
  const {
    data: project,
    loading,
    refetch,
  } = useApiClient<Params, Schemas.Project>({
    method: 'ProjectController_findOneById',
    params: {
      parameter: params,
    },
  });

  return useMemo(
    () => ({
      project,
      loading,
      refetch,
    }),
    [project, loading, refetch]
  );
};

export default useGetProjectById;
