import * as S from './styled';
import { Select, type SelectProps } from '@components';
import { useAccount, useTranslate } from '@hooks';
import { useGetContactsList } from '@hooks-api';
import { selectFilterOption } from '@utils';
import { FC } from 'react';

type FilterContactsProps = {
  placeholder?: string;
  isMultiple?: boolean;
} & SelectProps;

const FilterContacts: FC<FilterContactsProps & any> = ({
  placeholder,
  isMultiple = false,
  ...rest
}) => {
  const { companyId } = useAccount();
  const { translate } = useTranslate();

  const { data: contacts } = useGetContactsList({
    params: {
      companyId: companyId!,
    },
  });

  const Wrapper = isMultiple ? S.MultipleWrapper : Select;

  return (
    <Wrapper
      placeholder={placeholder || translate('documentsPage.dropdown.contact')}
      options={contacts?.records.map((contact) => ({
        label: contact.name,
        value: contact.id,
      }))}
      size="large"
      showSearch
      filterOption={selectFilterOption}
      {...rest}
    />
  );
};

export default FilterContacts;
