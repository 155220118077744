import styled from '@emotion/styled';

import { Typography } from 'antd';

export const Title = styled(Typography.Title)`
  &.ui-typography {
    font-size: 20px;
    margin-bottom: 8px;
  }
`;

export const Description = styled(Typography.Paragraph)`
  text-align: center;
`;
