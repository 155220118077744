import styled from '@emotion/styled';

export const Container = styled.div`
  width: 100%;
  height: 608px;
  border: 1px dashed ${({ theme }) => theme.colors.text200};
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  text-align: center;
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  margin-top: 10px;
`;

export const Description = styled.div`
  color: ${({ theme }) => theme.colors.text400};
  font-size: 12px;
  margin-bottom: 24px;
`;
