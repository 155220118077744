import { useEffect } from 'react';

import { useDocumentController_findAll } from '@api-client/generated/DocumentController/findAll';
import { Schemas } from '@api-client/generated/types';
import { useAccount } from '@hooks';
import { useInfiniteScroll, usePagination } from '@hooks/useInfiniteScroll';

export const useMatchedDocuments = ({
  conditions,
  enabled,
}: {
  // TODO that might be incorrect
  conditions: Partial<Schemas.Condition>[];
  enabled: boolean;
}) => {
  const { companyId } = useAccount();

  const {
    page,
    incrementPage,
    setTotalPages,
    hasNextPage,
    plainData,
    appendData,
    reset,
  } = usePagination<Schemas.Document>();

  const { data, isLoading, refetch } = useDocumentController_findAll({
    params: {
      companyId: companyId!,
      conditions: JSON.stringify(conditions),
      page,
    },
    config: {
      enabled: enabled,
      onSuccess: ({ records, metadata }) => {
        setTotalPages(metadata.totalPages);
        appendData(metadata.currentPage, records);
      },
    },
  });

  useEffect(() => {
    reset();
    refetch();
  }, [refetch, reset, conditions]);

  const { sentryRef } = useInfiniteScroll({
    isLoading,
    hasNextPage,
    onLoadMore: incrementPage,
  });

  return {
    data: plainData,
    totalPages: data?.metadata.totalRecords,
    hasNextPage,
    isLoading,
    infiniteScrollRef: sentryRef,
  };
};
