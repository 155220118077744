import { Button } from 'antd';
import { useTranslate } from '@hooks';
import * as S from './styled';
import { LocalePaths } from '@locales';

type Props = {
  fullPage?: boolean;
  title: LocalePaths;
  description: LocalePaths;
  buttonText?: LocalePaths;
  imgSrc: string;
  onClick?: () => void;
};

export const GenericError = ({
  fullPage,
  title,
  description,
  buttonText,
  imgSrc,
  onClick,
}: Props) => {
  const { translate } = useTranslate();

  return (
    <S.Wrapper
      fullPage={fullPage}
      align="center"
      justify="center"
      gap={24}
      vertical
    >
      <img src={imgSrc} alt="error" style={{ maxWidth: 309 }} />
      <S.Title>{translate(title)}</S.Title>
      <S.Description>{translate(description)}</S.Description>
      {buttonText && onClick && (
        <Button type="primary" onClick={onClick}>
          {translate(buttonText)}
        </Button>
      )}
    </S.Wrapper>
  );
};
