import { FC } from 'react';

import { type InputProps } from 'antd';

import * as S from './styled';

const InputHidden: FC<InputProps> = ({ ...rest }) => (
  <S.InputHidden {...rest} />
);

export default InputHidden;
