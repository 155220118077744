import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Typography, Button } from 'antd';

import { OnboardingCardHeader, OnboardingRadioControl } from '@entities';
import { useTranslate, useOnboarding } from '@hooks';

import * as S from './styled';

const { Paragraph } = Typography;

const OnboardingWelcomeLanguage: FC = () => {
  const { translate } = useTranslate();
  const { updateScreen, updateIncorporation } = useOnboarding();

  const { i18n } = useTranslation();

  const [selectedLocale, setSelectedLocale] = useState<string | null>(null);

  const handleNext = () => {
    const locale = selectedLocale! || 'en-GB';

    i18n.changeLanguage(locale);

    updateIncorporation({
      signUp: {
        locale,
      },
    });

    updateScreen('WELCOME', { active: true });
  };

  return (
    <S.Container>
      <S.Inner>
        <OnboardingCardHeader
          title={translate('onboarding.welcomeLanguage.title')}
          description={
            <>
              <Paragraph>
                {translate('onboarding.welcomeLanguage.description')}
              </Paragraph>

              <Paragraph strong>
                {translate('onboarding.welcomeLanguage.label')}
              </Paragraph>
            </>
          }
        />

        <OnboardingRadioControl
          defaultValue="en-GB"
          onSelected={setSelectedLocale}
          controls={[
            {
              value: 'en-GB',
              title: 'English',
            },
            {
              value: 'fr-FR',
              title: 'Français',
            },
          ]}
        />
      </S.Inner>

      <S.Actions justify="flex-end" gap={16}>
        <S.LoginHint>
          <Link to="/login">
            {translate('onboarding.welcomeLanguage.loginHint')}
          </Link>
        </S.LoginHint>

        <Button type="primary" onClick={handleNext}>
          {translate('onboarding.buttonStartIncorporation')}
        </Button>
      </S.Actions>
    </S.Container>
  );
};

export default OnboardingWelcomeLanguage;
