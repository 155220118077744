import { FC } from 'react';

import { MultipleSelect, Select, type SelectProps } from '@components';
import { useTypesList } from '@hooks';

const FilterTypes: FC<SelectProps & { isMultiple?: boolean }> = ({
  visualType,
  isMultiple = false,
  ...rest
}) => {
  const types = useTypesList();

  const Wrapper = isMultiple ? MultipleSelect : Select;

  return (
    <Wrapper
      options={types.map((type) => ({
        label: type.label,
        value: type.value,
      }))}
      size="large"
      visualType={visualType || 'default'}
      {...rest}
    />
  );
};

export default FilterTypes;
