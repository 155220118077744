import { useTranslate } from '@hooks';
import { Parameter$TransactionController_getStatsByCategory } from '@api-client/generated/client';

type BreakDown =
  Parameter$TransactionController_getStatsByCategory['breakDown'];

type BreakDownPeriodProps = {
  periods: { label: string; value: string }[];
  defaultPeriod: BreakDown;
};

const useBreakDownPeriods = (): BreakDownPeriodProps => {
  const { translate } = useTranslate();

  return {
    periods: [
      {
        label: translate(`common.breakDown.month`),
        value: 'month',
      },
      {
        label: translate(`common.breakDown.quarter`),
        value: 'quarter',
      },
    ],
    defaultPeriod: 'month',
  };
};

export default useBreakDownPeriods;
