import styled from '@emotion/styled';

import { Flex, Form } from 'antd';

export const TransactionContent = styled(Flex)`
  height: 100%;
`

export const Content = styled(Flex)<{ ismodal: string }>`
  width: 100%;
  max-width: 1440px;
  height: 100%;
`;

export const Item = styled(Form.Item)`
  margin-bottom: 16px;

  &&.ui-col,
  .ui-form-item-label {
    padding-bottom: 4px;
  }
`;

export const DetailsContainer = styled(Flex)<{ ismodal: string }>`
  width: ${({ ismodal }) => (ismodal.length ? '593px' : '60%')};
  padding: 30px 0;
  margin: 0 30px;
`;

export const FullWidth = styled(Flex)`
  width: 100%;
`;

export const FilelessWrapper = styled(Flex)<{ ismodal: string }>`
  width: ${({ ismodal }) => (ismodal.length ? '373px' : '40%')};
  min-height: 660px;
  background-color: ${({ theme }) => theme.colors.bgGrey};
  border-bottom-right-radius: 20px;
`;

export const Wrapper = styled.div`
  width: 333px;
  height: 150px;
  position: relative;

  :before {
    content: '';
    width: 44px;
    height: 44px;
    background: transparent;
    border-left: 1px solid ${({ theme }) => theme.colors.bgDarkGrey};
    border-bottom: 1px solid ${({ theme }) => theme.colors.bgDarkGrey};
    border-top: none;
    border-right: none;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 6;
  }
`;

export const BlockTitle = styled.span`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;

export const Preview = styled.div`
  min-width: 405px;
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Actions = styled.div`
  width: 233px;
`;
