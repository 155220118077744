import { Badge as BaseBadge } from 'antd';
import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { useTaskController_findAll } from '@api-client/generated/TaskController/findAll';
import { IconTasks } from '@assets';
import styled from '@emotion/styled';
import { useAccount } from '@hooks';

const Badge = styled(BaseBadge)`
  .ui-badge-dot {
    width: 12px;
    height: 12px;
    box-shadow: none;
  }
`;

// might be reused in the future, but since used just once is kept here
const useUnreadChatMessagesCounter = () => {
  const { companyId } = useAccount();
  const location = useLocation();

  const { data: tasks, refetch } = useTaskController_findAll({
    params: {
      companyId: companyId!,
    },
  });

  useEffect(() => {
    refetch();
  }, [location, refetch]);

  return useMemo(
    () =>
      tasks?.reduce((acc, task) => {
        return acc + task.unseenMessagesCount;
      }, 0),
    [tasks]
  );
};

// className is required for correct menu item styling with Antd
export const ChatIcon = ({ className }: { className?: string }) => {
  const hasUnreadMessages = !!useUnreadChatMessagesCounter();

  return (
    <Badge className={className} dot={hasUnreadMessages} offset={[-3.5, 3]}>
      <IconTasks />
    </Badge>
  );
};
