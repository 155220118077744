import { FC } from 'react';
import { Button, Flex } from 'antd';

import { PaymentDocumentImportInvoice, PaymentDocumentType } from '@entities';
import { useTranslate } from '@hooks';
import { IconEyeOff } from '@assets';

import * as S from './styled';

type IncomeErrorProps = {
  type: PaymentDocumentType;
  onDelete?: () => void;
};

const PaymentDocumentError: FC<IncomeErrorProps> = ({ type, onDelete }) => {
  const { translate } = useTranslate();

  const translationPrefix = type === 'income' ? 'income' : 'expenses';

  return (
    <S.Container>
      <S.Icon>
        <IconEyeOff width={32} height={32} />
      </S.Icon>

      <S.Title>{translate(`${translationPrefix}.error.title`)}</S.Title>

      <S.Description>
        {translate(`${translationPrefix}.error.description`)}
      </S.Description>

      <Flex align="center" gap={8}>
        <Button type="link" onClick={onDelete} danger>
          {translate(`${translationPrefix}.error.buttonDelete`)}
        </Button>

        <PaymentDocumentImportInvoice documentType={type}>
          <Button size="small">
            {translate(`${translationPrefix}.error.buttonUpload`)}
          </Button>
        </PaymentDocumentImportInvoice>
      </Flex>
    </S.Container>
  );
};

export default PaymentDocumentError;
