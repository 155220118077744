import styled from '@emotion/styled';
import { rgba } from 'emotion-rgba';

export const Card = styled.div`
  border-radius: 20px;
  box-shadow: 0 4px 26px 0 ${({ theme }) => rgba(theme.colors.dark, 0.06)};
  padding: 24px;
  font-weight: 500;

  h3.ui-typography {
    font-size: 20px;
  }
`;

export const Grid = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const GridLeft = styled.div`
  width: 500px;
  position: relative;
`;

export const GridRight = styled.div`
  width: 100%;
  flex: 1;
`;

export const TotalAmount = styled.div`
  width: 130px;
  position: absolute;
  top: 105px;
  left: 36px;
  text-align: center;

  .ui-typography {
    font-size: 20px;
    font-weight: 600;
  }
`;
