import { Params$FileMappingController_import } from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';

import { useApiClientMutation } from '@hooks';

const useImportFileMappingsById = () => {
  return useApiClientMutation<
    Params$FileMappingController_import,
    Schemas.TransactionIngest
  >({
    method: 'FileMappingController_import',
  });
};

export default useImportFileMappingsById;
