import { Flex } from 'antd';
import dayjs from 'dayjs';
import { FC, Ref } from 'react';

import { Schemas } from '@api-client/generated/types';
import Amount from '@entities/common/Amount';

import { BaseCornerCard } from '../../components/CornerCard/CornerCard';

type MatchingDocumentsModalProps = {
  paginationConfig: {
    data: Schemas.Document[];
    hasNextPage: boolean;
    isLoading: boolean;
    infiniteScrollRef: Ref<HTMLDivElement>;
  };
};

const MatchingDocumentsModal: FC<MatchingDocumentsModalProps> = ({
  paginationConfig,
}) => {
  return (
    <>
      <Flex
        gap={16}
        align="flex-end"
        wrap="wrap"
        justify="space-between"
        style={{ marginBottom: 12 }}
      >
        {paginationConfig.data.map((document) => (
          <BaseCornerCard
            key={document.id}
            contact={document.contact?.name}
            description={document.documentMetadata.description}
            formattedDate={dayjs(document.issueDate).format('DD.MM.YYYY')}
            formattedAmount={
              document.documentMetadata.amount && (
                <Amount
                  amount={document.documentMetadata.amount}
                  currencyCode={document.documentMetadata.currency || undefined}
                />
              )
            }
          />
        ))}
      </Flex>
    </>
  );
};

export default MatchingDocumentsModal;
