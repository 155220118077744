import styled from '@emotion/styled';
import { Flex } from 'antd';

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 457px;
  margin-bottom: 28px;
  padding-bottom: 28px;
  border-bottom: 2px solid ${({ theme }) => theme.colors.bgGrey};
`
export const Image = styled.img`
  width: 325px;
  height: 300px;
`;

export const TextContainer = styled(Flex)`
  max-width: 567px;
  text-align: center;
`

export const Title = styled.span`
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
`

export const Footer = styled(Flex)`
  margin-top: 40px;
`

export const Bold = styled.span`
  font-weight: 600;
`

export const Primary = styled.span`
  color: ${({ theme }) => theme.colors.primary};
`

export const LoaderWrapper = styled(Flex)`
  width: 246px;
`

export const Action = styled.span`
  font-weight: 600;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary};

  :hover {
    opacity: 0.7;
  }
`;
