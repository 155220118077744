import styled from '@emotion/styled';
import { Flex } from 'antd';

export const Wrapper = styled(Flex)`
  flex-direction: column;
  width: 326px;
`;

export const ListWrapper = styled(Flex)`
  flex-direction: column;
`;
