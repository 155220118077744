import styled from '@emotion/styled';
import { rgba } from 'emotion-rgba';

import { Input as TextInput, Form } from 'antd';

export const Header = styled.div`
  position: sticky;
  top: 80px;
  z-index: 3;
  background: ${({ theme }) => theme.colors.bgLightBlue200};
  margin: -50px -50px 0px -50px;
  padding: 20px 50px;
  border-bottom: 2px solid ${({ theme }) => theme.colors.strokeDarkGrey};
`;

export const Container = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.bgLightBlue200};
  border-radius: 12px;
  padding: 20px;
`;

export const Input = styled(TextInput)`
  font-size: 38px;
  font-weight: 600;
`;

export const SelectWrapper = styled(Form.Item)`
  width: 100%;
`;

export const LoaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  background: ${({ theme }) => rgba(theme.colors.dark, 0.2)};
  width: 100%;
  height: 100%;
`;

export const TableContainer = styled.div`
  .ui-table-row {
    cursor: pointer;
  }
  .ui-table-wrapper table {
    width: auto;
  }
  overflow-x: auto;
  max-width: 100%;
`;

export const Img = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 12px;
  max-height: 520px;
  object-fit: contain;
`;

export const Pdf = styled.iframe`
  width: 100%;
  height: 520px;
  border-radius: 12px;
`;
