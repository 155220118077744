import { Button, Flex, Form, Typography } from 'antd';

import { IconEye, IconMoreVertical } from '@assets';
import styled from '@emotion/styled';

export const Content = styled(Flex)`
  width: 100%;
`;

export const Item = styled(Form.Item)`
  margin-bottom: 16px;

  &&.ui-col,
  .ui-form-item-label {
    padding-bottom: 4px;
  }
`;

export const Wrapper = styled.div`
  width: 333px;
  height: 150px;
  position: relative;

  :before {
    content: '';
    width: 44px;
    height: 44px;
    background: transparent;
    border-left: 1px solid ${({ theme }) => theme.colors.bgDarkGrey};
    border-bottom: 1px solid ${({ theme }) => theme.colors.bgDarkGrey};
    border-top: none;
    border-right: none;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 6;
  }
`;

export const BlockTitle = styled.span`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;

export const AttachButton = styled(Button)`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  height: 20px;
  gap: 4px;
  align-items: center;
  padding: 0;

  && {
    svg {
      width: 12px;
      height: 12px;
    }
  }
`;

export const SupplementaryDocuments = styled(Flex)`
  padding: 20px;
  background: ${({ theme }) => theme.colors.badgeGrey};
  height: 100%;
  border-bottom-right-radius: 20px;
`;

export const SupplementaryItem = styled(Flex)`
  padding: 12px 0;

  :hover {
    .supplementary-item-actions {
      display: flex;
      gap: 12px;
    }
  }
`;

export const SupplementaryItemActions = styled.div`
  display: none;
  padding-left: 12px;
`;

export const SupplementaryItemInner = styled(Flex)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SupplementaryItemTitle = styled(Typography.Paragraph)`
  && {
    width: 100%;
    height: 20px;
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 0;
  }
`;

export const NoDocuments = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  color: ${({ theme }) => theme.colors.textDisabled};
`;

export const ViewIcon = styled(IconEye)`
  width: 20px;
  height: 20px;
  cursor: pointer;

  :hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const MoreIcon = styled(IconMoreVertical)`
  width: 20px;
  height: 20px;
  cursor: pointer;

  :hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const Preview = styled.div`
  min-width: 405px;
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Actions = styled.div`
  width: 233px;
`;

export const AttachNew = styled(Flex)`
  height: 38px;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.text400};
  padding: 8px;
  border-radius: 8px;
  font-weight: 400;

  &:hover {
    background-color: ${({ theme }) => theme.colors.bgBrightBlue};
  }
`;

export const UploadWrapper = styled.div`
  && .ui-upload {
    width: 100%;
  }
`;

export const UploadExisting = styled(Flex)`
  height: 38px;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.text400};
  padding: 8px;
  border-radius: 8px;
  font-weight: 400;

  &:hover {
    background-color: ${({ theme }) => theme.colors.bgBrightBlue};
  }
`;
