import { Button, Popover, type PopoverProps } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { useContactsController_findAll } from '@api-client/generated/ContactsController/findAll';
import { Schemas } from '@api-client/generated/types';
import { Scrollbar } from '@components';
import { DEFAULT_TIMEOUT_FOR_SEARCH } from '@constants';
import { ContactAction, ContactsSimpleList, SearchControl } from '@entities';
import { useAccount, useTranslate } from '@hooks';
import { useInfiniteScroll, usePagination } from '@hooks/useInfiniteScroll';

import * as S from './styled';

type Contact = Schemas.Contact;

type ContactsPopupListProps = {
  width?: number;
  selectedId?: string | null;
  onSelect: (contact: Contact) => void;
} & PopoverProps;

const ContactsPopupList: FC<ContactsPopupListProps> = ({
  width,
  children,
  selectedId,
  onSelect,
  ...rest
}) => {
  const { translate } = useTranslate();
  const { companyId } = useAccount();

  const [open, setOpen] = useState(false);
  const [isContactCreateVisible, setIsContactCreateVisible] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const {
    page,
    incrementPage,
    setTotalPages,
    hasNextPage,
    plainData,
    appendData,
    reset,
  } = usePagination<Contact>();

  const { isFetching } = useContactsController_findAll({
    params: {
      companyId: companyId!,
      term: searchValue,
      page,
    },
    config: {
      enabled: open,
      onSuccess: ({ records, metadata }) => {
        setTotalPages(metadata.totalPages);
        appendData(metadata.currentPage, records);
      },
    },
  });

  const { sentryRef, rootRef } = useInfiniteScroll({
    isLoading: isFetching,
    hasNextPage,
    onLoadMore: incrementPage,
  });

  const handleSearch = useDebouncedCallback((value) => {
    setSearchValue(value);
  }, DEFAULT_TIMEOUT_FOR_SEARCH);

  useEffect(() => {
    reset();
  }, [reset, searchValue]);

  const handleAddNewContact = () => {
    setIsContactCreateVisible(true);
    setOpen(false);
    setSearchValue('');
  };

  const handleSelectContact = (contact: Contact) => {
    onSelect(contact);

    setOpen(false);
    setSearchValue('');
  };

  const handleChangePopup = (status: boolean) => {
    setOpen(status);

    if (!status) {
      setSearchValue('');
    }
  };

  return (
    <>
      <ContactAction
        open={isContactCreateVisible}
        onCancel={() => setIsContactCreateVisible(false)}
      />

      <Popover
        open={open}
        content={
          <S.Content width={width} gap={4} vertical>
            <S.Inner>
              <SearchControl
                onChange={(e) => handleSearch(e.target.value)}
                fitWidth
              />
            </S.Inner>

            <S.Contacts>
              <Scrollbar ref={rootRef} height={276} withoutPadding>
                <ContactsSimpleList
                  selectedId={selectedId}
                  dataSource={plainData}
                  loading={isFetching}
                  onSelect={handleSelectContact}
                />

                {hasNextPage && <div ref={sentryRef} />}
              </Scrollbar>
            </S.Contacts>

            <S.Inner>
              <Button onClick={handleAddNewContact} size="small" block>
                {translate('contactsPage.popupContacts.buttonAdd')}
              </Button>
            </S.Inner>
          </S.Content>
        }
        trigger="click"
        placement="bottom"
        arrow={false}
        onOpenChange={handleChangePopup}
        {...rest}
      >
        {children}
      </Popover>
    </>
  );
};

export default ContactsPopupList;
