import { Table, TableColumnType } from 'antd';
import { FC, Ref } from 'react';

import { Schemas } from '@api-client/generated/types';
import { useTranslate } from '@hooks';

import getColumnsConfig from './getColumnsConfig';
import * as S from './styled';

type MatchingTransactionsModalProps = {
  paginationConfig: {
    data: Schemas.Transaction[];
    hasNextPage: boolean;
    isLoading: boolean;
    infiniteScrollRef: Ref<HTMLDivElement>;
  };
};

const MatchingTransactionsModal: FC<MatchingTransactionsModalProps> = ({
  paginationConfig,
}) => {
  const { translate } = useTranslate();

  const columns: TableColumnType<Schemas.Transaction>[] =
    getColumnsConfig(translate);

  return (
    <S.Wrapper gap={24} align="flex-end" vertical>
      <Table
        rowKey={({ id }) => id}
        dataSource={paginationConfig.data}
        columns={columns}
        loading={paginationConfig.isLoading}
        pagination={false}
        scroll={{ y: 406, scrollToFirstRowOnChange: false }}
        summary={() => (
          <>
            {paginationConfig.hasNextPage && (
              <div ref={paginationConfig.infiniteScrollRef} />
            )}
          </>
        )}
      />
    </S.Wrapper>
  );
};

export default MatchingTransactionsModal;
