import { message } from 'antd';
import { useCallback, useState } from 'react';

import { useRuleController_create } from '@api-client/generated/RuleController/create';
import { Schemas } from '@api-client/generated/types';
import { useAccount, useTranslate } from '@hooks';

import { AddRuleModal } from './modal/AddRuleModal/AddRuleModal';
import { CountedTitle } from './modal/AddRuleModal/CountedTitle';
import MatchingDocumentsModal from './modal/AddRuleModal/MatchingDocumentsModal';
import MatchingTransactionsModal from './modal/AddRuleModal/MatchingTransactionsModal';
import { Modal } from './modal/Modal';
import { RulePreview } from './modal/RulePreview';
import { useLinkedData } from './useLinkedData';
import { useMatchedDocuments } from './useMatchedDocuments';
import { useMatchedTransactions } from './useMatchedTransactions';

type PartialRuleDto<
  TAction extends Schemas.Action['name'] = Schemas.Action['name'],
> = {
  entityType: Schemas.RuleDto['entityType'];
  actions: Partial<{ name: TAction; value: string }>[];
  conditions: Partial<Schemas.Condition>[];
};

type FormValues<
  TAction extends Schemas.Action['name'] = Schemas.Action['name'],
> = Omit<PartialRuleDto<TAction>, 'entityType'>;

const isValidCondition = (
  condition: Partial<Schemas.Condition>
): condition is Schemas.Condition =>
  !!(condition.attribute && condition.operator && condition.value);

const isValidAction = (
  action: Partial<Schemas.Action>
): action is Schemas.Action =>
  !!(action.name && action.value) ||
  action.name === 'exclude_from_pl_report' ||
  action.name === 'mark_as_fileless';

const isValidRuleDto = (rule: PartialRuleDto): rule is Schemas.RuleDto =>
  rule.actions.every(isValidAction) && rule.conditions.every(isValidCondition);

const getRuleDto = (rule: PartialRuleDto) => {
  if (!isValidRuleDto(rule)) {
    throw new Error('Invalid Rule DTO');
  }

  return rule;
};

const emptyTransactionsState: FormValues = {
  conditions: [{ attribute: 'contact_name' }],
  actions: [{ name: 'assign_contact' }],
};

const emptyDocumentsState: FormValues<'assign_contact'> = {
  conditions: [{ attribute: 'contact_name' }],
  actions: [{ name: 'assign_contact' }],
};

type FormStep =
  | 'closed'
  | 'form'
  | 'review'
  | 'matching_transactions'
  | 'matching_documents';

export const useGetRules = ({
  onSuccess,
}: {
  onSuccess: VoidFunction;
}): {
  setStep: React.Dispatch<React.SetStateAction<FormStep>>;
  modalProps: Parameters<typeof Modal>[0];
} => {
  const { companyId } = useAccount();
  const { translate } = useTranslate();
  const { projects, categories, contacts } = useLinkedData();

  const [step, setStep] = useState<FormStep>('closed');

  const [transactionsState, setTransactionsState] = useState<FormValues>(
    emptyTransactionsState
  );

  const [documentsState, setDocumentsState] =
    useState<FormValues<'assign_contact'>>(emptyDocumentsState);

  const [activeTab, setActiveTab] = useState<'transaction' | 'file'>(
    'transaction'
  );

  const [createRule] = useRuleController_create();

  const matchingTransactions = useMatchedTransactions({
    conditions: transactionsState.conditions,
    enabled: transactionsState.conditions.every((condition) =>
      isValidCondition(condition)
    ),
  });

  const matchingDocuments = useMatchedDocuments({
    conditions: documentsState.conditions,
    enabled: documentsState.conditions.every((condition) =>
      isValidCondition(condition)
    ),
  });

  const handleChangeFormValues = useCallback(
    ({ conditions, actions }: Partial<FormValues>) => {
      const setState = <
        TAction extends Schemas.Action['name'] = Schemas.Action['name'],
      >(
        prev: FormValues<TAction>
      ): FormValues<TAction> => {
        const nextConditions = conditions
          ? {
              conditions: conditions,
            }
          : {};

        const nextActions = actions
          ? {
              actions: (actions as FormValues<TAction>['actions']).map(
                (item, index) =>
                  item.name !== prev.actions[index]?.name
                    ? { name: item.name }
                    : item
              ),
            }
          : {};

        return { ...prev, ...nextActions, ...nextConditions };
      };

      switch (activeTab) {
        case 'transaction':
          setTransactionsState(setState);
          break;
        case 'file':
          setDocumentsState(setState<'assign_contact'>);
          break;
      }
    },
    [activeTab]
  );

  const handleChangeActiveTab = useCallback((activeKey: string) => {
    const isValidKey = (str: string): str is 'transaction' | 'file' =>
      str === 'transaction' || str === 'file';

    if (!isValidKey(activeKey)) {
      throw new Error('Invalid tab key');
    }

    setActiveTab(activeKey);
  }, []);

  const handleCreateRule = () => {
    createRule(
      {
        parameter: {
          companyId: companyId!,
        },
        requestBody: getRuleDto(
          activeTab === 'transaction'
            ? { entityType: 'transaction', ...transactionsState }
            : { entityType: 'file', ...documentsState }
        ),
      },
      {
        onSuccess: () => {
          onSuccess();
          setStep('closed');
          setTransactionsState(emptyTransactionsState);
          setDocumentsState(emptyDocumentsState);
          message.success(translate('rules.ruleAddSuccess'));
        },
        onFailure: (err) => {
          message.error(err.message);
        },
      }
    );
  };

  const modalProps = (() => {
    const formValues: PartialRuleDto =
      activeTab === 'transaction'
        ? { entityType: 'transaction', ...transactionsState }
        : { entityType: 'file', ...documentsState };

    switch (step) {
      case 'closed':
        return {
          open: false,
          title: translate('rules.modal.title'),
          description: translate('rules.modal.description'),
        };
      case 'form':
        return {
          okText: translate('rules.modal.next'),
          title: translate('rules.modal.title'),
          description: translate('rules.modal.description'),
          okButtonProps: {
            disabled: !isValidRuleDto(formValues),
          },
          cancelButtonProps: { style: { display: 'none' } },
          onOk: () => setStep('review'),
          onCancel: () => setStep('closed'),
          open: true,
          children: (
            <AddRuleModal
              {...{
                activeTab,
                handleChangeActiveTab,
                handleChangeFormValues,
                matchingTransactions: {
                  totalPages: matchingTransactions.totalPages,
                  setIsOpen: () => setStep('matching_transactions'),
                  canBeShown:
                    !matchingTransactions.isLoading &&
                    transactionsState.conditions.every((condition) =>
                      isValidCondition(condition)
                    ),
                },
                matchingDocuments: {
                  totalPages: matchingDocuments.totalPages,
                  setIsOpen: () => setStep('matching_documents'),
                  canBeShown:
                    !matchingDocuments.isLoading &&
                    documentsState.conditions.every((condition) =>
                      isValidCondition(condition)
                    ),
                },
                formValues: {
                  transaction: transactionsState,
                  file: documentsState,
                },
              }}
            />
          ),
        };
      case 'review':
        return {
          okText: translate('rules.rulePreview.submit'),
          cancelText: translate('rules.rulePreview.goBack'),
          title: translate('rules.rulePreview.title'),
          description: translate('rules.rulePreview.description'),
          okButtonProps: { style: { marginLeft: 16 } },
          cancelButtonProps: {
            onClick: () => setStep('form'),
          },
          onOk: handleCreateRule,
          onCancel: () => setStep('closed'),
          open: true,
          children: (
            <RulePreview
              item={getRuleDto(formValues)}
              projects={projects}
              categories={categories}
              contacts={contacts}
            />
          ),
        };
      case 'matching_transactions':
        return {
          title: (
            <CountedTitle
              count={matchingTransactions.totalPages}
              titleKey="rules.modal.transaction.matchingTransactions"
            />
          ),
          open: true,
          onCancel: () => setStep('form'),
          okButtonProps: { style: { display: 'none' } },
          cancelText: translate('rules.modal.goBack'),
          width: 1018,
          children: (
            <MatchingTransactionsModal
              paginationConfig={{
                data: matchingTransactions.data,
                hasNextPage: matchingTransactions.hasNextPage,
                isLoading: matchingTransactions.isLoading,
                infiniteScrollRef: matchingTransactions.infiniteScrollRef,
              }}
            />
          ),
        };
      case 'matching_documents':
        return {
          title: (
            <CountedTitle
              count={matchingDocuments.totalPages}
              titleKey="rules.modal.document.matchingDocuments"
            />
          ),
          open: true,
          onCancel: () => setStep('form'),
          okButtonProps: { style: { display: 'none' } },
          cancelText: translate('rules.modal.goBack'),
          width: 866,
          children: (
            <MatchingDocumentsModal
              paginationConfig={{
                data: matchingDocuments.data,
                hasNextPage: matchingDocuments.hasNextPage,
                isLoading: matchingDocuments.isLoading,
                infiniteScrollRef: matchingDocuments.infiniteScrollRef,
              }}
            />
          ),
        };
    }
  })();

  return {
    setStep,
    modalProps,
  };
};
