import styled from '@emotion/styled';
import { IconDocuments } from '@assets';
import { Flex, Typography } from 'antd';

export const Container = styled(Flex)`
  height: 100%;
  padding: 80px 0;
`;

export const Icon = styled(IconDocuments)`
  color: ${({ theme }) => theme.colors.white};
  padding: 18px;
  height: 72px;
  width: 72px;
`;

export const IconContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.text200};
  height: 72px;
  width: 72px;
  border-radius: 50%;
`;

export const Title = styled(Typography.Title)`
  && {
    padding: 0;
    margin-bottom: 0;
    color: ${({ theme }) => theme.colors.text400};
  }
`;

export const Description = styled(Typography.Text)`
  color: ${({ theme }) => theme.colors.text400};
  text-align: center;
`;
