import styled from '@emotion/styled';

import { InputNumber, Typography } from 'antd';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  .ui-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
  }
`;

export const Inner = styled.div`
  margin-bottom: 24px;
`;

export const FormInner = styled.div`
  background-color: ${({ theme }) => theme.colors.bgGrey};
  border-radius: 8px;
  padding: 14px 20px;
  margin-bottom: 16px;
`;

export const FormSubInner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
`;

export const FormItem = styled.div`
  padding: 14px 0;

  &:not(:last-child) {
    border-bottom: 2px solid ${({ theme }) => theme.colors.strokeDarkGrey};
  }
`;

export const FormLabel = styled.div`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 12px;
  padding: 0;
`;

export const FormSubLabel = styled.div`
  text-align: right;
`;

export const ButtonAdd = styled.a`
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 600;

  .ui-icon {
    font-size: 16px;
  }
`;

export const Action = styled.div`
  padding: 10px 5px;
`;

export const Total = styled.div`
  color: ${({ theme }) => theme.colors.secondary};
`;

export const ButtonRemove = styled.a`
  color: ${({ theme }) => theme.colors.secondary};
  display: inline-flex;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
`;

export const NumberInput = styled(InputNumber)`
  width: 100%;
`;

export const ErrorText = styled.div`
  color: ${({ theme }) => theme.colors.error};
`;

export const ExtraText = styled(Typography.Paragraph)`
  &.ui-typography {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.textBlue400};
  }
`;
