import { FC, useState } from 'react';

import { Row, Col, Typography } from 'antd';

import { Select } from '@components';
import {
  OnboardingCardHeader,
  OnboardingCompanyTypeAccountingCard,
} from '@entities';
import { useOnboarding, useTranslate } from '@hooks';

import * as S from './styled';

const { Paragraph } = Typography;

const OnboardingCompanyTypeAccounting: FC = () => {
  const { translate } = useTranslate();

  const { incorporationDetails } = useOnboarding();

  const [planId, setPlanId] = useState(
    incorporationDetails?.accountingPlanId || 1
  );

  // TODO: pull from API when it's clear how it's managed
  const prices = [
    {
      id: 1,
      amount: 90,
    },
    {
      id: 2,
      amount: 180,
    },
    {
      id: 3,
      amount: 270,
    },
    {
      id: 4,
      amount: 396,
    },
    {
      id: 5,
      amount: 495,
    },
    {
      id: 6,
      amount: 810,
    },
    {
      id: 7,
      amount: null,
    },
  ];

  const getPriceForPlan = (planId: number) =>
    prices.find((person) => person.id === planId)?.amount;

  return (
    <>
      <OnboardingCardHeader
        title={translate('onboarding.accountingService.header.title')}
        description={
          <Paragraph>
            {translate('onboarding.accountingService.header.description')}
          </Paragraph>
        }
      />

      <Row gutter={[25, 0]}>
        <Col span={12}>
          <S.Wrapper>
            <S.Label>
              {translate('onboarding.accountingService.turnover.label')}
            </S.Label>

            <Select
              options={[
                {
                  label: '€0-€75,000',
                  value: 1,
                },
                {
                  label: '€75,000-€150,000',
                  value: 2,
                },
                {
                  label: '€150,000-€300,000',
                  value: 3,
                },
                {
                  label: '€300,000-€500,000',
                  value: 4,
                },
                {
                  label: '€500,000-€1,000,000',
                  value: 5,
                },
                {
                  label: '€1,000,000-€2,000,000',
                  value: 6,
                },
                {
                  label: '€2,000,000 and more',
                  value: 7,
                },
              ]}
              value={planId}
              onSelect={(value) => setPlanId(value)}
              size="large"
            />
          </S.Wrapper>
        </Col>

        <Col span={12}>
          <OnboardingCompanyTypeAccountingCard
            price={getPriceForPlan(planId) as number}
            planId={planId}
          />
        </Col>
      </Row>
    </>
  );
};

export default OnboardingCompanyTypeAccounting;
