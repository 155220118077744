import styled from '@emotion/styled';

export const Container = styled.div`
  padding-top: 4px;

  .ui-table {
    background: transparent;

    .ui-table-thead,
    .ui-table-tbody {
      .ui-table-cell {
        background: transparent;
      }
    }
  }
`;
