import { FC } from 'react';

import { Flex, Row, Col } from 'antd';

import { Scrollbar, CopyButton } from '@components';

import { useTranslate } from '@hooks';

import { getAddressLine } from '@utils';

import { Schemas } from '@api-client/generated/types';

import * as S from './styled';

type ContactsDetailsProps = {
  details: Schemas.Contact;
};

const ContactsDetails: FC<ContactsDetailsProps> = ({
  details: {
    name,
    taxNumber,
    vatId,
    billingAddress,
    email,
    contactPerson,
    phone,
  },
}) => {
  const { translate } = useTranslate();

  const addressLine = getAddressLine(billingAddress);

  return (
    <Scrollbar height="calc(100vh - 385px)">
      <Row gutter={[30, 0]}>
        <Col span={11}>
          <S.Card gap={20} vertical>
            <Flex align="center" justify="space-between" gap={24}>
              <Flex gap={2} vertical>
                <S.Label>
                  {translate('contactsPage.details.companyName')}
                </S.Label>
                <S.Value>{name}</S.Value>
              </Flex>

              {name && (
                <S.Copy>
                  <CopyButton value={name} />
                </S.Copy>
              )}
            </Flex>

            <Flex align="center" justify="space-between" gap={24}>
              <Flex gap={2} vertical>
                <S.Label>{translate('contactsPage.details.taxNumber')}</S.Label>
                <S.Value>{taxNumber || '-'}</S.Value>
              </Flex>

              {taxNumber && (
                <S.Copy>
                  <CopyButton value={taxNumber} />
                </S.Copy>
              )}
            </Flex>

            <Flex align="center" justify="space-between" gap={24}>
              <Flex gap={2} vertical>
                <S.Label>{translate('contactsPage.details.vatId')}</S.Label>
                <S.Value>{vatId || '-'}</S.Value>
              </Flex>

              {vatId && (
                <S.Copy>
                  <CopyButton value={vatId} />
                </S.Copy>
              )}
            </Flex>

            <Flex align="center" justify="space-between" gap={24}>
              <Flex gap={2} vertical>
                <S.Label>{translate('contactsPage.details.address')}</S.Label>
                {billingAddress && <S.Value>{addressLine}</S.Value>}
              </Flex>

              {billingAddress && (
                <S.Copy>
                  <CopyButton value={addressLine} />
                </S.Copy>
              )}
            </Flex>

            <Flex align="center" justify="space-between" gap={24}>
              <Flex gap={2} vertical>
                <S.Label>
                  {translate('contactsPage.details.contactPerson')}
                </S.Label>

                {email || contactPerson || phone ? (
                  <S.Value vertical>
                    {email && <span>{email}</span>}
                    {contactPerson && <span>{contactPerson}</span>}
                    {phone && <span>{phone}</span>}
                  </S.Value>
                ) : (
                  <S.Value vertical>-</S.Value>
                )}
              </Flex>

              <S.Copy />
            </Flex>
          </S.Card>
        </Col>

        {/* <Col span={9}>
        <S.Rules gap={2} vertical>
          <S.RulesTitle>
            {translate('contactsPage.details.alternative.title')}
          </S.RulesTitle>

          <S.RulesDescription>
            {translate('contactsPage.details.alternative.description')}
          </S.RulesDescription>

          <S.RulesApply>
            {translate('contactsPage.details.alternative.buttonApply')}
          </S.RulesApply>
        </S.Rules>
      </Col> */}
      </Row>
    </Scrollbar>
  );
};

export default ContactsDetails;
