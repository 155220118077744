import { DatePicker, type DatePickerProps } from 'antd';
import { Dayjs } from 'dayjs';

import styled from '@emotion/styled';

const BaseDatePicker: React.FC<
  DatePickerProps & {
    onChange: (date: Dayjs, dateString: string | string[]) => void;
  }
> = DatePicker;

export const DatePickerControl = styled(BaseDatePicker)`
  && {
    &.ui-picker {
      width: 0;
      visibility: hidden;
      padding: 0;
    }
  }
`;

export const Action = styled.div`
  display: inline-flex;
`;
