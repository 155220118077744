import styled from '@emotion/styled';
import { Flex } from 'antd';

export const Container = styled(Flex)`
  position: relative;
`;

export const CardWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 60px;
  z-index: 9;
`;
