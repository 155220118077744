import { Button, Flex, Typography } from 'antd';

import styled from '@emotion/styled';

import { SubItemType } from '../../../pages/Categories/Categories';

export const Wrapper = styled(Flex)`
  justify-content: space-between;
  padding: 0 16px;
`;

export const Container = styled(Flex, {
  shouldForwardProp: (prop) => prop !== 'isin',
})<SubItemType>`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid
    ${({ theme, isin }) => (isin ? theme.colors.success : theme.colors.error)};
`;

export const HeaderButton = styled(Button)`
  gap: 6px;
  padding: 10px 0;
  width: auto;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.text400};
  font-size: 14px;
  font-weight: 600;
`;

export const Title = styled(Typography)`
  font-weight: 600;
  font-size: 18px;
`;
