import styled from '@emotion/styled';

import { Button, Flex } from 'antd';
import { Select } from '@components';

export const Wrapper = styled(Flex)`
  width: 100%;
  border-radius: 10px;
  border: 1px solid ${({theme}) => theme.colors.strokeGrey};
`

export const Content = styled(Flex)`
  padding: 16px;
  background-color: ${({theme}) => theme.colors.strokeDarkGrey};
`

export const Title = styled.span`
  font-weight: 600;
`

export const ListSelect = styled(Select)`
  height: 40px;
  width: 100%;
`

export const Footer = styled(Flex)`
  padding: 16px;
`

export const DetachButton = styled(Button)`
  && {
    height: 20px;
    padding: 0;
    font-weight: 600;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.error};
    gap: 8px;

    && {
      :hover {
        color: ${({ theme }) => theme.colors.error};
        opacity: 0.6;
      }
    }
  }
`;
