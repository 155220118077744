import { FC, PropsWithChildren } from 'react';

import * as S from './styled';

type OnboardingCardProps = {
  isAutoHeight?: boolean;
};

const OnboardingCard: FC<PropsWithChildren<OnboardingCardProps>> = ({
  isAutoHeight,
  children,
}) => <S.Container isAutoHeight={isAutoHeight}>{children}</S.Container>;

export default OnboardingCard;
