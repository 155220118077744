import { Params$FileMappingController_validate } from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';

import { useApiClientMutation } from '@hooks';

const useValidateFileMappingsById = () => {
  return useApiClientMutation<
    Params$FileMappingController_validate,
    Schemas.ValidationResult
  >({
    method: 'FileMappingController_validate',
  });
};

export default useValidateFileMappingsById;
