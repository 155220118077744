import { FC, useEffect, useState } from 'react';

import { useTranslate } from '@hooks';
import { PaymentDocumentType } from '@entities';

import * as S from './styled';

type ControlType = 'unpaid' | 'paid';

type PaymentDocumentSwitchControlProps = {
  type: PaymentDocumentType;
  status: string;
  onChange?: (type: ControlType) => void;
};

const PaymentDocumentSwitchControl: FC<PaymentDocumentSwitchControlProps> = ({
  status,
  onChange,
}) => {
  const { translate } = useTranslate();

  const [selectedKey, setSelectedKey] = useState<string>(status);

  useEffect(() => {
    setSelectedKey(status);
  }, [status]);

  const handleChange = (type: ControlType) => {
    setSelectedKey(type);
    onChange?.(type);
  };

  return (
    <S.Container align="center">
      <S.Control
        type="unpaid"
        onClick={() => handleChange('unpaid')}
        active={selectedKey === 'unpaid'}
      >
        {translate('income.details.tabs.unpaid')}
      </S.Control>

      <S.Control
        type="paid"
        onClick={() => handleChange('paid')}
        active={selectedKey === 'paid'}
      >
        {translate('income.details.tabs.paid')}
      </S.Control>
    </S.Container>
  );
};

export default PaymentDocumentSwitchControl;
