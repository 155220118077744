import { FC, ReactNode } from 'react';

import * as S from './styled';

type OnboardingCardProps = {
  title: string;
  description: ReactNode;
  picture: ReactNode;
};

const OnboardingMessage: FC<OnboardingCardProps> = ({
  picture,
  title,
  description,
}) => (
  <S.Container>
    <S.Picture>{picture}</S.Picture>
    <S.Title level={4}>{title}</S.Title>
    <S.Description>{description}</S.Description>
  </S.Container>
);

export default OnboardingMessage;
