import { MultipleSelect } from '@components';
import styled from '@emotion/styled';

export const MultipleWrapper = styled(MultipleSelect)`
  && {
    &.ui-select {
      min-width: 98px;
    }
  }
`;
