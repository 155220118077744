import { Flex } from 'antd';
import dayjs from 'dayjs';
import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Schemas } from '@api-client/generated/types';
import { Amount, PaymentDocumentType } from '@entities';
import { useAccount, useTranslate } from '@hooks';

import * as S from './styled';

type IncomeInvoiceProps = {
  type: PaymentDocumentType;
  selectedId?: string;
  file: Schemas.Document;
  onSelectedInvoice?: (id: string) => void;
};

const PaymentDocumentInvoice: FC<IncomeInvoiceProps> = ({
  type,
  selectedId,
  file,
  onSelectedInvoice,
}) => {
  const { translate } = useTranslate();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { userAccess } = useAccount();

  const isPaid = !!file.transactions?.length;

  const handleSelectInvoice = (id: string) => {
    navigate(`/${type}/${id}${search}`);
    onSelectedInvoice?.(id);
  };

  return (
    <S.Invoice
      gap={4}
      selected={selectedId === file.id}
      reviewed={!!file.documentMetadata.isReviewed}
      onClick={() => handleSelectInvoice?.(file.id)}
      justify="space-between"
      vertical
    >
      <Flex align="center" justify="space-between" gap={16}>
        <S.Name data-testid="name">{file?.contact?.name || file.name}</S.Name>

        <S.Status paid={isPaid} data-testid="status">
          {isPaid
            ? translate('income.statuses.paid')
            : translate('income.statuses.unpaid')}
        </S.Status>
      </Flex>

      {file.documentMetadata?.description && (
        <S.Description ellipsis>
          {file.documentMetadata?.description}
        </S.Description>
      )}

      <Flex justify="space-between" align="center">
        {file.documentMetadata.amount && (
          <S.Amount>
            <Amount
              amount={file.documentMetadata.amount!}
              currencyCode={file.documentMetadata.currency!}
              withoutType
            />
          </S.Amount>
        )}

        {!isPaid && userAccess?.transactions && (
          <S.Missing>{translate(`${type}.statuses.missing`)}</S.Missing>
        )}

        {file.documentMetadata.issueDate && (
          <S.Date data-testid="date">
            {dayjs(file.documentMetadata.issueDate).format('DD.MM.YYYY')}
          </S.Date>
        )}
      </Flex>
    </S.Invoice>
  );
};

export default PaymentDocumentInvoice;
