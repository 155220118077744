import { useContext } from 'react';

import { ProjectsContext } from '@context';

const useProjects = () => {
  const projects = useContext(ProjectsContext);

  return projects;
};

export default useProjects;
