import { FC, PropsWithChildren } from 'react';
import { message, Upload, type UploadProps } from 'antd';

import { useAccount, useTranslate } from '@hooks';
import { useDocumentCreate } from '@hooks-api';
import { PAYMENT_DOCUMENT_VALID_EXTENSIONS } from '@constants';
import { PaymentDocumentType } from '@entities';

import { Schemas } from '@api-client/generated/types';

import * as S from './styled';

type PaymentDocumentImportInvoiceProps = {
  documentType: PaymentDocumentType;
  onUpdate?: (file: Schemas.Document) => void;
} & UploadProps;

const PaymentDocumentImportInvoice: FC<
  PropsWithChildren<PaymentDocumentImportInvoiceProps>
> = ({ documentType, children, onUpdate, ...props }) => {
  const { translate } = useTranslate();
  const { companyId } = useAccount();

  const translationPrefix = documentType === 'income' ? 'income' : 'expenses';

  const [uploadDocument] = useDocumentCreate();

  const handleBeforeUpload = (file: File) => {
    const extension = `.${file.name.split('.').pop()}`.toLowerCase();

    if (!PAYMENT_DOCUMENT_VALID_EXTENSIONS.includes(extension)) {
      message.error(
        translate(`${translationPrefix}.errorExtensionsUpload`, {
          formats: PAYMENT_DOCUMENT_VALID_EXTENSIONS.join(', '),
          extension,
        })
      );

      return Upload.LIST_IGNORE;
    }

    return true;
  };

  const handleImportInvoice = (options: any) => {
    const formData = new FormData();

    formData.append('file', options.file);
    formData.append('name', options.file.name);
    formData.append(
      'type',
      documentType === 'income' ? 'income_document' : 'expence_document'
    );

    uploadDocument(
      {
        parameter: {
          companyId: companyId!,
        },
        requestBody: formData as any,
      },
      {
        onSuccess: (response) => {
          onUpdate?.(response);
        },
      }
    );
  };

  return (
    <S.Upload
      accept={PAYMENT_DOCUMENT_VALID_EXTENSIONS.join(',')}
      beforeUpload={handleBeforeUpload}
      customRequest={handleImportInvoice}
      showUploadList={false}
      multiple
      {...props}
    >
      {children}
    </S.Upload>
  );
};

export default PaymentDocumentImportInvoice;
