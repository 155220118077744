import styled from '@emotion/styled';
import { Flex, FlexProps, Modal, Typography } from 'antd';
import { rgba } from 'emotion-rgba';

export const PreviewModal = styled(Modal)`
  && {
    padding: 0;
    top: 50px;
    
    .ui-modal-content {
      padding: 0;
    }
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.bgGrey};
  border: 1px solid ${({ theme }) => theme.colors.bgDarkGrey};
  box-shadow: 0 3px 8px 0 ${({ theme }) => rgba(theme.colors.dark, 0.1)};
  border-radius: 10px;
  display: flex;
  gap: 30px;
  padding: 30px;
  flex: 1;
`;

export const FullWidth = styled(Flex)`
  width: 100%;
`

export const FormWrapper = styled.div`
  max-width: 440px;
  flex: 1;
`;

export const FormHeader = styled(Flex)`
  margin-bottom: 8px;
`;

export const TitleDetails = styled(Typography.Title)`
  &.ui-typography {
    font-size: 16px;
    margin-bottom: 0;
  }
`;

export const Container = styled.div`
  position: relative;
`;

export const Content = styled(Flex)<FlexProps & { hidden: boolean }>`
  opacity: ${({ hidden }) => (hidden ? 0 : 1)};
`;

export const Loader = styled.div`
  width: 100%;
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
`;


export const UploadSkeletonTitle = styled.span`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: ${({theme}) => theme.colors.text400};
`

export const UploadSkeletonDescription = styled.span`
  width: 212px;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: ${({theme}) => theme.colors.text400};
`
