import styled from '@emotion/styled';
import { Button, Col, Flex, Input, Upload } from 'antd';

export const Header = styled(Flex)`
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 2px solid ${({theme}) => theme.colors.strokeGrey}
`

export const Inner = styled.div`
  margin-bottom: 24px;
`;

export const Step = styled(Col)`
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.bgGrey};
  border-radius: 12px;
`

export const StepCount = styled(Flex)`
  display: flex;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background: ${({theme}) => theme.colors.text400};
  
  color: ${({theme}) => theme.colors.white};
  font-size: 12px;
  font-weight: 600;
`

export const StepText = styled.span`
  font-size: 12px;
  color: ${({theme}) => theme.colors.black};
  line-height: 20px
`

export const DownloadInput = styled(Input)`
  height: 48px;
  cursor: pointer;
  
  &&.ui-input, input {
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    color: ${({theme}) => theme.colors.black};
  }
`

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;

  .ui-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;

    .ui-form-item {
      margin-bottom: 0;

      .ui-form-item-label {
        label {
          font-size: 12px;
        }
      }
    }
  }
`;

export const Uploading = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.strokeGrey};
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  gap: 12px;
  padding: 12px 16px;

  .ui-spin {
    .ui-spin-dot {
      font-size: 24px;
    }
  }
`;

export const FileList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const FileItem = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.strokeGrey};
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  gap: 12px;
  padding: 12px;
`;

export const FileName = styled.a`
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const FormLabel = styled.div`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 12px;
  padding: 0;
`;

export const UploadFileCol = styled(Col)`
  &&.ui-col {
    .ui-form-item {
      margin-bottom: 0;
    }
  }
`;

export const UploadFile = styled(Upload.Dragger)`
  &&.ui-upload-wrapper {
    &&.ui-upload,
    .ui-upload-drag {
      background-color: ${({ theme }) => theme.colors.white};
      color: ${({ theme }) => theme.colors.secondary};
    }
  }
`;

export const UploadContent = styled(Flex)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  font-size: 12px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.text400};
`;

export const UploadInner = styled(Flex)`
  flex-direction: column;
  gap: 6px;
  align-items: center;
`;

export const UploadButton = styled(Button)`
  font-weight: 600;
  height: 40px;
`;
