import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Flex, Upload } from 'antd';

import { StatusTag } from '@entities';
import { useAccount, useTranslate } from '@hooks';
import { BankDefaultLogotype, IconEditUnderline } from '@assets';
import { formatNumber, getCurrencySymbol, getDateDefault } from '@utils';
import { Schemas } from '@api-client/generated/types';
import { Loader } from '@components';
import { useCreateFileMappings } from '@hooks-api';

import type { UploadRequestOption } from 'rc-upload/lib/interface';

import * as S from './styled';

type Account = Schemas.Account;
type Balance = Schemas.Balance;

type AccountItemProps = {
  logo: string;
  account: Account;
  onEdit?: (account: Account) => void;
  onImportModalOpen?: (value: boolean) => void;
  onAccountIdChange?: (id: string) => void;
};

const AccountItem: FC<AccountItemProps> = ({
  logo,
  account,
  onEdit,
  onImportModalOpen,
  onAccountIdChange,
}) => {
  const { translate } = useTranslate();
  const navigate = useNavigate();
  const { companyId, userAccess } = useAccount();

  const [createFileMappings, createFileLoading] = useCreateFileMappings();

  const [isErrorLogo, setIsErrorLogo] = useState(false);

  const isNotConnectedAccount = !account.connection;
  const balance = account.balances.find((balance: Balance) => balance.balanceType === 'calculated') || account.balances[0];

  const handleFileAttach = (options: UploadRequestOption) => {
    onAccountIdChange?.(account.id);
    createFileMappings(
      {
        parameter: {
          companyId: companyId!,
        },
        requestBody: {
          accountId: account.id!,
          mapping: {
            topRowsSkipAmount: 0,
            bottomRowsSkipAmount: 0,
          } as Schemas.CSVMapping,
          file: options.file as string,
        },
      },
      {
        onSuccess: () => {
          if (isNotConnectedAccount) {
            navigate(`/transactions-import/${account.id}`);
          } else {
            onImportModalOpen?.(true);
          }
        },
      }
    );
  };

  return (
    <S.Wrapper gap={24} align="flex-start" justify="space-between" vertical>
      <S.Container align="center" justify="space-between" gap={16}>
        <Flex gap={8} align="center" justify="flex-start">
          <S.Logo
            src={isErrorLogo ? BankDefaultLogotype : logo}
            alt={account.accountName}
            onError={() => setIsErrorLogo(true)}
          />

          <S.Name className="accountName">
            {account.accountName || account.accountNumber}
          </S.Name>
        </Flex>

        {isNotConnectedAccount && (
          <S.Edit onClick={() => onEdit?.(account)} className="editBtn">
            <IconEditUnderline />
          </S.Edit>
        )}
      </S.Container>

      <S.Container vertical>
        <S.Header gap={4} vertical>
          <S.Amount>
            {getCurrencySymbol(balance?.balanceAmount.currency)}
            {formatNumber(balance?.balanceAmount.amount)}
          </S.Amount>

          {account.lastSyncedAt && (
            <S.Cell>
              <span>{translate('accounts.syncedOn')}</span>
              <span>{getDateDefault(account.lastSyncedAt)}</span>
            </S.Cell>
          )}
        </S.Header>

        <Flex justify="space-between" align="center">
          <StatusTag status={account.status ? account.status : 'manually'} />

          {userAccess?.transactions &&
            <Upload customRequest={handleFileAttach} showUploadList={false}>
              {createFileLoading ? (
                <Loader />
              ) : (
                <S.Action>{translate('accounts.import.importCsv')}</S.Action>
              )}
            </Upload>
          }
        </Flex>
      </S.Container>
    </S.Wrapper>
  );
};

export default AccountItem;
