import { useContext } from 'react';

import { TeamMembersContext } from '@context';

const useTeamMembers = () => {
  const teamMembers = useContext(TeamMembersContext);

  return teamMembers;
};

export default useTeamMembers;
