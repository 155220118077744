import { FC, useState } from 'react';

import { type ModalProps, Row, Col, Button, Flex, Form, Tooltip } from 'antd';

import {
  SelectVatType,
  InvoiceWrapperModal,
  InvoiceWrapperCard,
  InvoiceWrapperFormControl,
} from '@entities';

import { DeafSelect } from '@components';

import { useTranslate, useCountries } from '@hooks';

import { IconInfo } from '@assets';

import * as S from './styled';

type InvoiceDto = any;

type InvoiceEditProps = {
  onSubmit?: (values: Partial<InvoiceDto>) => void;
  onCancel: () => void;
} & ModalProps;

const InvoiceEdit: FC<InvoiceEditProps> = ({ open, onSubmit, onCancel }) => {
  const { translate } = useTranslate();

  const [form] = Form.useForm();

  const [isSelectTypeVisible, setIsSelectTypeVisible] = useState(false);

  const { countries } = useCountries();

  const handleSubmit = (values: InvoiceDto) => {
    onSubmit?.(values);
  };

  return (
    <InvoiceWrapperModal
      open={open}
      title={
        <Flex gap={8} vertical>
          {translate('invoiceGenerator.data.title')}

          <S.Description>
            {translate('invoiceGenerator.data.description')}
          </S.Description>
        </Flex>
      }
      onCancel={onCancel}
    >
      <SelectVatType
        open={isSelectTypeVisible}
        onCancel={() => setIsSelectTypeVisible(false)}
        onChange={() => {}}
      />

      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        requiredMark={false}
        initialValues={{
          billingAddress: {
            countryCode: 'LU',
          },
        }}
      >
        <InvoiceWrapperCard>
          <Row gutter={[12, 0]}>
            <InvoiceWrapperFormControl
              type="input"
              span={12}
              form={{
                label: translate('invoiceGenerator.data.fieldFirstName.label'),
                name: 'firstName',
              }}
            />

            <InvoiceWrapperFormControl
              type="input"
              span={12}
              form={{
                label: translate('invoiceGenerator.data.fieldLastName.label'),
                name: 'lastName',
              }}
            />
          </Row>

          <InvoiceWrapperFormControl
            type="input"
            form={{
              label: translate('invoiceGenerator.data.fieldCompanyName.label'),
              name: 'companyName',
            }}
          />
        </InvoiceWrapperCard>

        <InvoiceWrapperCard>
          <Row gutter={[12, 0]}>
            <InvoiceWrapperFormControl
              type="input"
              span={12}
              form={{
                label: translate('invoiceGenerator.data.fieldVatId.label'),
                name: 'vatId',
              }}
              control={{
                placeholder: translate(
                  'invoiceGenerator.data.fieldVatId.placeholder'
                ),
              }}
            />

            <Col span={12}>
              <Form.Item
                label={translate('invoiceGenerator.data.fieldVatType.label')}
              >
                <DeafSelect
                  placeholder={translate(
                    'invoiceGenerator.data.fieldVatType.placeholder'
                  )}
                  size="large"
                  onClick={() => setIsSelectTypeVisible(true)}
                />
              </Form.Item>
            </Col>

            <InvoiceWrapperFormControl
              type="input"
              span={8}
              form={{
                label: (
                  <Flex align="center" gap={6}>
                    {translate('invoiceGenerator.data.fieldTaxNumber.label')}

                    <Tooltip
                      title={translate(
                        'invoiceGenerator.data.fieldTaxNumber.info'
                      )}
                      placement="bottom"
                    >
                      <IconInfo />
                    </Tooltip>
                  </Flex>
                ),
                name: 'number',
              }}
              control={{
                placeholder: translate(
                  'invoiceGenerator.data.fieldTaxNumber.placeholder'
                ),
              }}
            />

            <InvoiceWrapperFormControl
              type="input"
              span={8}
              form={{
                label: translate(
                  'invoiceGenerator.data.fieldPermitNumber.label'
                ),
                name: 'permitNumber',
                rules: [{ required: true }],
              }}
              control={{
                placeholder: translate(
                  'invoiceGenerator.data.fieldPermitNumber.placeholder'
                ),
              }}
            />

            <InvoiceWrapperFormControl
              type="input"
              span={8}
              form={{
                label: (
                  <Flex align="center" gap={6}>
                    {translate(
                      'invoiceGenerator.data.fieldRegistrationNumber.label'
                    )}

                    <Tooltip
                      title={translate(
                        'invoiceGenerator.data.fieldRegistrationNumber.info'
                      )}
                      placement="bottom"
                    >
                      <IconInfo />
                    </Tooltip>
                  </Flex>
                ),
                name: 'registrationNumber',
                rules: [{ required: true }],
              }}
              control={{
                placeholder: translate(
                  'invoiceGenerator.data.fieldRegistrationNumber.placeholder'
                ),
              }}
            />
          </Row>
        </InvoiceWrapperCard>

        <InvoiceWrapperCard>
          <Row gutter={[12, 0]}>
            <InvoiceWrapperFormControl
              type="input"
              span={10}
              form={{
                label: translate('invoiceGenerator.data.fieldStreet.label'),
                name: ['billingAddress', 'street'],
              }}
            />

            <InvoiceWrapperFormControl
              type="input"
              span={7}
              form={{
                label: translate('invoiceGenerator.data.fieldNumber.label'),
                name: ['billingAddress', 'number'],
              }}
            />

            <InvoiceWrapperFormControl
              type="input"
              span={7}
              form={{
                label: translate('invoiceGenerator.data.fieldPostalCode.label'),
                name: ['billingAddress', 'postalCode'],
              }}
            />

            <InvoiceWrapperFormControl
              type="select"
              span={10}
              form={{
                label: translate('invoiceGenerator.data.fieldCountry.label'),
                name: ['billingAddress', 'countryCode'],
              }}
              control={{
                options: countries,
              }}
            />

            <InvoiceWrapperFormControl
              type="input"
              span={14}
              form={{
                label: translate('invoiceGenerator.data.fieldCity.label'),
                name: ['billingAddress', 'city'],
              }}
            />
          </Row>
        </InvoiceWrapperCard>

        <S.Submit>
          <Form.Item noStyle>
            <Flex justify="flex-end" gap={12}>
              <Button onClick={onCancel}>
                {translate('invoiceGenerator.buttonCancel')}
              </Button>

              <Button type="primary" htmlType="submit">
                {translate('invoiceGenerator.buttonSave')}
              </Button>
            </Flex>
          </Form.Item>
        </S.Submit>
      </Form>
    </InvoiceWrapperModal>
  );
};

export default InvoiceEdit;
