import styled from '@emotion/styled';
import { Flex } from 'antd';

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 32px;

  && {
    .ui-typography {
      margin-bottom: 0;
    }

    .ui-segmented {
      .ui-segmented-item {
        &.ui-segmented-item-selected {
          background-color: ${({ theme }) => theme.colors.primary};

          .ui-segmented-item-label {
            color: ${({ theme }) => theme.colors.white};
          }
        }

        .ui-segmented-item-label {
          min-height: 38px;
          line-height: 38px;
          font-weight: 500;
          padding: 0 12px;
          text-transform: none;
        }
      }
    }
  }
`;

export const SegmentedItem = styled(Flex)`
  height: 40px;
  text-transform: capitalize;
`

export const Divider = styled.div`
  height: 40px;
  width: 2px;
  background-color: ${({theme}) => theme.colors.bgDarkGrey};
`