import { Button, Flex } from 'antd';
import styled from '@emotion/styled';

import { IconWarningTransparent } from '@assets';

export const Title = styled.span`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`

export const AmountSummary = styled(Flex)`
  padding: 7px 12px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.iconWarning};
  align-items: center;
  gap: 8px;
  font-size: 12px;
`;

export const AmountLeftText = styled.span`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.iconDarkGrey};
  text-transform: capitalize;
`;

export const AmountLeftCount = styled.span`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.error};
`;

export const AddSplitButton = styled(Button)`
  font-size: 12px;
  font-weight: 600;
  padding: 5px 10px;
`

export const AttentionIconWrapper = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${({theme}) => theme.colors.error}}
`

export const IconWarning = styled(IconWarningTransparent)`
  color: ${({theme}) => theme.colors.white}
`
