import { Flex } from 'antd';
import { Dayjs } from 'dayjs';
import { FC, PropsWithChildren, useState } from 'react';

import * as S from './styled';

const DatePickerWithAction: FC<
  PropsWithChildren<{
    onChange: (date: Dayjs, dateString: string | string[]) => void;
  }>
> = ({ children, onChange }) => {
  const [open, setOpen] = useState(false);

  return (
    <Flex align="center" justify="center">
      <S.Action onClick={() => setOpen(true)}>{children}</S.Action>

      <S.DatePickerControl
        open={open}
        onChange={onChange}
        onOpenChange={setOpen}
        getPopupContainer={(trigger: HTMLElement) =>
          trigger.parentNode as HTMLElement
        }
        inputReadOnly
      />
    </Flex>
  );
};

export default DatePickerWithAction;
