import styled from '@emotion/styled';

import { Typography } from 'antd';

export const HeaderText = styled(Typography.Text)`
  &.ui-typography {
    color: ${({ theme }) => theme.colors.text400};
    font-weight: 600;
    margin-bottom: 0;
  }
`;
