import { FC } from 'react';
import { Flex, Switch } from 'antd';

import { IconLock } from '@assets';
import { useTranslate } from '@hooks';

import * as S from './styled';

type FilelessBlockProps = {
  isFileless: boolean;
  onChange?: (value: boolean) => void;
}

const FilelessBlock:FC<FilelessBlockProps> = ({isFileless, onChange}) => {
  const { translate } = useTranslate();
  return (
    <S.FilelessCard align="center" justify="center" gap={8} vertical>
      <IconLock width={24} height={24} />
      <S.FilelessTitle>
        {translate('transaction.documents.isFileless')}
      </S.FilelessTitle>
      <Flex gap={16}>
        <S.FilelessDescription>
          {translate('transaction.documents.filelessTransaction')}
        </S.FilelessDescription>
        <Switch checked={isFileless} onChange={onChange} />
      </Flex>
    </S.FilelessCard>
  );
};

export default FilelessBlock;
