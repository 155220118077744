import styled from '@emotion/styled';

import { Flex } from 'antd';

export const Container = styled(Flex)`
  width: 448px;
  min-height: 548px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 12px;
  padding: 24px;
`;

export const Circle = styled.div`
  width: 78px;
  height: 78px;
  background-color: ${({ theme }) => theme.colors.bgDarkGrey};
  border-radius: 50%;
`;

export const Line = styled.div<{ width?: number | string }>`
  width: ${({ width }) => (typeof width === 'number' ? `${width}px` : width)};
  height: 16px;
  background-color: ${({ theme }) => theme.colors.bgDarkGrey};
  border-radius: 16px;
`;
