import _ from 'lodash';
import {
  FC,
  type PropsWithChildren,
  createContext,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import type { PartialDeep } from 'type-fest';

import { Schemas } from '@api-client/generated/types';

type Incorporation = PartialDeep<
  Schemas.Incorporation & {
    signUp: Schemas.IncorporationDto['signUp'];
    login: Schemas.IncorporationDto['login'];
  }
>;

type StepParams = {
  status: 'inProgress' | 'completed' | 'errorsFound' | 'onReview' | null;
  active: boolean;
};

type ScreenParams = {
  step?: number;
  visibleSteps: boolean;
  active: boolean;
  disabled?: boolean;
};

type StepName =
  | 'COMPANY_NAME'
  | 'SHAREHOLDERS'
  | 'ONLINE_KYC'
  | 'VERIFY_DOCUMENTS'
  | 'BANK_TRANSFERRING'
  | 'REGISTRATION_IN_RCS'
  | 'INCORPORATED';

type ScreensOfCompanyName =
  | 'WELCOME'
  | 'WELCOME_LANGUAGE'
  | 'COMPANY_NAME'
  | 'DESCRIBE'
  | 'CREATE_ACCOUNT'
  | 'VERIFY_ACCOUNT'
  | 'MESSAGE_COMPLETED_COMPANY';

type ScreensOfShareHolders =
  | 'COMPANY_TYPE'
  | 'ACCOUNTING_PLAN'
  | 'SHAREHOLDERS'
  | 'MANAGERS'
  | 'MESSAGE_PROCESS_ONLINE_KYC';

type ScreensOfOnlineKyc =
  | 'UPLOAD_DOCUMENTS'
  | 'REVIEW_DOCUMENTS'
  | 'LEGAL_ADDRESS'
  | 'PROVIDE_ADDRESS'
  | 'MESSAGE_PROCESS_DOCUMENTS'
  | 'MESSAGE_ERROR_DOCUMENTS'
  | 'MESSAGE_COMPLETED_DOCUMENTS';

type ScreensOfVerifyDocuments =
  | 'REVIEW_SERVICES'
  | 'INVITE_SHAREHOLDERS_MANAGERS'
  | 'MESSAGE_PROCESS_VERIFY_DOCUMENTS';

type ScreensOfBankTransferring =
  | 'PAYMENT'
  | 'PAYMENT_DETAILS'
  | 'MESSAGE_PROCESS_PAYMENT_SENT'
  | 'MESSAGE_PROCESS_ACCOUNT_OPENED'
  | 'TRANSFER_SHARE'
  | 'HONOR_DECLARATION'
  | 'MESSAGE_PROCESS_SARL_S_RCS'
  | 'MESSAGE_PROCESS_PAYMENT_TO_RCS'
  | 'COMPLETED_ACCOUNTING';

type ScreensOfRegistration = 'COMPANY_READY';

export type ScreenName =
  | 'INCORPORATION'
  | ScreensOfCompanyName
  | ScreensOfShareHolders
  | ScreensOfOnlineKyc
  | ScreensOfVerifyDocuments
  | ScreensOfBankTransferring
  | ScreensOfRegistration;

type StepEntity = Record<StepName, StepParams>;

type ScreenEntity = Record<ScreenName, ScreenParams>;

type OnboardingContextProps = {
  steps: StepEntity;
  screens: ScreenEntity;
  currentScreenName: ScreenName;
  incorporationDetails: Incorporation | null;
  paymentIntentSecret: string | null;
  initialIncorporation: (details: Incorporation) => void;
  updateIncorporation: (details: Incorporation) => void;
  updateInitialIncorporation: () => void;
  updateScreen: (name: ScreenName, params: Partial<ScreenParams>) => void;
  updateStep: (name: StepName, params: Partial<StepParams>) => void;
  allPeopleHaveEmails: () => boolean;
  isIncorporation: () => boolean;
};

const steps: StepEntity = {
  COMPANY_NAME: {
    status: null,
    active: false,
  },
  SHAREHOLDERS: {
    status: null,
    active: false,
  },
  ONLINE_KYC: {
    status: null,
    active: false,
  },
  VERIFY_DOCUMENTS: {
    status: null,
    active: false,
  },
  BANK_TRANSFERRING: {
    status: null,
    active: false,
  },
  REGISTRATION_IN_RCS: {
    status: null,
    active: false,
  },
  INCORPORATED: {
    status: null,
    active: false,
  },
};

const screens: ScreenEntity = {
  INCORPORATION: {
    visibleSteps: false,
    active: false,
  },

  WELCOME: {
    visibleSteps: false,
    active: false,
  },
  WELCOME_LANGUAGE: {
    visibleSteps: false,
    active: false,
  },
  COMPANY_NAME: {
    step: 1,
    visibleSteps: true,
    active: false,
  },
  DESCRIBE: {
    step: 2,
    visibleSteps: true,
    active: false,
  },
  CREATE_ACCOUNT: {
    step: 3,
    visibleSteps: true,
    active: false,
  },
  VERIFY_ACCOUNT: {
    visibleSteps: false,
    active: false,
  },
  MESSAGE_COMPLETED_COMPANY: {
    visibleSteps: false,
    active: false,
  },

  COMPANY_TYPE: {
    step: 1,
    visibleSteps: true,
    active: false,
  },
  ACCOUNTING_PLAN: {
    step: 1,
    visibleSteps: true,
    active: false,
  },
  SHAREHOLDERS: {
    step: 2,
    visibleSteps: true,
    active: false,
  },
  MANAGERS: {
    step: 3,
    visibleSteps: true,
    active: false,
  },
  MESSAGE_PROCESS_ONLINE_KYC: {
    visibleSteps: false,
    active: false,
  },

  UPLOAD_DOCUMENTS: {
    step: 1,
    visibleSteps: true,
    active: false,
  },
  REVIEW_DOCUMENTS: {
    step: 2,
    visibleSteps: true,
    active: false,
  },
  LEGAL_ADDRESS: {
    step: 3,
    visibleSteps: true,
    active: false,
  },
  PROVIDE_ADDRESS: {
    step: 3,
    visibleSteps: true,
    active: false,
  },
  MESSAGE_PROCESS_DOCUMENTS: {
    visibleSteps: false,
    active: false,
  },
  MESSAGE_ERROR_DOCUMENTS: {
    visibleSteps: false,
    active: false,
  },
  MESSAGE_COMPLETED_DOCUMENTS: {
    visibleSteps: false,
    active: false,
  },

  REVIEW_SERVICES: {
    step: 1,
    visibleSteps: true,
    active: false,
  },
  INVITE_SHAREHOLDERS_MANAGERS: {
    step: 2,
    visibleSteps: true,
    active: false,
  },
  MESSAGE_PROCESS_VERIFY_DOCUMENTS: {
    visibleSteps: false,
    active: false,
  },

  PAYMENT: {
    step: 1,
    visibleSteps: true,
    active: false,
  },
  PAYMENT_DETAILS: {
    step: 2,
    visibleSteps: true,
    active: false,
  },
  MESSAGE_PROCESS_PAYMENT_SENT: {
    visibleSteps: false,
    active: false,
  },
  MESSAGE_PROCESS_ACCOUNT_OPENED: {
    visibleSteps: false,
    active: false,
  },
  TRANSFER_SHARE: {
    visibleSteps: true,
    active: false,
  },
  MESSAGE_PROCESS_PAYMENT_TO_RCS: {
    visibleSteps: false,
    active: false,
  },
  HONOR_DECLARATION: {
    visibleSteps: false,
    active: false,
  },
  MESSAGE_PROCESS_SARL_S_RCS: {
    visibleSteps: false,
    active: false,
  },
  COMPLETED_ACCOUNTING: {
    visibleSteps: false,
    active: false,
  },

  COMPANY_READY: {
    visibleSteps: false,
    active: false,
  },
};

export const OnboardingContext = createContext<OnboardingContextProps>(
  {} as OnboardingContextProps
);

export const OnboardingProvider: FC<PropsWithChildren> = ({ children }) => {
  const [initialDetails, setInitialDetails] = useState<Incorporation | null>(
    null
  );
  const [details, setDetails] = useState<Incorporation | null>(null);

  const [stepsList, setStepsList] = useState<StepEntity>(steps);

  const [screensList, setScreensList] = useState<ScreenEntity>(screens);

  const [paymentIntentSecret, setPaymentIntentSecret] = useState<string | null>(
    null
  );

  const [searchParams] = useSearchParams();

  const [currentScreenName, setCurrentScreenName] =
    useState<ScreenName>('WELCOME_LANGUAGE');

  const allPeopleHaveEmails = useCallback(
    () =>
      (details?.people || [])
        .filter((person) => !person.isCompanyItself)
        .filter((person) => !person.email)?.length === 0,
    [details?.people]
  );

  const isIncorporation = useCallback(
    () => details?.incorporationType === 'incorporation',
    [details?.incorporationType]
  );

  const updateScreenListByName = useCallback(
    (name: ScreenName, params: Partial<ScreenParams>) => {
      setCurrentScreenName(name);

      setScreensList((prevState) => ({
        ..._.mapValues(prevState, (screen) => ({
          ...screen,
          active: false,
        })),
        [name]: {
          ...prevState[name],
          ...params,
        },
      }));
    },
    []
  );

  const updateStepByName = useCallback(
    (name: StepName, params: Partial<StepParams>) => {
      setStepsList((prevState) => ({
        ..._.mapValues(prevState, (screen) => ({
          ...screen,
          active: false,
        })),
        [name]: {
          ...prevState[name],
          ...params,
        },
      }));
    },
    []
  );

  useEffect(() => {
    if (!initialDetails) {
      updateScreenListByName('WELCOME_LANGUAGE', { active: true });
      updateStepByName('COMPANY_NAME', { active: true });
    }

    setPaymentIntentSecret(searchParams.get('payment_intent_client_secret'));

    if (initialDetails) {
      const {
        group,
        companyType,
        people,
        files,
        isPaid,
        isPaying,
        isConfirmedEmail,
        bankAccountData,
        isTransferedShareCapital,
        isSubmittedToKyc,
        isKycFailed,
        isAddressProofFailed,
        isCheckComplete,
        isAgreementSigned,
      } = initialDetails;

      const isSignup = group === 'signup';
      const isShareholders = group === 'shareholders';
      const isOnlineKyc = group === 'online-kyc';
      const isVerifyDocuments = group === 'verify-documents';
      const isPayment = group === 'payment';
      const isRcs = group === 'rcs';
      const isIncorporated = group === 'incorporated';

      if (isShareholders) {
        updateStepByName('COMPANY_NAME', { status: 'completed' });
      }

      if (isOnlineKyc) {
        updateStepByName('SHAREHOLDERS', { status: 'completed' });
        updateStepByName('COMPANY_NAME', { status: 'completed' });
      }

      if (isVerifyDocuments) {
        updateStepByName('ONLINE_KYC', { status: 'completed' });
        updateStepByName('SHAREHOLDERS', { status: 'completed' });
        updateStepByName('COMPANY_NAME', { status: 'completed' });
      }

      if (isPayment) {
        updateStepByName('VERIFY_DOCUMENTS', { status: 'completed' });
        updateStepByName('ONLINE_KYC', { status: 'completed' });
        updateStepByName('SHAREHOLDERS', { status: 'completed' });
        updateStepByName('COMPANY_NAME', { status: 'completed' });
      }

      if (isRcs) {
        updateStepByName('VERIFY_DOCUMENTS', { status: 'completed' });
        updateStepByName('ONLINE_KYC', { status: 'completed' });
        updateStepByName('SHAREHOLDERS', { status: 'completed' });
        updateStepByName('COMPANY_NAME', { status: 'completed' });
        updateStepByName('BANK_TRANSFERRING', { status: 'completed' });
      }

      if (isIncorporated) {
        updateStepByName('VERIFY_DOCUMENTS', { status: 'completed' });
        updateStepByName('ONLINE_KYC', { status: 'completed' });
        updateStepByName('SHAREHOLDERS', { status: 'completed' });
        updateStepByName('COMPANY_NAME', { status: 'completed' });
        updateStepByName('BANK_TRANSFERRING', { status: 'completed' });
        updateStepByName('REGISTRATION_IN_RCS', { status: 'completed' });
      }

      if (isIncorporated) {
        updateStepByName('INCORPORATED', {
          active: true,
          status: 'inProgress',
        });
        updateScreenListByName('INCORPORATION', { active: true });

        return;
      }

      if (!isIncorporation() && isAgreementSigned) {
        updateStepByName('BANK_TRANSFERRING', {
          active: true,
          status: 'completed',
        });
        updateScreenListByName('COMPLETED_ACCOUNTING', { active: true });

        return;
      }

      if (isRcs) {
        updateStepByName('REGISTRATION_IN_RCS', {
          active: true,
          status: 'inProgress',
        });
        updateScreenListByName('INCORPORATION', { active: true });

        return;
      }

      if (isPayment && isTransferedShareCapital) {
        updateStepByName('BANK_TRANSFERRING', {
          active: true,
          status: 'inProgress',
        });
        updateScreenListByName('INCORPORATION', { active: true });

        return;
      }

      if (isPayment && bankAccountData && isPaid) {
        updateStepByName('BANK_TRANSFERRING', {
          active: true,
          status: 'inProgress',
        });
        updateScreenListByName('INCORPORATION', { active: true });

        return;
      }

      if (isPayment && isPaying) {
        updateStepByName('BANK_TRANSFERRING', {
          active: true,
          status: 'inProgress',
        });
        updateScreenListByName('MESSAGE_PROCESS_PAYMENT_SENT', {
          active: true,
        });

        return;
      }

      if (isPayment && !isPaid && paymentIntentSecret) {
        updateStepByName('BANK_TRANSFERRING', {
          active: true,
          status: 'inProgress',
        });
        updateScreenListByName('PAYMENT_DETAILS', { active: true });

        return;
      }

      if (isPayment && isPaid) {
        updateStepByName('BANK_TRANSFERRING', {
          active: true,
          status: 'inProgress',
        });
        updateScreenListByName('INCORPORATION', { active: true });

        return;
      }

      if (isPayment && !isPaid) {
        updateStepByName('BANK_TRANSFERRING', { active: true });
        updateScreenListByName('INCORPORATION', { active: true });

        return;
      }

      if (isVerifyDocuments && allPeopleHaveEmails()) {
        updateStepByName('VERIFY_DOCUMENTS', {
          active: true,
          status: 'inProgress',
        });
        updateScreenListByName('INCORPORATION', { active: true });

        return;
      }

      if (isVerifyDocuments) {
        updateStepByName('VERIFY_DOCUMENTS', { active: true });
        updateScreenListByName('INCORPORATION', { active: true });

        return;
      }

      if (isOnlineKyc && isCheckComplete) {
        updateStepByName('ONLINE_KYC', { active: true });
        updateScreenListByName('MESSAGE_COMPLETED_DOCUMENTS', { active: true });

        return;
      }

      if (isOnlineKyc && (isKycFailed || isAddressProofFailed)) {
        updateStepByName('ONLINE_KYC', { active: true, status: 'errorsFound' });
        updateScreenListByName('INCORPORATION', { active: true });

        return;
      }

      if (isOnlineKyc && isSubmittedToKyc) {
        updateStepByName('ONLINE_KYC', { active: true, status: 'onReview' });
        updateScreenListByName('INCORPORATION', { active: true });

        return;
      }

      if (isOnlineKyc && !_.isEmpty(files)) {
        updateStepByName('ONLINE_KYC', { active: true });
        updateScreenListByName('UPLOAD_DOCUMENTS', { active: true });

        return;
      }

      if (isOnlineKyc && _.isEmpty(files)) {
        updateStepByName('ONLINE_KYC', { active: true });
        updateScreenListByName('INCORPORATION', { active: true });

        return;
      }

      if (
        isShareholders &&
        !_.isEmpty((people || []).filter((item) => item.isDirector))
      ) {
        updateStepByName('SHAREHOLDERS', { status: 'completed' });
        updateStepByName('ONLINE_KYC', { active: true });

        updateScreenListByName('INCORPORATION', { active: true });

        return;
      }

      if (isShareholders && !_.isEmpty(people)) {
        updateStepByName('SHAREHOLDERS', { active: true });

        updateScreenListByName('SHAREHOLDERS', { active: true });

        return;
      }

      if (isShareholders && companyType) {
        updateStepByName('SHAREHOLDERS', { active: true });

        updateScreenListByName('SHAREHOLDERS', { active: true });

        return;
      }

      if (isShareholders) {
        updateStepByName('COMPANY_NAME', { status: 'completed' });
        updateStepByName('SHAREHOLDERS', { active: true });

        updateScreenListByName('INCORPORATION', { active: true });

        return;
      }

      if (isSignup && !isConfirmedEmail) {
        updateStepByName('COMPANY_NAME', {
          active: true,
          status: 'inProgress',
        });

        updateScreenListByName('VERIFY_ACCOUNT', { active: true });

        return;
      }
    }
  }, [initialDetails]);

  const initialIncorporation = useCallback((details: Incorporation) => {
    setDetails(details);
    setInitialDetails(details);
  }, []);

  const updateInitialIncorporation = useCallback(
    () => setInitialDetails(details),
    [details]
  );

  const updateIncorporation = useCallback(
    (updatedFields: Incorporation) =>
      setDetails((prevState) => ({
        ...(prevState || {}),
        ...updatedFields,
      })),
    []
  );

  return (
    <OnboardingContext.Provider
      value={{
        steps: stepsList,
        screens: screensList,
        incorporationDetails: details,
        currentScreenName,
        paymentIntentSecret,
        initialIncorporation,
        updateIncorporation,
        updateInitialIncorporation,
        updateScreen: updateScreenListByName,
        updateStep: updateStepByName,
        allPeopleHaveEmails,
        isIncorporation,
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};
