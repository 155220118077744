import styled from '@emotion/styled';

import { InputNumber, Typography } from 'antd';

type FormLabelProps = {
  align?: 'center';
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  .ui-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;

    .ui-switch {
      margin: 0 auto;
    }

    .ui-select-borderless {
      height: 22px;
      pointer-events: none;

      .ui-select-selector {
        padding: 0;
      }

      .ui-select-arrow {
        display: none;
      }
    }

    .ui-input-borderless {
      height: 22px;
      pointer-events: none;
      padding: 0;
    }
  }
`;

export const Inner = styled.div`
  margin-bottom: 24px;
`;

export const FormInner = styled.div`
  background-color: ${({ theme }) => theme.colors.bgGrey};
  border-radius: 8px;
  padding: 14px 20px;
  margin-bottom: 16px;
`;

export const FormItem = styled.div`
  padding: 14px 0;

  &:not(:last-child) {
    border-bottom: 2px solid ${({ theme }) => theme.colors.strokeDarkGrey};
  }
`;

export const FormLabel = styled.div<FormLabelProps>`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 12px;
  padding: 0;
  text-align: ${({ align }) => (align === 'center' ? 'center' : 'left')};
`;

export const ButtonAdd = styled.a`
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 600;

  .ui-icon {
    font-size: 16px;
  }
`;

export const Total = styled.div`
  color: ${({ theme }) => theme.colors.secondary};
`;

export const Wrapper = styled.div`
  width: 20px;
  height: 20px;
`;

export const ButtonRemove = styled.a`
  color: ${({ theme }) => theme.colors.secondary};
  display: flex;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
`;

export const NumberInput = styled(InputNumber)`
  width: 100%;
`;

export const ExtraText = styled(Typography.Paragraph)`
  &.ui-typography {
    color: ${({ theme }) => theme.colors.textBlue400};
    font-size: 12px;
  }
`;
