import { FC, useState } from 'react';
import { Trans } from 'react-i18next';

import { Button, Tooltip, message } from 'antd';

import { StatusTag, PopupConfirm } from '@entities';
import { useTranslate, useAccount, useProjects } from '@hooks';
import { useDeleteProject } from '@hooks-api';
import { IconTrash } from '@assets';
import { Schemas } from '@api-client/generated/types';

import * as S from './styled';

type Project = Schemas.Project;

type ProjectItemProps = {
  project: Project;
};

const ProjectItem: FC<ProjectItemProps> = ({ project }) => {
  const { translate } = useTranslate();
  const { companyId } = useAccount();

  const [isOpenPopupConfirm, setIsOpenPopupConfirm] = useState(false);

  const { deleteProject } = useProjects();

  const [deleteProjectById] = useDeleteProject();

  const handleRemove = (id: string) =>
    deleteProjectById(
      {
        parameter: {
          id: id!,
          companyId: companyId!,
        },
      },
      {
        onSuccess: () => {
          setIsOpenPopupConfirm(false);
          deleteProject(id);

          message.open({
            type: 'success',
            content: translate('projects.success.delete'),
          });
        },
      }
    );

  return (
    <S.Wrapper>
      <PopupConfirm
        open={isOpenPopupConfirm}
        title={translate('projects.popupDelete.title')}
        description={translate('projects.popupDelete.description')}
        confirmParams={{
          text: translate('projects.popupDelete.buttonConfirm'),
        }}
        onConfirm={() => handleRemove(project.id)}
        onCancel={() => setIsOpenPopupConfirm(false)}
      />

      <S.Header>
        <S.Name to={`/projects/${project.id}`}>{project.name}</S.Name>

        <S.Count>
          <Trans
            i18nKey={translate('projects.transactionsCount')}
            components={[<span>0</span>]}
          />
        </S.Count>
      </S.Header>

      <StatusTag status={project.status} />

      <Tooltip title={translate('projects.tooltipDelete')} placement="bottom">
        <S.Remove>
          <Button
            type="text"
            icon={<IconTrash />}
            size="small"
            onClick={() => setIsOpenPopupConfirm(true)}
          />
        </S.Remove>
      </Tooltip>
    </S.Wrapper>
  );
};

export default ProjectItem;
