import styled from '@emotion/styled';

import { Layout, type LayoutProps } from 'antd';

import { layout } from '@theme';

export const Container = styled(Layout)<LayoutProps>`
  min-height: 100vh;
  padding-left: ${`${layout.sidebarWidth}px`};
`;

export const TransactionsImportContent = styled.div`
  display: flex;
  height: 100%;
`

export const Content = styled(Layout.Content)<LayoutProps & {pathname: string}>`
  padding: ${({pathname}) => pathname.includes('transactions/') ? '50px 0 0 0' : '50px'};
`;
