import styled from '@emotion/styled';
import { Flex, TreeSelect } from 'antd';

export const Label = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.secondary};
  margin-bottom: 4px;
`;

export const SelectContainer = styled(Flex)`
  flex-direction: column;
  justify-content: flex-start;
  position: relative;

  .ui-category-filter-select {
    .ui-select-selector {
      .ui-select-selection-overflow {
        .ui-select-selection-search {
          max-width: 240px;
          margin-left: 8px;
        }
      }
    }
  }
`;

export const SelectTree = styled(TreeSelect)`
  && {
    .ui-select-selector {
      min-height: 40px;
      height: auto;
    }

    .ui-select-selection-item {
      min-height: 32px;
      align-items: center;
    }
  }
`;

export const DisplayedValue = styled.p`
  height: 41px;
  position: absolute;
  bottom: -24px;
  left: 11px;
  cursor: pointer;
`;

export const SingleValue = styled.div`
  margin-left: 6px;
  width: 100%;
  max-width: 320px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
