import { FC, useState } from 'react';

import { Button, Flex, Modal, type ModalProps } from 'antd';

import { RadioGroup } from '@components';

import { useTranslate } from '@hooks';

import { vatTypes } from '@constants';

import * as S from './styled';

type SelectVatTypeProps = {
  value?: string;
  onCancel: () => void;
  onChange: (type: string) => void;
} & ModalProps;

const SelectVatType: FC<SelectVatTypeProps> = ({
  value,
  open,
  onChange,
  onCancel,
  ...rest
}) => {
  const { translate, tDynamic } = useTranslate();

  const [selectedType, setSelectedType] = useState(value || vatTypes[0]);

  const handleCancel = () => {
    setSelectedType(vatTypes[0]);
    onCancel();
  };

  const handleSubmit = () => {
    onChange(selectedType);
    onCancel();
  };

  return (
    <Modal
      width={575}
      title={
        <Flex gap={8} vertical>
          {translate('common.vatType.title')}

          <S.Description>
            {translate('common.vatType.description')}
          </S.Description>
        </Flex>
      }
      open={open}
      footer={null}
      onCancel={handleCancel}
      destroyOnClose
      centered
      {...rest}
    >
      <Flex gap={40} vertical>
        <RadioGroup
          defaultValue={selectedType}
          controls={vatTypes.map((type) => ({
            title: tDynamic(`common.vatType.${type}.title`),
            content: tDynamic(`common.vatType.${type}.description`),
            value: type,
          }))}
          size="large"
          onChange={setSelectedType}
          vertical
        />

        <Flex justify="flex-end" gap={12}>
          <Button onClick={handleCancel}>
            {translate('common.vatType.buttonCancel')}
          </Button>

          <Button type="primary" onClick={handleSubmit}>
            {translate('common.vatType.buttonSelect')}
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default SelectVatType;
