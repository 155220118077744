import { Flex } from 'antd';

import styled from '@emotion/styled';

export const Wrapper = styled(Flex)`
  margin-bottom: 12px;

  && {
    .ui-table {
      min-height: 406px;
    }

    .ui-table-body {
      overflow-x: hidden;
      scrollbar-width: none;
      -ms-overflow-style: none;
    }

    .ui-table-body {
      &&::-webkit-scrollbar {
        display: none;
      }

      .ui-table-cell {
        vertical-align: middle;
      }
    }
  }
`;
