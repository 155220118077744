import { FC } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ConfigProvider, App as AppDesign } from 'antd';
import { ThemeProvider } from '@emotion/react';
import { IoProvider } from 'socket.io-react-hook';
import { ErrorBoundary } from '@sentry/react';
import { IntercomProvider } from 'react-use-intercom';

import AppRouter from './AppRouter';
import { ErrorFallback } from '@entities/errorBoundary/ErrorFallback';

import { AccountProvider } from '@context';
import { GlobalStyles } from '@styles/GlobalStyles';
import { config, colors } from '@theme';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

const queryClient = new QueryClient();

const intercomAppId = import.meta.env.VITE_ROUTE_INTERCOM_APP_ID;
const intercomApiBase = import.meta.env.VITE_ROUTE_INTERCOM_API_BASE;

const App: FC = () => (
  <ErrorBoundary
    fallback={(errorData) => <ErrorFallback {...errorData} fullPage />}
  >
    <QueryClientProvider client={queryClient}>
      <IoProvider>
        <ConfigProvider prefixCls="ui" iconPrefixCls="ui-icon" theme={config}>
          <ThemeProvider theme={{ colors }}>
            <IntercomProvider appId={intercomAppId} apiBase={intercomApiBase}>
              <AppDesign>
                <GlobalStyles />
                <AccountProvider>
                  <DndProvider backend={HTML5Backend}>
                    <AppRouter />
                  </DndProvider>
                </AccountProvider>
              </AppDesign>
            </IntercomProvider>
          </ThemeProvider>
        </ConfigProvider>
      </IoProvider>
    </QueryClientProvider>
  </ErrorBoundary>
);

export default App;
