import { FC } from 'react';

import * as S from './styled';
import { useTranslate } from '@hooks';
import { Flex } from 'antd';

type EmptyDocumentTransactionsProps = {
  actionButton?: JSX.Element;
};

const EmptyDocumentTransactions: FC<EmptyDocumentTransactionsProps> = ({
  actionButton,
}) => {
  const { translate } = useTranslate();
  return (
    <S.Container justify="center" align="center" vertical gap={30}>
      <Flex vertical gap={15} align="center">
        <S.Title level={4}>
          {translate('documentsPage.messages.noTransactions')}
        </S.Title>
        <S.Description>
          {translate('documentsPage.messages.shouldAttachTransactions')}
        </S.Description>
      </Flex>

      {actionButton}
    </S.Container>
  );
};

export default EmptyDocumentTransactions;
