import { Flex, type FlexProps, Typography } from 'antd';

import styled from '@emotion/styled';

type ContactProps = {
  selected: boolean;
};

export const Contact = styled(Flex)<FlexProps & ContactProps>`
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.strokeGrey : theme.colors.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.strokeDarkGrey};
  cursor: pointer;
  padding: 16px;
  position: relative;
`;

export const Title = styled(Typography.Paragraph)`
  && {
    font-weight: 600;
    font-size: 16px;
    flex: 1;
    margin-bottom: 0;
  }
`;

export const VatId = styled.div`
  min-height: 22px;
`;

export const Stat = styled(Flex)<FlexProps>`
  min-width: 60px;
  font-size: 12px;
  font-weight: 600;

  svg {
    color: ${({ theme }) => theme.colors.text400};
  }
`;
