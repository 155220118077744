import { Flex, Tabs } from 'antd';
import { FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Schemas } from '@api-client/generated/types';
import { IconEditUnderline } from '@assets';
import { Loader } from '@components';
import {
  ContactsAlertDelete,
  ContactsDetails,
  ContactsPaymentDocs,
  ContactsSkeleton,
  ContactsSupplementaryDocs,
  ContactsTransactions,
} from '@entities';
import { useAccount, useTranslate } from '@hooks';
import { useDeleteContact, useGetDocumentsList } from '@hooks-api';

import * as S from './styled';

type ContactsCardProps = {
  filesCount: number;
  transactionsCount: number;
  details: Schemas.Contact | null;
  loading?: boolean;
  onEdit: () => void;
  onAfterDelete: () => void;
};

const ContactsCard: FC<ContactsCardProps> = ({
  filesCount,
  transactionsCount,
  details,
  loading,
  onEdit,
  onAfterDelete,
}) => {
  const navigate = useNavigate();
  const { id: contactId } = useParams();

  const { companyId } = useAccount();
  const { tDynamic } = useTranslate();

  const [selectedKey, setSelectedKey] = useState('transactions');

  const [deleteContact] = useDeleteContact();

  const { data: paymentDocs } = useGetDocumentsList({
    params: {
      companyId: companyId!,
      contactIds: contactId,
      withMetadataOnly: true,
    },
  });

  const { data: supplementaryDocs } = useGetDocumentsList({
    params: {
      companyId: companyId!,
      contactIds: contactId,
      withoutMetadataOnly: true,
    },
  });

  const handleDeleteContact = () => {
    if (details) {
      deleteContact(
        {
          parameter: {
            companyId: companyId!,
            id: details.id,
          },
        },
        {
          onSuccess: () => {
            onAfterDelete();
            navigate('/contacts');
          },
        }
      );
    }
  };

  if (loading) {
    return (
      <S.Container justify="center" align="center">
        <Loader />
      </S.Container>
    );
  }

  if (!details) {
    return (
      <S.Container gap={24} vertical>
        <ContactsSkeleton />
      </S.Container>
    );
  }

  const tabsItems = [
    {
      key: 'transactions',
      count: transactionsCount,
      component: <ContactsTransactions />,
    },
    {
      key: 'paymentDocs',
      count: paymentDocs?.metadata.totalRecords,
      component: <ContactsPaymentDocs />,
    },
    {
      key: 'supplementaryDocs',
      count: supplementaryDocs?.metadata.totalRecords,
      component: <ContactsSupplementaryDocs />,
    },
    {
      key: 'details',
      component: <ContactsDetails details={details} />,
    },
  ];

  return (
    <S.Container gap={24} vertical>
      <Flex align="center" justify="space-between" gap={24}>
        <Flex gap={4} vertical>
          <Flex align="center" gap={8}>
            <S.Title>{details.name}</S.Title>

            <S.ButtonEdit
              type="text"
              icon={<IconEditUnderline />}
              size="small"
              onClick={onEdit}
            />
          </Flex>

          {details.contactPerson && (
            <S.Description>{details.contactPerson}</S.Description>
          )}
        </Flex>

        {!filesCount && !transactionsCount && (
          <ContactsAlertDelete onDelete={handleDeleteContact} />
        )}
      </Flex>

      <Tabs
        activeKey={selectedKey}
        size="large"
        items={tabsItems.map((tab) => ({
          key: tab.key,
          label: (
            <Flex align="center" gap={8}>
              {tDynamic(`contactsPage.tabs.${tab.key}`)}

              {(tab.count || tab.count === 0) && (
                <S.Count selected={selectedKey === tab.key}>
                  {tab.count}
                </S.Count>
              )}
            </Flex>
          ),
          children: tab.component,
        }))}
        onChange={setSelectedKey}
      />
    </S.Container>
  );
};

export default ContactsCard;
