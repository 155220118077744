import { FC } from 'react';
import { Trans } from 'react-i18next';

import { Button, Typography } from 'antd';

import { useAccount, useOnboarding, useTranslate } from '@hooks';
import { IconSuccessCircle, IconClock } from '@assets';
import { colors } from '@theme';

import * as S from './styled';
import { useUpdateIncorporation } from '@hooks-api';

type OnboardingCompanyTypeAccountingCardProps = {
  price?: number;
  planId: number;
};

const { Text } = Typography;

const OnboardingCompanyTypeAccountingCard: FC<
  OnboardingCompanyTypeAccountingCardProps
> = ({ price, planId }) => {
  const { translate, tObjects } = useTranslate();

  const { updateScreen, updateIncorporation } = useOnboarding();
  const { companyId } = useAccount();

  const [update, isLoading] = useUpdateIncorporation();

  const listSoftware = tObjects(`onboarding.accountingService.listSoftware`, {
    returnObjects: true,
  });

  const listBookkeeping = tObjects(
    `onboarding.accountingService.listBookkeeping`,
    {
      returnObjects: true,
    }
  );

  const listExpert = tObjects(`onboarding.accountingService.listExpert`, {
    returnObjects: true,
  });

  const listTax = tObjects(`onboarding.accountingService.listTax`, {
    returnObjects: true,
  });

  const handleContinue = () => {
    const body = {
      accountingPlanId: planId,
    };
    update(
      {
        parameter: {
          companyId: companyId!,
        },
        requestBody: body,
      },
      {
        onSuccess: () => {
          updateScreen('SHAREHOLDERS', { active: true });
          updateIncorporation(body);
        },
      }
    );
  };

  return (
    <S.Card>
      <S.Inner>
        <S.Title level={3}>
          {translate(`onboarding.accountingService.title`)}
        </S.Title>

        <S.Description>
          {translate(`onboarding.accountingService.description`)}
        </S.Description>
      </S.Inner>

      <S.Price>
        <S.PriceTitle level={4}>
          {price
            ? translate(`onboarding.accountingService.price`, {
                value: `€${price}`,
              })
            : translate(`onboarding.accountingService.customPrice`)}
        </S.PriceTitle>

        <S.PriceSubtitle>
          {translate(`onboarding.accountingService.billedMonthly`)}
        </S.PriceSubtitle>

        <S.PriceDetails>
          <S.PriceDetailsTitle level={4}>
            {translate(`onboarding.accountingService.subTitleSoftware`)}
          </S.PriceDetailsTitle>

          {listSoftware.map((item, index) => (
            <S.PriceDetailsItem key={item}>
              {index < 4 ? (
                <IconSuccessCircle color={colors.bgDarkBlue} />
              ) : (
                <S.Soon>
                  <IconClock />
                  {translate(`onboarding.accountingService.soon`)}
                </S.Soon>
              )}
              {item}
            </S.PriceDetailsItem>
          ))}
        </S.PriceDetails>

        <S.PriceDetails>
          <S.PriceDetailsTitle level={4}>
            {translate(`onboarding.accountingService.subTitleBookkeeping`)}
          </S.PriceDetailsTitle>

          {listBookkeeping.map((item) => (
            <S.PriceDetailsItem key={item}>
              <IconSuccessCircle color={colors.bgDarkBlue} /> {item}
            </S.PriceDetailsItem>
          ))}
        </S.PriceDetails>

        <S.PriceDetails>
          <S.PriceDetailsTitle level={4}>
            {translate(`onboarding.accountingService.subTitleExpert`)}
          </S.PriceDetailsTitle>

          {listExpert.map((item) => (
            <S.PriceDetailsItem key={item}>
              <IconSuccessCircle color={colors.bgDarkBlue} /> {item}
            </S.PriceDetailsItem>
          ))}
        </S.PriceDetails>

        <S.PriceDetails>
          <S.PriceDetailsTitle level={4}>
            {translate(`onboarding.accountingService.subTitleTax`)}
          </S.PriceDetailsTitle>

          {listTax.map((item) => (
            <S.PriceDetailsItem key={item}>
              <IconSuccessCircle color={colors.bgDarkBlue} /> {item}
            </S.PriceDetailsItem>
          ))}
        </S.PriceDetails>

        <Button
          type="primary"
          onClick={handleContinue}
          loading={isLoading}
          block
        >
          {translate('onboarding.buttonContinue')}
        </Button>
      </S.Price>

      <S.Notes>
        <S.NotesTitle level={4}>
          {translate('onboarding.accountingService.notes.title')}
        </S.NotesTitle>

        <S.NotesList>
          <S.NotesItem>
            <Trans
              i18nKey={translate(
                'onboarding.accountingService.notes.consultation'
              )}
              components={[<Text />]}
              tOptions={{
                value: '€110-450',
              }}
            />
          </S.NotesItem>

          <S.NotesItem>
            <Trans
              i18nKey={translate('onboarding.accountingService.notes.coSec')}
              components={[<Text />]}
              tOptions={{
                value: '€200',
              }}
            />
          </S.NotesItem>

          <S.NotesItem>
            <Trans
              i18nKey={translate('onboarding.accountingService.notes.shared')}
              components={[<Text />]}
              tOptions={{
                value: '€650',
              }}
            />
          </S.NotesItem>

          <S.NotesItem>
            <Trans
              i18nKey={translate('onboarding.accountingService.notes.director')}
              components={[<Text />]}
              tOptions={{
                value: '€2500',
              }}
            />
          </S.NotesItem>

          <S.NotesItem>
            <Trans
              i18nKey={translate('onboarding.accountingService.notes.payroll')}
              components={[<Text />]}
              tOptions={{
                value: '€24',
              }}
            />
          </S.NotesItem>

          <S.NotesItem>
            <Trans
              i18nKey={translate('onboarding.accountingService.notes.meeting')}
              components={[<Text />]}
              tOptions={{
                value: '€750',
              }}
            />
          </S.NotesItem>
        </S.NotesList>
      </S.Notes>
    </S.Card>
  );
};

export default OnboardingCompanyTypeAccountingCard;
