import styled from '@emotion/styled';

export const Container = styled.div`
  max-width: 881px;
  width: calc(100vw - 560px);
  max-height: 625px;

  .ui-table {
    .ui-table-thead {
      .ui-table-cell {
        padding-block: 0;
      }
    }

    .row-disabled {
      opacity: 0.4;
    }
  }
`;

export const ErrorIcon = styled.div`
  width: 20px;
  height: 20px;
  background-color: ${({ theme }) => theme.colors.error};
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.white};
  display: inline-flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 12px;
    height: 10px;
  }
`;
