import { Params$CategoriesController_createCategory } from '@api-client/generated/client';
import { useApiClientMutation } from '@hooks';

const useCreateCategory = () => {
  return useApiClientMutation<Params$CategoriesController_createCategory>({
    method: 'CategoriesController_createCategory',
  });
};

export default useCreateCategory;
