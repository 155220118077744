import { Schemas } from '@api-client/generated/types';

type CategoryGroupsType = {
  operational: CategoryGroupType;
  financial: CategoryGroupType;
  investments: CategoryGroupType;
};

type CategoryGroupType = {
  money_in?: Schemas.CategoryGroup[];
  money_out?: Schemas.CategoryGroup[];
};

type ExtractedGroup = {
  label: string;
  title: string;
  id: string;
  options?: { label: string; value: string; id: string }[];
};

// TODO that should not be a hook
const useExtractCategoryGroups = (categoryGroups?: CategoryGroupsType) => {
  let combinedGroups: Schemas.CategoryGroup[] = [];

  const combineFlowsFromGroup = (group: CategoryGroupType) => {
    if (group.money_in && Array.isArray(group.money_in)) {
      combinedGroups = combinedGroups.concat(group.money_in);
    }
    if (group.money_out && Array.isArray(group.money_out)) {
      combinedGroups = combinedGroups.concat(group.money_out);
    }
  };

  for (const groupKey in categoryGroups) {
    if (Object.prototype.hasOwnProperty.call(categoryGroups, groupKey)) {
      combineFlowsFromGroup(
        categoryGroups[groupKey as keyof CategoryGroupsType]
      );
    }
  }

  const extractedGroupsWithChildren: ExtractedGroup[] = combinedGroups.map(
    (group) => ({
      label: group.name,
      value: group.id,
      title: group.name,
      id: group.id,
      options: group.categories.map((category: Schemas.Category) => ({
        label: category.name,
        value: category.id,
        id: category.id,
      })),
    })
  );

  const findParentsOrChildren = (ids: string[]): ExtractedGroup[] => {
    const results: ExtractedGroup[] = [];

    const searchGroups = (
      groups: ExtractedGroup[],
      id: string
    ): ExtractedGroup | null => {
      for (const group of groups) {
        if (group.id === id) {
          return group;
        }
        if (group.options) {
          for (const option of group.options) {
            if (option.id === id) {
              return group;
            }
          }
        }
      }
      return null;
    };

    ids.forEach((id) => {
      const result = searchGroups(extractedGroupsWithChildren, id);
      if (result && !results.includes(result)) {
        results.push(result);
      }
    });

    return results;
  };

  const findById = (id: string): ExtractedGroup | null => {
    const searchGroups = (
      groups: ExtractedGroup[],
      id: string
    ): ExtractedGroup | null => {
      for (const group of groups) {
        if (group.id === id) {
          return group;
        }
        if (group.options) {
          for (const option of group.options) {
            if (option.id === id) {
              return option as any;
            }
          }
        }
      }
      return null;
    };

    return searchGroups(extractedGroupsWithChildren, id);
  };

  return { extractedGroupsWithChildren, findParentsOrChildren, findById };
};

export default useExtractCategoryGroups;
