import { FC } from 'react';
import { Trans } from 'react-i18next';

import { Typography, Button, Flex } from 'antd';

import { OnboardingMessage } from '@entities';
import { useTranslate, useOnboarding } from '@hooks';

import { MessageProcessDocuments } from '@assets';

import * as S from './styled';

const { Paragraph, Text } = Typography;

const OnboardingMessageProcessDocuments: FC = () => {
  const { translate } = useTranslate();
  const { updateScreen, updateStep } = useOnboarding();

  const handleContinue = () => {
    updateStep('ONLINE_KYC', { status: 'onReview' });
    updateScreen('INCORPORATION', { active: true });
  };

  return (
    <S.Container>
      <OnboardingMessage
        picture={<MessageProcessDocuments />}
        title={translate('onboarding.messages.processDocuments.title')}
        description={
          <Trans
            i18nKey={translate(
              'onboarding.messages.processDocuments.description'
            )}
            components={[<Paragraph />, <Text strong />]}
          />
        }
      />

      <Flex justify="flex-end">
        <Button type="primary" onClick={handleContinue}>
          {translate('onboarding.buttonContinue')}
        </Button>
      </Flex>
    </S.Container>
  );
};

export default OnboardingMessageProcessDocuments;
