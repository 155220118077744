import styled from '@emotion/styled';

import { type FlexProps, Flex } from 'antd';

export const Message = styled(Flex)<FlexProps>`
  padding-left: 28px;
`;

export const MessageTitle = styled.div`
  color: ${({ theme }) => theme.colors.textBlue400};
  font-weight: 600;
  font-size: 12px;
`;

export const MessageDescription = styled.div`
  color: ${({ theme }) => theme.colors.textBlue400};
  font-size: 12px;
`;
