import styled from '@emotion/styled';
import { Button, Flex, Form, Modal } from 'antd';
import { IconEdit, IconTrash } from '@assets';

export const AccessModal = styled(Modal)`
  && {
    .ui-modal-footer {
      margin-top: 40px;
    }
  }
`;

export const ContentWrapper = styled(Flex)`
  padding: 0 16px;
`

export const Container = styled(Flex)<{ isbordered?: string }>`
  border-bottom: ${({ isbordered, theme }) =>
  isbordered?.length ? `1px solid ${theme.colors.strokeGrey}` : ''};
  padding-bottom: 16px;
`

export const EmailContainer = styled(Flex)`
  padding: 16px;
  border-radius: 12px;
  background: ${({ theme }) => theme.colors.bgGrey};
  margin: 0 -12px;
`

export const Label = styled.span`
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.textBlue400};
`

export const Name = styled.span`
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
`;

export const Email = styled.span`
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.text200};
`;

export const Edit = styled(IconEdit)`
  color: ${({ theme }) => theme.colors.text200};
  border-bottom: 2px solid ${({ theme }) => theme.colors.text200};
  width: 24px;
  height: 24px;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
`;

export const Item = styled(Form.Item)`
  margin-bottom: 20px;

  &&:last-child {
    margin-bottom: 0;
  }

  && {
    .ui-checkbox-wrapper {
      .ui-checkbox {
        .ui-checkbox-inner {
          width: 24px;
          height: 24px;

          :after {
            width: 8px;
            height: 12px;
          }
        }
      }
    }
  }
`;

export const IconRemove = styled(IconTrash)`
  color: ${({ theme }) => theme.colors.error};
  width: 24px;
  height: 24px;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
`;

export const FormTitle = styled.span`
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  margin: 0 -12px;
`;

export const FormItemTitle = styled.span`
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
`;

export const RemoveButton = styled(Button)`
  && {
    color: ${({theme}) => theme.colors.error};
    border-color: ${({theme}) => theme.colors.error};
    font-size: 16px;
    font-weight: 600;
  }
`
